import React, { useState, useEffect } from 'react';
import { useDispatch } from "react-redux"
import { getoSolicitudBase } from "../../helpers/comunicabackend_helper"
import { map } from "lodash"
import registrosApi from "./data/registrosApi"
import TablaComida from "./tabla-registro-comida"
import TablaDiapers from "./tabla-registro-diapers"
import TablaSiesta from "./tabla-registro-siesta"
import TablaBath from "./tabla-registro-bath"
import TablaAcademia from "./tabla-registro-academia"
import CardContact from "../Contacts/card-contact"
import PropTypes from 'prop-types'
import classnames from "classnames";
import 'devextreme/dist/css/dx.light.css';
import {
    Container,
    Row,
    Col,
    NavItem,
    NavLink,
    Nav,
    TabContent,
    TabPane,
    Card,
    CardBody,
    CardTitle,
    Button,
    Table,
} from "reactstrap"

const CardReporte = (props) => {

    console.log('CardReporte::props: ', props)
    console.log('CardReporte::props.listas: ', props.listas)

    return (
        <React.Fragment>
            <Card>
                <CardBody className='p-1'>
                    {/* <CardTitle >
                        <Row className="">
                            <Col xs="12">
                                CardTitle
                            </Col>
                        </Row>
                    </CardTitle> */}

                    <div className="table-responsive">
                        <Table className="table mb-0">
                            <thead>
                                <tr>
                                    <th>Lista</th>
                                    <th>Destinatarios</th>
                                </tr>
                            </thead>
                            <tbody>
                                {map(props.listas, (lista, key) => (
                                    <tr key={key}>
                                        <td>{lista.nombre}</td>
                                        <td>

                                            <tbody>
                                                {map(lista.destinatarios, (destinatario, key) => (
                                                    <tr>
                                                        <td>{destinatario.nombre}</td>
                                                        <td>{destinatario.email ? (
                                                            <i style={{color: "green"}} className="mdi mdi-18px mdi-email-check-outline m-3"></i>
                                                        ) : (
                                                            <></>
                                                        )}</td>
                                                        <td>{destinatario.mobile ? (
                                                            <i style={{color: "green"}} className="mdi mdi-18px mdi-cellphone-android m-3 "></i>
                                                        ) : (
                                                            <></>
                                                        )}</td>
                                                        {destinatario.bError ? 
                                                        (<td className='bg-danger text-white'>{destinatario.sMensaje}</td>) : 
                                                        (destinatario.bWarning ? (<td className='bg-warning text-white'>{destinatario.sMensaje}</td>) :
                                                        (<></>))}
                                                        
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </td>
                                    </tr>))}
                            </tbody>
                        </Table>
                    </div>
                </CardBody>
                {/* <CardImg bottom className="img-fluid" src={img7} alt="Skote" /> */}
            </Card>
        </React.Fragment >
    );
}


CardReporte.propTypes = {
    registroAlumno: PropTypes.any,
    employee: PropTypes.array,
    listas: PropTypes.array,
    cantidades: PropTypes.array,
    minutos: PropTypes.array,
    observaciones: PropTypes.object,
    edicion: PropTypes.bool,
    onpreview: PropTypes.func,
}

export default CardReporte

