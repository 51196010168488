//REGISTER
export const POST_FAKE_REGISTER = "/post-fake-register"

// UPLOAD FILES
export const POST_UPLOAD_FILE = "/api/photo"

// COMUNICA
export const POST_USERNAME_PASSWORD_LOGIN = "/api/authenticate/v1/login"

// REPORTE ENVIOS
export const GET_REPORTE_ENVIOS = "/api/libreta/reporteenvios/listado"
export const GET_REPORTE_ENVIO_DETALLE = "/api/libreta/reporteenvios/detalle"

// FIles
export const GET_FILE = "/api/files"
export const ADD_FILE = "/api/files"
export const ADD_FILE_BASE64 = "/api/files/base64"
export const DELETE_FILE = "/api/files"

//LOGIN
export const POST_FAKE_LOGIN = "/post-fake-login"
export const POST_FAKE_JWT_LOGIN = "/post-jwt-login"
export const POST_FAKE_PASSWORD_FORGET = "/fake-forget-pwd"
export const POST_FAKE_JWT_PASSWORD_FORGET = "/jwt-forget-pwd"
//export const SOCIAL_LOGIN = "/social-login"
export const SOCIAL_LOGIN = "/api/authenticate/v1/login"

//PROFILE
export const POST_EDIT_JWT_PROFILE = "/post-jwt-profile"
export const POST_EDIT_PROFILE = "/post-fake-profile"

//PRODUCTS
export const GET_PRODUCTS = "/products"
export const GET_PRODUCTS_DETAIL = "/product"

//Paginas
export const GET_SECCIONES_PAGINAS = "/api/config/listado"

//Mails
export const MODIFY_MAILS = "/api/inbox/modifica"
export const GET_RECIPIENTS_TO_MAILS = "/api/listadistribucion/listado"
export const SEND_EMAIL_MAILS = "/api/inbox/"
export const GET_INBOX_MAILS = "/api/inbox/listado"
export const ADD_NEW_INBOX_MAIL = "/add/inboxmail"
export const DELETE_INBOX_MAIL = "/api/inbox"

//starred mail
export const GET_STARRED_MAILS = "/starredmails"

//important mails
export const GET_IMPORTANT_MAILS = "/importantmails"

//Draft mail
export const GET_DRAFT_MAILS = "/draftmails"

//Send mail
export const GET_SENT_MAILS = "/sentmails"

//Trash mail
export const GET_TRASH_MAILS = "/trashmails"

//CALENDER
export const GET_EVENTS = "/api/evento/listado"
export const ADD_NEW_EVENT = "/api/evento"
export const UPDATE_EVENT = "/api/evento"
export const DELETE_EVENT = "/api/evento"
export const GET_CATEGORIES = "/api/evento/listado"

//CHATS
export const GET_CHATS = "/chats"
export const GET_GROUPS = "/groups"
export const GET_CONTACTS = "/contacts"
export const GET_MESSAGES = "/messages"
export const ADD_MESSAGE = "/add/messages"

//ORDERS
export const GET_ORDERS = "/orders"
export const ADD_NEW_ORDER = "/add/order"
export const UPDATE_ORDER = "/update/order"
export const DELETE_ORDER = "/delete/order"

//CART DATA
export const GET_CART_DATA = "/cart"

//CUSTOMERS
export const GET_CUSTOMERS = "/customers"
export const ADD_NEW_CUSTOMER = "/add/customer"
export const UPDATE_CUSTOMER = "/update/customer"
export const DELETE_CUSTOMER = "/delete/customer"

//SHOPS
export const GET_SHOPS = "/shops"

//CRYPTO
export const GET_WALLET = "/wallet"
export const GET_CRYPTO_ORDERS = "/crypto/orders"

//INVOICES
export const GET_INVOICES = "/invoices"
export const GET_INVOICE_DETAIL = "/invoice"

//PROJECTS
export const GET_PROJECTS = "/projects"
export const GET_PROJECT_DETAIL = "/project"
export const ADD_NEW_PROJECT = "/add/project"
export const UPDATE_PROJECT = "/update/project"
export const DELETE_PROJECT = "/delete/project"

//TASKS
export const GET_TASKS = "/tasks"

//CONTACTS
export const GET_USERS = "/users"
export const GET_USER_PROFILE = "/user"
export const ADD_NEW_USER = "/add/user"
export const UPDATE_USER = "/update/user"
export const DELETE_USER = "/delete/user"

//ALUMNOS
export const GET_ALUMNO = "/api/alumno"
export const GET_ALUMNOS = "/api/alumno/listado"
export const GET_ALUMNOS_LIBRETA = "/api/alumno/listado_alumnos_libreta"
export const GET_ALUMNO_PROFILE = "/user"
export const ADD_NEW_ALUMNO = "/api/alumno/add"
export const UPDATE_ALUMNO = "/api/alumno/update"
export const DELETE_ALUMNO = "/api/alumno/delete"

//FAMILIAS
export const GET_FAMILIA = "/api/familia"
export const GET_FAMILIAS = "/api/familia/listado"
export const ADD_FAMILIA = "/api/familia/add"
export const UPDATE_FAMILIA = "/api/familia/update"
export const DELETE_FAMILIA = "/api/familia/delete"

// CONTACTOS
export const GET_CONTACTO = "/api/contactos"
export const GET_CONTACTOS = "/api/contactos/listado"
export const ADD_CONTACTO = "/api/contactos/add"
export const UPDATE_CONTACTO = "/api/contactos/update"
export const DELETE_CONTACTO = "/api/contactos/delete"

//LIBRETA
export const GET_LIBRETA_EVENTOS_CATALOGOS = "/api/libreta/eventos/catalogo"
export const GET_LIBRETA_REGISTRO_EVENTOS = "/api/libreta/eventos/registrosxgrupoacademico"
export const GET_GRUPOS_ACADEMICOS = "/api/grupoacademico/listado"
export const GET_GRUPOS_ACADEMICOS_LIBRETA = "/api/grupoacademico/listado_libreta"

export const ADD_REGISTRO_EVENTO_COMIDA_LIBRETA = "api/libreta/Registro_Comida"
export const ADD_REGISTRO_EVENTO_DAIPERS_LIBRETA = "api/libreta/Registro_Diapers"
export const ADD_REGISTRO_EVENTO_SIESTA_LIBRETA = "api/libreta/Registro_Siesta"
export const ADD_REGISTRO_EVENTO_BATH_LIBRETA = "api/libreta/Registro_Bath"
export const ADD_REGISTRO_EVENTO_ACADEMIA_LIBRETA = "api/libreta/Registro_Academia"

//LIBRETA UPDATE
export const UPDATE_REGISTRO_EVENTO_COMIDA_LIBRETA = "api/libreta/Registro_Comida"
export const UPDATE_REGISTRO_EVENTO_DAIPERS_LIBRETA = "api/libreta/Registro_Diapers"
export const UPDATE_REGISTRO_EVENTO_SIESTA_LIBRETA = "api/libreta/Registro_Siesta"
export const UPDATE_REGISTRO_EVENTO_BATH_LIBRETA = "api/libreta/Registro_Bath"
export const UPDATE_REGISTRO_EVENTO_ACADEMIA_LIBRETA = "api/libreta/Registro_Academia"

//LIBRETA DELETE    
export const DELETE_REGISTRO_EVENTO_COMIDA_LIBRETA = "api/libreta/Registro_Comida"
export const DELETE_REGISTRO_EVENTO_DAIPERS_LIBRETA = "api/libreta/Registro_Diapers"
export const DELETE_REGISTRO_EVENTO_SIESTA_LIBRETA = "api/libreta/Registro_Siesta"
export const DELETE_REGISTRO_EVENTO_BATH_LIBRETA = "api/libreta/Registro_Bath"
export const DELETE_REGISTRO_EVENTO_ACADEMIA_LIBRETA = "api/libreta/Registro_Academia"

//LIBRETA OBSERVACIONES
export const ADD_LIBRETA_OBSERVACIONES = "api/libreta/observacion"
export const UPDATE_LIBRETA_OBSERVACIONES = "api/libreta/observacion"
export const DELETE_LIBRETA_OBSERVACIONES = "api/libreta/observacion"

//LIBRETA REGISTRO NOTIFICACIONES
export const ADD_NOTIFICACIONES = "api/libreta/Registra_Notificaciones"
// *********************************************************************************

//dashboard charts data
export const GET_WEEKLY_DATA = "/weekly-data"
export const GET_YEARLY_DATA = "/yearly-data"
export const GET_MONTHLY_DATA = "/monthly-data"

export const TOP_SELLING_DATA = "/top-selling-data"

export const GET_EARNING_DATA = "/earning-charts-data"

export const GET_PRODUCT_COMMENTS = "/comments-product"

export const ON_LIKNE_COMMENT = "/comments-product-action"

export const ON_ADD_REPLY = "/comments-product-add-reply"

export const ON_ADD_COMMENT = "/comments-product-add-comment"
