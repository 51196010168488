import {
  GET_GRUPOS_ACADEMICOS_SUCCESS,
  GET_GRUPOS_ACADEMICOS_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  gruposacademicos: [],
  error: {},
}

const gruposAcademicos = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_GRUPOS_ACADEMICOS_SUCCESS:
      return {
        ...state,
        gruposacademicos: action.payload,
      }

    case GET_GRUPOS_ACADEMICOS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    default:
      return state
  }
}

export default gruposAcademicos
