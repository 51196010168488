import axios from "axios"
import { del, get, post, put } from "./api_helper"
import * as url from "./url_helper"

// Gets the logged in user data from local session
const getLoggedInUser = () => {
  const user = localStorage.getItem("authUser")
  if (user) return JSON.parse(user)
  return null
}

//is user is logged in
const isUserAuthenticated = () => {
  return getLoggedInUser() !== null
}

//is user is logged in 
const getIdContactoUserAuthenticated = () => {
  const UserAuthenticated = getLoggedInUser()
  return UserAuthenticated ? UserAuthenticated.idContacto : -1
}

//is user is logged in
const getIdColegioFromUserAuthenticated = () => {
  const UserAuthenticated = getLoggedInUser()
  //return 1
  return UserAuthenticated ? UserAuthenticated.idColegio : -1
}

const getoSolicitudBase = (
  Descripcion_Bitacora = "",
  Comando = "",
  Datos = [],
  Parametros = []
) => {
  const idContacto = isUserAuthenticated ? getIdContactoUserAuthenticated().toString() : "-1"
  const oSolicitudBase = {
    id_Contacto: idContacto ? idContacto : -1,
    Descripcion_Bitacora: Descripcion_Bitacora,
    Comando: Comando,
    Datos: Datos,
    Parametros: Parametros,
  }
  return oSolicitudBase
}

// ******************************************************************************
// Files - Post, Delete
export const getApiFile = (oSolicitudBase) =>
  get(url.GET_FILE, { params: { oSolicitud_Base: oSolicitudBase } })

export const postApiFile = (oSolicitudBase) =>
  post(url.ADD_FILE, oSolicitudBase)

export const postApiFileBase64 = (oSolicitudBase) =>
  post(url.ADD_FILE_BASE64, oSolicitudBase)

export const delApiFile = (oSolicitudBase) =>
  del(url.DELETE_FILE, { params: { oSolicitud_Base: oSolicitudBase } })
// ******************************************************************************


// ******************************************************************************
// Reporte Envios - Get
export const getApiReporteEnvios = (oSolicitudBase) =>
  get(url.GET_REPORTE_ENVIOS, { params: { oSolicitud_Base: oSolicitudBase } })

export const getApiReporteEnvioDetalle = (oSolicitudBase) =>
  get(url.GET_REPORTE_ENVIO_DETALLE, { params: { oSolicitud_Base: oSolicitudBase } })
// ******************************************************************************

// Register Method
const postFakeRegister = data => {
  return axios
    .post(url.POST_FAKE_REGISTER, data)
    .then(response => {
      if (response.status >= 200 || response.status <= 299) return response.data
      throw response.data
    })
    .catch(err => {
      let message
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found"
            break
          case 500:
            message =
              "Sorry! something went wrong, please contact our support team"
            break
          case 401:
            message = "Invalid credentials"
            break
          default:
            message = err[1]
            break
        }
      }
      throw message
    })
}

// Login Method
const postFakeLogin = data => post(url.POST_FAKE_LOGIN, data)

// postForgetPwd
const postFakeForgetPwd = data => post(url.POST_FAKE_PASSWORD_FORGET, data)

// Edit profile
const postJwtProfile = data => post(url.POST_EDIT_JWT_PROFILE, data)

const postFakeProfile = data => post(url.POST_EDIT_PROFILE, data)

// Register Method
const postJwtRegister = (url, data) => {
  return axios
    .post(url, data)
    .then(response => {
      if (response.status >= 200 || response.status <= 299) return response.data
      throw response.data
    })
    .catch(err => {
      var message
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found"
            break
          case 500:
            message =
              "Sorry! something went wrong, please contact our support team"
            break
          case 401:
            message = "Invalid credentials"
            break
          default:
            message = err[1]
            break
        }
      }
      throw message
    })
}

// Login Method
const postJwtLogin = data => post(url.POST_USERNAME_PASSWORD_LOGIN, data)

// postForgetPwd
const postJwtForgetPwd = data => post(url.POST_FAKE_JWT_PASSWORD_FORGET, data)

// postSocialLogin
export const postSocialLogin = data => post(url.SOCIAL_LOGIN, data)

// get Products
export const getProducts = () => get(url.GET_PRODUCTS)

// get Product detail
export const getProductDetail = id =>
  get(`${url.GET_PRODUCTS_DETAIL}/${id}`, { params: { id } })

// get Events
export const getEvents = jsonparameters =>
  get(url.GET_EVENTS, { params: { jsonparameters } })

// add Events
export const addNewEvent = event => post(url.ADD_NEW_EVENT, event)

// update Event
export const updateEvent = event => put(url.UPDATE_EVENT, event)

// delete Event
export const deleteEvent = event =>
  del(`${url.DELETE_EVENT}/${event.id}`, { headers: { event } })

// get Categories
export const getCategories = jsonparameters =>
  get(url.GET_CATEGORIES, { params: { jsonparameters } })

// get chats
export const getChats = () => get(url.GET_CHATS)

// get groups
export const getGroups = () => get(url.GET_GROUPS)

// get Contacts
export const getContacts = () => get(url.GET_CONTACTS)

// get messages
export const getMessages = (roomId = "") =>
  get(`${url.GET_MESSAGES}/${roomId}`, { params: { roomId } })

// post messages
export const addMessage = message => post(url.ADD_MESSAGE, message)

// get orders
export const getOrders = () => get(url.GET_ORDERS)

// add order
export const addNewOrder = order => post(url.ADD_NEW_ORDER, order)

// update order
export const updateOrder = order => put(url.UPDATE_ORDER, order)

// delete order
export const deleteOrder = order =>
  del(url.DELETE_ORDER, { headers: { order } })

// get cart data
export const getCartData = () => get(url.GET_CART_DATA)

// get customers
export const getCustomers = () => get(url.GET_CUSTOMERS)

// add CUSTOMER
export const addNewCustomer = customer => post(url.ADD_NEW_CUSTOMER, customer)

// update CUSTOMER
export const updateCustomer = customer => put(url.UPDATE_CUSTOMER, customer)

// delete CUSTOMER
export const deleteCustomer = customer =>
  del(url.DELETE_CUSTOMER, { headers: { customer } })

// get shops
export const getShops = () => get(url.GET_SHOPS)

// get wallet
export const getWallet = () => get(url.GET_WALLET)

// get crypto order
export const getCryptoOrder = () => get(url.GET_CRYPTO_ORDERS)

// get invoices
export const getInvoices = () => get(url.GET_INVOICES)

// get invoice details
export const getInvoiceDetail = id =>
  get(`${url.GET_INVOICE_DETAIL}/${id}`, { params: { id } })

// get project
export const getProjects = () => get(url.GET_PROJECTS)

// get project details
export const getProjectsDetails = id =>
  get(`${url.GET_PROJECT_DETAIL}/${id}`, { params: { id } })

// get tasks
export const getTasks = () => get(url.GET_TASKS)

// get contacts
export const getUsers = () => get(url.GET_USERS)

// get gruposAcademicos
export const getGruposAcademicos = oSolicitudBase =>
  get(url.GET_GRUPOS_ACADEMICOS, { params: { oSolicitudBase } })

export const getApiGruposAcademicosLibreta = oSolicitudBase =>
  get(url.GET_GRUPOS_ACADEMICOS_LIBRETA, { params: { oSolicitud_Base: oSolicitudBase } })

// ******************************************************************************
// Alumnos - get Alumnos
export const getApiAlumno = oSolicitudBase =>
get(url.GET_ALUMNO, { params: { oSolicitud_Base: oSolicitudBase } })

export const getApiAlumnos = oSolicitudBase =>
get(url.GET_ALUMNOS, { params: { oSolicitud_Base: oSolicitudBase } })

export const getApiAddNewAlumno = oSolicitudBase =>
post(url.ADD_NEW_ALUMNO, oSolicitudBase)

export const getApiUpdateAlumno = oSolicitudBase =>
put(url.UPDATE_ALUMNO, oSolicitudBase)

export const getApiDeleteAlumno = oSolicitudBase =>
del(url.DELETE_ALUMNO, { params: { oSolicitud_Base: oSolicitudBase } })
// ******************************************************************************

// ******************************************************************************
// Familias - get Familias
export const getApiFamilia = oSolicitudBase =>
get(url.GET_FAMILIA, { params: { oSolicitudBase } })

export const getApiFamilias = oSolicitudBase =>
get(url.GET_FAMILIAS, { params: { oSolicitudBase } })

export const getApiAddFamilia = oSolicitudBase =>
post(url.ADD_FAMILIA, oSolicitudBase)

export const getApiUpdateFamilia = oSolicitudBase =>
put(url.UPDATE_FAMILIA, oSolicitudBase)

export const getApiDeleteFamilia = oSolicitudBase =>
del(url.DELETE_FAMILIA, oSolicitudBase)
// ******************************************************************************

// ******************************************************************************
// Contactos - get Contactos
export const getApiContacto = oSolicitudBase =>
get(url.GET_CONTACTO, { params: { oSolicitudBase } })

export const getApiContactos = oSolicitudBase =>
get(url.GET_CONTACTOS, { params: { oSolicitudBase } })

export const getApiAddContacto = oSolicitudBase =>
post(url.ADD_CONTACTO, oSolicitudBase)

export const getApiUpdateContacto = oSolicitudBase =>
put(url.UPDATE_CONTACTO, oSolicitudBase)

export const getApiDeleteContacto = oSolicitudBase =>
del(url.DELETE_CONTACTO, oSolicitudBase)
// ******************************************************************************


export const getApiAlumnosLibreta = (oSolicitudBase) =>
  get(url.GET_ALUMNOS_LIBRETA, { params: { oSolicitud_Base: oSolicitudBase } })

// ******************************************************************************
// Libreta - Eventos
export const getApiLibretaCatalogos = (oSolicitudBase) =>
  get(url.GET_LIBRETA_EVENTOS_CATALOGOS, { params: { oSolicitud_Base: oSolicitudBase } })

export const getApiLibretaRegistroEventos = (oSolicitudBase) =>
  get(url.GET_LIBRETA_REGISTRO_EVENTOS, { params: { oSolicitud_Base: oSolicitudBase } })

export const postApiLibretaRegistraEventoComida = (data) =>
  post(url.ADD_REGISTRO_EVENTO_COMIDA_LIBRETA, data)

export const postApiLibretaRegistraEventoDiapers = (data) =>
  post(url.ADD_REGISTRO_EVENTO_DAIPERS_LIBRETA, data)

export const postApiLibretaRegistraEventoSiesta = (data) =>
  post(url.ADD_REGISTRO_EVENTO_SIESTA_LIBRETA, data)

export const postApiLibretaRegistraEventoBath = (data) =>
  post(url.ADD_REGISTRO_EVENTO_BATH_LIBRETA, data)

export const postApiLibretaRegistraEventoAcademia = (data) =>
  post(url.ADD_REGISTRO_EVENTO_ACADEMIA_LIBRETA, data)
// ********************************************************************************



// ******************************************************************************
// Libreta - Observaciones

export const postApiLibretaAddObservacion = (data) =>
  post(url.ADD_LIBRETA_OBSERVACIONES, data)

export const putApiLibretaAddObservacion = (data) =>
  put(url.UPDATE_LIBRETA_OBSERVACIONES, data)

export const delApiLibretaAddObservacion = (data) =>
  del(url.DELETE_LIBRETA_OBSERVACIONES, data)

// ********************************************************************************



// ******************************************************************************
// Libreta - Update Eventos
export const putApiLibretaUpdateEventoComida = (data) =>
  put(url.UPDATE_REGISTRO_EVENTO_COMIDA_LIBRETA, data)

export const putApiLibretaUpdateEventoDiapers = (data) =>
  put(url.UPDATE_REGISTRO_EVENTO_DAIPERS_LIBRETA, data)

export const putApiLibretaUpdateEventoSiesta = (data) =>
  put(url.UPDATE_REGISTRO_EVENTO_SIESTA_LIBRETA, data)

export const putApiLibretaUpdateEventoBath = (data) =>
  put(url.UPDATE_REGISTRO_EVENTO_BATH_LIBRETA, data)

export const putApiLibretaUpdateEventoAcademia = (data) =>
  put(url.UPDATE_REGISTRO_EVENTO_ACADEMIA_LIBRETA, data)

// ******************************************************************************

// ******************************************************************************
// Libreta - Delete Eventos
export const delApiLibretaDeleteEventoComida = (oSolicitudBase) =>
  del(url.DELETE_REGISTRO_EVENTO_COMIDA_LIBRETA, { params: { oSolicitud_Base: oSolicitudBase } })

export const delApiLibretaDeleteEventoDiapers = (oSolicitudBase) =>
  del(url.DELETE_REGISTRO_EVENTO_DAIPERS_LIBRETA, { params: { oSolicitud_Base: oSolicitudBase } })

export const delApiLibretaDeleteEventoSiesta = (oSolicitudBase) =>
  del(url.DELETE_REGISTRO_EVENTO_SIESTA_LIBRETA, { params: { oSolicitud_Base: oSolicitudBase } })

export const delApiLibretaDeleteEventoBath = (oSolicitudBase) =>
  del(url.DELETE_REGISTRO_EVENTO_BATH_LIBRETA, { params: { oSolicitud_Base: oSolicitudBase } })

export const delApiLibretaDeleteEventoAcademia = (oSolicitudBase) =>
  del(url.DELETE_REGISTRO_EVENTO_ACADEMIA_LIBRETA, { params: { oSolicitud_Base: oSolicitudBase } })

// ******************************************************************************



// ******************************************************************************
// Libreta - Registra Notificaciones
export const postApiLibretaRegistraNotificaciones = (data) =>
  post(url.ADD_NOTIFICACIONES, data)



// add user
export const addNewUser = user => post(url.ADD_NEW_USER, user)

// update user
export const updateUser = user => put(url.UPDATE_USER, user)

// delete user
export const deleteUser = user => del(url.DELETE_USER, { headers: { user } })

/** PROJECT */
// add user
export const addNewProject = project => post(url.ADD_NEW_PROJECT, project)

// update user
export const updateProject = project => put(url.UPDATE_PROJECT, project)

// delete user
export const deleteProject = project =>
  del(url.DELETE_PROJECT, { headers: { project } })

export const getUserProfile = () => get(url.GET_USER_PROFILE)

// get inboxmail
export const modifyMails = jsonparameters => {
  const response = put(url.MODIFY_MAILS, jsonparameters)
  return response
}

// Paginas de la App
export const getSeccionesPaginas = jsonparameters =>
  get(url.GET_SECCIONES_PAGINAS, {
    params: { jsonparameters },
  })

export const getInboxMails = jsonparameters =>
  get(url.GET_INBOX_MAILS, {
    params: { jsonparameters },
  })

// add inboxmail
export const addNewInboxMail = inboxmail =>
  post(url.ADD_NEW_INBOX_MAIL, inboxmail)

// delete inboxmail
export const deleteInboxMail = id =>
  //del(url.DELETE_INBOX_MAIL, { headers: { inboxmail } });
  del(`${url.DELETE_INBOX_MAIL}/${id}`)

// get starredmail
export const getStarredMails = () => get(url.GET_STARRED_MAILS)

// get importantmail
export const getImportantMails = () => get(url.GET_IMPORTANT_MAILS)

// get sent mail
export const getSentMails = () => get(url.GET_SENT_MAILS)

// get trash mail
export const getTrashMails = () => get(url.GET_TRASH_MAILS)

// get starredmail
export const getDraftMails = () => get(url.GET_DRAFT_MAILS)

// get dashboard charts data
export const getWeeklyData = () => get(url.GET_WEEKLY_DATA)
export const getYearlyData = () => get(url.GET_YEARLY_DATA)
export const getMonthlyData = () => get(url.GET_MONTHLY_DATA)

export const topSellingData = month =>
  get(`${url.TOP_SELLING_DATA}/${month}`, { params: { month } })

export const getEarningChartsData = month =>
  get(`${url.GET_EARNING_DATA}/${month}`, { params: { month } })

const getProductComents = () => get(url.GET_PRODUCT_COMMENTS)

const onLikeComment = (commentId, productId) => {
  return post(`${url.ON_LIKNE_COMMENT}/${productId}/${commentId}`, {
    params: { commentId, productId },
  })
}
const onLikeReply = (commentId, productId, replyId) => {
  return post(`${url.ON_LIKNE_COMMENT}/${productId}/${commentId}/${replyId}`, {
    params: { commentId, productId, replyId },
  })
}

const onAddReply = (commentId, productId, replyText) => {
  return post(`${url.ON_ADD_REPLY}/${productId}/${commentId}`, {
    params: { commentId, productId, replyText },
  })
}

const onAddComment = (productId, commentText) => {
  return post(`${url.ON_ADD_COMMENT}/${productId}`, {
    params: { productId, commentText },
  })
}

export const sendInboxMails = email => {
  post(url.SEND_EMAIL_MAILS, email)
}

export {
  getLoggedInUser,
  isUserAuthenticated,
  getIdContactoUserAuthenticated,
  getIdColegioFromUserAuthenticated,
  getoSolicitudBase,
  postFakeRegister,
  postFakeLogin,
  postFakeProfile,
  postFakeForgetPwd,
  postJwtRegister,
  postJwtLogin,
  postJwtForgetPwd,
  postJwtProfile,
  getProductComents,
  onLikeComment,
  onLikeReply,
  onAddReply,
  onAddComment
}

