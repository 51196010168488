import {
  GET_SECCIONES_PAGINAS_SUCCESS,
  GET_SECCIONES_PAGINAS_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  paginas: [{}],
  error: {},
}

const Secciones = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_SECCIONES_PAGINAS_SUCCESS:
      return { ...state, paginas: action.payload }

    case GET_SECCIONES_PAGINAS_FAIL:
      return { ...state, error: action.payload }

    default:
      return state
  }
}

export default Secciones
