import React from "react"
import PropTypes from 'prop-types'
import {
    Row,
    Col,
} from "reactstrap"

const ItemResumenReporteEnvio = (props) => {

    //console.log("--------------| iniciando ItemResumenReporteEnvio|--------------")

    const formatDate = new Intl.DateTimeFormat('es-MX').format;

    return (
        <React.Fragment>
            <div className="">
                <Row>
                    <Col className="g-0 col-auto ">
                        {/* {formatDate(new Date(props.reporte.fecha))}  */}
                        {new Date(props.reporte.fecha).toLocaleTimeString()} / {props.reporte.tema} / <i
                            className="mdi mdi-radio-tower m-1"
                            style={{ fontSize: "1em" }}
                        /> {props.reporte.emisor}
                    </Col>
                </Row>
                <Row>
                    <Col className="g-0 col-auto ">
                        <i
                            className="mdi mdi-cellphone-sound m-2"
                            style={{ fontSize: "1em", color: "green" }}
                        />
                        {props.reporte.iContador_Mobile_Vs_Mobile_Delivered}
                    </Col>
                    <Col className="g-0 col-auto ">
                        <i
                            className="mdi mdi-email-send-outline m-2"
                            style={{ fontSize: "1em", color: "green" }}
                        />
                        {props.reporte.iContador_Email_Vs_Email_Delivered}
                    </Col>
                    <Col className="g-0 col-auto ">
                        <i
                            className="mdi mdi-close-thick m-2"
                            style={{ fontSize: "1em", color: "red" }}
                        />
                        {props.reporte.iError}
                    </Col>
                </Row>
            </div>
        </React.Fragment>
    )

}

ItemResumenReporteEnvio.propTypes = {
    reporte: PropTypes.any,
    fecha: PropTypes.any,
    iContador_Mobile: PropTypes.any,
    iContador_Email: PropTypes.any,
    iError: PropTypes.any,
}

export default ItemResumenReporteEnvio