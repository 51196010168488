import {
    GET_FILE,
    GET_FILE_SUCCESS,
    GET_FILE_FAIL,

    ADD_FILE,
    ADD_FILE_SUCCESS,
    ADD_FILE_FAIL,

    ADD_FILE_BASE64,

    UPDATE_FILE,
    UPDATE_FILE_SUCCESS,
    UPDATE_FILE_FAIL,

    UPDATE_FILE_BASE64,

    DELETE_FILE,
    DELETE_FILE_SUCCESS,
    DELETE_FILE_FAIL,

    GET_REPORTE_ENVIOS,
    GET_REPORTE_ENVIOS_SUCCESS,
    GET_REPORTE_ENVIOS_FAIL,

    GET_REPORTE_ENVIO_DETALLE,
    GET_REPORTE_ENVIO_DETALLE_SUCCESS,
    GET_REPORTE_ENVIO_DETALLE_FAIL,

    SET_DISPLAY_NOTIFICATION,
    UNSET_DISPLAY_NOTIFICATION,
    
} from "./actionTypes"


/* Files */
export const getFile = oSolicitud => ({
    type: GET_FILE,
    payload: oSolicitud,
})

export const getFileSuccess = events => ({
    type: GET_FILE_SUCCESS,
    payload: events,
})

export const getFileFail = error => ({
    type: GET_FILE_FAIL,
    payload: error,
})

export const addFile = oSolicitud => ({
    type: ADD_FILE,
    payload: oSolicitud,
})

export const addFileSuccess = events => ({
    type: ADD_FILE_SUCCESS,
    payload: events,
})

export const addFileFail = error => ({
    type: ADD_FILE_FAIL,
    payload: error,
})

export const addFileBase64 = oSolicitud => ({
    type: ADD_FILE_BASE64,
    payload: oSolicitud,
})

export const addFileBase64Success = events => ({
    type: ADD_FILE_SUCCESS,
    payload: events,
})

export const addFileBase64Fail = error => ({
    type: ADD_FILE_FAIL,
    payload: error,
})

export const updateFile = oSolicitud => ({
    type: UPDATE_FILE,
    payload: oSolicitud,
})

export const updateFileSuccess = events => ({
    type: UPDATE_FILE_SUCCESS,
    payload: events,
})

export const updateFileFail = error => ({
    type: UPDATE_FILE_FAIL,
    payload: error,
})

export const updateFileBase64 = oSolicitud => ({
    type: UPDATE_FILE,
    payload: oSolicitud,
})

export const updateFileBase64Success = events => ({
    type: UPDATE_FILE_SUCCESS,
    payload: events,
})

export const updateFileBase64Fail = error => ({
    type: UPDATE_FILE_FAIL,
    payload: error,
})

export const deleteFile = oSolicitud => ({
    type: DELETE_FILE,
    payload: oSolicitud,
})

export const deleteFileSuccess = events => ({
    type: DELETE_FILE_SUCCESS,
    payload: events,
})

export const deleteFileFail = error => ({
    type: DELETE_FILE_FAIL,
    payload: error,
})

/* Reporte Envios */
export const getReporteEnvios = oSolicitud => ({
    type: GET_REPORTE_ENVIOS,
    payload: oSolicitud,
})

export const getReporteEnviosSuccess = events => ({
    type: GET_REPORTE_ENVIOS_SUCCESS,
    payload: events,
})

export const getReporteEnviosFail = error => ({
    type: GET_REPORTE_ENVIOS_FAIL,
    payload: error,
})

/* Reporte Envios */
export const getReporteEnvioDetalle = oSolicitud => ({
    type: GET_REPORTE_ENVIO_DETALLE,
    payload: oSolicitud,
})

export const getReporteEnvioDetalleSuccess = events => ({
    type: GET_REPORTE_ENVIO_DETALLE_SUCCESS,
    payload: events,
})

export const getReporteEnvioDetalleFail = error => ({
    type: GET_REPORTE_ENVIO_DETALLE_FAIL,
    payload: error,
})


// *************************************************************
//     DisplayNotification
export const setDisplayNotification = (notification) => ({
    type: SET_DISPLAY_NOTIFICATION,
    payload: notification
  })
  export const unSetDisplayNotification = () => ({
    type: UNSET_DISPLAY_NOTIFICATION
  })
