import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import {
  GET_GRUPOS_ACADEMICOS,
  //GET_GRUPOS_ACADEMICOS_LIBRETA,
} from "./actionTypes"

import {
  getGruposAcademicosSuccess,
  getGruposAcademicosFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getGruposAcademicos,
} from "../../helpers/comunicabackend_helper"

function* fetchGruposAcademicos({ payload: oSolicitudBase }) {
  try {
    const response = yield call(getGruposAcademicos, oSolicitudBase)
    yield put(getGruposAcademicosSuccess(response.datos))
  } catch (error) {
    yield put(getGruposAcademicosFail('Hubo un error al traer de la base de datos a los alumnos de la libreta.'))
  }
}


function* gruposAcademicosSaga() {
  yield takeEvery(GET_GRUPOS_ACADEMICOS, fetchGruposAcademicos)
  //yield takeEvery(GET_GRUPOS_ACADEMICOS_LIBRETA, fetchGruposAcademicosLibreta)
}

export default gruposAcademicosSaga
