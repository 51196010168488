import React, { useEffect } from "react"
import MetaTags from 'react-meta-tags';

import { Container, Row, Col, Card, CardBody } from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

const IconMaterialdesign = props => {
  useEffect(() => {
    function isNew(icon) {
      return icon.version === "5.0.45"
    }

    function getIconItem(icon, isNewIcon) {
      const div = document.createElement("div"),
        i = document.createElement("i")
      div.className = "col-xl-3 col-lg-4 col-sm-6"
      i.className = "mdi mdi-" + icon.name
      div.appendChild(i)
      const span = document.createElement("span")
      span.appendChild(document.createTextNode("mdi-" + icon.name))
      div.appendChild(span)
      return div
    }
    (function () {
      const icons = [
        {
          name: "ab-testing",
          hex: "F01C9",
          version: "4.0.96"
        }
      ]
      icons.push({ name: "blank", hex: "f68c" })
      icons.forEach(function (icon) {
        const item = getIconItem(icon, isNew(icon))
        document.getElementById("icons").appendChild(item)
        if (isNew(icon)) {
          const newItem = getIconItem(icon, false, false)
          document.getElementById("newIcons").appendChild(newItem)
        }
      })
    })()
  })

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Material Design | Comunica</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title="Icons" breadcrumbItem="MDI" />

          <Row className="icons-demo-content">
            <Col xs="12">
              <Card>
                <CardBody>
                  <h4 className="card-title">New Icons</h4>
                  <p className="card-title-desc mb-2">
                    Use{" "}
                    <code>
                      &lt;i className=&quot;mdi mdi-speedometer-slow&ldquo;&gt;&lt;/i&gt;
                    </code>{" "}
                    <span className="badge badge-success">v 5.0.45</span>.
                  </p>

                  <Row className="icon-demo-content" id="newIcons"></Row>
                </CardBody>
              </Card>

              <Card>
                <CardBody>
                  <h4 className="card-title mb-4">All Icons</h4>
                  <Row className="icon-demo-content" id="icons"></Row>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <h4 className="card-title">Size</h4>

                  <Row className="icon-demo-content">
                    <Col xl="3" lg="4" sm="6">
                      <i className="mdi mdi-18px mdi-account"></i> mdi-18px
                    </Col>

                    <Col xl="3" lg="4" sm="6">
                      <i className="mdi mdi-24px mdi-account"></i> mdi-24px
                    </Col>

                    <Col xl="3" lg="4" sm="6">
                      <i className="mdi mdi-36px mdi-account"></i> mdi-36px
                    </Col>

                    <Col xl="3" lg="4" sm="6">
                      <i className="mdi mdi-48px mdi-account"></i> mdi-48px
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <h4 className="card-title">Rotate</h4>

                  <Row className="icon-demo-content">
                    <Col xl="3" lg="4" sm="6">
                      <i className="mdi mdi-rotate-45 mdi-account"></i>{" "}
                      mdi-rotate-45
                    </Col>
                    <Col xl="3" lg="4" sm="6">
                      <i className="mdi mdi-rotate-90 mdi-account"></i>{" "}
                      mdi-rotate-90
                    </Col>
                    <Col xl="3" lg="4" sm="6">
                      <i className="mdi mdi-rotate-135 mdi-account"></i>{" "}
                      mdi-rotate-135
                    </Col>
                    <Col xl="3" lg="4" sm="6">
                      <i className="mdi mdi-rotate-180 mdi-account"></i>{" "}
                      mdi-rotate-180
                    </Col>
                    <Col xl="3" lg="4" sm="6">
                      <i className="mdi mdi-rotate-225 mdi-account"></i>{" "}
                      mdi-rotate-225
                    </Col>
                    <Col xl="3" lg="4" sm="6">
                      <i className="mdi mdi-rotate-270 mdi-account"></i>{" "}
                      mdi-rotate-270
                    </Col>
                    <Col xl="3" lg="4" sm="6">
                      <i className="mdi mdi-rotate-315 mdi-account"></i>{" "}
                      mdi-rotate-315
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <h4 className="card-title">Spin</h4>

                  <Row>
                    <Col xl="3" lg="4" sm="6">
                      <i className="mdi mdi-spin mdi-loading"></i> mdi-spin
                    </Col>
                    <Col xl="3" lg="4" sm="6">
                      <i className="mdi mdi-spin mdi-star"></i> mdi-spin
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default IconMaterialdesign
