

const dataListadoCantidades =
    [
        {
            "id_Libreta_Cantidad": 6,
            "id_Colegio": 7,
            "nombre_Cantidad": "Todo",
            "icono": "mdi mdi-animation-outline",
            "borrado": false,
            "indice": 0
        },
        {
            "id_Libreta_Cantidad": 7,
            "id_Colegio": 7,
            "nombre_Cantidad": "Sufuciente",
            "icono": "mdi mdi-apple",
            "borrado": false,
            "indice": 1
        },
        {
            "id_Libreta_Cantidad": 8,
            "id_Colegio": 7,
            "nombre_Cantidad": "Moderado",
            "icono": "mdi mdi-circle-half-full",
            "borrado": false,
            "indice": 2
        },
        {
            "id_Libreta_Cantidad": 9,
            "id_Colegio": 7,
            "nombre_Cantidad": "Poco",
            "icono": "mdi mdi-adjust",
            "borrado": false,
            "indice": 3
        },
        {
            "id_Libreta_Cantidad": 10,
            "id_Colegio": 7,
            "nombre_Cantidad": "Nada",
            "icono": "mdi mdi-alert-rhombus-outline",
            "borrado": false,
            "indice": 4
        }
    ]

export default dataListadoCantidades