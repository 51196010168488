//redux
import { useSelector } from "react-redux"
import { map } from "lodash"

// *************************************************************
//     Misc

  export function useError() {
    return useSelector(state => state.familias.error);
  }

  export function useFamilia() {
    return useSelector(state => state.familias.familia);
  }

  export function useFamilias() {
    return useSelector(state => state.familias.familias);
  }

  export function useFamiliasOptions() {
    const familias = useSelector(state => state.familias.familias);
    let opciones = [{ value: -1, label: "Selecciona..." }];
    familias.forEach(familia => {
      opciones.push({ value: familia.id_Familia, label: familia.nombre_Familia })
    });
    return opciones
  }