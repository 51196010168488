import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

// Importamos tipos de acciones que van a hacer consultas al API
import {
  ALUMNOS_GET_ALUMNO,
  ALUMNOS_GET_ALUMNOS,
  ADD_NEW_ALUMNO,
  UPDATE_ALUMNO,
  DELETE_ALUMNO,

} from "./actionTypes"

// Importamos las acciones que levantaremos derivado del resultado de la llamada al api
import {
  getAlumnoSuccess,
  getAlumnoFail,
  getAlumnosSuccess,
  getAlumnosFail,
  addNewAlumnoSuccess,
  addNewAlumnoFail,
  updateAlumnoSuccess,
  updateAlumnoFail,
  deleteAlumnoSuccess,
  deleteAlumnoFail,
} from "./actions"

// Importamos las funciones que hacen la llamada al API
import {
  getApiAlumno,
  getApiAlumnos,
  getApiAddNewAlumno,
  getApiUpdateAlumno,
  getApiDeleteAlumno,
} from "../../helpers/comunicabackend_helper"

// **********************************************************
// Funciones que se disparan al recibir una acción

// Tipo de Accion Recibida: ALUMNOS_GET_ALUMNO
export function* watchOnGetAlumno({ payload: oSolicitudBase }) {
  try {
    const response = yield call(getApiAlumno, oSolicitudBase)
    response.status == "success" ? 
    yield put(getAlumnoSuccess(response.datos))
    : yield put(getAlumnoFail(response.Mensaje_Detalle))
  } catch (error) {
    yield put(getAlumnoFail(error))
  }
}

// Tipo de Accion Recibida: ALUMNOS_GET_ALUMNOS
export function* watchOnGetAlumnos({ payload: oSolicitudBase }) {
  try {
    const response = yield call(getApiAlumnos, oSolicitudBase)
    response.status == "success" ? 
    yield put(getAlumnosSuccess(response.datos))
    : yield put(getAlumnosFail(response.Mensaje_Detalle))
  } catch (error) {
    yield put(getAlumnosFail(error))
  }
}

// Tipo de Accion Recibida: ADD_NEW_ALUMNO
export function* watchOnAddAlumno(action) {
  console.log('watchOnAddAlumno')
  try {
    const response = yield call(getApiAddNewAlumno, action.payload)
    console.log(response)
    response.status == "success" ? 
    yield put(addNewAlumnoSuccess(response.datos))
    : yield put(addNewAlumnoFail(response.Mensaje_Detalle))
  } catch (error) {
    yield put(addNewAlumnoFail(error))
  }
}

// Tipo de Accion Recibida: UPDATE_ALUMNO
export function* watchOnUpdateAlumno(action) {
  try {
    const response = yield call(getApiUpdateAlumno, action.payload)
    response.status == "success" ? 
    yield put(updateAlumnoSuccess(response.datos))
    : yield put(updateAlumnoFail(response.Mensaje_Detalle))
  } catch (error) {
    yield put(updateAlumnoFail(error))
  }
}

// Tipo de Accion Recibida: DELETE_ALUMNO
export function* watchOnDeleteAlumno({ payload: oSolicitudBase }) {
  try {
    const response = yield call(getApiDeleteAlumno, oSolicitudBase)
    response.status == "success" ? 
    yield put(deleteAlumnoSuccess(response.datos))
    : yield put(deleteAlumnoFail(response.Mensaje_Detalle))
  } catch (error) {
    yield put(deleteAlumnoFail(error))
  }
}


function* alumnosSaga() {
  yield takeEvery(ALUMNOS_GET_ALUMNO, watchOnGetAlumno)
  yield takeEvery(ALUMNOS_GET_ALUMNOS, watchOnGetAlumnos)
  yield takeEvery(ADD_NEW_ALUMNO, watchOnAddAlumno)
  yield takeEvery(UPDATE_ALUMNO, watchOnUpdateAlumno)
  yield takeEvery(DELETE_ALUMNO, watchOnDeleteAlumno)
}

export default alumnosSaga 
