//redux
import { useSelector } from "react-redux"
import jwt_decode from "jwt-decode";

import getaccessToken from "../../../helpers/jwt-token-access/accessToken"

export function useAuthStatus() {
  return useSelector(state => state.Login.value);
}

export function useAuthUser() {
  if (localStorage.getItem("authUser")) {
    return JSON.parse(localStorage.getItem("authUser"))
  } 
  return null
}

export function useAuthError() {
  return useSelector(state => {
    //console.log("state: ", state);
    return state.Login.context.authError
  });
}

export function useAuthLoading() {
  return useSelector(state => state.Login.context.authLoading);
}

export function useAuthIdColegio() {
  return useSelector(state => state.Login.context.authIdColegio);
}

export function useAuthUserGmtOffset() {
  let decoded = { gmt: -6 }
  const accessToken = getaccessToken()
  if (accessToken.length > 0) {
    decoded = jwt_decode(accessToken)
  }
  //const decoded = jwt_decode(accessToken)
  // console.log("decoded: ", decoded)
  // console.log("decoded.gmt: ", decoded.gmt)
  return useSelector(state => decoded.gmt)
}
