

 
export const getaccessToken = () => {

  let accessToken = ""

  //console.log("::accessToken.js::getaccessToken::localStorage.getItem::Linea 6 ");
  if (localStorage.getItem("authUser")) {
    const obj = JSON.parse(localStorage.getItem("authUser"))
    //console.log("obj: " + JSON.stringify(obj));
    accessToken = "Bearer " + obj.accessToken
  }
  //console.log("::accessToken.js::getaccessToken::/localStorage.getItem::Linea 12 ");
  //console.log("accessToken: ", accessToken);

  return accessToken
}

export default getaccessToken
