


/* FAMILIAS */
export const FAMILIAS_GET_FAMILIA = "FAMILIAS/GET/FAMILIA"
export const FAMILIAS_GET_FAMILIA_SUCCESS = "FAMILIAS/GET/FAMILIA_SUCCESS"
export const FAMILIAS_GET_FAMILIA_FAIL = "FAMILIAS/GET/FAMILIA_FAIL"

export const FAMILIAS_GET_FAMILIAS = "FAMILIAS/GET/FAMILIAS"
export const FAMILIAS_GET_FAMILIAS_SUCCESS = "FAMILIAS/GET/FAMILIAS_SUCCESS"
export const FAMILIAS_GET_FAMILIAS_FAIL = "FAMILIAS/GET/FAMILIAS_FAIL"
/* ---------------------------------------------------------------------------------------*/

/**
 * add Familia
 */
 export const FAMILIAS_ADD_FAMILIA = "FAMILIAS/ADD/FAMILIA"
 export const FAMILIAS_ADD_FAMILIA_SUCCESS = "FAMILIAS/ADD/FAMILIA_SUCCESS"
 export const FAMILIAS_ADD_FAMILIA_FAIL = "FAMILIAS/ADD/FAMILIA_FAIL"
 
 /**
  * Edit Familia
  */
 export const FAMILIAS_UPDATE_FAMILIA = "FAMILIAS/UPDATE/FAMILIA"
 export const FAMILIAS_UPDATE_FAMILIA_SUCCESS = "FAMILIAS/UPDATE/FAMILIA_SUCCESS"
 export const FAMILIAS_UPDATE_FAMILIA_FAIL = "FAMILIAS/UPDATE/FAMILIA_FAIL"
 
 /**
  * Delete Familia
  */
 export const FAMILIAS_DELETE_FAMILIA = "FAMILIAS/DELETE/FAMILIA"
 export const FAMILIAS_DELETE_FAMILIA_SUCCESS = "FAMILIAS/DELETE/FAMILIA_SUCCESS"
 export const FAMILIAS_DELETE_FAMILIA_FAIL = "FAMILIAS/DELETE/FAMILIA_FAIL"

