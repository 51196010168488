//redux
import { useSelector } from "react-redux"
import { map } from "lodash";

// *************************************************************
//     Misc
export function useGruposAcademicos() {
  return useSelector(state => state.gruposAcademicos.gruposacademicos);
}

export function useGruposAcademicosOptions() {
  const gruposAcademicos = useSelector(state => state.gruposAcademicos.gruposacademicos);
  const options = map(gruposAcademicos, (oGrupoAcademico, key)=> ({value: oGrupoAcademico.id_Grupo_Academico, label: oGrupoAcademico.nombre_Grupo_Academico}))
  options.unshift({ value: -1, label: "Selecciona..." })
  return options
}