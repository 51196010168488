//redux
import { useSelector } from "react-redux"
import { map } from "lodash"

// *************************************************************
//     Misc

  export function useError() {
    return useSelector(state => state.contacts.error);
  }

  export function useContacto() {
    return useSelector(state => state.contacts.contacto);
  }

  export function useContactos() {
    return useSelector(state => state.contacts.contactos);
  }

  export function useContactosOptions() {
    const contactos = useSelector(state => state.contacts.contactos);
    let opciones = [{ value: -1, label: "Selecciona..." }];
    contactos.forEach(contacto => {
      opciones.push({ value: contacto.id_Contacto, label: contacto.nombre_Contacto })
    });
    return opciones
  }