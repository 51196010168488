import React, { useState, useEffect } from "react"

//redux
import { useSelector, useDispatch } from "react-redux"

import PropTypes from "prop-types"
import MetaTags from "react-meta-tags" // Added Meta Tag npm Package
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap"

import { get_secciones_paginas as onGetPaginas } from "store/secciones/actions"

import { getoSolicitudBase } from "helpers/comunicabackend_helper"

import { map } from "lodash"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

import classnames from "classnames"
import "./secciones.css"

// let data = [
//   {
//     id_Pagina: 3303,
//     id_Item_Menu: 3303,
//     id_Colegio: 8,
//     indice: 0,
//     id_seccion: 1,
//     seccion: "Joy of Learning",
//     id_TipoItem: 1,
//     id_Tipo_Item_Menu: 1,
//     nombre_TipoItem: "Descripcion",
//     menuItemTexto: "Child of God",
//     menuItemHref: "#viewSubMenu?nombreSubmenu=submenu",
//     menuItemImageSource: "",
//     menuItemIcon: "",
//     contenido:
//       '<a href="https://comunika.blob.core.windows.net/data/8/pictures/Oraciones%20en%20Casa%20APP%20y%20Sites.pdf">\n    <img src="https://comunika.blob.core.windows.net/data/8/pictures/Oraciones-Diarias_Mesa%20de%20trabajo%201%20(1).png" class="ri" alt="" /></a><p><br /></p>\n\n<a href="https://sites.google.com/tzitlacalli.edu.mx/childofgod/inicio">\n    <img src="https://comunika.blob.core.windows.net/data/8/pictures/SErafin.JPG" class="ri" alt="" /></a><p><br /></p>\n\n\n\n\n<!-- \n<p><a href="https://docs.google.com/forms/d/e/1FAIpQLSfaKZGsbGtjFt600varvtHtJUWLJVXHtSz2yJxnRVZ1Jf_icA/viewform"></a></p>\n -->',
//     lastModified: "2021-09-02T16:50:57.873",
//     parent: -1,
//     iError: 0,
//     mensaje_Error: "",
//     id_UniqueIdentifier: "00000000-0000-0000-0000-000000000000",
//     rowsAffected: 0,
//   },
//   {
//     id_Pagina: 3313,
//     id_Item_Menu: 3313,
//     id_Colegio: 8,
//     indice: 5,
//     id_seccion: 1,
//     seccion: "Joy of Learning",
//     id_TipoItem: 1,
//     id_Tipo_Item_Menu: 1,
//     nombre_TipoItem: "Descripcion",
//     menuItemTexto: "SEL Social Emotional Learning",
//     menuItemHref: "#paginadinamica?pagina=3313",
//     menuItemImageSource: "",
//     menuItemIcon: "",
//     contenido:
//       '<p>\n\t<a href="https://sites.google.com/tzitlacalli.edu.mx/educacionsocioemocional/p%C3%A1gina-principal">\n\t\t<img src="https://comunika.blob.core.windows.net/data/8/pictures/Neuro%2CSEL%2CMusica%2CIngl%C3%A9s_Mesa%20de%20trabajo%201.png" class="ri" alt="" />\n    </a>\n</p>',
//     lastModified: "2020-08-30T04:56:11.64",
//     parent: -1,
//     iError: 0,
//     mensaje_Error: "",
//     id_UniqueIdentifier: "00000000-0000-0000-0000-000000000000",
//     rowsAffected: 0,
//   },
//   {
//     id_Pagina: 3497,
//     id_Item_Menu: 3497,
//     id_Colegio: 8,
//     indice: 6,
//     id_seccion: 1,
//     seccion: "Joy of Learning",
//     id_TipoItem: 1,
//     id_Tipo_Item_Menu: 1,
//     nombre_TipoItem: "Descripcion",
//     menuItemTexto: "Segunda entrevista - Miss Nadia ",
//     menuItemHref: "#paginadinamica?pagina=3497",
//     menuItemImageSource: "",
//     menuItemIcon: "",
//     contenido:
//       '<a href="https://meet.google.com/nhn-vkdq-qzu?authuser=6"><img src="https://comunika.blob.core.windows.net/data/8/pictures/Meet%20your%20teacher%20(7).jpg" alt="" class="ri" /></a>',
//     lastModified: "2022-01-13T16:58:28.203",
//     parent: -1,
//     iError: 0,
//     mensaje_Error: "",
//     id_UniqueIdentifier: "00000000-0000-0000-0000-000000000000",
//     rowsAffected: 0,
//   },
//   {
//     id_Pagina: 3498,
//     id_Item_Menu: 3498,
//     id_Colegio: 8,
//     indice: 7,
//     id_seccion: 1,
//     seccion: "Joy of Learning",
//     id_TipoItem: 1,
//     id_Tipo_Item_Menu: 1,
//     nombre_TipoItem: "Descripcion",
//     menuItemTexto: "Segunda entrevista - Miss Estefy",
//     menuItemHref: "#paginadinamica?pagina=3498",
//     menuItemImageSource: "",
//     menuItemIcon: "",
//     contenido:
//       '<a href="https://meet.google.com/dvp-swoq-cnp?authuser=7"><img src="https://comunika.blob.core.windows.net/data/8/pictures/Meet%20your%20teacher%20(11).jpg" alt="" class="ri" /></a>',
//     lastModified: "2022-01-13T17:00:29.843",
//     parent: -1,
//     iError: 0,
//     mensaje_Error: "",
//     id_UniqueIdentifier: "00000000-0000-0000-0000-000000000000",
//     rowsAffected: 0,
//   },
//   {
//     id_Pagina: 3487,
//     id_Item_Menu: 3487,
//     id_Colegio: 8,
//     indice: 8,
//     id_seccion: 1,
//     seccion: "Joy of Learning",
//     id_TipoItem: 1,
//     id_Tipo_Item_Menu: 1,
//     nombre_TipoItem: "Descripcion",
//     menuItemTexto: "Segunda entrevista - Segundo: Miss Angie",
//     menuItemHref: "#paginadinamica?pagina=3487",
//     menuItemImageSource: "",
//     menuItemIcon: "",
//     contenido:
//       '<a href="https://meet.google.com/bqp-svpe-iyt?authuser=3"><img src="https://comunika.blob.core.windows.net/data/8/pictures/Meet%20your%20teacher%20(6).jpg" alt="" class="ri" /></a>',
//     lastModified: "2022-01-13T17:01:08.907",
//     parent: -1,
//     iError: 0,
//     mensaje_Error: "",
//     id_UniqueIdentifier: "00000000-0000-0000-0000-000000000000",
//     rowsAffected: 0,
//   },
//   {
//     id_Pagina: 3488,
//     id_Item_Menu: 3488,
//     id_Colegio: 8,
//     indice: 9,
//     id_seccion: 1,
//     seccion: "Joy of Learning",
//     id_TipoItem: 1,
//     id_Tipo_Item_Menu: 1,
//     nombre_TipoItem: "Descripcion",
//     menuItemTexto: "Segunda entrevista - Segundo: Miss Denisse",
//     menuItemHref: "#paginadinamica?pagina=3488",
//     menuItemImageSource: "",
//     menuItemIcon: "",
//     contenido:
//       '<a href="https://meet.google.com/rxk-gkcy-jme?authuser=2"><img src="https://comunika.blob.core.windows.net/data/8/pictures/Meet%20your%20teacher%20(8).jpg" alt="" class="ri" /></a>',
//     lastModified: "2022-01-13T17:01:58",
//     parent: -1,
//     iError: 0,
//     mensaje_Error: "",
//     id_UniqueIdentifier: "00000000-0000-0000-0000-000000000000",
//     rowsAffected: 0,
//   },
//   {
//     id_Pagina: 3489,
//     id_Item_Menu: 3489,
//     id_Colegio: 8,
//     indice: 10,
//     id_seccion: 1,
//     seccion: "Joy of Learning",
//     id_TipoItem: 1,
//     id_Tipo_Item_Menu: 1,
//     nombre_TipoItem: "Descripcion",
//     menuItemTexto: "Segunda entrevista - Prepri: Miss Vero",
//     menuItemHref: "#paginadinamica?pagina=3489",
//     menuItemImageSource: "",
//     menuItemIcon: "",
//     contenido:
//       '<a href="https://meet.google.com/bwx-unps-dmv?authuser=5"><img src="https://comunika.blob.core.windows.net/data/8/pictures/Meet%20your%20teacher%20(9).jpg" alt="" class="ri" /></a>',
//     lastModified: "2022-01-13T17:02:41.603",
//     parent: -1,
//     iError: 0,
//     mensaje_Error: "",
//     id_UniqueIdentifier: "00000000-0000-0000-0000-000000000000",
//     rowsAffected: 0,
//   },
//   {
//     id_Pagina: 3490,
//     id_Item_Menu: 3490,
//     id_Colegio: 8,
//     indice: 11,
//     id_seccion: 1,
//     seccion: "Joy of Learning",
//     id_TipoItem: 1,
//     id_Tipo_Item_Menu: 1,
//     nombre_TipoItem: "Descripcion",
//     menuItemTexto: "Segunda entrevista - Prepri: Miss Sofi",
//     menuItemHref: "#paginadinamica?pagina=3490",
//     menuItemImageSource: "",
//     menuItemIcon: "",
//     contenido:
//       '<a href="https://meet.google.com/dhi-aewi-wno?authuser=4"><img src="https://comunika.blob.core.windows.net/data/8/pictures/Meet%20your%20teacher%20(10).jpg" alt="" class="ri" /></a>',
//     lastModified: "2022-01-13T17:03:27.733",
//     parent: -1,
//     iError: 0,
//     mensaje_Error: "",
//     id_UniqueIdentifier: "00000000-0000-0000-0000-000000000000",
//     rowsAffected: 0,
//   },
//   {
//     id_Pagina: 3491,
//     id_Item_Menu: 3491,
//     id_Colegio: 8,
//     indice: 12,
//     id_seccion: 1,
//     seccion: "Joy of Learning",
//     id_TipoItem: 1,
//     id_Tipo_Item_Menu: 1,
//     nombre_TipoItem: "Descripcion",
//     menuItemTexto: "Evaluación de entrevista - Segunda ronda ",
//     menuItemHref: "#paginadinamica?pagina=3491",
//     menuItemImageSource: "",
//     menuItemIcon: "",
//     contenido:
//       '<a href="https://evamodena.questionpro.com/"><img src="https://comunika.blob.core.windows.net/data/8/pictures/Copia%20de%20Blue%20Yellow%20Illustrated%20Stars%20Space%20Kids%20Menu%20(3).jpg" alt="" class="ri" /></a>',
//     lastModified: "2022-01-21T17:46:23.447",
//     parent: -1,
//     iError: 0,
//     mensaje_Error: "",
//     id_UniqueIdentifier: "00000000-0000-0000-0000-000000000000",
//     rowsAffected: 0,
//   },
//   {
//     id_Pagina: 3316,
//     id_Item_Menu: 3316,
//     id_Colegio: 8,
//     indice: 2,
//     id_seccion: 3,
//     seccion: "Joy of Parenting",
//     id_TipoItem: 1,
//     id_Tipo_Item_Menu: 1,
//     nombre_TipoItem: "Descripcion",
//     menuItemTexto: "Marriages",
//     menuItemHref: "#paginadinamica?pagina=3316",
//     menuItemImageSource: "",
//     menuItemIcon: "",
//     contenido:
//       '<p>\n\t<a>\n\t\t<img src="https://comunika.blob.core.windows.net/data/8/pictures/Titulos-Formaci%C3%B3n_Mesa%20de%20trabajo%201.png" class="ri" alt="" /></a></p><p><a><br /></a>\n</p>',
//     lastModified: "2021-09-02T16:50:42.923",
//     parent: -1,
//     iError: 0,
//     mensaje_Error: "",
//     id_UniqueIdentifier: "00000000-0000-0000-0000-000000000000",
//     rowsAffected: 0,
//   },
//   {
//     id_Pagina: 3315,
//     id_Item_Menu: 3315,
//     id_Colegio: 8,
//     indice: 3,
//     id_seccion: 3,
//     seccion: "Joy of Parenting",
//     id_TipoItem: 1,
//     id_Tipo_Item_Menu: 1,
//     nombre_TipoItem: "Descripcion",
//     menuItemTexto: "Our Family",
//     menuItemHref: "#paginadinamica?pagina=3315",
//     menuItemImageSource: "",
//     menuItemIcon: "",
//     contenido:
//       '<p>\n\t<a>\n\t\t<img src="https://comunika.blob.core.windows.net/data/8/pictures/Titulos-Formaci%C3%B3n-02.png" class="ri" alt="" /></a></p>',
//     lastModified: "2022-02-01T19:40:26.507",
//     parent: -1,
//     iError: 0,
//     mensaje_Error: "",
//     id_UniqueIdentifier: "00000000-0000-0000-0000-000000000000",
//     rowsAffected: 0,
//   },
//   {
//     id_Pagina: 3252,
//     id_Item_Menu: 3252,
//     id_Colegio: 8,
//     indice: 5,
//     id_seccion: 3,
//     seccion: "Joy of Parenting",
//     id_TipoItem: 1,
//     id_Tipo_Item_Menu: 1,
//     nombre_TipoItem: "Descripcion",
//     menuItemTexto: "Share the Love",
//     menuItemHref: "#paginadinamica?pagina=3252",
//     menuItemImageSource: "",
//     menuItemIcon: "",
//     contenido:
//       '<p>\n\t<a>\n\t\t<img src="https://comunika.blob.core.windows.net/data/8/pictures/Titulos-Formaci%C3%B3n-04.png" class="ri" alt="" />\n    </a>\n</p>',
//     lastModified: "2020-08-26T04:36:06.557",
//     parent: -1,
//     iError: 0,
//     mensaje_Error: "",
//     id_UniqueIdentifier: "00000000-0000-0000-0000-000000000000",
//     rowsAffected: 0,
//   },
//   {
//     id_Pagina: 3096,
//     id_Item_Menu: 3096,
//     id_Colegio: 8,
//     indice: 5,
//     id_seccion: 3,
//     seccion: "Joy of Parenting",
//     id_TipoItem: 1,
//     id_Tipo_Item_Menu: 1,
//     nombre_TipoItem: "Descripcion",
//     menuItemTexto: "The Best of You",
//     menuItemHref: "#paginadinamica?pagina=3096",
//     menuItemImageSource: "",
//     menuItemIcon: "",
//     contenido:
//       '<!-- The best of you -->\n\n\n<p>\n\t<a>\n\t\t<img src="https://comunika.blob.core.windows.net/data/8/pictures/Titulos-Formaci%C3%B3n-03.png" class="ri" alt="" /></a></p>',
//     lastModified: "2021-09-02T16:51:39.427",
//     parent: -1,
//     iError: 0,
//     mensaje_Error: "",
//     id_UniqueIdentifier: "00000000-0000-0000-0000-000000000000",
//     rowsAffected: 0,
//   },
//   {
//     id_Pagina: 3493,
//     id_Item_Menu: 3493,
//     id_Colegio: 8,
//     indice: 6,
//     id_seccion: 3,
//     seccion: "Joy of Parenting",
//     id_TipoItem: 1,
//     id_Tipo_Item_Menu: 1,
//     nombre_TipoItem: "Descripcion",
//     menuItemTexto: "Adviento",
//     menuItemHref: "#paginadinamica?pagina=3493",
//     menuItemImageSource: "",
//     menuItemIcon: "",
//     contenido:
//       '<img src="https://comunika.blob.core.windows.net/data/8/pictures/Adviento%201-10(1).png" alt="" class="ri" />',
//     lastModified: "2021-12-02T14:43:07.047",
//     parent: -1,
//     iError: 0,
//     mensaje_Error: "",
//     id_UniqueIdentifier: "00000000-0000-0000-0000-000000000000",
//     rowsAffected: 0,
//   },
//   {
//     id_Pagina: 3082,
//     id_Item_Menu: 3082,
//     id_Colegio: 8,
//     indice: 7,
//     id_seccion: 3,
//     seccion: "Joy of Parenting",
//     id_TipoItem: 1,
//     id_Tipo_Item_Menu: 1,
//     nombre_TipoItem: "Descripcion",
//     menuItemTexto: "God Stops",
//     menuItemHref: "#paginadinamica?pagina=3082",
//     menuItemImageSource: "",
//     menuItemIcon: "",
//     contenido:
//       '<p>\n\t<a>\n\t\t<img src="https://comunika.blob.core.windows.net/data/8/pictures/Titulos-Formaci%C3%B3n-05.png" class="ri" alt="" />\n    </a>\n</p>',
//     lastModified: "2021-09-14T13:37:10.027",
//     parent: -1,
//     iError: 0,
//     mensaje_Error: "",
//     id_UniqueIdentifier: "00000000-0000-0000-0000-000000000000",
//     rowsAffected: 0,
//   },
//   {
//     id_Pagina: 3088,
//     id_Item_Menu: 3088,
//     id_Colegio: 8,
//     indice: 0,
//     id_seccion: 4,
//     seccion: "Our Community",
//     id_TipoItem: 1,
//     id_Tipo_Item_Menu: 1,
//     nombre_TipoItem: "Descripcion",
//     menuItemTexto: "Monthly News",
//     menuItemHref: "#paginadinamica?pagina=3088",
//     menuItemImageSource: "",
//     menuItemIcon: "",
//     contenido:
//       '<p style="font-family: &quot;Century Gothic&quot;;"><img src="https://comunika.blob.core.windows.net/data/8/pictures/cintillo%20monthly%20news-04(1).png" alt="" class="ri" /></p><p style="font-family: &quot;Century Gothic&quot;;"><img src="https://comunika.blob.core.windows.net/data/8/pictures/Modena-01(7).png" alt="" class="ri" /><br /></p>',
//     lastModified: "2022-04-28T16:05:51.963",
//     parent: -1,
//     iError: 0,
//     mensaje_Error: "",
//     id_UniqueIdentifier: "00000000-0000-0000-0000-000000000000",
//     rowsAffected: 0,
//   },
//   {
//     id_Pagina: 3477,
//     id_Item_Menu: 3477,
//     id_Colegio: 8,
//     indice: 1,
//     id_seccion: 4,
//     seccion: "Our Community",
//     id_TipoItem: 1,
//     id_Tipo_Item_Menu: 1,
//     nombre_TipoItem: "Descripcion",
//     menuItemTexto: "Joy of being healthy",
//     menuItemHref: "#paginadinamica?pagina=3477",
//     menuItemImageSource: "",
//     menuItemIcon: "",
//     contenido:
//       '<img src="https://comunika.blob.core.windows.net/data/8/pictures/Medidas%20de%20salud%20COVID-ene22-03(3).png" alt="" class="ri" />',
//     lastModified: "2022-02-04T18:26:31.33",
//     parent: -1,
//     iError: 0,
//     mensaje_Error: "",
//     id_UniqueIdentifier: "00000000-0000-0000-0000-000000000000",
//     rowsAffected: 0,
//   },
//   {
//     id_Pagina: 3471,
//     id_Item_Menu: 3471,
//     id_Colegio: 8,
//     indice: 2,
//     id_seccion: 4,
//     seccion: "Our Community",
//     id_TipoItem: 1,
//     id_Tipo_Item_Menu: 1,
//     nombre_TipoItem: "Descripcion",
//     menuItemTexto: "Nutrition Tips",
//     menuItemHref: "#paginadinamica?pagina=3471",
//     menuItemImageSource: "",
//     menuItemIcon: "",
//     contenido:
//       '<p style="font-family: &quot;Century Gothic&quot;;"><img src="https://comunika.blob.core.windows.net/data/8/pictures/Refrigerios%20sugeridos_carta-07(3).png" alt="" class="ri" /><img src="https://comunika.blob.core.windows.net/data/8/pictures/Refrigerios%20sugeridos_carta-08(3).png" alt="" class="ri" /></p>',
//     lastModified: "2021-09-02T16:57:18.777",
//     parent: -1,
//     iError: 0,
//     mensaje_Error: "",
//     id_UniqueIdentifier: "00000000-0000-0000-0000-000000000000",
//     rowsAffected: 0,
//   },
//   {
//     id_Pagina: 3094,
//     id_Item_Menu: 3094,
//     id_Colegio: 8,
//     indice: 3,
//     id_seccion: 4,
//     seccion: "Our Community",
//     id_TipoItem: 1,
//     id_Tipo_Item_Menu: 1,
//     nombre_TipoItem: "Descripcion",
//     menuItemTexto: "Daily Routines",
//     menuItemHref: "#paginadinamica?pagina=3094",
//     menuItemImageSource: "",
//     menuItemIcon: "",
//     contenido:
//       '<p>\n\t<a>\n\t\t<img src="https://comunika.blob.core.windows.net/data/8/pictures/Rutina-Horario-STARTS-01.png" class="ri" alt="" />\n    </a>\n</p>\n<p>\n\t<a>\n\t\t<img src="https://comunika.blob.core.windows.net/data/8/pictures/Rutina-Horario-01.png" class="ri" alt="" />\n    </a>\n</p>',
//     lastModified: "2020-08-26T04:52:32.697",
//     parent: -1,
//     iError: 0,
//     mensaje_Error: "",
//     id_UniqueIdentifier: "00000000-0000-0000-0000-000000000000",
//     rowsAffected: 0,
//   },
//   {
//     id_Pagina: 3472,
//     id_Item_Menu: 3472,
//     id_Colegio: 8,
//     indice: 4,
//     id_seccion: 4,
//     seccion: "Our Community",
//     id_TipoItem: 1,
//     id_Tipo_Item_Menu: 1,
//     nombre_TipoItem: "Descripcion",
//     menuItemTexto: "Happy Birthday",
//     menuItemHref: "#paginadinamica?pagina=3472",
//     menuItemImageSource: "",
//     menuItemIcon: "",
//     contenido:
//       '<img src="https://comunika.blob.core.windows.net/data/8/pictures/Festejos%20de%20cumplean%CC%83os%20en%20pandemia_Mesa%20de%20trabajo%201(9).png" alt="" class="ri" />',
//     lastModified: "2021-09-02T17:57:33.66",
//     parent: -1,
//     iError: 0,
//     mensaje_Error: "",
//     id_UniqueIdentifier: "00000000-0000-0000-0000-000000000000",
//     rowsAffected: 0,
//   },
//   {
//     id_Pagina: 3194,
//     id_Item_Menu: 3194,
//     id_Colegio: 8,
//     indice: 5,
//     id_seccion: 4,
//     seccion: "Our Community",
//     id_TipoItem: 1,
//     id_Tipo_Item_Menu: 1,
//     nombre_TipoItem: "Descripcion",
//     menuItemTexto: "Online Tips",
//     menuItemHref: "#paginadinamica?pagina=3194",
//     menuItemImageSource: "",
//     menuItemIcon: "",
//     contenido:
//       '<p>\n\t<a>\n\t\t<img src="https://comunika.blob.core.windows.net/data/8/pictures/Reglas-Clases-Videollamadas_Mesa%20de%20trabajo%201.png" class="ri" alt="" />\n    </a>\n</p>',
//     lastModified: "2020-08-26T04:53:13.05",
//     parent: -1,
//     iError: 0,
//     mensaje_Error: "",
//     id_UniqueIdentifier: "00000000-0000-0000-0000-000000000000",
//     rowsAffected: 0,
//   },
//   {
//     id_Pagina: 3100,
//     id_Item_Menu: 3100,
//     id_Colegio: 8,
//     indice: 9,
//     id_seccion: 4,
//     seccion: "Our Community",
//     id_TipoItem: 1,
//     id_Tipo_Item_Menu: 1,
//     nombre_TipoItem: "Descripcion",
//     menuItemTexto: "The Joy of Beauty",
//     menuItemHref: "#paginadinamica?pagina=3100",
//     menuItemImageSource: "",
//     menuItemIcon: "",
//     contenido:
//       '<p style="font-family: &quot;Century Gothic&quot;;"><img src="https://comunika.blob.core.windows.net/data/8/pictures/Joy%20of%20Beauty.png" alt="" class="ri" /></p><p style="font-family: &quot;Century Gothic&quot;;"><img src="https://comunika.blob.core.windows.net/data/8/pictures/1%20Modena%20Junta%20Inicio.pptx.jpg" alt="" class="ri" /></p>',
//     lastModified: "2020-08-30T05:08:16.023",
//     parent: -1,
//     iError: 0,
//     mensaje_Error: "",
//     id_UniqueIdentifier: "00000000-0000-0000-0000-000000000000",
//     rowsAffected: 0,
//   },
// ]

const Secciones = () => {
  const dispatch = useDispatch()

  const { paginas } = useSelector(state => ({
    paginas: state.secciones.paginas,
  }))

  const [secciones, setSecciones] = useState([])
  const [customActiveTab, setcustomActiveTab] = useState(1)
  const [verticalActiveTab, setverticalActiveTab] = useState(3303)

  const toggleCustom = tab => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab)
    }
  }

  const toggleVertical = tab => {
    if (verticalActiveTab !== tab) {
      setverticalActiveTab(tab)
    }
  }

  useEffect(() => {
    const Parametros = []
    const oSolicitudBase = getoSolicitudBase(
      "Secciones::useEffect::getSeccionesPaginas",
      "paginas",
      [],
      Parametros
    )
    dispatch(onGetPaginas(oSolicitudBase))
  }, [dispatch])

  useEffect(() => {
    let sections = []
    paginas.filter(function (item) {
      var i = sections.findIndex(x => x.id == item.id_seccion)
      if (i <= -1) {
        sections.push({ id: item.id_seccion, nombre: item.seccion })
      }
      return null
    })
    console.log("sections: " + JSON.stringify(sections))
    setSecciones(sections)
  }, [paginas])

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Inbox | Comunica</title>
        </MetaTags>
        <Container fluid={true} className="bg-soft">
          {/* <Breadcrumbs title="Secciones" breadcrumbItem="Secciones" /> */}
          <Row className="border">
            <Col lg={9}>
              <Card>
                <CardBody>
                  <Nav tabs className="nav-tabs-custom nav-justified">
                    {map(secciones, (seccion, key) => (
                      <NavItem key={key}>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: customActiveTab === seccion.id,
                          })}
                          onClick={() => {
                            toggleCustom(seccion.id)
                          }}
                        >
                          <span className="d-block d-sm-none">
                            <i className="fas fa-home"></i>
                          </span>
                          <span className="d-none d-sm-block">
                            {seccion.nombre}
                          </span>
                        </NavLink>
                      </NavItem>
                    ))}
                  </Nav>

                  <TabContent
                    activeTab={customActiveTab}
                    className="p-3 text-muted"
                  >
                    {map(secciones, (seccion, key) => (
                      <TabPane key={key} tabId={seccion.id}>
                        <Row>
                          <Col md="3">
                            <Nav pills className="flex-column">
                              {map(paginas, (pagina, key) => {
                                if (pagina.id_seccion === seccion.id) {
                                  return (
                                    <NavItem key={key}>
                                      <NavLink
                                        style={{ cursor: "pointer" }}
                                        className={classnames({
                                          active:
                                            verticalActiveTab ===
                                            pagina.id_Pagina,
                                        })}
                                        onClick={() => {
                                          toggleVertical(pagina.id_Pagina)
                                        }}
                                      >
                                        <span className="d-block d-sm-none">
                                          <i className="fas fa-home"></i>
                                        </span>
                                        <span className="d-none d-sm-block">
                                          {pagina.menuItemTexto}
                                        </span>
                                      </NavLink>
                                    </NavItem>
                                  )
                                }
                              })}
                            </Nav>
                          </Col>
                          <Col md="9">
                            <TabContent
                              activeTab={verticalActiveTab}
                              className="text-muted mt-4 mt-md-0"
                            >
                              {map(
                                paginas,
                                (pagina, key) =>
                                  pagina.id_seccion === seccion.id && (
                                    <TabPane key={key} tabId={pagina.id_Pagina}>
                                      <div
                                        dangerouslySetInnerHTML={{
                                          __html: pagina.contenido,
                                        }}
                                      ></div>
                                    </TabPane>
                                  )
                              )}
                            </TabContent>
                          </Col>
                        </Row>
                      </TabPane>
                    ))}
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

Secciones.propTypes = {
  paginas: PropTypes.array,
  secciones: PropTypes.array,
  onGetPaginas: PropTypes.func,
}

export default Secciones
