import {
  ALUMNOS_GET_ALUMNO,
  ALUMNOS_GET_ALUMNO_SUCCESS,
  ALUMNOS_GET_ALUMNO_FAIL,

  ALUMNOS_GET_ALUMNOS,
  ALUMNOS_GET_ALUMNOS_SUCCESS,
  ALUMNOS_GET_ALUMNOS_FAIL,
  
  ADD_NEW_ALUMNO,
  ADD_ALUMNO_SUCCESS,
  ADD_ALUMNO_FAIL,
  
  UPDATE_ALUMNO,
  UPDATE_ALUMNO_SUCCESS,
  UPDATE_ALUMNO_FAIL,
  
  DELETE_ALUMNO,
  DELETE_ALUMNO_SUCCESS,
  DELETE_ALUMNO_FAIL,
  

} from "./actionTypes"

export const getAlumno = oSolicitudBase => ({
  type: ALUMNOS_GET_ALUMNO,
  payload: oSolicitudBase,
})

export const getAlumnoSuccess = alumnos => ({
  type: ALUMNOS_GET_ALUMNO_SUCCESS,
  payload: alumnos,
})

export const getAlumnoFail = error => ({
  type: ALUMNOS_GET_ALUMNO_FAIL,
  payload: error,
})

export const getAlumnos = oSolicitudBase => ({
  type: ALUMNOS_GET_ALUMNOS,
  payload: oSolicitudBase,
})

export const getAlumnosSuccess = alumnos => ({
  type: ALUMNOS_GET_ALUMNOS_SUCCESS,
  payload: alumnos,
})

export const getAlumnosFail = error => ({
  type: ALUMNOS_GET_ALUMNOS_FAIL,
  payload: error,
})

export const addNewAlumno = oSolicitudBase => ({
  type: ADD_NEW_ALUMNO,
  payload: oSolicitudBase,
})

export const addNewAlumnoSuccess = alumnos => ({
  type: ADD_ALUMNO_SUCCESS,
  payload: alumnos,
})

export const addNewAlumnoFail = error => ({
  type: ADD_ALUMNO_FAIL,
  payload: error,
})

export const updateAlumno = oSolicitudBase => ({
  type: UPDATE_ALUMNO,
  payload: oSolicitudBase,
})

export const updateAlumnoSuccess = alumnos => ({
  type: UPDATE_ALUMNO_SUCCESS,
  payload: alumnos,
})

export const updateAlumnoFail = error => ({
  type: UPDATE_ALUMNO_FAIL,
  payload: error,
})

export const deleteAlumno = oSolicitudBase => ({
  type: DELETE_ALUMNO,
  payload: oSolicitudBase,
})

export const deleteAlumnoSuccess = alumnos => ({
  type: DELETE_ALUMNO_SUCCESS,
  payload: alumnos,
})

export const deleteAlumnoFail = error => ({
  type: DELETE_ALUMNO_FAIL,
  payload: error,
})




