import React, { useState } from "react";
import './styles/grid.css';

const TestGrid = () => {

    return (
        <React.Fragment>
            <div className="page-content">
                <div className="">
                    <h2 className="component display-4">Grid</h2>
                    <hr />

                    <div className="code-example mb-5">
                        <div className="container">
                            <h3 className="component-variation text-muted">Equal width</h3>
                            <p>Grid with auto-layout and equal width</p>
                        </div>
                        <div className="code-live">
                            <div className="container">
                                <div className="row">
                                    <div className="col">.col</div>
                                    <div className="col">.col</div>
                                </div>
                                <div className="row">
                                    <div className="col">.col</div>
                                    <div className="col">.col</div>
                                    <div className="col">.col</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="code-example mb-5">
                        <div className="container">
                            <h3 className="component-variation text-muted">One column width</h3>
                            <p>Grid with auto-layout around one column with set width</p>
                        </div>
                        <div className="code-live">
                            <div className="container">
                                <div className="row">
                                    <div className="col">.col</div>
                                    <div className="col-7">.col-7</div>
                                    <div className="col">.col</div>
                                </div>
                                <div className="row">
                                    <div className="col-3">.col-3</div>
                                    <div className="col">.col</div>
                                    <div className="col">.col</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="code-example mb-5">
                        <div className="container">
                            <h3 className="component-variation text-muted">Variable width content</h3>
                            <p>Grid with auto-layout around columns with set width and natural (auto) width</p>
                        </div>
                        <div className="code-live">
                            <div className="container">
                                <div className="row">
                                    <div className="col">.col</div>
                                    <div className="col-auto">.col-auto</div>
                                    <div className="col">.col</div>
                                </div>
                                <div className="row">
                                    <div className="col">.col</div>
                                    <div className="col-auto">.col-auto</div>
                                    <div className="col-2">.col-2</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="code-example mb-5">
                        <h3 className="component-variation text-muted container">Responsive classes</h3>
                        <div className="code-live">
                            <div className="container">
                                <h4 className="h5 text-muted mt-3">All breakpoints</h4>
                                <p>Same grid across all devices: use .col and .col-*</p>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <div className="col">.col</div>
                                    <div className="col">.col</div>
                                    <div className="col">.col</div>
                                    <div className="col">.col</div>
                                </div>
                                <div className="row">
                                    <div className="col-6">.col-6</div>
                                    <div className="col-3">.col-3</div>
                                    <div className="col-3">.col-3</div>
                                </div>
                            </div>
                            <div className="container">
                                <h4 className="h5 text-muted mt-3">Responsive</h4>
                                <p>Advanced grid changing layout across different device sizes: use device prefixes</p>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <div className="col-12 col-md-6 col-lg-3">.col-12 .col-md-6 .col-lg-3</div>
                                    <div className="col-12 col-md-6 col-lg-3">.col-12 .col-md-6 .col-lg-3</div>
                                    <div className="col-6 col-lg-3">.col-6 .col-lg-3</div>
                                    <div className="col-6 col-lg-3">.col-6 .col-lg-3</div>
                                </div>
                                <div className="row">
                                    <div className="col-6 col-sm-4">.col-6 .col-sm-4</div>
                                    <div className="col-6 col-sm-4">.col-6 .col-sm-4</div>
                                    <div className="col-6 col-sm-4">.col-6 .col-sm-4</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="code-example mb-5">
                        <div className="container">
                            <h3 className="component-variation text-muted">Row columns</h3>
                        </div>
                        <div className="code-live">
                            <h4 className="h5 text-muted mt-3 container">Auto</h4>
                            <div className="container">
                                <div className="row row-cols-auto">
                                    <div className="col">Column</div>
                                    <div className="col">Column</div>
                                    <div className="col">Column</div>
                                    <div className="col">Column</div>
                                </div>
                            </div>
                            <h4 className="h5 text-muted mt-3 container">2</h4>
                            <div className="container">
                                <div className="row row-cols-2">
                                    <div className="col">Column</div>
                                    <div className="col">Column</div>
                                    <div className="col">Column</div>
                                    <div className="col">Column</div>
                                </div>
                            </div>
                            <h4 className="h5 text-muted mt-3 container">3</h4>
                            <div className="container">
                                <div className="row row-cols-3">
                                    <div className="col">Column</div>
                                    <div className="col">Column</div>
                                    <div className="col">Column</div>
                                    <div className="col">Column</div>
                                </div>
                            </div>
                            <h4 className="h5 text-muted mt-3 container">4</h4>
                            <div className="container">
                                <div className="row row-cols-4">
                                    <div className="col">Column</div>
                                    <div className="col">Column</div>
                                    <div className="col">Column</div>
                                    <div className="col">Column</div>
                                </div>
                            </div>
                            <h4 className="h5 text-muted mt-3 container">Responsive</h4>
                            <p>Advanced grid changing layout across different device sizes: use device prefixes</p>
                            <div className="container">
                                <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 row-cols-xxl-4">
                                    <div className="col">Column</div>
                                    <div className="col">Column</div>
                                    <div className="col">Column</div>
                                    <div className="col">Column</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="code-example mb-5">
                        <h3 className="component-variation text-muted container">Nesting</h3>
                        <div className="code-live">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-8">
                                        <div className="row">
                                            <div className="col-md-12 col-lg-6">.col-md-12 .col-lg-6</div>
                                            <div className="col-md-6 col-lg-3">.col-md-12 .col-lg-3</div>
                                            <div className="col-md-6 col-lg-3">.col-md-12 .col-lg-3</div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">.col-md-4</div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </React.Fragment>
    )

}

export default TestGrid