/* Files */
export const GET_FILE = "COMMON/GET/FILE"
export const GET_FILE_SUCCESS = "COMMON/GET/FILE/SUCCESS"
export const GET_FILE_FAIL = "COMMON/GET/FILE/FAIL"

export const ADD_FILE = "COMMON/ADD/FILE"
export const ADD_FILE_BASE64 = "COMMON/ADD/BASE64/FILE"
export const ADD_FILE_SUCCESS = "COMMON/ADD/FILE/SUCCESS"
export const ADD_FILE_FAIL = "COMMON/ADD/FILE/FAIL"

export const UPDATE_FILE = "COMMON/UPDATE/FILE"
export const UPDATE_FILE_BASE64 = "COMMON/UPDATE/BASE64/FILE"
export const UPDATE_FILE_SUCCESS = "COMMON/UPDATE/FILE/SUCCESS"
export const UPDATE_FILE_FAIL = "COMMON/UPDATE/FILE/FAIL"

export const DELETE_FILE = "COMMON/DELETE/FILE"
export const DELETE_FILE_SUCCESS = "COMMON/DELETE/FILE/SUCCESS"
export const DELETE_FILE_FAIL = "COMMON/DELETE/FILE/FAIL"

/* Reporte Envio */
export const GET_REPORTE_ENVIOS = "COMMON/GET/REPORTE_ENVIOS"
export const GET_REPORTE_ENVIOS_SUCCESS = "COMMON/GET/REPORTE_ENVIOS/SUCCESS"
export const GET_REPORTE_ENVIOS_FAIL = "COMMON/GET/REPORTE_ENVIOS/FAIL"

export const GET_REPORTE_ENVIO_DETALLE = "COMMON/GET/REPORTE_ENVIO_DETALLE"
export const GET_REPORTE_ENVIO_DETALLE_SUCCESS = "COMMON/GET/REPORTE_ENVIO_DETALLE/SUCCESS"
export const GET_REPORTE_ENVIO_DETALLE_FAIL = "COMMON/GET/REPORTE_ENVIO_DETALLE/FAIL"

/* DisplayNotification */
export const SET_DISPLAY_NOTIFICATION = "COMMON/SET/DISPLAY_NOTIFICATION"
export const UNSET_DISPLAY_NOTIFICATION = "COMMON/UNSET/DISPLAY_NOTIFICATION"
