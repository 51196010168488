import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import PropTypes from "prop-types"
import { isEmpty } from "lodash"
import toastr from "toastr"
import "toastr/build/toastr.min.css"

import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  FormGroup,
} from "reactstrap"
import * as Yup from "yup"
import { useFormik } from "formik"
import { map } from "lodash"

import FullCalendar from "@fullcalendar/react"
import dayGridPlugin from "@fullcalendar/daygrid"
import interactionPlugin, { Draggable } from "@fullcalendar/interaction"
import BootstrapTheme from "@fullcalendar/bootstrap"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

import {
  addNewEvent as onAddNewEvent,
  deleteEvent as onDeleteEvent,
  getCategories as onGetCategories,
  getEvents as onGetEvents,
  updateEvent as onUpdateEvent,
} from "../../store/actions"

import {
  getoSolicitudBase,
  getIdColegioFromUserAuthenticated,
} from "../../helpers/comunicabackend_helper"

import DeleteModal from "./DeleteModal"

//css
import "@fullcalendar/bootstrap/main.css"

//redux
import { useSelector, useDispatch } from "react-redux"

const tipoeventos = [
  {
    id_Tipo_Evento: 1,
    Nombre_Tipo_Evento: "Junta",
    Borrado: false,
  },
  {
    id_Tipo_Evento: 2,
    Nombre_Tipo_Evento: "Tarea",
    Borrado: false,
  },
  {
    id_Tipo_Evento: 3,
    Nombre_Tipo_Evento: "Dia Festivo",
    Borrado: false,
  },
]

const Calender = props => {
  const dispatch = useDispatch()

  const [event, setEvent] = useState({})

  // events validation
  const URL =
    /^((https?|ftp):\/\/)?(www.)?(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(\#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i

  //moment(inicio).format("DD-MM-YY hh:mm")
  Yup.setLocale({
    date: {
      min: "Debe ser posterior a la fecha de inicio: ${min}",
    },
  })

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      title: (event && event.title) || "",
      url: (event && event.url) || "",
      location: (event && event.location) || "",
      description: (event && event.description) || "",
      notes: (event && event.notes) || "",
      id_Tipo_Evento: (event && event.id_Tipo_Evento) || "",
    },
    validationSchema: Yup.object({
      title: Yup.string().required("Por favor escribe el título del evento"),
      url: Yup.string().matches(URL, "Escribe un Url válido.").notRequired(),
      startdate: Yup.date(),
      enddate: Yup.date().when(
        "startdate",
        (startdate, enddate) => startdate && enddate.min(startdate) // moment(startdate).format("DD-MM-YY hh:mm")
      ),
      location: Yup.string().notRequired(),
      description: Yup.string().notRequired(),
      notes: Yup.string().notRequired(),
      id_Tipo_Evento: Yup.number()
        .required("Por favor elige una categoria")
        .positive()
        .integer(),
    }),
    onSubmit: values => {
      console.log("values", values)
      if (isEdit) {
        const updateEvent = {
          id_evento: values.id_evento,
          startdate: values.startdate,
          enddate: values.enddate,
          location: values.location,
          notes: values.notes,
          title: values.title,
          description: values.description,
          url: values.url,
          id_Grupo_Academico: -1,
          id_Tipo_Evento: values.id_Tipo_Evento,
        }
        // update event
        dispatch(onUpdateEvent(updateEvent))
        validation.resetForm()
      } else {
        const newEvent = {
          id_evento: -1,
          startdate: values.startdate,
          enddate: values.enddate,
          location: values.location,
          notes: values.notes,
          title: values.title,
          description: values.description,
          url: values.url,
          id_Grupo_Academico: -1,
          id_Tipo_Evento: values.id_Tipo_Evento,
        }
        // save new event
        dispatch(onAddNewEvent(newEvent))
        validation.resetForm()
      }
      setSelectedDay(null)
      toggle()
    },
  })

  // // category validation
  // const categoryValidation = useFormik({
  //   // enableReinitialize : use this flag when initial values needs to be changed
  //   enableReinitialize: true,

  //   initialValues: {
  //     title: (event && event.title) || "",
  //     category: (event && event.category) || "",
  //   },
  //   validationSchema: Yup.object({
  //     title: Yup.string().required("Please Enter Your Order Id"),
  //     category: Yup.string().required("Please Enter Your Billing Name"),
  //   }),
  //   onSubmit: values => {
  //     console.log("values", values)
  //     const newEvent = {
  //       id: Math.floor(Math.random() * 100),
  //       title: values["title"],
  //       start: selectedDay ? selectedDay.date : new Date(),
  //       className: values.event_category
  //         ? values.event_category + " text-white"
  //         : "bg-danger text-white",
  //     }
  //     // save new event

  //     dispatch(onAddNewEvent(newEvent))
  //     toggleCategory()
  //   },
  // })

  const { events, categories, error, displayNotification } = useSelector(
    state => ({
      events: state.calendar.events,
      categories: state.calendar.categories,
      error: state.calendar.error,
      displayNotification: state.calendar.displayNotification,
    })
  )

  //console.log("Calender::events: " + JSON.stringify(events))

  const [modal, setModal] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)
  const [modalcategory, setModalcategory] = useState(false)

  const [selectedDay, setSelectedDay] = useState(0)
  const [isEdit, setIsEdit] = useState(false)

  useEffect(() => {
    const oSolicitudBase_Categorias = getoSolicitudBase(
      "Calendar::index::useEffect",
      "getlistatiposeventos",
      [],
      []
    )
    console.log(
      "oSolicitudBase_Categorias: " + JSON.stringify(oSolicitudBase_Categorias)
    )
    dispatch(onGetCategories(oSolicitudBase_Categorias))
    const oSolicitudBase = getoSolicitudBase(
      "Calendar::index::useEffect",
      "geteventosgenerales",
      [],
      []
    )
    console.log("oSolicitudBase: " + JSON.stringify(oSolicitudBase))
    dispatch(onGetEvents(oSolicitudBase))
    new Draggable(document.getElementById("external-events"), {
      itemSelector: ".external-event",
    })
  }, [dispatch])

  useEffect(() => {
    console.log("entrando a useEffect por modal o por event")
    if (!modal && !isEmpty(event) && !!isEdit) {
      setTimeout(() => {
        setEvent({})
        setIsEdit(false)
        console.log(
          "useEffect::limpiando(evento y edit): ",
          modal,
          event,
          isEdit
        )
      }, 500)
    }
  }, [modal, event])

  useEffect(() => {
    console.log("useEffect::displayNotification: ", displayNotification)
    if (displayNotification !== undefined) {
      if (displayNotification.tipo) {
        switch (displayNotification.tipo) {
          case "success":
            toastr.success(
              displayNotification.mensaje,
              displayNotification.title
            )
            const oSolicitudBase = getoSolicitudBase(
              "Calendar::index::useEffect",
              "geteventosgenerales",
              [],
              []
            )
            dispatch(onGetEvents(oSolicitudBase))
            break
          case "info":
            toastr.info(displayNotification.mensaje, displayNotification.titulo)
            break
          case "warning":
            toastr.warning(
              displayNotification.mensaje,
              displayNotification.titulo
            )
            break
          case "error":
            toastr.error(
              displayNotification.mensaje,
              displayNotification.titulo
            )
            break
          default:
            break
        }
      }
    }
  }, [displayNotification])

  /**
   * Handling the modal state
   */
  const toggle = () => {
    if (modal) {
      setModal(false)
      setEvent(null)
    } else {
      setModal(true)
    }
  }

  const toggleCategory = () => {
    setModalcategory(!modalcategory)
  }

  /**
   * Handling date click on calendar
   */
  const handleDateClick = arg => {
    const date = arg["date"]
    const day = date.getDate()
    const month = date.getMonth()
    const year = date.getFullYear()

    const currectDate = new Date()
    const currentHour = currectDate.getHours()
    const currentMin = currectDate.getMinutes()
    const currentSec = currectDate.getSeconds()
    const modifiedDate = new Date(
      year,
      month,
      day,
      currentHour,
      currentMin,
      currentSec
    )
    const modifiedData = { ...arg, date: modifiedDate }

    setSelectedDay(modifiedData)
    toggle()
  }

  /**
   * Handling click on event on calendar
   */
  const handleEventClick = arg => {
    const event = arg.event
    // console.log("handleEventClick::arg.event:" + JSON.stringify(arg.event))
    // console.log("handleEventClick::isEdit:" + isEdit)
    setEvent({
      id: event.id,
      start: event.start,
      end: event.end,
      location: event.location,
      notes: event.notes,
      title: event.title,
      description: event.description,
      url: event.url,
    })
    //console.log("handleEventClick::setIsEdit(true):" + isEdit)
    setIsEdit(true)
    // console.log("/handleEventClick::setIsEdit(true):" + isEdit)
    // console.log("handleEventClick::event:" + JSON.stringify(event))
    toggle()
  }

  /**
   * On delete event
   */
  const handleDeleteEvent = () => {
    // console.log("handleDeleteEvent::event: " + JSON.stringify(event))
    // console.log("handleDeleteEvent::isEdit: " + isEdit)
    dispatch(onDeleteEvent(event))
    setDeleteModal(false)
    toggle()
  }

  /**
   * On category darg event
   */
  const onDrag = event => {
    event.preventDefault()
  }

  /**
   * On calendar drop event
   */
  const onDrop = event => {
    const date = event["date"]
    const day = date.getDate()
    const month = date.getMonth()
    const year = date.getFullYear()

    const currectDate = new Date()
    const currentHour = currectDate.getHours()
    const currentMin = currectDate.getMinutes()
    const currentSec = currectDate.getSeconds()
    const modifiedDate = new Date(
      year,
      month,
      day,
      currentHour,
      currentMin,
      currentSec
    )

    const draggedEl = event.draggedEl
    const modifiedData = {
      id: Math.floor(Math.random() * 100),
      title: draggedEl.innerText,
      start: modifiedDate,
      className: draggedEl.className,
    }
    dispatch(onAddNewEvent(modifiedData))
  }

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteEvent}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <MetaTags>
          <title>Calendar | Comunica </title>
        </MetaTags>
        <Container fluid={true}>
          {/* Render Breadcrumb */}
          <Breadcrumbs title="Calendario" breadcrumbItem="Calendar" />
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <Row>
                    <Col lg={3}>
                      <Button
                        color="primary"
                        className="font-16 btn-block"
                        onClick={toggle}
                      >
                        <i className="mdi mdi-plus-circle-outline me-1" />
                        Create New Event
                      </Button>

                      <div id="external-events" className="mt-3">
                        <p className="text-muted">
                          Drag and drop your event or click in the calendar
                        </p>
                        {categories &&
                          categories.map((category, i) => (
                            <div
                              className={`${category.type} external-event text-white p-1 mb-2`}
                              key={"cat-" + category.id}
                              draggable
                              onDrag={event => onDrag(event, category)}
                            >
                              <i className="mdi mdi-checkbox-blank-circle me-2 vertical-middle" />
                              {category.title}
                            </div>
                          ))}
                      </div>

                      <div className="mt-5 d-none d-xl-block">
                        <h5 className="text-center">How It Works ?</h5>

                        <ul className="ps-3">
                          <li className="text-muted mb-3">
                            It has survived not only five centuries, but also
                            the leap into electronic typesetting, remaining
                            essentially unchanged.
                          </li>
                          <li className="text-muted mb-3">
                            Richard McClintock, a Latin professor at
                            Hampden-Sydney College in Virginia, looked up one of
                            the more obscure Latin words, consectetur, from a
                            Lorem Ipsum passage.
                          </li>
                          <li className="text-muted mb-3">
                            It has survived not only five centuries, but also
                            the leap into electronic typesetting, remaining
                            essentially unchanged.
                          </li>
                        </ul>
                      </div>
                    </Col>
                    <Col className="col-lg-9">
                      {/* fullcalendar control */}
                      <FullCalendar
                        plugins={[
                          BootstrapTheme,
                          dayGridPlugin,
                          interactionPlugin,
                        ]}
                        slotDuration={"00:15:00"}
                        handleWindowResize={true}
                        themeSystem="bootstrap"
                        headerToolbar={{
                          left: "prev,next today",
                          center: "title",
                          right: "dayGridMonth,dayGridWeek,dayGridDay",
                        }}
                        events={events}
                        editable={true}
                        droppable={true}
                        selectable={true}
                        dateClick={handleDateClick}
                        eventClick={handleEventClick}
                        drop={onDrop}
                      />

                      {/* New/Edit event modal */}
                      <Modal isOpen={modal} className={props.className}>
                        <ModalHeader toggle={toggle} tag="h4">
                          {!!isEdit ? "Edit Event" : "Add Event"}
                        </ModalHeader>
                        <ModalBody>
                          <Form
                            className="needs-validation"
                            onSubmit={e => {
                              e.preventDefault()
                              validation.handleSubmit()
                              return false
                            }}
                          >
                            <Row>
                              <Col>
                                <FormGroup className="mb-3">
                                  <Label htmlFor="input-title">* Título</Label>
                                  <Input
                                    name="title"
                                    type="text"
                                    className="form-control"
                                    id="input-title"
                                    placeholder="Título"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.title || ""}
                                    invalid={
                                      validation.touched.title &&
                                      validation.errors.title
                                        ? true
                                        : false
                                    }
                                  />
                                  {validation.touched.title &&
                                  validation.errors.title ? (
                                    <FormFeedback type="invalid">
                                      {validation.errors.title}
                                    </FormFeedback>
                                  ) : null}
                                </FormGroup>
                              </Col>
                              <Col>
                                <div className="mb-3">
                                  <Label htmlFor="input-location">Lugar</Label>
                                  <Input
                                    name="Lugar"
                                    type="text"
                                    className="form-control"
                                    id="input-Lugar"
                                    placeholder="Lugar"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    invalid={
                                      validation.touched.Lugar &&
                                      validation.errors.Lugar
                                        ? true
                                        : false
                                    }
                                  />
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <div className="mb-3">
                                  <Label htmlFor="input-description">
                                    Descripción
                                  </Label>
                                  <Input
                                    name="description"
                                    type="textarea"
                                    className="form-control"
                                    id="input-description"
                                    placeholder="Descripción"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    invalid={
                                      validation.touched.description &&
                                      validation.errors.description
                                        ? true
                                        : false
                                    }
                                  />
                                </div>
                              </Col>
                              <Col>
                                <div className="mb-3">
                                  <Label htmlFor="input-url">Liga</Label>
                                  <Input
                                    name="url"
                                    type="text"
                                    className="form-control"
                                    id="input-url"
                                    placeholder="https://"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.url || ""}
                                    invalid={
                                      validation.touched.url &&
                                      validation.errors.url
                                        ? true
                                        : false
                                    }
                                  />
                                  {validation.touched.url &&
                                  validation.errors.url ? (
                                    <FormFeedback type="invalid">
                                      {validation.errors.url}
                                    </FormFeedback>
                                  ) : null}
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <div className="mb-3">
                                  <Label htmlFor="input-notes">Notas</Label>
                                  <Input
                                    name="notes"
                                    type="text"
                                    className="form-control"
                                    id="input-notes"
                                    placeholder="Notas"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    invalid={
                                      validation.touched.notes &&
                                      validation.errors.notes
                                        ? true
                                        : false
                                    }
                                  />
                                </div>
                              </Col>
                              <Col>
                                <div className="mb-3">
                                  <Label htmlFor="input-id_Tipo_Evento">
                                    * Categoria
                                  </Label>
                                  <Input
                                    name="id_Tipo_Evento"
                                    type="select"
                                    className="form-control"
                                    id="input-id_Tipo_Evento"
                                    placeholder="Categoria"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    invalid={
                                      validation.touched.id_Tipo_Evento &&
                                      validation.errors.id_Tipo_Evento
                                        ? true
                                        : false
                                    }
                                  >
                                    <option value="0">Elige...</option>
                                    {map(tipoeventos, (tipoevento, key) => (
                                      <option
                                        key={key}
                                        value={tipoevento.id_Tipo_Evento}
                                      >
                                        {tipoevento.Nombre_Tipo_Evento}
                                      </option>
                                    ))}
                                  </Input>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <div className="mb-3">
                                  <Label htmlFor="input-startdate">
                                    * Inicio
                                  </Label>
                                  <Input
                                    //locale={'format'= 'DD/MM/YYYY'}
                                    //steps="10"
                                    name="startdate"
                                    type="datetime-local"
                                    className="form-control"
                                    id="input-startdate"
                                    placeholder="Inicio"
                                    //value={validation.values.inicio}
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    invalid={
                                      validation.touched.startdate &&
                                      validation.errors.startdate
                                        ? true
                                        : false
                                    }
                                  />
                                  {validation.touched.startdate &&
                                  validation.errors.startdate ? (
                                    <FormFeedback type="invalid">
                                      {validation.errors.startdate}
                                    </FormFeedback>
                                  ) : null}
                                </div>
                              </Col>
                              <Col>
                                <div className="mb-3">
                                  <Label htmlFor="input-fecha-final">
                                    * Final
                                  </Label>
                                  <Input
                                    name="enddate"
                                    type="datetime-local"
                                    className="form-control"
                                    id="input-fecha-final"
                                    placeholder="Final"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    invalid={
                                      validation.touched.enddate &&
                                      validation.errors.enddate
                                        ? true
                                        : false
                                    }
                                  />
                                  {validation.touched.enddate &&
                                  validation.errors.enddate ? (
                                    <FormFeedback type="invalid">
                                      {validation.errors.enddate}
                                    </FormFeedback>
                                  ) : null}
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <div className="text-end">
                                  <button
                                    type="button"
                                    className="btn btn-light me-2"
                                    onClick={() => {
                                      toggle()
                                      setIsEdit(false)
                                    }}
                                  >
                                    Close
                                  </button>
                                  {!!isEdit && (
                                    <button
                                      type="button"
                                      className="btn btn-danger me-2"
                                      onClick={() => setDeleteModal(true)}
                                    >
                                      Delete
                                    </button>
                                  )}
                                  <button
                                    type="submit"
                                    className="btn btn-success save-event"
                                  >
                                    Save
                                  </button>
                                </div>
                              </Col>
                            </Row>
                          </Form>
                        </ModalBody>
                      </Modal>

                      <Modal
                        isOpen={modalcategory}
                        toggle={toggleCategory}
                        className={props.className}
                      >
                        <ModalHeader toggle={toggleCategory} tag="h4">
                          Add a category
                        </ModalHeader>
                        <ModalBody>
                          <Form
                            className="needs-validation"
                            onSubmit={e => {
                              e.preventDefault()
                              validation.handleSubmit()
                              return false
                            }}
                          >
                            <Row>
                              <Col>
                                <FormGroup className="mb-3">
                                  <Label htmlFor="input-title">* Título</Label>
                                  <Input
                                    name="title"
                                    type="text"
                                    className="form-control"
                                    id="input-title"
                                    placeholder="Título"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.title || ""}
                                    invalid={
                                      validation.touched.title &&
                                      validation.errors.title
                                        ? true
                                        : false
                                    }
                                  />
                                  {validation.touched.title &&
                                  validation.errors.title ? (
                                    <FormFeedback type="invalid">
                                      {validation.errors.title}
                                    </FormFeedback>
                                  ) : null}
                                </FormGroup>
                              </Col>
                              <Col>
                                <div className="mb-3">
                                  <Label htmlFor="input-lugar">Lugar</Label>
                                  <Input
                                    name="lugar"
                                    type="text"
                                    className="form-control"
                                    id="input-lugar"
                                    placeholder="Lugar"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    invalid={
                                      validation.touched.lugar &&
                                      validation.errors.lugar
                                        ? true
                                        : false
                                    }
                                  />
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <div className="mb-3">
                                  <Label htmlFor="input-description">
                                    Descripción
                                  </Label>
                                  <Input
                                    name="description"
                                    type="textarea"
                                    className="form-control"
                                    id="input-description"
                                    placeholder="Descripción"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    invalid={
                                      validation.touched.description &&
                                      validation.errors.description
                                        ? true
                                        : false
                                    }
                                  />
                                </div>
                              </Col>
                              <Col>
                                <div className="mb-3">
                                  <Label htmlFor="input-url">Liga</Label>
                                  <Input
                                    name="url"
                                    type="text"
                                    className="form-control"
                                    id="input-url"
                                    placeholder="https://"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.url || ""}
                                    invalid={
                                      validation.touched.url &&
                                      validation.errors.url
                                        ? true
                                        : false
                                    }
                                  />
                                  {validation.touched.url &&
                                  validation.errors.url ? (
                                    <FormFeedback type="invalid">
                                      {validation.errors.url}
                                    </FormFeedback>
                                  ) : null}
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <div className="mb-3">
                                  <Label htmlFor="input-notes">notes</Label>
                                  <Input
                                    name="notes"
                                    type="text"
                                    className="form-control"
                                    id="input-notes"
                                    placeholder="notes"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    invalid={
                                      validation.touched.notes &&
                                      validation.errors.notes
                                        ? true
                                        : false
                                    }
                                  />
                                </div>
                              </Col>
                              <Col>
                                <div className="mb-3">
                                  <Label htmlFor="input-id_Tipo_Evento">
                                    * Categoria
                                  </Label>
                                  <Input
                                    name="categoria"
                                    type="select"
                                    className="form-control"
                                    id="input-categoria"
                                    placeholder="Categoria"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    invalid={
                                      validation.touched.categoria &&
                                      validation.errors.categoria
                                        ? true
                                        : false
                                    }
                                  >
                                    <option value="0">Elige...</option>
                                    {map(tipoeventos, (tipoevento, key) => (
                                      <option
                                        key={key}
                                        value={tipoevento.id_Tipo_Evento}
                                      >
                                        {tipoevento.Nombre_Tipo_Evento}
                                      </option>
                                    ))}
                                  </Input>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <div className="mb-3">
                                  <Label htmlFor="input-fecha-inicio">
                                    * Inicio
                                  </Label>
                                  <Input
                                    //locale={'format'= 'DD/MM/YYYY'}
                                    //steps="10"
                                    name="inicio"
                                    type="datetime-local"
                                    className="form-control"
                                    id="input-fecha Inicio"
                                    placeholder="Inicio"
                                    //value={validation.values.inicio}
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    invalid={
                                      validation.touched.inicio &&
                                      validation.errors.inicio
                                        ? true
                                        : false
                                    }
                                  />
                                  {validation.touched.inicio &&
                                  validation.errors.inicio ? (
                                    <FormFeedback type="invalid">
                                      {validation.errors.inicio}
                                    </FormFeedback>
                                  ) : null}
                                </div>
                              </Col>
                              <Col>
                                <div className="mb-3">
                                  <Label htmlFor="input-fecha-final">
                                    * Final
                                  </Label>
                                  <Input
                                    name="final"
                                    type="datetime-local"
                                    className="form-control"
                                    id="input-fecha-final"
                                    placeholder="Final"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    invalid={
                                      validation.touched.final &&
                                      validation.errors.final
                                        ? true
                                        : false
                                    }
                                  />
                                  {validation.touched.final &&
                                  validation.errors.final ? (
                                    <FormFeedback type="invalid">
                                      {validation.errors.final}
                                    </FormFeedback>
                                  ) : null}
                                </div>
                              </Col>
                            </Row>
                            <div className="mb-3">
                              <Button color="primary" type="submit">
                                Submit form
                              </Button>
                            </div>
                          </Form>
                        </ModalBody>
                      </Modal>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

Calender.propTypes = {
  events: PropTypes.array,
  categories: PropTypes.array,
  className: PropTypes.string,
  onGetEvents: PropTypes.func,
  onAddNewEvent: PropTypes.func,
  onUpdateEvent: PropTypes.func,
  onDeleteEvent: PropTypes.func,
  onGetCategories: PropTypes.func,
}

export default Calender
