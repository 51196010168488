import { takeEvery, put, call } from "redux-saga/effects"

// Calender Redux States
import {
  ADD_NEW_EVENT,
  DELETE_EVENT,
  GET_CATEGORIES,
  GET_EVENTS,
  UPDATE_EVENT,
} from "./actionTypes"
import {
  getEventsSuccess,
  getEventsFail,
  addEventFail,
  addEventSuccess,
  updateEventSuccess,
  updateEventFail,
  deleteEventSuccess,
  deleteEventFail,
  getCategoriesSuccess,
  getCategoriesFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getEvents,
  addNewEvent,
  updateEvent,
  deleteEvent,
  getCategories,
} from "../../helpers/comunicabackend_helper"

const getClassname = id_Tipo_Evento => {
  let className = ""
  switch (id_Tipo_Evento) {
    case 1: // 1	Reunión
      className = "bg-primary text-white"
      break
    case 2: // 2	Examen
      className = "bg-success text-white"
      break
    case 3: // 3	Quiz Presencial
      className = "bg-info text-white"
      break
    case 4: // 4	Quiz en Línea
      className = "bg-warning text-white"
      break
    case 5: // 5	Extraordinario
      className = "bg-danger text-white"
      break
    case 6: // 6	Tarea
      className = "bg-dark text-white"
      break
    case 7: // 7	Bitácora
      className = "bg-secondary text-white"
      break
    case 8: // 8	Evento
      className = "bg-primary bg-gradient text-white"
      break
    case 9: // 9	Día Libre
      className = "bg-success bg-gradient text-white"
      break
    case 10: // 10	Otro
      className = "bg-info bg-gradient text-white"
      break
    case 11: // 11	Planeación
      className = "bg-warning bg-gradient text-white"
      break
    case 12: // 12	Informe Diario
      className = "bg-danger bg-gradient text-white"
      break
    case 13: // 12	Informe Diario
      className = "bg-dark bg-gradient text-white"
      break
    case 14: // 12	Informe Diario
      className = "bg-secondary bg-gradient text-white"
      break

    default:
      break
  }
  return className
}

function* fetchEvents({ jsonparameters: jsonparameters }) {
  try {
    const response = yield call(getEvents, jsonparameters)
    // id: event.id,
    // title: event.title,
    // title_category: event.title_category,
    // start: event.start,
    // className: event.classNames,
    // category: event.classNames[0],
    // event_category: event.classNames[0],
    // {
    //   id: 2,
    //   title: "See John Deo",
    //   start: new Date(),
    //   end: new Date(),
    //   className: "bg-success text-white",
    // },
    // id_Tipo_Evento	Nombre_Tipo_Evento
    // 1	Reunión
    // 2	Examen
    // 3	Quiz Presencial
    // 4	Quiz en Línea
    // 5	Extraordinario
    // 6	Tarea
    // 7	Bitácora
    // 8	Evento
    // 9	Día Libre
    // 10	Otro
    // 11	Planeación
    // 12	Informe Diario
    const eventosTransformados = response.datos.map(event => {
      const className = getClassname(event.id_Tipo_Evento)
      return {
        id: event.id_evento,
        start: event.unixstartdate,
        end: event.unixenddate,
        location: event.location,
        notes: event.notes,
        title: event.title,
        description: event.description,
        //url: event.url,
        id_Grupo_Academico: event.id_Grupo_Academico,
        id_Tipo_Evento: event.id_Tipo_Evento,
        // title_category: className[0],
        // category: className[0],
        event_category: event.type,
        className: className,
      }
    })
    console.log(
      "eventosTransformados:: " + JSON.stringify(eventosTransformados)
    )
    yield put(getEventsSuccess(eventosTransformados))
  } catch (error) {
    yield put(getEventsFail(error))
  }
}

function* onAddNewEvent({ payload: event }) {
  try {
    const response = yield call(addNewEvent, event)
    yield put(addEventSuccess(event))
  } catch (error) {
    yield put(addEventFail(error))
  }
}

function* onUpdateEvent({ payload: event }) {
  try {
    const response = yield call(updateEvent, event)
    yield put(updateEventSuccess(response))
  } catch (error) {
    yield put(updateEventFail(error))
  }
}

function* onDeleteEvent({ payload: event }) {
  try {
    const response = yield call(deleteEvent, event)
    yield put(deleteEventSuccess(event))
  } catch (error) {
    yield put(deleteEventFail(error))
  }
}

function* onGetCategories({ jsonparameters: jsonparameters }) {
  try {
    const response = yield call(getCategories, jsonparameters)
    const eventosTransformados = response.datos.map(category => {
      const className = getClassname(category.id_Tipo_Evento)
      return {
        id: category.id_Tipo_Evento,
        type: className,
        title: category.nombre_Tipo_Evento,
      }
    })
    yield put(getCategoriesSuccess(eventosTransformados))
  } catch (error) {
    yield put(getCategoriesFail(error))
  }
}

function* calendarSaga() {
  yield takeEvery(GET_EVENTS, fetchEvents)
  yield takeEvery(ADD_NEW_EVENT, onAddNewEvent)
  yield takeEvery(UPDATE_EVENT, onUpdateEvent)
  yield takeEvery(DELETE_EVENT, onDeleteEvent)
  yield takeEvery(GET_CATEGORIES, onGetCategories)
}

export default calendarSaga
