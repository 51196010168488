import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

// Login Redux States
import { LOGIN_USER, LOGOUT_USER, SOCIAL_LOGIN } from "./actionTypes"
import { apiError, loginSuccess, loginFail, logoutUserSuccess } from "./actions"

//Include Both Helper File with needed methods
import { getFirebaseBackend } from "../../../helpers/firebase_helper"
import {
  postFakeLogin,
  postJwtLogin,
  postSocialLogin,
} from "../../../helpers/comunicabackend_helper"

const fireBaseBackend = getFirebaseBackend()

function* loginUser({ payload: { user, history } }) {
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const response = yield call(
        fireBaseBackend.loginUser,
        user.email,
        user.password
      )
      yield put(loginSuccess(response))
    } else if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
      try {
        const response = yield call(postJwtLogin, {
          id_Contacto: "-1",
          Descripcion_Bitacora: "Post Login",
          Datos: {},
          Parametros: [
            {
              nombre: "provider",
              valor: "username-password",
            },
            {
              nombre: "id_colegio",
              valor: "7",
            },
            {
              nombre: "username",
              valor: user.email,
            },
            {
              nombre: "password",
              valor: Buffer.from(user.password).toString("base64"),
            },
            {
              nombre: "appversion",
              valor: "",
            },
            {
              nombre: "device_token",
              valor: "",
            },
            {
              nombre: "GMT_Offset",
              valor: "-6",
            },
          ],
        })
        localStorage.setItem("authUser", JSON.stringify(response.datos))
        yield put(loginSuccess(response.datos))
      } catch (error) {
        console.log("postJwtLogin::loginUser::error: " + error)
        yield put(loginFail(error))
      }
    } else if (process.env.REACT_APP_DEFAULTAUTH === "fake") {
      const response = yield call(postFakeLogin, {
        email: user.email,
        password: user.password,
      })
      localStorage.setItem("authUser", JSON.stringify(response.datos))
      yield put(loginSuccess(response.datos))
    }
    history.push("/libreta")
  } catch (error) {
    console.log("loginUser::catch::general::error: " + error)
    yield put(apiError(error.mensaje_Detalle))
  }
}

function* logoutUser({ payload: { history } }) {
  try {
    localStorage.removeItem("authUser")

    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const response = yield call(fireBaseBackend.logout)
      yield put(logoutUserSuccess(response))
    }
    history.push("/login")
  } catch (error) {
    yield put(apiError(error))
  }
}

function* socialLogin({ payload: { data, history, type } }) {
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const response = yield call(
        fireBaseBackend.loginUser,
        user.email,
        user.password
      )
      yield put(loginSuccess(response))
    } else {
      const response = yield call(postSocialLogin, data)
      // console.log(
      //   "saga.js::socialLogin::response.datos::" +
      //     JSON.stringify(response.datos)
      // );
      localStorage.setItem("authUser", JSON.stringify(response.datos))
      yield put(loginSuccess(response.datos))
    }
    history.push("/libreta")
  } catch (error) {
    yield put(apiError(error))
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser)
  yield takeLatest(SOCIAL_LOGIN, socialLogin)
  yield takeEvery(LOGOUT_USER, logoutUser)
}

export default authSaga
