import { call, put, takeEvery } from "redux-saga/effects"

import moment from "moment"

// Crypto Redux States
import {
  POST_UPLOAD_FILE,
  MODIFY_MAILS,
  GET_RECIPIENTS_TO_MAILS,
  SEND_INBOX_MAILS,
  GET_INBOX_MAILS,
  ADD_NEW_INBOX_MAIL,
  DELETE_INBOX_MAIL,
  GET_STARRED_MAILS,
  GET_IMPORTANT_MAILS,
  GET_DRAFT_MAILS,
  GET_SENT_MAILS,
  GET_TRASH_MAILS,
} from "./actionTypes"

import {
  getInboxMailsSuccess,
  getInboxMailsFail,
  addInboxMailFail,
  addInboxMailSuccess,
  deleteInboxMailSuccess,
  deleteInboxMailFail,
  getStarredMailsSuccess,
  getStarredMailsFail,
  getImportantMailsSuccess,
  getImportantMailsFail,
  getDraftMailsSuccess,
  getDraftMailsFail,
  getSentMailsSuccess,
  getSentMailsFail,
  getTrashMailsSuccess,
  getTrashMailsFail,
  modifyMailMailsSuccess,
  modifyMailMailsFail,
  sendEmailMailsSuccess,
  sendEmailMailsFail,
} from "./actions"
import {
  setDisplayNotification as setDisplayNotificationAction, 
  unSetDisplayNotification as unSetDisplayNotificationAction 
} from "../common/actions"

//Include Both Helper File with needed methods
import {
  getInboxMails,
  addNewInboxMail,
  deleteInboxMail,
  getStarredMails,
  getImportantMails,
  getDraftMails,
  getSentMails,
  getTrashMails,
  modifyMails,
  sendInboxMails,
} from "helpers/comunicabackend_helper"

function* fetchInboxMails({ jsonparameters, datainbox }) {
  try {
    //console.log("datainbox: " + JSON.stringify(datainbox))
    //console.log("1...store/mails/saga.js::fetchInboxMails::jsonparameters: " + JSON.stringify(jsonparameters) );
    const response = yield call(getInboxMails, jsonparameters)
    // console.log(
    //   "2...store/mails/saga.js::fetchInboxMails::yield call(getInboxMails, jsonparameters)::response: " +
    //     JSON.stringify(response)
    // );
    const mailsTransformed = response.datos.map(mensaje => {
      return {
        id: mensaje.id_Mensaje,
        fromid: mensaje.fromid,
        fromidContacto: mensaje.fromidContacto,
        from: mensaje.emisor,
        to: mensaje.receptor,
        toid: mensaje.toid,
        toidContacto: mensaje.toidContacto,
        to: mensaje.receptor,
        subject: mensaje.tema,
        message: mensaje.mensaje,
        read: mensaje.leido,
        fav: false,
        date: moment(mensaje.fecha_Registro).format("ll").split(",")[0],
      }
    })
    //console.log("mailsTransformed: " + JSON.stringify(mailsTransformed))
    const TotalUnreadMail = response.parametros.find(
      e => e.nombre === "TotalUnreadMail"
    )
    let datainboxupdated = {
      ...datainbox,
      unread: TotalUnreadMail ? TotalUnreadMail.valor : 0,
      currentpage: response.currentPage,
      pagesize: response.pageSize,
      totalmails: response.totalRowsCount,
      idcurrentmail: -1,
      mailselected: [],
    }
    //console.log("datainboxupdated: " + JSON.stringify(datainboxupdated))
    // console.log(
    //   "2...store/mails/saga.js::fetchInboxMails::yield call(getInboxMails, jsonparameters)::response: " +
    //     JSON.stringify(mailsTransformed)
    // );
    yield put(getInboxMailsSuccess(mailsTransformed, datainboxupdated))
  } catch (error) {
    yield put(getInboxMailsFail(error))
  }
}

function* fetchStarredMails() {
  try {
    const response = yield call(getStarredMails)
    yield put(getStarredMailsSuccess(response))
  } catch (error) {
    yield put(getStarredMailsFail(error))
  }
}

function* fetchImportantMails() {
  try {
    const response = yield call(getImportantMails)
    yield put(getImportantMailsSuccess(response))
  } catch (error) {
    yield put(getImportantMailsFail(error))
  }
}

function* fetchDraftMails() {
  try {
    const response = yield call(getDraftMails)
    yield put(getDraftMailsSuccess(response))
  } catch (error) {
    yield put(getDraftMailsFail(error))
  }
}

function* fetchSentMails() {
  try {
    const response = yield call(getSentMails)
    yield put(getSentMailsSuccess(response))
  } catch (error) {
    yield put(getSentMailsFail(error))
  }
}

function* fetchTrashMails() {
  try {
    const response = yield call(getTrashMails)
    yield put(getTrashMailsSuccess(response))
  } catch (error) {
    yield put(getTrashMailsFail(error))
  }
}

function* onAddNewInboxMail({ payload: inboxmail }) {
  try {
    const response = yield call(addNewInboxMail, inboxmail)
    yield put(addInboxMailSuccess(response))
  } catch (error) {
    yield put(addInboxMailFail(error))
  }
}

function* onDeleteInboxMail({ payload: id }) {
  try {
    const response = yield call(deleteInboxMail, id)
    if (response.status !== 'success') {
      yield put(deleteInboxMailFail(response.mensaje_Detalle ))
      yield put(setDisplayNotificationAction({ tipo: "error", titulo: "Error al tratar de borrar el mensaje.", mensaje: "Imposible borrar el correo." })) 
    } else {
      yield put(deleteInboxMailSuccess(response.datos))
    }
  } catch (error) {
    yield put(deleteInboxMailFail(error))
  }
}

function* onModifyMail({ jsonparameters }) {
  try {
    const response = yield call(modifyMails, jsonparameters)
    yield put(modifyMailMailsSuccess(response, jsonparameters.actionSuccess))
  } catch (error) {
    yield put(modifyMailMailsFail(error, jsonparameters.actionFail))
  }
}

function* onSendNewEmail({ payload: email }) {
  try {
    const response = yield call(sendInboxMails, email)
    console.log("saga::onSendNewEmail::response: " + JSON.stringify(response))
    const toastNotification = {sucess: true, message: "Mensaje envíado con éxito.", title: "Envío éxitoso."}
    yield put(sendEmailMailsSuccess(toastNotification))
  } catch (error) {
    const toastNotification = {sucess: false, message: error, title: "Ocurrió un error al tratar de envíar el mensaje."}
    yield put(sendEmailMailsFail(toastNotification))
  }
}

function* mailsSaga() {
  yield takeEvery(GET_INBOX_MAILS, fetchInboxMails)
  yield takeEvery(GET_STARRED_MAILS, fetchStarredMails)
  yield takeEvery(GET_IMPORTANT_MAILS, fetchImportantMails)
  yield takeEvery(GET_DRAFT_MAILS, fetchDraftMails)
  yield takeEvery(GET_SENT_MAILS, fetchSentMails)
  yield takeEvery(GET_TRASH_MAILS, fetchTrashMails)
  yield takeEvery(ADD_NEW_INBOX_MAIL, onAddNewInboxMail)
  yield takeEvery(DELETE_INBOX_MAIL, onDeleteInboxMail)
  yield takeEvery(MODIFY_MAILS, onModifyMail)
  yield takeEvery(SEND_INBOX_MAILS, onSendNewEmail)
}

export default mailsSaga
