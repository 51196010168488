import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { Link, withRouter } from "react-router-dom"
import toastr from "toastr"
import "toastr/build/toastr.min.css"
import MetaTags from "react-meta-tags"
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  CardText,
  Col,
  Container,
  Input,
  Label,
  Row,
  TabContent,
  TabPane,
  Nav,
  Media,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  NavItem,
  NavLink,
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

import classnames from "classnames"

import { map } from "lodash"

// Import Editor
import { Editor } from "react-draft-wysiwyg"

import {
  getInboxMails as onGetInboxMails,
  getStarredMails as onGetStarredMails,
  getImportantMails as onGetImportantMails,
  getDraftMails as onGetDraftMails,
  getSentMails as onGetSentMails,
  getTrashMails as onGetTrashMails,
  modifyMailMails as onModifyMailMails,
  updateDataInboxMails as onUpdateDataInboxMails,
  sendEmailMails as onSendInboxMails,
  displaySentNotificationMails as onDisplaySentNotificationMails,
} from "store/mails/actions"

//Import Email Topbar
import EmailToolbar from "./email-toolbar"

//Import images
import avatar1 from "../../assets/images/users/avatar-1.jpg"

import {
  getoSolicitudBase,
  deleteInboxMail,
  getIdColegioFromUserAuthenticated,
  getIdContactoUserAuthenticated,
} from "../../helpers/comunicabackend_helper"

//redux
import { useSelector, useDispatch } from "react-redux"

const numeromailsxpagina = 20

const datainboxInit = {
  unread: 0,
  currentpage: 0,
  pagesize: 0,
  totalmails: 0,
  idcurrentmail: -1,
  mailselected: [],
}

const EmailInbox = props => {
  const dispatch = useDispatch()

  const {
    inboxmails,
    starredmails,
    importantmails,
    trashmails,
    draftmails,
    sentmails,
    datainbox = datainboxInit,
    displayNotification = undefined,
    loading,
  } = useSelector(state => ({
    inboxmails: state.mails.inboxmails,
    starredmails: state.mails.starredmails,
    importantmails: state.mails.importantmails,
    trashmails: state.mails.trashmails,
    draftmails: state.mails.draftmails,
    sentmails: state.mails.sentmails,
    datainbox: state.mails.datainbox,
    displayNotification: state.mails.displayNotification,
    loading: state.mails.loading,
  }))

  const [activeTab, setactiveTab] = useState("1")
  const [modal, setmodal] = useState(false)
  const [currentEmail, setCurrentEmail] = useState({})

  useEffect(() => {
    const Parametros = [{ nombre: "RowsOfPage", valor: numeromailsxpagina }]
    const oSolicitudBase = getoSolicitudBase(
      "onGetInboxMails",
      "",
      [],
      Parametros
    )
    dispatch(onGetInboxMails(oSolicitudBase, datainbox))

    // dispatch(onGetStarredMails())
    // dispatch(onGetImportantMails())
    // dispatch(onGetDraftMails())
    // dispatch(onGetSentMails())
    // dispatch(onGetTrashMails())
  }, [dispatch])

  useEffect(() => {
    console.log("useEffect::displayNotification: " + displayNotification)
    if (displayNotification !== undefined) {
      displayNotification
        ? toastr.success(displayNotification.message, displayNotification.title)
        : toastr.error(displayNotification.message, displayNotification.title)
      dispatch(onDisplaySentNotificationMails(undefined))
    }
  }, [displayNotification])

  const onOpenMailHandler = id => {
    const email = inboxmails.find(x => x.id === id)
    !email.read &&
      dispatch(
        onUpdateDataInboxMails({ ...datainbox, unread: datainbox.unread - 1 })
      )
    email.read = true
    setCurrentEmail(email)
    setactiveTab("7")
    const oSolicitudBase = getoSolicitudBase(
      "onOpenMailHandler",
      "MarcarComoLeido",
      [email.id]
    )
    dispatch(onModifyMailMails(oSolicitudBase))
  }

  const onPageIncrementHandler = () => {
    const Parametros = [
      { nombre: "PageNumber", valor: datainbox.currentpage + 1 },
      { nombre: "RowsOfPage", valor: numeromailsxpagina },
    ]
    const oSolicitudBase = getoSolicitudBase(
      "onPageIncrementHandler",
      "",
      [],
      Parametros
    )
    dispatch(onGetInboxMails(oSolicitudBase, datainbox))
  }

  const onPageDecrementHandler = () => {
    const Parametros = [
      { nombre: "PageNumber", valor: datainbox.currentpage - 1 },
      { nombre: "RowsOfPage", valor: numeromailsxpagina },
    ]
    const oSolicitudBase = getoSolicitudBase(
      "onPageDecrementHandler",
      "",
      [],
      Parametros
    )
    dispatch(onGetInboxMails(oSolicitudBase, datainbox))
  }

  const onRefreshInbox = () => {
    const Parametros = [
      { nombre: "PageNumber", valor: datainbox.currentpage },
      { nombre: "RowsOfPage", valor: numeromailsxpagina },
    ]
    const oSolicitudBase = getoSolicitudBase(
      "onRefreshInbox",
      "",
      [],
      Parametros
    )
    dispatch(onGetInboxMails(oSolicitudBase, datainbox))
  }

  const openInboxHanlder = () => {
    setCurrentEmail({})
    setactiveTab("1")
  }

  const onDeleteCurrentEmailHanlder = () => {
    deleteInboxMail(currentEmail.id)
    setCurrentEmail({})
    onRefreshInbox()
    setactiveTab("1")
  }

  const onSendEmailHandler = () => {
    var Tema = currentEmail.subject ? currentEmail.subject : ""
    var MensajeOriginal = currentEmail.message ? currentEmail.message : ""
    var Mensaje = document.getElementById("textareaMensaje").value

    const listaEmails = []

    if (Mensaje.length > 0) {
      const email = {
        id_Canal_Comunicacion: 1,
        id_Colegio: getIdColegioFromUserAuthenticated(),
        Mensaje:
          Mensaje +
          "<hr><br>" +
          "De: " +
          currentEmail.from +
          "<br>" +
          "Para: " +
          currentEmail.to +
          "<br>" +
          "Tema: " +
          Tema +
          "<br>" +
          "Fecha: " +
          currentEmail.date +
          "<br>" +
          "<br>" +
          MensajeOriginal,
        Tema: "Re: " + Tema,
        fromidContacto: getIdContactoUserAuthenticated(),
        toid: currentEmail.fromid,
        toidContacto: currentEmail.fromidContacto
          ? currentEmail.fromidContacto
          : -1,
        //toidListaDistribucion: 31079,
      }
      listaEmails.push(email)
      console.log("onSendEmailHandler::/onSendInboxMails")
      dispatch(onSendInboxMails(email))
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Inbox | Comunica</title>
        </MetaTags>
        <Container fluid>
          {/* Ocultar divs depende el grid */}
          {/* <div className="d-lg-none">1 hide on lg and wider screens</div>
          <div className="d-none d-lg-block">
            2 hide on screens smaller than lg
          </div> */}
          {/* Render Breadcrumbs */}
          {/* <Breadcrumbs title="Email" breadcrumbItem="Inbox" /> */}
          <Row>
            <Col xs="12">
              {/* Render Email SideBar */}
              <Card className="email-leftbar">
                {" "}
                {/*"email-leftbar d-none d-lg-block"*/}
                <Button
                  type="button"
                  color="danger"
                  className=""
                  onClick={() => {
                    setmodal(!modal)
                  }}
                  block
                >
                  Compose
                </Button>
                <div className="mail-list mt-4">
                  <Nav tabs className="nav-tabs-custom" vertical role="tablist">
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: activeTab === "1",
                        })}
                        onClick={() => {
                          setactiveTab("1")
                        }}
                      >
                        <i className="mdi mdi-email-outline me-2"></i> Inbox{" "}
                        <span className="ml-1 float-end">
                          ({datainbox.unread})
                        </span>
                      </NavLink>
                    </NavItem>

                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: activeTab === "2",
                        })}
                        onClick={() => {
                          setactiveTab("2")
                        }}
                      >
                        <i className="mdi mdi-star-outline me-2"></i>Starred
                      </NavLink>
                    </NavItem>

                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: activeTab === "3",
                        })}
                        onClick={() => {
                          setactiveTab("3")
                        }}
                      >
                        <i className="mdi mdi-diamond-stone me-2"></i>Important
                      </NavLink>
                    </NavItem>

                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: activeTab === "4",
                        })}
                        onClick={() => {
                          setactiveTab("4")
                        }}
                      >
                        <i className="mdi mdi-file-outline me-2"></i>Draft
                      </NavLink>
                    </NavItem>

                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: activeTab === "5",
                        })}
                        onClick={() => {
                          setactiveTab("5")
                        }}
                      >
                        <i className="mdi mdi-email-check-outline me-2"></i>Sent
                        Mail
                      </NavLink>
                    </NavItem>

                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: activeTab === "6",
                        })}
                        onClick={() => {
                          setactiveTab("6")
                        }}
                      >
                        <i className="mdi mdi-trash-can-outline me-2"></i>Trash
                      </NavLink>
                    </NavItem>
                  </Nav>
                </div>
              </Card>

              <Modal
                isOpen={modal}
                role="dialog"
                autoFocus={true}
                centered={true}
                className="exampleModal"
                tabIndex="-1"
                toggle={() => {
                  setmodal(!modal)
                }}
              >
                <div className="modal-content">
                  <ModalHeader
                    toggle={() => {
                      setmodal(!modal)
                    }}
                  >
                    New Message
                  </ModalHeader>
                  <ModalBody>
                    <form>
                      <div className="mb-3">
                        <Input
                          type="email"
                          className="form-control"
                          placeholder="To"
                        />
                      </div>

                      <div className="mb-3">
                        <Input
                          type="text"
                          className="form-control"
                          placeholder="Subject"
                        />
                      </div>
                      <Editor
                        toolbarClassName="toolbarClassName"
                        wrapperClassName="wrapperClassName"
                        editorClassName="editorClassName"
                      />
                    </form>
                  </ModalBody>
                  <ModalFooter>
                    <Button
                      type="button"
                      color="secondary"
                      onClick={() => {
                        setmodal(!modal)
                      }}
                    >
                      Close
                    </Button>
                    <Button type="button" color="primary">
                      Send <i className="fab fa-telegram-plane ms-1"></i>
                    </Button>
                  </ModalFooter>
                </div>
              </Modal>
              <div className="email-rightbar mb-3">
                <Card>
                  {/* Render Email Top Tool Bar */}
                  <EmailToolbar
                    datainbox={datainbox}
                    activeTab={activeTab}
                    onPageDecrementHandler={onPageDecrementHandler}
                    onPageIncrementHandler={onPageIncrementHandler}
                    onRefreshInbox={onRefreshInbox}
                    openInboxHanlder={openInboxHanlder}
                    onDeleteCurrentEmailHanlder={onDeleteCurrentEmailHanlder}
                  />
                  <TabContent activeTab={activeTab}>
                    <TabPane tabId="1">
                      <ul className="message-list">
                        {/* {
                          console.log(
                          "inboxmails: " + JSON.stringify(inboxmails)
                          )
                        } */}
                        {map(inboxmails, (email, key) => (
                          <li key={key} className={email.read ? "" : "unread"}>
                            <div className="col-mail col-mail-1">
                              <div className="checkbox-wrapper-mail">
                                <Input
                                  type="checkbox"
                                  id={email.id}
                                  // checked={datainbox.mailselected.find(
                                  //   id => id === email.id
                                  // )}
                                  onChange={() => {
                                    ;() => {}
                                    // this.onMailSelected(email.id);
                                  }}
                                />
                                <Label htmlFor={email.id} className="toggle" />
                              </div>
                              <Link
                                to="#"
                                className="title"
                                onClick={() => {
                                  onOpenMailHandler(email.id)
                                }}
                              >
                                {email.from}
                              </Link>
                              <span className="star-toggle far fa-star" />
                            </div>
                            <div className="col-mail col-mail-2">
                              <Link
                                to="#"
                                className="title"
                                onClick={
                                  () => {}
                                  // this.onOpenMailHandler(email.id)
                                }
                              >
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: email.subject,
                                  }}
                                ></div>
                              </Link>

                              <div className="date">{email.date}</div>
                            </div>
                          </li>
                        ))}
                      </ul>
                    </TabPane>
                    <TabPane tabId="2">
                      <ul className="message-list">
                        {map(starredmails, (starred, key) => (
                          <li
                            key={"starred-" + key}
                            className={starred.read ? "" : "unread"}
                          >
                            <div className="col-mail col-mail-1">
                              <div className="checkbox-wrapper-mail">
                                <Input
                                  type="checkbox"
                                  id={starred.id + "starred"}
                                />
                                <Label
                                  htmlFor={starred.id + "starred"}
                                  className="toggle"
                                />
                              </div>
                              <Link to="#" className="title">
                                {starred.name}
                              </Link>
                              <span className="star-toggle fas fa-star" />
                            </div>
                            <div className="col-mail col-mail-2">
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: starred.description,
                                }}
                              ></div>
                              <div className="date">{starred.date}</div>
                            </div>
                          </li>
                        ))}
                      </ul>
                    </TabPane>
                    <TabPane tabId="3">
                      <ul className="message-list">
                        {map(importantmails, (important, key) => (
                          <li
                            key={"important-" + key}
                            className={important.read ? "" : "unread"}
                          >
                            <div className="col-mail col-mail-1">
                              <div className="checkbox-wrapper-mail">
                                <Input
                                  type="checkbox"
                                  id={important.id + "important"}
                                />
                                <Label
                                  htmlFor={important.id + "important"}
                                  className="toggle"
                                />
                              </div>
                              <Link to="#" className="title">
                                {important.name}
                              </Link>
                              <span className="star-toggle far fa-star" />
                            </div>
                            <div className="col-mail col-mail-2">
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: important.description,
                                }}
                              ></div>
                              <div className="date">{important.date}</div>
                            </div>
                          </li>
                        ))}
                      </ul>
                    </TabPane>
                    <TabPane tabId="4">
                      <ul className="message-list">
                        {map(draftmails, (draft, key) => (
                          <li
                            key={"draft-" + key}
                            className={draft.read ? "" : "unread"}
                          >
                            <div className="col-mail col-mail-1">
                              <div className="checkbox-wrapper-mail">
                                <Input
                                  type="checkbox"
                                  id={draft.id + "draft"}
                                />
                                <Label
                                  htmlFor={draft.id + "draft"}
                                  className="toggle"
                                />
                              </div>
                              <Link to="#" className="title">
                                {draft.name}
                              </Link>
                              <span className="star-toggle far fa-star" />
                            </div>
                            <div className="col-mail col-mail-2">
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: draft.description,
                                }}
                              ></div>
                              <div className="date">{draft.date}</div>
                            </div>
                          </li>
                        ))}
                      </ul>
                    </TabPane>
                    <TabPane tabId="5">
                      <ul className="message-list">
                        {map(sentmails, (sent, key) => (
                          <li
                            key={"sent-" + key}
                            className={sent.read ? "" : "unread"}
                          >
                            <div className="col-mail col-mail-1">
                              <div className="checkbox-wrapper-mail">
                                <Input type="checkbox" id={sent.id + "sent"} />
                                <Label
                                  htmlFor={sent.id + "sent"}
                                  className="toggle"
                                />
                              </div>
                              <Link to="#" className="title">
                                {sent.name}
                              </Link>
                              <span className="star-toggle far fa-star" />
                            </div>
                            <div className="col-mail col-mail-2">
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: sent.description,
                                }}
                              ></div>
                              <div className="date">{sent.date}</div>
                            </div>
                          </li>
                        ))}
                      </ul>
                    </TabPane>
                    <TabPane tabId="6">
                      <ul className="message-list">
                        {map(trashmails, (trash, key) => (
                          <li
                            key={"trash-" + key}
                            className={trash.read ? "" : "unread"}
                          >
                            <div className="col-mail col-mail-1">
                              <div className="checkbox-wrapper-mail">
                                <Input
                                  type="checkbox"
                                  id={trash.id + "trash"}
                                />
                                <Label
                                  htmlFor={trash.id + "trash"}
                                  className="toggle"
                                />
                              </div>
                              <Link to="#" className="title">
                                {trash.name}
                              </Link>
                              <span className="star-toggle far fa-star" />
                            </div>
                            <div className="col-mail col-mail-2">
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: trash.description,
                                }}
                              ></div>
                              <div className="date">{trash.date}</div>
                            </div>
                          </li>
                        ))}
                      </ul>
                    </TabPane>
                    <TabPane tabId="7">
                      <Card>
                        <CardBody>
                          <div className="float-end">{currentEmail.date}</div>
                          <h4 className="mt-0 font-size-16">
                            {currentEmail && currentEmail.subject
                              ? currentEmail.subject
                              : "[Tema]"}
                          </h4>
                          <Media className="mb-4">
                            <img
                              className="d-flex me-3 rounded-circle avatar-sm"
                              src={avatar1}
                              alt={currentEmail.from}
                            />
                            <Media body>
                              <h5 className="font-size-14 mt-1">
                                De:{" "}
                                {currentEmail && currentEmail.from
                                  ? currentEmail.from
                                  : "Sistema"}
                              </h5>
                              <h5 className="font-size-14 mt-1">
                                Para:{" "}
                                {currentEmail && currentEmail.to
                                  ? currentEmail.to
                                  : ""}
                              </h5>
                              {/* <small className="text-muted">
                                  support@domain.com
                                </small> */}
                            </Media>
                          </Media>

                          <div
                            dangerouslySetInnerHTML={{
                              __html: currentEmail.message,
                            }}
                          />
                        </CardBody>
                      </Card>
                      <Card>
                        <CardBody>
                          <CardTitle className="h4 mt-0 border-bottom">
                            Responder
                          </CardTitle>
                          <CardText>
                            <Input
                              type="textarea"
                              id="textareaMensaje"
                              //onChange={this.textareachange}
                              //maxLength="225"
                              rows="4"
                              placeholder=""
                            />
                          </CardText>
                        </CardBody>
                        <CardFooter className="bg-transparent border-top ">
                          {/* <div className="col-xl-3 col-lg-4 col-sm-6 float-end border">
                              <i className="mdi mdi-paperclip"></i>
                            </div> */}
                          <div className="auto border">
                            <Button
                              type="button"
                              color="primary"
                              className="btn btn-primary"
                              onClick={onSendEmailHandler}
                            >
                              Send{" "}
                              <i className="fab fa-telegram-plane ms-1"></i>
                            </Button>
                          </div>
                        </CardFooter>
                        <CardFooter className="bg-transparent border-top icon-demo-content"></CardFooter>
                      </Card>
                    </TabPane>
                  </TabContent>
                </Card>
                {activeTab == "1" && (
                  <Row>
                    <Col xs="7">
                      Showing{" "}
                      {datainbox.pagesize * datainbox.currentpage -
                        datainbox.pagesize +
                        1}{" "}
                      -{" "}
                      {datainbox.pagesize * datainbox.currentpage <=
                      datainbox.totalmails
                        ? datainbox.pagesize * datainbox.currentpage
                        : datainbox.pagesize * datainbox.currentpage +
                          (datainbox.totalmails -
                            datainbox.pagesize * datainbox.currentpage)}{" "}
                      of {datainbox.totalmails}
                    </Col>
                    <Col xs="5">
                      <div className="btn-group float-end">
                        <Button
                          type="button"
                          color="success"
                          size="sm"
                          onClick={onPageDecrementHandler}
                          className={
                            datainbox.currentpage == 1 ? "disabled" : ""
                          }
                        >
                          <i className="fa fa-chevron-left" />
                        </Button>
                        <Button
                          type="button"
                          color="success"
                          size="sm"
                          onClick={onPageIncrementHandler}
                          className={
                            datainbox.pagesize * datainbox.currentpage >=
                            datainbox.totalmails
                              ? "disabled"
                              : ""
                          }
                        >
                          <i className="fa fa-chevron-right" />
                        </Button>
                      </div>
                    </Col>
                  </Row>
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

EmailInbox.propTypes = {
  inboxmails: PropTypes.array,
  starredmails: PropTypes.array,
  onGetInboxMails: PropTypes.func,
  onGetStarredMails: PropTypes.func,
  importantmails: PropTypes.array,
  onGetImportantMails: PropTypes.func,
  importantmails: PropTypes.array,
  onGetImportantMails: PropTypes.func,
  importantmails: PropTypes.array,
  onGetImportantMails: PropTypes.func,
  importantmails: PropTypes.array,
  onGetImportantMails: PropTypes.func,
  onModifyMailMails: PropTypes.func,
  onUpdateDataInboxMails: PropTypes.func,
  onSendInboxMails: PropTypes.func,
}

export default withRouter(EmailInbox)
