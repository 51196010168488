import React, { useState, useEffect } from "react"
import MetaTags from "react-meta-tags"
import { Link } from "react-router-dom"
import images from "assets/images"
import toastr from "toastr"
import "toastr/build/toastr.min.css"
import {
  Button,
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  FormFeedback,
  NavItem,
  NavLink,
  CardTitle,
  CardSubtitle,
  TabPane,
  Card,
  CardBody,
  Table,
  TabContent,
  Nav,
  CardText,
  Modal,
} from "reactstrap"
import { map } from "lodash"
import Select from "react-select"
import ButtonDev from 'devextreme-react/button';

//redux
import { useSelector, useDispatch } from "react-redux"
import {
  useLibretaStatus,
  useModal_backdrop,
  useLibretaGruposAcademicos,
  useIdGrupoAcademicoSelected,
  useIdAlumnoSelected,
  useAlumnoSelected,
  useLibretaAlumnos,
  useLibretaError,
  useLibretaLoading,
  useLibretaTipoEvento,
  useLibretaEventoFechaHora,
  useLibretaEventoEstadoAnimo,
  useLibretaEventosCatalogo,
  useLibretaEventosCatalogoEstadosAnimo,
  useLibretaEventosCatalogoComidaCantidad,
  useLibretaEventosComidaIdCantidad,
  useLibretaEventosEventoRegistrado,
  useDisplayNotification,
  useLibretaEventosDiapersPipi,
  useLibretaEventosDiapersPopo,
  useLibretaEventosSiestaHora,
  useLibretaEventosSiestaMinutos,
  useLibretaEventosSiestaDuracion,
  useLibretaEventosBathSet,
  useLibretaEventosCatalogoComidaObservaciones,
  useLibretaEventosCatalogoDiapersObservaciones,
  useLibretaEventosCatalogoSiestaObservaciones,
  useLibretaEventosCatalogoBathObservaciones,
  useLibretaEventosCatalogoAcademiaObservaciones,
  useIdLibretaObservacion,
  useLibretaObservacionComida,
  useLibretaObservacionDiapers,
  useLibretaObservacionSiesta,
  useLibretaObservacionBath,
  useLibretaObservacionAcademia,
  useLibretaOGrupoAcademicoSelected,
} from "../../store/libreta/selectors"

import { useAuthUserGmtOffset } from "../../store/auth/loginState/selectors"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb2"

import classnames from "classnames";

//Import Card
import CardContact from "../Contacts/card-contact"

import {
  eventAction as onEventAction,

  actionSetDisplayNotification as onSetDisplayNotification,
  actionUnSetDisplayNotification as onUnSetDisplayNotification,

  selectAcademicGroupAction as onSelectAcademicGroupAction,
  selectUserAction as onSelectUserAction,
  setAlumnoLibretaSelected as onSetAlumnoLibretaSelected,

  eventOpenModalAction as onEventOpenModalAction,
  eventCloseModalAction as onEventCloseModalAction,

  setTipoEvento as onSetTipoEvento,
  getGruposAcademicosLibreta as onGruposAcademicosLibreta,
  setIdGrupoAcademicoLibretaSelected as onSetIdGrupoAcademicoLibretaSelected,
  setLibretaOGrupoAcademicoSelected as onSetLibretaOGrupoAcademicoSelected,
  getAlumnosLibreta as onAlumnosLibreta,
  setEventosFechaHora as onSetEventosFechaHora,
  setEventosEstadoAnimo as onSetEventosEstadoAnimo,
  getLibretaCatalogosEventos as onGetLibretaCatalogosEventos,
  setEventosCantidadComida as onSetEventosCantidadComida,

  setEventosComidaObservaciones as onSetEventosComidaObservaciones,
  setEventosDiapersObservaciones as onSetEventosDiapersObservaciones,
  setEventosSiestaObservaciones as onSetEventosSiestaObservaciones,
  setEventosBathObservaciones as onSetEventosBathObservaciones,
  setEventosAcademiaObservaciones as onSetEventosAcademiaObservaciones,

  eventRegisterEventAction as onEventRegisterEvent,
  setEventosDiapersPipi as onSetEventosDiapersPipi,
  setEventosDiapersPopo as onSetEventosDiapersPopo,
  setEventosSiestaHora as onSetEventosSiestaHora,
  setEventosSiestaMinutos as onSetEventosSiestaMinutos,
  setEventosSiestaDuracion as onSetEventosSiestaDuracion,
  setEventosFuealBath as onSetEventosFuealBath,
  setEventosIdObservacion as onSetEventosIdObservacion,
  actionSetEstadoEventos as onActionSetEstadoEventos,
} from "../../store/libreta/actions"

import {
  getoSolicitudBase,
} from "../../helpers/comunicabackend_helper"
import { date } from "yup"

const Libreta = () => {

  const dispatch = useDispatch()

  // Con este objeto recorreré la lista para el paginado del alumno
  const [oPaginado, setPaginado] = useState({ oAlumnoAnterior: {}, oAlumnoAnteriorVacio: false, oAlumnoSiguiente: {}, oAlumnoSiguienteVacio: false });

  const libretaStatus = useLibretaStatus()
  const gruposAcademicos = useLibretaGruposAcademicos()
  const alumnos = useLibretaAlumnos()
  const LibretaLoading = useLibretaLoading()
  const idGrupoAcademicoSelected = useIdGrupoAcademicoSelected()
  const oAlumnoSelected = useAlumnoSelected()
  const tipoEvento = useLibretaTipoEvento()
  const EventoFechaHora = useLibretaEventoFechaHora()
  const EventoEstadoAnimo = useLibretaEventoEstadoAnimo()
  const EventosCatalogo = useLibretaEventosCatalogo()
  const EventosCatalogoEstadosAnimo = useLibretaEventosCatalogoEstadosAnimo()
  const EventosCatalogoComidaCantidad = useLibretaEventosCatalogoComidaCantidad()
  const EventosComidaIdCantidad = useLibretaEventosComidaIdCantidad()
  const EventosEventoRegistrado = useLibretaEventosEventoRegistrado()
  const displayNotification = useDisplayNotification()
  const pipi = useLibretaEventosDiapersPipi()
  const popo = useLibretaEventosDiapersPopo()
  const siestaHora = useLibretaEventosSiestaHora()
  const siestaMinutos = useLibretaEventosSiestaMinutos()
  const siestaDuracion = useLibretaEventosSiestaDuracion()
  const fuealBath = useLibretaEventosBathSet()

  const EventosCatalogoComidaObservaciones = useLibretaEventosCatalogoComidaObservaciones()
  const EventosCatalogoDiapersObservaciones = useLibretaEventosCatalogoDiapersObservaciones()
  const EventosCatalogoSiestaObservaciones = useLibretaEventosCatalogoSiestaObservaciones()
  const EventosCatalogoBathObservaciones = useLibretaEventosCatalogoBathObservaciones()
  const EventosCatalogoAcademiaObservaciones = useLibretaEventosCatalogoAcademiaObservaciones()

  const ComidaObservaciones = useLibretaObservacionComida()
  const DiapersObservaciones = useLibretaObservacionDiapers()
  const SiestaObservaciones = useLibretaObservacionSiesta()
  const BathObservaciones = useLibretaObservacionBath()
  const AcademiaObservaciones = useLibretaObservacionAcademia()

  const UserGmtOffset = useAuthUserGmtOffset()
  const idLibretaObservacion = useIdLibretaObservacion()
  const oGrupoAcademicoSelected = useLibretaOGrupoAcademicoSelected()
  const openModal = useModal_backdrop()

  console.log("--------------| iniciando index|--------------")
  // console.log("libretaStatus: ", libretaStatus)
  //console.log("gruposAcademicos: ", gruposAcademicos)
  console.log("alumnos: ", alumnos)
  // console.log("idGrupoAcademicoSelected: ", idGrupoAcademicoSelected)
  // console.log("useLibretaLoading: ", LibretaLoading)
  console.log("oAlumnoSelected: ", oAlumnoSelected)
  // console.log("tipoEvento: ", tipoEvento)
  // console.log("EventoFechaHora: ", EventoFechaHora)
  //console.log("EventoEstadoAnimo: ", EventoEstadoAnimo)
  // console.log("EventosCatalogo: ", EventosCatalogo)
  // console.log("EventosComidaIdCantidad: ", EventosComidaIdCantidad)
  // console.log("EventosEventoRegistrado: ", EventosEventoRegistrado)
  // console.log("pipi: ", pipi)
  // console.log("popo: ", popo)
  // console.log("siestaHora: ", siestaHora)
  // console.log("siestaMinutos: ", siestaMinutos)
  // console.log("siestaDuracion: ", siestaDuracion)
  // console.log("fuealBath: ", fuealBath)

  // console.log("EventosComidaObservaciones: ", ComidaObservaciones)
  // console.log("EventosDiapersObservaciones: ", DiapersObservaciones)
  // console.log("EventosSiestaObservaciones: ", SiestaObservaciones)
  // console.log("EventosBathObservaciones: ", BathObservaciones)
  // console.log("EventosAcademiaObservaciones: ", AcademiaObservaciones)
  // console.log("UserGmtOffset: ", UserGmtOffset)
  // console.log("idLibretaObservacion: ", idLibretaObservacion)
  // console.log("EventosCatalogoComidaObservaciones:", EventosCatalogoComidaObservaciones)
  // console.log("EventosCatalogoEstadosAnimo: ", EventosCatalogoEstadosAnimo)
  // console.log("EventosCatalogoComidaCantidad: ", EventosCatalogoComidaCantidad)
  //console.log("oGrupoAcademicoSelected: ", oGrupoAcademicoSelected)



  //set Estado Eventos
  useEffect(() => {

    console.log("--------------------------------------")
    console.log("useEffect :: set Estado Eventos")

    dispatch(onActionSetEstadoEventos())
  }, [])

  // Cuando cambie el grupo seleccionado
  useEffect(() => {
    const oSolicitudBase_Alumnos = getoSolicitudBase(
      "Libreta::index::useEffect",
      "getalumnoslibreta",
      [],
      [{ nombre: "id_Grupo_Academico", valor: oGrupoAcademicoSelected.id_Grupo_Academico }]
    );
    dispatch(onAlumnosLibreta(oSolicitudBase_Alumnos))
  }, [dispatch, oGrupoAcademicoSelected])

  // Grupos Academicos
  useEffect(() => {
    const oSolicitudBase_GruposAcademicos = getoSolicitudBase(
      "Libreta::index::useEffect",
      "getgruposacademicoslibreta",
      [],
      []
    )
    if (gruposAcademicos && !gruposAcademicos.length) {
      dispatch(onGruposAcademicosLibreta(oSolicitudBase_GruposAcademicos))
    }
    const oSolicitudBase_Catalogos = getoSolicitudBase(
      "Libreta::useEffect",
      "onGetLibretaCatalogosEventos",
      [],
      []
    );
    dispatch(onGetLibretaCatalogosEventos(oSolicitudBase_Catalogos));
  }, [dispatch, gruposAcademicos])

  // Catálogos
  useEffect(() => {
    const oSolicitudBase_Catalogos = getoSolicitudBase(
      "Libreta::useEffect",
      "onGetLibretaCatalogosEventos",
      [],
      []
    );
    dispatch(onGetLibretaCatalogosEventos(oSolicitudBase_Catalogos));
  }, [])

  useEffect(() => {
    // a la fecha actual, le resto el GmtOffset multiplicado por 60 minutos, por 60 segundos por mil
    var final = new Date(new Date().getTime() + (UserGmtOffset * 60 * 60000));
    dispatch(onSetEventosFechaHora(final.toISOString().split('.')[0]))
  }, [dispatch, onSetEventosFechaHora, UserGmtOffset])

  useEffect(() => {
    console.log("useEffect::displayNotification: ", displayNotification)
    if (displayNotification !== undefined) {
      if (displayNotification.tipo) {
        switch (displayNotification.tipo) {
          case "success":
            toastr.success(
              displayNotification.mensaje,
              displayNotification.title
            )
            break
          case "info":
            toastr.info(displayNotification.mensaje, displayNotification.titulo)
            break
          case "warning":
            toastr.warning(
              displayNotification.mensaje,
              displayNotification.titulo
            )
            break
          case "error":
            toastr.error(
              displayNotification.mensaje,
              displayNotification.titulo
            )
            break
          default:
            break
        }
      }
      dispatch(onUnSetDisplayNotification());
    }
  }, [dispatch, onUnSetDisplayNotification, displayNotification])

  const [customLibretaActiveTab, setcustomLibretaActiveTab] = useState("comida");

  const selectUser = user => {
    dispatch(onSelectUserAction(user));
    removeBodyCss();
  };

  const selectAlumno = alumno => {

    console.log('***************** |  fn select Alumno  |**************************** ')
    console.log('alumno: ', alumno)

    const index = alumnos.map(alumnoPaginado => alumnoPaginado.id_Alumno).indexOf(alumno.id_Alumno);
    console.log('index: ', index)
    if (index == -1) {
      // No se encontró el objeto, debo de lanzar un error
      dispatch(onSetDisplayNotification({ tipo: "error", titulo: "No se encontró el alumno en la lista de alumnos", mensaje: "No funcionará el paginado" }));
    } else {
      if (index == 0) {
        // El inicio de la lista
        setPaginado({ oAlumnoAnterior: {}, oAlumnoAnteriorVacio: true, oAlumnoSiguiente: alumnos[index + 1], oAlumnoSiguienteVacio: false })
        console.log("El inicio de la lista oAlumnoSiguiente: ", alumnos[index + 1])
      } else
        if (index < (alumnos.length - 1)) {
          // Normal
          setPaginado({ oAlumnoAnterior: alumnos[index - 1], oAlumnoAnteriorVacio: false, oAlumnoSiguiente: alumnos[index + 1], oAlumnoSiguienteVacio: false })
          console.log("oAlumnoAnterior: ", alumnos[index - 1])
          console.log("oAlumnoSiguiente: ", alumnos[index + 1])
        } else {
          // Final de la lista
          setPaginado({ oAlumnoAnterior: alumnos[index - 1], oAlumnoAnteriorVacio: false, oAlumnoSiguiente: {}, oAlumnoSiguienteVacio: true })
          console.log("Final de la lista oAlumnoAnterior: ", alumnos[index - 1])
        }
    }

    console.log('oPaginado: ', oPaginado)

    dispatch(onSetAlumnoLibretaSelected(alumno));
    dispatch(onEventOpenModalAction())

    removeBodyCss();
  };

  const togglePestañaGrupoAcademico = tab => {
    //console.log("togglePestañaGrupoAcademico::tab: ", tab)
    if (oGrupoAcademicoSelected.id_Grupo_Academico !== tab) {

      dispatch(onSetIdGrupoAcademicoLibretaSelected(tab))
      dispatch(onSetLibretaOGrupoAcademicoSelected(gruposAcademicos.filter(ogrupoAcademico => ogrupoAcademico.id_Grupo_Academico == tab)[0]))

      const oSolicitudBase_Alumnos = getoSolicitudBase(
        "Libreta::index::useEffect",
        "getalumnoslibreta",
        [],
        [{ nombre: "id_Grupo_Academico", valor: tab }]
      );
      dispatch(onAlumnosLibreta(oSolicitudBase_Alumnos))
    }
  };

  const toggleTipoEvento = tab => {
    if (customLibretaActiveTab !== tab) {

      setcustomLibretaActiveTab(tab)
      dispatch(onSetTipoEvento(tab))

      const oSolicitudBase_DataxTipoEvento = getoSolicitudBase(
        "Libreta::toggleTipoEvento",
        "getDataxTipoEvento",
        [],
        [{ nombre: "tipoEvento", valor: tab }]
      );
      //dispatch(onAlumnosLibreta(oSolicitudBase_Alumnos))

    }
  };

  const onDropdownChange = (event) => {
    if (event) {
      console.log("event.value: ", event.value, "-", event.label)
      dispatch(onSetEventosIdObservacion(event.value))
    }
  }

  const handleEventoFechaHoraChange = (event) => {
    console.log("EventoFechaHoraChange: ", event.target.value.toString())
    dispatch(onSetEventosFechaHora(event.target.value));
  }

  const handleEventoEstadoAnimo = (idEstadoAnimo) => {
    dispatch(onSetEventosEstadoAnimo(idEstadoAnimo));
  }

  const handleonChangeButtonBath = (event) => {
    switch (event.target.id) {
      case "BathButtonSi":
        dispatch(onSetEventosFuealBath());
        break;
      case "BathISi":
        dispatch(onSetEventosFuealBath());
        break;
      case "BathButtonNo":
        dispatch(onSetEventosFuealBath());
        break;
      case "BathINo":
        dispatch(onSetEventosFuealBath());
        break;

      default:
        break;
    }
  }

  const handleonChangeButtonDiapers = (event) => {
    switch (event.target.id) {
      case "pipi":
        dispatch(onSetEventosDiapersPipi());
        break;
      case "pipis":
        dispatch(onSetEventosDiapersPipi());
        break;
      case "popo":
        dispatch(onSetEventosDiapersPopo());
        break;
      case "popos":
        dispatch(onSetEventosDiapersPopo());
        break;

      default:
        break;
    }
  }

  const handleEventoComidaCantidad = (id_Cantidad) => {
    dispatch(onSetEventosCantidadComida(id_Cantidad));
  }

  const handleEventoonChangeObservaciones = (event) => {
    switch (event.target.id) {
      case "observacionesComida":
        dispatch(onSetEventosComidaObservaciones(event.target.value.toString()));
        break;
      case "observacionesPañal":
        dispatch(onSetEventosDiapersObservaciones(event.target.value.toString()));
        break;
      case "observacionesSiesta":
        dispatch(onSetEventosSiestaObservaciones(event.target.value.toString()));
        break;
      case "observacionesBath":
        dispatch(onSetEventosBathObservaciones(event.target.value.toString()));
        break;
      case "observacionesAcademia":
        dispatch(onSetEventosAcademiaObservaciones(event.target.value.toString()));
        break;

      default:
        break;
    }

  }

  const handleRegisterEvent = () => {
    let datos = []
    console.log('handleRegisterEvent::EventoEstadoAnimo: ', EventoEstadoAnimo)
    if (EventoEstadoAnimo < 1) {
      dispatch(onSetDisplayNotification({ tipo: "error", titulo: "Falta la opción Ánimo.", mensaje: "Debes seleccionar el ánimo del alumno." }));
    } else {
      switch (tipoEvento) {
        case "comida":
          if (EventosComidaIdCantidad < 1) {
            dispatch(onSetDisplayNotification({ tipo: "error", titulo: "Falta la opción Cantidad.", mensaje: "Debes seleccionar la cantidad de la comida." }));
          } else {
            datos = {
              id_Alumno: oAlumnoSelected.id_Alumno.toString(),
              id_Libreta_Cantidad: EventosComidaIdCantidad.toString(),
              id_Libreta_Estado_Animo: EventoEstadoAnimo.toString(),
              id_Libreta_Observacion: idLibretaObservacion ? idLibretaObservacion : -1,
              Observacion: ComidaObservaciones.toString(),
              Fecha: EventoFechaHora.toString()
            }
          }
          break;
        case "pañal":
          datos = {
            id_Alumno: oAlumnoSelected.id_Alumno.toString(),
            Pipi: pipi.toString(),
            Popo: popo.toString(),
            id_Libreta_Estado_Animo: EventoEstadoAnimo.toString(),
            id_Libreta_Observacion: idLibretaObservacion,
            Observacion: DiapersObservaciones.toString(),
            Fecha: EventoFechaHora.toString()
          }
          break;
        case "siesta":
          //console.log("EventoFechaHora: ", EventoFechaHora)
          var originalFecha = new Date(EventoFechaHora)
          // console.log("originalFecha: ", originalFecha)
          // console.log("Year: ", originalFecha.getFullYear())
          // console.log("Month: ", originalFecha.getMonth())
          // console.log("Dia: ", originalFecha.getUTCDate())
          // console.log("originalFecha.toISOString: ", originalFecha.toISOString())
          var inicio = new Date(
            originalFecha.getFullYear(),
            originalFecha.getMonth(),
            originalFecha.getUTCDate(),
            siestaHora.toString(),
            siestaMinutos.toString(),
            "0",
            "0")
          console.log("inicio: ", inicio.toISOString())
          var final = new Date(inicio.getTime() + siestaDuracion * 60000);
          console.log("final: ", final.toISOString())
          datos = {
            id_Alumno: oAlumnoSelected.id_Alumno.toString(),
            id_Libreta_Cantidad: EventosComidaIdCantidad.toString(),
            id_Libreta_Estado_Animo: EventoEstadoAnimo.toString(),
            id_Libreta_Observacion: idLibretaObservacion,
            Observacion: SiestaObservaciones.toString(),
            Inicio: inicio.toISOString(),
            Final: final.toISOString(),
            Fecha: EventoFechaHora.toString(),
          }
          break;
        case "baño":
          datos = {
            id_Alumno: oAlumnoSelected.id_Alumno.toString(),
            id_Libreta_Cantidad: EventosComidaIdCantidad.toString(),
            id_Libreta_Estado_Animo: EventoEstadoAnimo.toString(),
            id_Libreta_Observacion: idLibretaObservacion,
            Bath: fuealBath,
            Observacion: BathObservaciones.toString(),
            Fecha: EventoFechaHora.toString()
          }
          break;
        case "academia":
          datos = {
            id_Alumno: oAlumnoSelected.id_Alumno.toString(),
            id_Libreta_Cantidad: EventosComidaIdCantidad.toString(),
            id_Libreta_Estado_Animo: EventoEstadoAnimo.toString(),
            id_Libreta_Observacion: idLibretaObservacion,
            Observacion: AcademiaObservaciones.toString(),
            Fecha: EventoFechaHora.toString()
          }
          break;

        default:
          break;
      }
      if (datos.hasOwnProperty('id_Alumno')) {
        const oSolicitudBase_RegistroEvento = getoSolicitudBase(
          "Libreta::handleRegisterEvent",
          "onEventRegisterEvent",
          datos,
          [{ nombre: "tipoEvento", valor: tipoEvento }]
        );
        console.log("oSolicitudBase_RegistroEvento: ", oSolicitudBase_RegistroEvento);
        dispatch(onEventRegisterEvent(oSolicitudBase_RegistroEvento));
      }
    }
  }

  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Comunica | Libreta</title>
        </MetaTags>
        <Container fluid={true}>
          <Breadcrumbs title="Libreta"
            breadcrumbItems={[
              { title: "Eventos", link: "/libreta" },
              { title: "Revisión", link: "/libreta/revision" },
              { title: "Envío", link: "/libreta/envio" },
              { title: "Configuración", link: "/libreta/configuracion" },
            ]}
            activo="Eventos" />
          <Row className="no-gutters">
            {LibretaLoading ? (
              <span className="avatar-title rounded-circle bg-light">
                <div className="spinner-border text-primary m-1" role="status"><span className="sr-only">Loading...</span></div>
              </span>
            ) : ((<></>))}
            <Nav tabs className="nav-tabs-custom nav-justified">

              {map(gruposAcademicos, (o, keyga) => (
                <NavItem
                  key={keyga}>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      active: oGrupoAcademicoSelected.id_Grupo_Academico === o.id_Grupo_Academico,
                    })}
                    onClick={() => {
                      togglePestañaGrupoAcademico(o.id_Grupo_Academico);
                    }}
                  >
                    <span className="d-block d-sm-none">
                      <i className=""> {o.nombre_Grupo_Academico}</i>
                    </span>
                    <span className="d-none d-sm-block">{o.nombre_Grupo_Academico}</span>
                  </NavLink>
                </NavItem>
              ))
              }

            </Nav>

            <TabContent
              activeTab={oGrupoAcademicoSelected.id_Grupo_Academico}
              className="p-3 text-muted"
            >
              <TabPane tabId={oGrupoAcademicoSelected.id_Grupo_Academico}>
                <Row className="no-gutters">
                  {map(alumnos, (alumno, key) => (
                    <CardContact user={alumno} key={"_alumno_" + key}
                      Click={selectAlumno} />
                  ))}
                </Row>
              </TabPane>
            </TabContent>


          </Row>
        </Container>
        <Modal
          isOpen={useModal_backdrop()}
          backdrop={'static'}
          id="staticBackdrop"
        >
          <div className="modal-header p-2">
            <Container fluid={true} className=" p-0 m-0" >
              <Row>
                <Col className="d-flex align-items-end ">
                  <button type="button" className="btn-close"
                    onClick={() => {
                      toggleTipoEvento("comida");
                      dispatch(onEventCloseModalAction())
                    }} aria-label="Close">
                  </button>
                </Col>
              </Row>
              <Row className="bg-dark bg-soft" >
                {/* Columna con botón para regresar */}
                <Col className="col-auto d-flex align-items-center  g-0">

                  <Button
                    type="button"
                    color="success"
                    size="sm"
                    disabled={oPaginado.oAlumnoAnteriorVacio}
                    onClick={() => {
                      //dispatch(onSetAlumnoLibretaSelected(oPaginado.oAlumnoAnterior));
                      selectAlumno(oPaginado.oAlumnoAnterior)
                    }}
                  >
                    <i className="fa fa-chevron-left" />
                  </Button>

                </Col>

                {/* { Columna Central} */}
                <Col className="d-flex align-items-start g-1">

                  {/* Contenedor para abarcar el 100% del espacio */}
                  <Container fluid={true} className=" p-0 m-0" >
                    <Row>
                      <Col className="col-auto d-flex align-items-center ">
                        <Card className="text-center mt-2 mb-2">
                          <CardBody>
                            <div className="mb-4">
                              <img
                                className="rounded-circle avatar-sm"
                                src={images["avatar1"]}
                                alt=""
                                onClick={() => {
                                  console.log('disparando desde card');
                                }}
                              />
                            </div>
                            <h5 className="font-size-15 mb-1">
                              <Link to="#" className="text-dark">
                                {oAlumnoSelected.nombres + ' ' + oAlumnoSelected.apellido_Paterno}
                              </Link>
                            </h5>
                          </CardBody>
                        </Card>
                      </Col>


                      {/* { Columna Datos Reloj - Mamá} */}
                      <Col className="">

                        <Row className="mb-3">

                          <Col className="text-end">
                            {new Date(EventoFechaHora).toLocaleString().split(",")[1]}
                          </Col>

                        </Row>

                        <Row>

                          <Col className="text-start">
                            {oAlumnoSelected.grupos ?
                              oAlumnoSelected.grupos[0] ? (null) : oAlumnoSelected.grupos[0].nombre_Grupo_Academico
                              : null}

                          </Col>



                        </Row>
                      </Col>
                    </Row>
                  </Container>

                </Col>
                {/* { Columna Central} */}

                {/* Columna con botón para regresar */}
                <Col className="col-auto d-flex align-items-center  g-0">

                  <Button
                    type="button"
                    color="success"
                    size="sm"
                    disabled={oPaginado.oAlumnoSiguienteVacio}
                    onClick={() => {
                      //dispatch(onSetAlumnoLibretaSelected(oPaginado.oAlumnoSiguiente));
                      selectAlumno(oPaginado.oAlumnoSiguiente)
                    }}
                  >
                    <i className="fa fa-chevron-right" />
                  </Button>

                </Col>

                {/* Fila de Botónes */}
              </Row>
              <Row className=" d-flex align-items-center ">
                <Col className=" d-flex align-items-center ">
                  <div className="d-flex align-items-center gap-1 p-1 pt-1">
                    <button
                      type="button"
                      className={classnames({ 'btn-light': tipoEvento !== 'comida' }, { 'btn-primary': tipoEvento === 'comida' }, 'btn ')}
                      onClick={() => {
                        toggleTipoEvento("comida");
                      }}
                    >
                      <i className="mdi mdi-food d-block font-size-16"></i>{" "}
                      Comida
                    </button>
                    <button
                      type="button"
                      className={classnames({ 'btn-light': tipoEvento !== 'pañal' }, { 'btn-primary': tipoEvento === 'pañal' }, 'btn ')}
                      onClick={() => {
                        toggleTipoEvento("pañal");
                      }}
                    >
                      <i className="mdi mdi-human-baby-changing-table d-block font-size-16"></i>{" "}
                      Pañal
                    </button>
                    <button
                      type="button"
                      className={classnames({ 'btn-light': tipoEvento !== 'siesta' }, { 'btn-primary': tipoEvento === 'siesta' }, 'btn ')}
                      onClick={() => {
                        toggleTipoEvento("siesta");
                      }}
                    >
                      <i className="mdi mdi-bed d-block font-size-16"></i>{" "}
                      Siesta
                    </button>
                    <button
                      type="button"
                      className={classnames({ 'btn-light': tipoEvento !== 'baño' }, { 'btn-primary': tipoEvento === 'baño' }, 'btn ')}
                      onClick={() => {
                        toggleTipoEvento("baño");
                      }}
                    >
                      <i className="mdi mdi-beta d-block font-size-16"></i>{" "}
                      Baño
                    </button>
                    <button
                      type="button"
                      className={classnames({ 'btn-light': tipoEvento !== 'academia' }, { 'btn-primary': tipoEvento === 'academia' }, 'btn ')}
                      onClick={() => {
                        toggleTipoEvento("academia");
                      }}
                    >
                      <i className="mdi mdi-bank d-block font-size-16"></i>{" "}
                      Academia
                    </button>
                  </div>
                </Col>
              </Row>
              <Row className=" d-flex align-items-center ">
                <Col className=" d-flex align-items-center ">
                  <div className="d-flex align-items-center gap-1 p-1 pt-1">
                    {
                      EventosCatalogoEstadosAnimo.length ? (map(EventosCatalogoEstadosAnimo, (edoAnimo, key) => (
                        <button
                          key={"_edoAnimo_" + key}
                          type="button"
                          className={classnames({ 'btn-light': EventoEstadoAnimo != edoAnimo.id_Libreta_Estado_Animo }, 
                          { 'btn-primary': EventoEstadoAnimo == edoAnimo.id_Libreta_Estado_Animo }, 'btn ')}
                          onClick={() => {
                            handleEventoEstadoAnimo(edoAnimo.id_Libreta_Estado_Animo);
                          }}
                        >
                          <i className={classnames(edoAnimo.icono, 'd-block font-size-16')}></i>{" "}
                          {edoAnimo.nombre_Estado_Animo}
                        </button>
                      ))) : (<></>)
                    }
                  </div>
                </Col>
              </Row>
              {/* Fila de Botónes */}
            </Container>
          </div>
          <Row></Row>
          <Col></Col>
          <div className="modal-body  bg-soft p-2">
            <Container fluid={true} className="">
              <TabContent
                activeTab={customLibretaActiveTab}
                className="p-0 text-muted">

                <TabPane tabId="comida">

                  {/* Fila de palabra Cantidad */}
                  <Row>
                    <Col className="g-0">
                      Cantidad:
                    </Col>
                  </Row>

                  {/* Fila de Botones para cantidad */}
                  <Row>
                    <Col className="g-0">
                      <div className="col mt-1 mb-2">
                        <div className="d-flex gap-2">
                          {
                            EventosCatalogoComidaCantidad.length ? (map(EventosCatalogoComidaCantidad, (cantidad, key) => (
                              <button
                                key={"_cantidad_" + key}
                                type="button"
                                className={classnames({ 'btn-light': EventosComidaIdCantidad != cantidad.id_Libreta_Cantidad }, { 'btn-primary': EventosComidaIdCantidad == cantidad.id_Libreta_Cantidad }, 'btn ')}
                                onClick={() => {
                                  handleEventoComidaCantidad(cantidad.id_Libreta_Cantidad);
                                }}
                              >
                                <i className={classnames(cantidad.icono, 'd-block font-size-16')}></i>{" "}
                                {cantidad.nombre_Cantidad}
                              </button>
                            ))) : (<></>)
                          }
                        </div>
                      </div>
                    </Col>
                  </Row>

                  {/* Fila de Catalogo Observaciones */}
                  {
                    EventosCatalogoComidaObservaciones.length ? (
                      <Row className="mb-2">
                        <Col className="g-1">
                          Observaciones:
                        </Col>
                        <Select className="form-control"
                          isClearable
                          id="idObservacionesComida"
                          options={EventosCatalogoComidaObservaciones}
                          onChange={onDropdownChange}
                          placeholder="Catalogo Observaciones">
                        </Select>
                      </Row>
                    ) : null
                  }


                  {/* Fila de Catalogo Notas */}
                  <Row>
                    <Col className="g-1">
                      Notas:
                    </Col>
                    <textarea
                      className="form-control"
                      id="observacionesComida"
                      placeholder="Notas..."
                      rows="3"
                      onChange={handleEventoonChangeObservaciones}
                    ></textarea>
                  </Row>

                </TabPane>

                <TabPane tabId="pañal">

                  {/* Fila de palabra Cambio de pañal por */}
                  <Row>
                    <Col className="g-0">
                      Cambio de pañal por:
                    </Col>
                  </Row>

                  {/* Fila de Botones para pipi y popo */}
                  <Row>
                    <Col className="g-0">
                      <div className="col mt-1 mb-2">
                        <div className="d-flex gap-2">
                          <button
                            id='pipi'
                            type="button"
                            className={classnames({ 'btn-light': !pipi }, { 'btn-primary': pipi }, 'btn w-sm')}
                            onClick={handleonChangeButtonDiapers}
                          >
                            <i id='pipis' className="mdi mdi-water d-block font-size-16"></i>{" "}
                            Pipi
                          </button>
                          <button
                            id='popo'
                            type="button"
                            className={classnames({ 'btn-light': !popo }, { 'btn-primary': popo }, 'btn w-sm')}
                            onClick={handleonChangeButtonDiapers}
                          >
                            <i id='popos' className="mdi mdi-emoticon-poop d-block font-size-16"></i>{" "}
                            Popo
                          </button>
                        </div>
                      </div>
                    </Col>
                  </Row>

                  {/* Fila de Catalogo Observaciones */}
                  {
                    EventosCatalogoDiapersObservaciones.length ? (
                      <Row className="mb-2">
                        <Col className="g-1">
                          Observaciones:
                        </Col>
                        <Select className="form-control"
                          isClearable
                          id="idObservacionesDiapers"
                          options={EventosCatalogoDiapersObservaciones}
                          onChange={onDropdownChange}
                          placeholder="Catalogo Observaciones">
                        </Select>
                      </Row>
                    ) : null
                  }

                  {/* Fila de Catalogo Notas */}
                  <Row>
                    <Col className="g-1">
                      Notas:
                    </Col>
                    <textarea
                      className="form-control"
                      id="observacionesPañal"
                      placeholder="Notas..."
                      rows="3"
                      onChange={handleEventoonChangeObservaciones}
                    ></textarea>
                  </Row>

                </TabPane>

                <TabPane tabId="siesta">

                  {/* Fila de palabra Siesta desde las */}
                  <Row>
                    <Col className="g-0">
                      Siesta desde las:
                    </Col>
                  </Row>
                  <Row>
                    <div className="btn-toolbar">
                      <div className="btn-group me-2">
                        <button type="button" onClick={() => { dispatch(onSetEventosSiestaHora("7")) }} className={classnames('btn ', { 'btn-outline-secondary ': siestaHora != 7 }, { 'btn-secondary ': siestaHora == 7 })}>7</button>
                        <button type="button" onClick={() => { dispatch(onSetEventosSiestaHora("8")) }} className={classnames('btn ', { 'btn-outline-secondary ': siestaHora != 8 }, { 'btn-secondary ': siestaHora == 8 })}>8</button>
                        <button type="button" onClick={() => { dispatch(onSetEventosSiestaHora("9")) }} className={classnames('btn ', { 'btn-outline-secondary ': siestaHora != 9 }, { 'btn-secondary ': siestaHora == 9 })}>9</button>
                        <button type="button" onClick={() => { dispatch(onSetEventosSiestaHora("10")) }} className={classnames('btn ', { 'btn-outline-secondary ': siestaHora != 10 }, { 'btn-secondary ': siestaHora == 10 })}>10</button>
                        <button type="button" onClick={() => { dispatch(onSetEventosSiestaHora("11")) }} className={classnames('btn ', { 'btn-outline-secondary ': siestaHora != 11 }, { 'btn-secondary ': siestaHora == 11 })}>11</button>
                        <button type="button" onClick={() => { dispatch(onSetEventosSiestaHora("12")) }} className={classnames('btn ', { 'btn-outline-secondary ': siestaHora != 12 }, { 'btn-secondary ': siestaHora == 12 })}>12</button>
                        <button type="button" onClick={() => { dispatch(onSetEventosSiestaHora("13")) }} className={classnames('btn ', { 'btn-outline-secondary ': siestaHora != 13 }, { 'btn-secondary ': siestaHora == 13 })}>13</button>
                        <button type="button" onClick={() => { dispatch(onSetEventosSiestaHora("14")) }} className={classnames('btn ', { 'btn-outline-secondary ': siestaHora != 14 }, { 'btn-secondary ': siestaHora == 14 })}>14</button>
                      </div>
                    </div>
                  </Row>
                  <Row>
                    <div className="btn-toolbar">
                      <div className="btn-group me-2">
                        <button type="button" onClick={() => { dispatch(onSetEventosSiestaMinutos("00")) }} className={classnames('btn ', { 'btn-outline-secondary ': siestaMinutos != "00" }, { 'btn-secondary ': siestaMinutos == "00" })}>00</button>
                        <button type="button" onClick={() => { dispatch(onSetEventosSiestaMinutos("10")) }} className={classnames('btn ', { 'btn-outline-secondary ': siestaMinutos != "10" }, { 'btn-secondary ': siestaMinutos == "10" })}>10</button>
                        <button type="button" onClick={() => { dispatch(onSetEventosSiestaMinutos("20")) }} className={classnames('btn ', { 'btn-outline-secondary ': siestaMinutos != "20" }, { 'btn-secondary ': siestaMinutos == "20" })}>20</button>
                        <button type="button" onClick={() => { dispatch(onSetEventosSiestaMinutos("30")) }} className={classnames('btn ', { 'btn-outline-secondary ': siestaMinutos != "30" }, { 'btn-secondary ': siestaMinutos == "30" })}>30</button>
                        <button type="button" onClick={() => { dispatch(onSetEventosSiestaMinutos("40")) }} className={classnames('btn ', { 'btn-outline-secondary ': siestaMinutos != "40" }, { 'btn-secondary ': siestaMinutos == "40" })}>40</button>
                        <button type="button" onClick={() => { dispatch(onSetEventosSiestaMinutos("50")) }} className={classnames('btn ', { 'btn-outline-secondary ': siestaMinutos != "50" }, { 'btn-secondary ': siestaMinutos == "50" })}>50</button>
                      </div>
                      <div className=""><h2>{siestaHora}:{siestaMinutos}</h2></div>

                    </div>
                  </Row>
                  <Row>
                    <Col className="mt-2 g-0">
                      Duración:
                    </Col>
                  </Row>
                  <Row>
                    <div className="btn-toolbar">
                      <div className="btn-group me-2">
                        <button type="button" onClick={() => { dispatch(onSetEventosSiestaDuracion("10")) }} className={classnames('btn ', { 'btn-outline-secondary ': siestaDuracion != "10" }, { 'btn-secondary ': siestaDuracion == "10" })}>10</button>
                        <button type="button" onClick={() => { dispatch(onSetEventosSiestaDuracion("20")) }} className={classnames('btn ', { 'btn-outline-secondary ': siestaDuracion != "20" }, { 'btn-secondary ': siestaDuracion == "20" })}>20</button>
                        <button type="button" onClick={() => { dispatch(onSetEventosSiestaDuracion("30")) }} className={classnames('btn ', { 'btn-outline-secondary ': siestaDuracion != "30" }, { 'btn-secondary ': siestaDuracion == "30" })}>30</button>
                        <button type="button" onClick={() => { dispatch(onSetEventosSiestaDuracion("40")) }} className={classnames('btn ', { 'btn-outline-secondary ': siestaDuracion != "40" }, { 'btn-secondary ': siestaDuracion == "40" })}>40</button>
                        <button type="button" onClick={() => { dispatch(onSetEventosSiestaDuracion("50")) }} className={classnames('btn ', { 'btn-outline-secondary ': siestaDuracion != "50" }, { 'btn-secondary ': siestaDuracion == "50" })}>50</button>
                        <button type="button" onClick={() => { dispatch(onSetEventosSiestaDuracion("60")) }} className={classnames('btn ', { 'btn-outline-secondary ': siestaDuracion != "60" }, { 'btn-secondary ': siestaDuracion == "60" })}>60</button>
                      </div>
                      <div className=""><h4>{siestaDuracion}</h4>minutos</div>
                    </div>
                  </Row>

                  {/* Fila de Catalogo Observaciones */}
                  {
                    EventosCatalogoSiestaObservaciones.length ? (
                      <Row className="mt-1 mb-2">
                        <Col className="g-1">
                          Observaciones:
                        </Col>
                        <Select className="form-control"
                          isClearable
                          id="idObservacionesDiapers"
                          options={EventosCatalogoSiestaObservaciones}
                          onChange={onDropdownChange}
                          placeholder="Catalogo Observaciones">
                        </Select>
                      </Row>
                    ) : null
                  }

                  {/* Fila de Catalogo Notas */}
                  <Row>
                    <Col className="g-1">
                      Notas:
                    </Col>
                    <textarea
                      className="form-control"
                      id="observacionesSiesta"
                      placeholder="Notas..."
                      rows="3"
                      onChange={handleEventoonChangeObservaciones}
                    ></textarea>
                  </Row>


                </TabPane>

                <TabPane tabId="baño">

                  {/* Fila de palabra Fue al baño */}
                  <Row>
                    <Col className="g-0">
                      Fue al baño:
                    </Col>
                  </Row>

                  {/* Fila de Botones baño */}
                  <Row>
                    <Col className="g-0">
                      <div className="col mt-1 mb-2">
                        <div className="d-flex gap-2">
                          <button
                            type="button"
                            id="BathButtonSi"
                            className={classnames({ 'btn-light': !fuealBath }, { 'btn-primary': fuealBath }, 'btn w-sm')}
                            onClick={handleonChangeButtonBath}
                          >
                            <i id="BathISi" className="mdi mdi-check-box-outline d-block font-size-16"></i>{" "}
                            Sí
                          </button>
                          <button
                            type="button"
                            id="BathButtonNo"
                            className={classnames({ 'btn-light': fuealBath }, { 'btn-primary': !fuealBath }, 'btn w-sm')}
                            onClick={handleonChangeButtonBath}
                          >
                            <i id="BathINo" className="mdi mdi-checkbox-blank-outline d-block font-size-16"></i>{" "}
                            No
                          </button>
                        </div>
                      </div>
                    </Col>
                  </Row>

                  {/* Fila de Catalogo Observaciones */}
                  {
                    EventosCatalogoBathObservaciones.length ? (
                      <Row className="mb-2">
                        <Col className="g-1">
                          Observaciones:
                        </Col>
                        <Select className="form-control"
                          isClearable
                          id="idObservacionesBaño"
                          options={EventosCatalogoBathObservaciones}
                          onChange={onDropdownChange}
                          placeholder="Catalogo Observaciones">
                        </Select>
                      </Row>
                    ) : null
                  }

                  {/* Fila de Catalogo Notas */}
                  <Row>
                    <Col className="g-1">
                      Notas:
                    </Col>
                    <textarea
                      className="form-control"
                      id="observacionesBath"
                      placeholder="Notas..."
                      rows="3"
                      onChange={handleEventoonChangeObservaciones}
                    ></textarea>
                  </Row>

                </TabPane>

                <TabPane tabId="academia">

                  {/* Fila de palabra Academia */}
                  <Row>
                    <Col className="g-0 mb-2">
                      Academia:
                    </Col>
                  </Row>

                  {/* Fila de Catalogo Observaciones */}
                  {
                    EventosCatalogoAcademiaObservaciones.length ? (
                      <Row className="mb-2">
                        <Col className="g-1">
                          Observaciones:
                        </Col>
                        <Select className="form-control"
                          isClearable
                          id="idObservacionesAcademia"
                          options={EventosCatalogoAcademiaObservaciones}
                          onChange={onDropdownChange}
                          placeholder="Catalogo Observaciones">
                        </Select>
                      </Row>
                    ) : null
                  }

                  {/* Fila de Catalogo Notas */}
                  <Row>
                    <Col className="g-1">
                      Notas:
                    </Col>
                    <textarea
                      className="form-control"
                      id="observacionesAcademia"
                      placeholder="Notas..."
                      rows="3"
                      onChange={handleEventoonChangeObservaciones}
                    ></textarea>
                  </Row>

                </TabPane>

              </TabContent>
            </Container>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-light" onClick={() => {
              toggleTipoEvento("comida");
              dispatch(onSetEventosSiestaHora("7"))
              dispatch(onSetEventosSiestaMinutos("00"))
              dispatch(onSetEventosSiestaDuracion("00"))
              dispatch(onEventCloseModalAction());
            }}>Cerrar</button>
            <button type="button" className="btn btn-primary" onClick={handleRegisterEvent}>Registrar</button>
          </div>

        </Modal>
      </div>
    </React.Fragment>
  )
}

export default Libreta
