import React, { useState, useEffect } from "react"
import { useDispatch } from "react-redux"
import PropTypes from 'prop-types'
import ItemResumenReporteEnvio from "./item-resumen-reporte-envio"
import { Card, CardBody, CardHeader, Table, Collapse, } from "reactstrap"
import { map } from "lodash";
import classnames from "classnames";
import { getoSolicitudBase } from "../../helpers/comunicabackend_helper"
import { useAuthUser } from "../../store/auth/loginState/selectors"
import { useReporteEnvioListaStatus } from "../../store/common/selectors"
import { useRefreshReporteEnvioDetalles } from "../../store/libreta/selectors"
import { getReporteEnvioDetalle as getReporteEnvioDetalleAction } from "../../store/common/actions"
import { deleteInboxMail as deleteInboxMailAction } from "../../store/mails/actions"
import { ClearRefreshListaStatus as ClearRefreshListaStatusAction } from "../../store/libreta/actions"

const ListadoReporteEnvios = (props) => {

    console.log("--------------| iniciando listado-reportes-envio |--------------")

    const [accordionitem, setAccordionitem] = useState(-1);

    const dispatch = useDispatch()
    const ListaStatus = useReporteEnvioListaStatus()
    const RefreshReporteEnvioDetalles = useRefreshReporteEnvioDetalles()
    const authUser = useAuthUser();

    useEffect(() => {
        if (RefreshReporteEnvioDetalles && accordionitem != -1) {
            getDetalle(accordionitem)
            dispatch(ClearRefreshListaStatusAction())
        }
    }, [RefreshReporteEnvioDetalles])

    const getDetalle = (id) => {
        const oSolicitudBase = getoSolicitudBase(
            "Reportes::useEffect",
            "getlistareporteenvios",
            [],
            [
                { nombre: "PageNumber", valor: "1" },
                { nombre: "RowsOfPage", valor: "1000000" },
                { nombre: "fromid", valor: authUser.idPersona },
                { nombre: "idReporteEnvio", valor: id },
            ]
        )
        dispatch(getReporteEnvioDetalleAction(oSolicitudBase))
    }

    const toogleAccordion = (id) => {
        if (accordionitem !== id) {
            setAccordionitem(id)
            getDetalle(id)
        } else {
            setAccordionitem(-1)
        }
    }

    const MarkDeleted = (id) => {
        marcarBorrado(id)
    }

    const marcarBorrado = (id) => {
        dispatch(deleteInboxMailAction(id))
    }

    const isOpenAccordion = (id) => {
        if (accordionitem === id) {
            return true
        }
        return false
    }

    return (
        <React.Fragment>
            <div
                className="accordion accordion-flush"
                id="accordionFlushExample"
            >
                {
                    props.reportes.length ? (map(props.reportes, (reporte, key) => (

                        <div className="accordion-item" key={key + "_" + reporte.id_Reporte_Envio}>
                            <h2 className="accordion-header" id="headingFlushOne">
                                <button
                                    className={classnames(
                                        "accordion-button",
                                        "fw-medium",
                                        { collapsed: !(accordionitem === reporte.id_Reporte_Envio) }
                                    )}
                                    type="button"
                                    onClick={() => {
                                        toogleAccordion(reporte.id_Reporte_Envio)
                                    }}
                                    style={{ cursor: "pointer" }}
                                >
                                    <ItemResumenReporteEnvio
                                        key={key + "algo"}
                                        reporte={reporte}
                                    ></ItemResumenReporteEnvio>
                                </button>
                            </h2>

                            <Collapse
                                isOpen={isOpenAccordion(reporte.id_Reporte_Envio)}
                                className="accordion-collapse"
                            >
                                <div className="accordion-body p-0">

                                    <div className="table-responsive">
                                        <Table className="table table-striped m-0">
                                            <thead>
                                                <tr>
                                                    <th className="p-1">Destino</th>
                                                    <th className="p-1">Canal</th>
                                                    <th className="p-1">Enviado</th>
                                                    <th className="p-1">Leido</th>
                                                    <th className="p-1">Borrado</th>
                                                    {/* <th>Mensaje</th> */}
                                                    <th className="p-1">Acción</th>
                                                </tr>
                                            </thead>
                                            <tbody>

                                                {ListaStatus.length ? (map(ListaStatus, (oStatus, key2) => (
                                                    <tr key={key2}>
                                                        <th className="p-1" scope="row">{oStatus.toId_Name}</th>
                                                        <td>{oStatus.nombre_Canal_Comunicacion}</td>
                                                        <td>{oStatus.enviado ?
                                                            (<i
                                                                className="mdi mdi-check-box-outline m-2"
                                                                style={{ fontSize: "1em", color: "green" }}
                                                            />) : (<i
                                                                className="mdi mdi-checkbox-blank-outline m-2"
                                                                style={{ fontSize: "1em", color: "green" }}
                                                            />)

                                                        }</td>
                                                        <td>{oStatus.leido ?
                                                            (<i
                                                                className="mdi mdi-check-box-outline m-2"
                                                                style={{ fontSize: "1em", color: "green" }}
                                                            />) : (<i
                                                                className="mdi mdi-checkbox-blank-outline m-2"
                                                                style={{ fontSize: "1em", color: "green" }}
                                                            />)

                                                        }</td>
                                                        <td>{oStatus.borrado ?
                                                            (<i
                                                                className="mdi mdi-check-box-outline m-2"
                                                                style={{ fontSize: "1em", color: "green" }}
                                                            />) : (<i
                                                                className="mdi mdi-checkbox-blank-outline m-2"
                                                                style={{ fontSize: "1em", color: "green" }}
                                                            />)

                                                        }</td>
                                                        {/* <td>{oStatus.sError}</td> */}
                                                        <td>{!oStatus.borrado ? (
                                                            <div
                                                                className="text-decoration-underline"
                                                                style={{ cursor: "pointer" }}
                                                                onClick={() => {
                                                                    MarkDeleted(oStatus.id_Mensaje)
                                                                }}
                                                            >
                                                                Borrar
                                                            </div>
                                                        ) : null}</td>
                                                    </tr>
                                                ))) : null}


                                            </tbody>
                                        </Table>
                                    </div>



                                </div>

                            </Collapse>

                        </div>

                        // "toId_Name": "Villanueva Emma Laura",
                        // "id_Mensaje": 1212758,
                        // "id_Canal_Comunicacion": 3,
                        // "nombre_Canal_Comunicacion": "App",
                        // "enviado": false,
                        // "fecha_Enviado": "0001-01-01T00:00:00",
                        // "leido": false,
                        // "fecha_Leido": "0001-01-01T00:00:00",
                        // "acusado": false,
                        // "borrado": false,
                        // "id_Tipo_Mensaje": 1,
                        // "sError": ""

                    ))) : (<></>)
                }

            </div>
        </React.Fragment>
    )

}

ListadoReporteEnvios.propTypes = {
    employee: PropTypes.any,
    reportes: PropTypes.any,
}

export default ListadoReporteEnvios