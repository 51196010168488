import React from "react"
import { Redirect } from "react-router-dom"

// Test
import Test from "../pages/Test"
import TestContainer from "../pages/Test/containers"
import TestGrid from "../pages/Test/grid"
import TestColumn from "../pages/Test/columns"
import TestGutter from "../pages/Test/gutters"

// Academia
import Academia from "../pages/Academia"
import AcademiaAlumnos from "../pages/Academia/alumnos-list"
import AcademiaProfesores from "../pages/Academia/profesores-list"
// //import Academia from "../pages/Academia/index"
import AcademiaConfiguracion from "../pages/Academia/configuracion"

// Libreta
import Libreta from "../pages/Libreta"
import LibretaRevision from "../pages/Libreta/revision"
import LibretaEnvio from "../pages/Libreta/envio"
import LibretaReportes from "../pages/Libreta/reportes"
import LibretaConfiguracion from "../pages/Libreta/configuracion"

// Profile
import UserProfile from "../pages/Authentication/user-profile"

// Secciones
import Secciones from "../pages/Secciones/index"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"

// Dashboard
import Dashboard from "../pages/Libreta/index"

// Pages Calendar
import Calendar from "../pages/Calendar/index"

//Contacts
import ContactsGrid from "../pages/Contacts/contacts-grid"
import ContactsList from "../pages/Contacts/ContactList/contacts-list"
import ContactsProfile from "../pages/Contacts/ContactsProfile/contacts-profile"

//Email
import EmailInbox from "../pages/Email/email-inbox"
import EmailRead from "../pages/Email/email-read"
import EmailBasicTemplte from "../pages/Email/email-basic-templte"
import EmailAlertTemplte from "../pages/Email/email-template-alert"
import EmailTemplateBilling from "../pages/Email/email-template-billing"

//Icons
import IconBoxicons from "../pages/Icons/IconBoxicons"
import IconDripicons from "../pages/Icons/IconDripicons"
import IconMaterialdesign from "../pages/Icons/IconMaterialdesign"
import IconFontawesome from "../pages/Icons/IconFontawesome"

const authProtectedRoutes = [

  { path: "/test", component: Test},
  { path: "/test/container", component: TestContainer},
  { path: "/test/grid", component: TestGrid},
  { path: "/test/columns", component: TestColumn},
  { path: "/test/gutters", component: TestGutter},
  
  // //Academia
  { path: "/Academia", component: Academia},
  { path: "/Academia/Alumnos", component: AcademiaAlumnos},
  { path: "/Academia/Profesores", component: AcademiaProfesores},
 // { path: "/Academia/Reportes", component: AcademiaReportes},
  { path: "/Academia/Configuracion", component: AcademiaConfiguracion},

  // //libreta
  { path: "/libreta", component: Libreta},
  { path: "/libreta/Revision", component: LibretaRevision},
  { path: "/libreta/Envio", component: LibretaEnvio},
  { path: "/libreta/Reportes", component: LibretaReportes},
  { path: "/libreta/Configuracion", component: LibretaConfiguracion},

  { path: "/dashboard", component: Dashboard },

  // //profile
  { path: "/profile", component: UserProfile },

  // //calendar
  { path: "/calendar", component: Calendar },

  // //Secciones
  { path: "/secciones", component: Secciones },

  // Contacts
  { path: "/contacts-grid", component: ContactsGrid },
  { path: "/contacts-list", component: ContactsList },
  { path: "/contacts-profile", component: ContactsProfile },

  //Email
  { path: "/email-inbox", component: EmailInbox },
  { path: "/email-read", component: EmailRead },
  { path: "/email-template-basic", component: EmailBasicTemplte },
  { path: "/email-template-alert", component: EmailAlertTemplte },
  { path: "/email-template-billing", component: EmailTemplateBilling },

  // Icons
  { path: "/icons-boxicons", component: IconBoxicons },
  { path: "/icons-dripicons", component: IconDripicons },
  { path: "/icons-materialdesign", component: IconMaterialdesign },
  { path: "/icons-fontawesome", component: IconFontawesome },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  { path: "/", exact: true, component: () => <Redirect to="/libreta" /> },
]

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/register", component: Register },
]

export { publicRoutes, authProtectedRoutes }
