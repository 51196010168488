


/* Eventos / Revision / Envio / Configuracion */
export const LIBRETA_SET_EVENTOS = "LIBRETA/SET/EVENTOS"
export const LIBRETA_SET_REVISION = "LIBRETA/SET/REVISION"
export const LIBRETA_SET_ENVIO = "LIBRETA/SET/ENVIO"
export const LIBRETA_SET_CONFIGURACION = "LIBRETA/SET/CONFIGURACION"
export const LIBRETA_SET_REPORTES = "LIBRETA/SET/REPORTES"

export const LIBRETA_EVENT = "LIBRETA/EVENT"

export const LIBRETA_EVENT_OPENMODAL = "LIBRETA/EVENT/OPENMODAL"
export const LIBRETA_EVENT_CLOSEMODAL = "LIBRETA/EVENT/CLOSEMODAL"

/* DisplayNotification */
export const LIBRETA_SET_DISPLAY_NOTIFICATION = "LIBRETA/SET/DISPLAY_NOTIFICATION"
export const LIBRETA_UNSET_DISPLAY_NOTIFICATION = "LIBRETA/UNSET/DISPLAY_NOTIFICATION"

/* DisplayModalReporteEnvio */
export const LIBRETA_SET_DISPLAY_REPORTE_ENVIO = "LIBRETA/SET/DISPLAY_REPORTE_ENVIO"
export const LIBRETA_UNSET_DISPLAY_REPORTE_ENVIO = "LIBRETA/UNSET/DISPLAY_REPORTE_ENVIO"

/* GRUPOS_ACADEMICOS */
export const LIBRETA_GET_GRUPOS_ACADEMICOS = "LIBRETA/GET/GRUPOS_ACADEMICOS"
export const LIBRETA_GET_GRUPOS_ACADEMICOS_SUCCESS = "LIBRETA/GET/GRUPOS_ACADEMICOS/SUCCESS"
export const LIBRETA_GET_GRUPOS_ACADEMICOS_FAIL = "LIBRETA/GET/GRUPOS_ACADEMICOS/FAIL"
export const LIBRETA_SET_ID_GRUPO_ACADEMICO = "LIBRETA/SET/ID/GRUPO_ACADEMICO"
export const LIBRETA_SET_OGRUPO_ACADEMICO = "LIBRETA/SET/OGRUPO_ACADEMICO"
/* ---------------------------------------------------------------------------------------*/

/* ALUMNOS_LIBRETA */
export const LIBRETA_GET_ALUMNOS_LIBRETA = "LIBRETA/GET/ALUMNOS_LIBRETA"
export const LIBRETA_GET_ALUMNOS_LIBRETA_SUCCESS = "LIBRETA/GET/ALUMNOS_LIBRETA/SUCCESS"
export const LIBRETA_GET_ALUMNOS_LIBRETA_FAIL = "LIBRETA/GET/ALUMNOS_LIBRETA/FAIL"
export const LIBRETA_SET_ID_ALUMNO = "LIBRETA/SET/ID/ALUMNO"
/* ---------------------------------------------------------------------------------------*/

/* Eventos */
export const LIBRETA_EVENTOS_SET_ENVIO = "LIBRETA/SET/ENVIO"
export const LIBRETA_EVENTOS_SET_REVISION = "LIBRETA/SET/REVISION"
export const LIBRETA_EVENTOS_SET_EVENTOS = "LIBRETA/SET/EVENTOS"
export const LIBRETA_EVENTOS_SET_CONFIGURACION = "LIBRETA/SET/CONFIGURACION"
export const LIBRETA_EVENTOS_SET_REPORTES = "LIBRETA/SET/REPORTES"

export const LIBRETA_EVENTOS_GET_CATALOGOS = "LIBRETA/EVENTOS/GET/CATALOGOS"
export const LIBRETA_EVENTOS_GET_CATALOGOS_SUCCESS = "LIBRETA/EVENTOS/GET/CATALOGOS_SUCCESS"
export const LIBRETA_EVENTOS_GET_CATALOGOS_FAIL = "LIBRETA/EVENTOS/GET/CATALOGOS_FAIL"

export const LIBRETA_EVENTOS_SET_TIPO_EVENTO = "LIBRETA/EVENTOS/SET/TIPO_EVENTO"
export const LIBRETA_EVENTOS_SET_FECHAHORA = "LIBRETA/EVENTOS/SET/FECHAHORA"
export const LIBRETA_EVENTOS_SET_ESTADO_ANIMO = "LIBRETA/EVENTOS/SET/ESTADO_ANIMO"

export const LIBRETA_GET_REGISTRO_EVENTOS_LIBRETA = "LIBRETA/EVENTOS/GET/REGISTROS"
export const LIBRETA_CLEAR_REGISTRO_EVENTOS_LIBRETA = "LIBRETA/EVENTOS/CLEAR/REGISTROS"
export const LIBRETA_GET_REGISTRO_EVENTOS_LIBRETA_SUCCESS = "LIBRETA/EVENTOS/GET/REGISTROS_SUCCESS"
export const LIBRETA_GET_REGISTRO_EVENTOS_LIBRETA_FAIL = "LIBRETA/EVENTOS/GET/REGISTROS_FAIL"
/* ---------------------------------------------------------------------------------------*/

/* Comida */
export const LIBRETA_EVENTOS_COMIDA_SET_CANTIDAD = "LIBRETA/EVENTOS/COMIDA/SET/CANTIDAD"

export const LIBRETA_EVENT_SELECTACADEMICGROUP = "LIBRETA/EVENT/SELECTACADEMICGROUP"
export const LIBRETA_EVENT_SELECTUSER = "LIBRETA/EVENT/SELECTUSER"

export const LIBRETA_EVENT_COMIDA = "LIBRETA/EVENT/COMIDA"
export const LIBRETA_EVENT_COMIDA_FECHAHORA = "LIBRETA/EVENT/COMIDA/FECHAHORA"
export const LIBRETA_EVENT_COMIDA_HUMOR = "LIBRETA/EVENT/COMIDA/HUMOR"
export const LIBRETA_EVENT_COMIDA_CANTIDAD = "LIBRETA/EVENT/COMIDA/CANTIDAD"
export const LIBRETA_EVENT_COMIDA_OBSERVACIONES = "LIBRETA/EVENT/COMIDA/OBSERVACIONES"
export const LIBRETA_EVENT_COMIDA_CANCEL = "LIBRETA/EVENT/COMIDA/CANCEL"

/* Diapers */
export const LIBRETA_EVENTOS_DIAPERS_SET_PIPI = "LIBRETA/EVENTOS/DIAPERS/SET/PIPI"
export const LIBRETA_EVENTOS_DIAPERS_SET_POPO = "LIBRETA/EVENTOS/DIAPERS/SET/POPO"

/* Siesta */
export const LIBRETA_EVENTOS_SIESTA_SET_HORA = "LIBRETA/EVENTOS/SIESTA/SET/HORA"
export const LIBRETA_EVENTOS_SIESTA_SET_MINUTOS = "LIBRETA/EVENTOS/SIESTA/SET/MINUTOS"
export const LIBRETA_EVENTOS_SIESTA_SET_DURACION = "LIBRETA/EVENTOS/SIESTA/SET/DURACION"

/* FuealBano */
export const LIBRETA_EVENTOS_BATH_SET = "LIBRETA/EVENTOS/BATH/SET"

/* OBSERVACIONES */
export const LIBRETA_EVENTOS_OBSERVACIONES_SET_ID = "LIBRETA/EVENTOS/OBSERVACIONES/SET/ID"
export const LIBRETA_EVENTOS_COMIDA_SET_OBSERVACIONES = "LIBRETA/EVENTOS/COMIDA/SET/OBSERVACIONES"
export const LIBRETA_EVENTOS_DIAPERS_SET_OBSERVACIONES = "LIBRETA/EVENTOS/DIAPERS/SET/OBSERVACIONES"
export const LIBRETA_EVENTOS_SIESTA_SET_OBSERVACIONES = "LIBRETA/EVENTOS/SIESTA/SET/OBSERVACIONES"
export const LIBRETA_EVENTOS_BATH_SET_OBSERVACIONES = "LIBRETA/EVENTOS/BATH/SET/OBSERVACIONES"
export const LIBRETA_EVENTOS_ACADEMIA_SET_OBSERVACIONES = "LIBRETA/EVENTOS/ACADEMIA/SET/OBSERVACIONES"

/* Registra Evento */
export const LIBRETA_EVENT_REGISTRA = "LIBRETA/EVENT/REGISTRA"

export const LIBRETA_EVENT_REGISTRA_COMIDA_SUCCESS = "LIBRETA/EVENT/REGISTRA/COMIDA_SUCCESS"
export const LIBRETA_EVENT_REGISTRA_DIAPERS_SUCCESS = "LIBRETA/EVENT/REGISTRA/DIAPERS_SUCCESS"
export const LIBRETA_EVENT_REGISTRA_SIESTA_SUCCESS = "LIBRETA/EVENT/REGISTRA/SIESTA_SUCCESS"
export const LIBRETA_EVENT_REGISTRA_BATH_SUCCESS = "LIBRETA/EVENT/REGISTRA/BATH_SUCCESS"
export const LIBRETA_EVENT_REGISTRA_ACADEMIA_SUCCESS = "LIBRETA/EVENT/REGISTRA/ACADEMIA_SUCCESS"

export const LIBRETA_EVENT_REGISTRA_FAIL = "LIBRETA/EVENT/REGISTRA_FAIL"


/* Update Evento */
export const LIBRETA_EVENT_UPDATE_EVENT = "LIBRETA/EVENT/UPDATE/EVENT"
export const LIBRETA_EVENT_UPDATE_SUCCESS = "LIBRETA/EVENT/UPDATE_SUCCESS"
export const LIBRETA_EVENT_UPDATE_FAIL = "LIBRETA/EVENT/UPDATE_FAIL"

/* Delete Evento */
export const LIBRETA_EVENT_DELETE_EVENT = "LIBRETA/EVENT/DELETE/EVENT"
export const LIBRETA_EVENT_DELETE_SUCCESS = "LIBRETA/EVENT/DELETE_SUCCESS"
export const LIBRETA_EVENT_DELETE_FAIL = "LIBRETA/EVENT/DELETE_FAIL"


/* *********************************************************** */
/* OBSERVACIONES */
/* Insert */
export const LIBRETA_CONFIG_ADD_OBSERVACION = "LIBRETA/CONFIG/ADD/OBSERVACION"
export const LIBRETA_CONFIG_ADD_OBSERVACION_SUCCESS = "LIBRETA/CONFIG/ADD/OBSERVACION_SUCCESS"
export const LIBRETA_CONFIG_ADD_OBSERVACION_FAIL = "LIBRETA/CONFIG/ADD/OBSERVACION_FAIL"

/* Update */
export const LIBRETA_CONFIG_UPDATE_OBSERVACION = "LIBRETA/CONFIG/UPDATE/OBSERVACION"
export const LIBRETA_CONFIG_UPDATE_OBSERVACION_SUCCESS = "LIBRETA/CONFIG/UPDATE/OBSERVACION_SUCCESS"
export const LIBRETA_CONFIG_UPDATE_OBSERVACION_FAIL = "LIBRETA/CONFIG/UPDATE/OBSERVACION_FAIL"

/* Delete */
export const LIBRETA_CONFIG_DELETE_OBSERVACION = "LIBRETA/CONFIG/DELETE/OBSERVACION"
export const LIBRETA_CONFIG_DELETE_OBSERVACION_SUCCESS = "LIBRETA/CONFIG/DELETE/OBSERVACION_SUCCESS"
export const LIBRETA_CONFIG_DELETE_OBSERVACION_FAIL = "LIBRETA/CONFIG/DELETE/OBSERVACION_FAIL"





/* update */

/* Delete */


/* Revision  */
export const LIBRETA_EVENT_REVISION_SET_FECHA = "LIBRETA/EVENT/REVISION/SET_FECHA"
export const LIBRETA_EVENT_REVISION_SET_FECHA_FUTURO = "LIBRETA/EVENT/REVISION/SET_FECHA/FUTURO"
export const LIBRETA_EVENT_REVISION_SET_FECHA_PASADO = "LIBRETA/EVENT/REVISION/SET_FECHA/PASADO"

/* Envio  */
export const LIBRETA_ENVIO = "LIBRETA/ENVIO"
export const LIBRETA_ENVIO_SUCCESS = "LIBRETA/ENVIO_SUCCESS"
export const LIBRETA_ENVIO_FAIL = "LIBRETA/ENVIO_FAIL"

/* Clear ReporteEnvioListaStatus  */
export const LIBRETA_CLEAR_REFRESH_LISTA_STATUS = "LIBRETA/CLEAR/REFRESH/LISTA_STATUS"

/* Reportes Fecha Inicio y Fecha Final  */
export const LIBRETA_REPORTES_SET_FECHA_INICIO = "LIBRETA/REPORTES/SET/FECHA_INICIO"
export const LIBRETA_REPORTES_SET_FECHA_FINAL = "LIBRETA/REPORTES/SET/FECHA_FINAL"


