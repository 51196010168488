



export const AUTH_LOGIN = "auth/LOGIN"
export const AUTH_LOGIN_SUCCESS = "auth/LOGIN_SUCCESS"
export const AUTH_LOGIN_ERROR = "auth/LOGIN_ERROR"

export const AUTH_LOGOUT = "auth/LOGOUT"
export const AUTH_API_ERROR = "auth/LOGIN_API_ERROR"

export const AUTH_ACCOUNT_SETUP = "auth/ACCOUNT_SETUP"
export const AUTH_CONFIRM_USER = "auth/CONFIRM_USER"

export const AUTH_SOCIAL_LOGIN = "auth/SOCIAL_LOGIN"

export const AUTH_SET_IDCOLEGIO = "auth/SETIDCOLEGIO"
