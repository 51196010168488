import {
  FAMILIAS_GET_FAMILIA,
  FAMILIAS_GET_FAMILIA_SUCCESS,
  FAMILIAS_GET_FAMILIA_FAIL,

  FAMILIAS_GET_FAMILIAS,
  FAMILIAS_GET_FAMILIAS_SUCCESS,
  FAMILIAS_GET_FAMILIAS_FAIL,
  
  FAMILIAS_ADD_FAMILIA,
  FAMILIAS_ADD_FAMILIA_SUCCESS,
  FAMILIAS_ADD_FAMILIA_FAIL,
  
  FAMILIAS_UPDATE_FAMILIA,
  FAMILIAS_UPDATE_FAMILIA_SUCCESS,
  FAMILIAS_UPDATE_FAMILIA_FAIL,
  
  FAMILIAS_DELETE_FAMILIA,
  FAMILIAS_DELETE_FAMILIA_SUCCESS,
  FAMILIAS_DELETE_FAMILIA_FAIL,
  

} from "./actionTypes"

export const getFamilia = oSolicitudBase => ({
  type: FAMILIAS_GET_FAMILIA,
  payload: oSolicitudBase,
})

export const getFamiliaSuccess = alumnos => ({
  type: FAMILIAS_GET_FAMILIA_SUCCESS,
  payload: alumnos,
})

export const getFamiliaFail = error => ({
  type: FAMILIAS_GET_FAMILIA_FAIL,
  payload: error,
})

export const getFamilias = oSolicitudBase => ({
  type: FAMILIAS_GET_FAMILIAS,
  payload: oSolicitudBase,
})

export const getFamiliasSuccess = alumnos => ({
  type: FAMILIAS_GET_FAMILIAS_SUCCESS,
  payload: alumnos,
})

export const getFamiliasFail = error => ({
  type: FAMILIAS_GET_FAMILIAS_FAIL,
  payload: error,
})

export const addFamilia = oSolicitudBase => ({
  type: FAMILIAS_ADD_FAMILIA,
  payload: oSolicitudBase,
})

export const addFamiliaSuccess = alumnos => ({
  type: FAMILIAS_ADD_FAMILIA_SUCCESS,
  payload: alumnos,
})

export const addFamiliaFail = error => ({
  type: FAMILIAS_ADD_FAMILIA_FAIL,
  payload: error,
})

export const updateFamilia = oSolicitudBase => ({
  type: FAMILIAS_UPDATE_FAMILIA,
  payload: oSolicitudBase,
})

export const updateFamiliaSuccess = alumnos => ({
  type: FAMILIAS_UPDATE_FAMILIA_SUCCESS,
  payload: alumnos,
})

export const updateFamiliaFail = error => ({
  type: FAMILIAS_UPDATE_FAMILIA_FAIL,
  payload: error,
})

export const deleteFamilia = oSolicitudBase => ({
  type: FAMILIAS_DELETE_FAMILIA,
  payload: oSolicitudBase,
})

export const deleteFamiliaSuccess = alumnos => ({
  type: FAMILIAS_DELETE_FAMILIA_SUCCESS,
  payload: alumnos,
})

export const deleteFamiliaFail = error => ({
  type: FAMILIAS_DELETE_FAMILIA_FAIL,
  payload: error,
})




