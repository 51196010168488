//redux
import { useSelector } from "react-redux"

// *************************************************************
//     Misc
export function useLibretaError() {
  return useSelector(state => state.Libreta.context.error);
}

export function useLibretaLoading() {
  return useSelector(state => state.Libreta.context.loading);
}

export function useModal_backdrop() {
  return useSelector(state => state.Libreta.context.modal_backdrop);
}

export function useDisplayNotification() {
  return useSelector(state => state.Libreta.context.displayNotification);
}

// *************************************************************
//     Machine State
export function useLibretaStatus() {
  return useSelector(state => state.Libreta.value);
}


// *************************************************************
//     Grupos Academicos
export function useLibretaGruposAcademicos() {
  return useSelector(state => state.Libreta.context.gruposAcademicos);
}

export function useIdGrupoAcademicoSelected() {
  return useSelector(state => state.Libreta.context.idGrupoAcademicoSelected);
}

// *************************************************************
//     Alumnos
export function useIdAlumnoSelected() {
  return useSelector(state => state.Libreta.context.oAlumnoSelected.id_Alumno);
}

export function useAlumnoSelected() {
  return useSelector(state => state.Libreta.context.oAlumnoSelected);
}

export function useLibretaAlumnos() {
  return useSelector(state => state.Libreta.context.alumnos);
}

// *************************************************************
//     Eventos 
export function useLibretaTipoEvento() {
  return useSelector(state => state.Libreta.context.eventos_tipoEvento);
}

export function useLibretaEventoFechaHora() {
  return useSelector(state => state.Libreta.context.eventos_fechaHora);
}

export function useLibretaEventoEstadoAnimo() {
  return useSelector(state => state.Libreta.context.eventos_estadoAnimo);
}

export function useLibretaEventoRegistrosApi() {
  return useSelector(state => state.Libreta.context.registrosApi);
}

// *************************************************************
//     Catalogos
export function useLibretaEventosCatalogo() {
  return useSelector(state => state.Libreta.context.eventos_catalogos);
}
export function useLibretaEventosCatalogoEstadosAnimo() {
  return useSelector(state => state.Libreta.context.eventos_catalogo_estadosAnimo);
}
export function useLibretaEventosCatalogoComidaCantidad() {
  return useSelector(state => state.Libreta.context.eventos_catalogo_comida_cantidad);
}
export function useLibretaEventosCatalogoComidaObservaciones() {
  const init = []
  return useSelector(state => state.Libreta.context.evento_catalogo_observaciones[0] ? state.Libreta.context.evento_catalogo_observaciones[0] : init);
}
export function useLibretaEventosCatalogoDiapersObservaciones() {
  const init = []
  return useSelector(state => state.Libreta.context.evento_catalogo_observaciones[1] ? state.Libreta.context.evento_catalogo_observaciones[1] : init);
}
export function useLibretaEventosCatalogoSiestaObservaciones() {
  const init = []
  return useSelector(state => state.Libreta.context.evento_catalogo_observaciones[2] ? state.Libreta.context.evento_catalogo_observaciones[2] : init);
}
export function useLibretaEventosCatalogoBathObservaciones() {
  const init = []
  return useSelector(state => state.Libreta.context.evento_catalogo_observaciones[3] ? state.Libreta.context.evento_catalogo_observaciones[3] : init);
}
export function useLibretaEventosCatalogoAcademiaObservaciones() {
  const init = []
  return useSelector(state => state.Libreta.context.evento_catalogo_observaciones[4] ? state.Libreta.context.evento_catalogo_observaciones[4] : init);
}
export function useLibretaEventosCatalogoObservaciones() {
  const init = []
  return useSelector(state => state.Libreta.context.evento_catalogo_observaciones[5] ? state.Libreta.context.evento_catalogo_observaciones[5] : init);
}

// *************************************************************
//     Comida
export function useLibretaEventosComidaIdCantidad() {
  return useSelector(state => state.Libreta.context.idCantidad);
}

// *************************************************************
//     Diapers
export function useLibretaEventosDiapersPipi() {
  return useSelector(state => state.Libreta.context.pipi);
}
export function useLibretaEventosDiapersPopo() {
  return useSelector(state => state.Libreta.context.popo);
}

// *************************************************************
//     Observaciones
export function useIdLibretaObservacion() {
  return useSelector(state => state.Libreta.context.idLibretaObservacion);
}

export function useLibretaObservacionComida() {
  return useSelector(state => state.Libreta.context.eventos_comida_observacion);
}

export function useLibretaObservacionDiapers() {
  return useSelector(state => state.Libreta.context.eventos_diapers_observacion);
}

export function useLibretaObservacionSiesta() {
  return useSelector(state => state.Libreta.context.eventos_siesta_observacion);
}

export function useLibretaObservacionBath() {
  return useSelector(state => state.Libreta.context.eventos_bath_observacion);
}

export function useLibretaObservacionAcademia() {
  return useSelector(state => state.Libreta.context.eventos_academia_observacion);
}

// *************************************************************
//  Catalogo Observaciones

// *************************************************************
//     Siesta
export function useLibretaEventosSiestaHora() {
  return useSelector(state => state.Libreta.context.siestaHora);
}
export function useLibretaEventosSiestaMinutos() {
  return useSelector(state => state.Libreta.context.siestaMinutos);
}
export function useLibretaEventosSiestaDuracion() {
  return useSelector(state => state.Libreta.context.siestaDuracion);
}

// *************************************************************
//     FuealBath
export function useLibretaEventosBathSet() {
  return useSelector(state => state.Libreta.context.fuealBath);
}

// *************************************************************
//     Registro Evento
export function useLibretaEventosEventoRegistrado() {
  return useSelector(state => state.Libreta.context.eventos_EventoRegistrado);
}

// *************************************************************
//     Revision - Fecha
export function useLibretaRevisionFecha() {
  return useSelector(state => state.Libreta.context.revision_fecha);
}


// *************************************************************
//     Envio displayRepote
export function useLibretaReporteEnvio() {
  return useSelector(state => state.Libreta.context.reporteEnvio);
}

export function useModal_envio() {
  return useSelector(state => state.Libreta.context.modal_envio);
}

export function useLibretaOGrupoAcademicoSelected() {
  return useSelector(state => state.Libreta.context.oGrupoAcademicoSelected);
}

export function useRefreshReporteEnvioDetalles() {
  return useSelector(state => state.Libreta.context.refreshReporteEnvioDetalles);
}

export function useLibretaReportesFechaInicio() {
  return useSelector(state => state.Libreta.context.reportes_fecha_inicio);
}

export function useLibretaReportesFechaFinal() {
  return useSelector(state => state.Libreta.context.reportes_fecha_final);
}
