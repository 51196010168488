

const registroApi = 
		{
			"oAlumno": {
				"id_Alumno": 5474,
				"id_Externo": -1,
				"id_Familia": 4452,
				"id_Grado": -1,
				"id_Lista_Distribucion_Mama": 29634,
				"grupos": [
					{
						"id_Grupo_Academico": 315,
						"id_Colegio": -1,
						"id_Nivel_Educativo": -1,
						"id_Grado": -1,
						"id_Grupo": -1,
						"nombre_Nivel_Educativo": "",
						"nombre_Grado": "",
						"nombre_Grupo": "",
						"nombre_Grupo_Academico": "",
						"alumnos": [],
						"profesores": [],
						"iError": 0,
						"mensaje_Error": "",
						"id_UniqueIdentifier": "00000000-0000-0000-0000-000000000000",
						"rowsAffected": 0
					}
				],
				"id_Familia_Externo": -1,
				"nombre_Familia": "",
				"id_Contacto": 31945,
				"id_Colegio": 7,
				"campo1": "Alumno",
				"campo2": "",
				"campo3": "",
				"campo4": "",
				"campo5": "",
				"celular": "9000825170",
				"celular2": "",
				"email": "Alumno_805020@SinCorreo.edu.mx",
				"email2": "",
				"token": "",
				"osToken": "",
				"token2": "",
				"osToken2": "",
				"token3": "",
				"osToken3": "",
				"nombre": "Montaño Gómez Alessa",
				"id_Relacion_Lista_Distribucion_Contacto": -1,
				"nombre_Conversacion": "",
				"id_Relacion_Conversacion_Contacto": -1,
				"lista_Listas_Distribucion_Permiso_Enviar": [],
				"roles": [],
				"id_Persona": 33192,
				"nombres": "Alessa",
				"apellido_Paterno": "Montaño",
				"apellido_Materno": "Gómez",
				"fecha_Nacimiento": "2020-05-07T00:00:00",
				"url_Fotografia": ""
			},
			"comida": [
				{
					"id_Libreta_Registro_Comida": 64,
					"id_Alumno": 5474,
					"id_Libreta_Cantidad": 6,
					"id_Libreta_Estado_Animo": 6,
					"id_Libreta_Observacion": -1,
					"observacion": "Notas Miss",
					"fecha": "2022-10-29T15:06:40",
					"borrado": false,
					"catalogo_Nombre_Estado_Animo": "Felíz",
					"catalogo_Icono_Estado_Animo": "mdi mdi-emoticon-happy-outline",
					"catalogo_Observacion": "No mandar lentejas",
					"catalogo_Cantidad": "Todo",
					"catalogo_Icono_Cantidad": "mdi mdi-animation-outline"
				},
				{
					"id_Libreta_Registro_Comida": 64,
					"id_Alumno": 5474,
					"id_Libreta_Cantidad": 6,
					"id_Libreta_Estado_Animo": 6,
					"id_Libreta_Observacion": -1,
					"observacion": "Notas Miss",
					"fecha": "2022-10-29T15:06:40",
					"borrado": false,
					"catalogo_Nombre_Estado_Animo": "Dif",
					"catalogo_Icono_Estado_Animo": "mdi mdi-emoticon-happy-outline",
					"catalogo_Observacion": "No mandar lentejas",
					"catalogo_Cantidad": "Todo",
					"catalogo_Icono_Cantidad": "mdi mdi-animation-outline"
				}
			],
			"diapers": [
				{
					"id_Libreta_Registro_Diapers": 18,
					"id_Alumno": 5474,
					"pipi": true,
					"popo": false,
					"id_Libreta_Estado_Animo": 7,
					"id_Libreta_Observacion": -1,
					"observacion": "5674567",
					"fecha": "2022-10-29T15:06:40",
					"borrado": false,
					"catalogo_Nombre_Estado_Animo": "Emocionado",
					"catalogo_Icono_Estado_Animo": "mdi mdi-emoticon-outline",
					"catalogo_Observacion": "uiopuiop"
				},
				{
					"id_Libreta_Registro_Diapers": 18,
					"id_Alumno": 5474,
					"pipi": true,
					"popo": false,
					"id_Libreta_Estado_Animo": 7,
					"id_Libreta_Observacion": -1,
					"observacion": "asdfasdf",
					"fecha": "2022-10-29T15:06:40",
					"borrado": false,
					"catalogo_Nombre_Estado_Animo": "Emocionado",
					"catalogo_Icono_Estado_Animo": "mdi mdi-emoticon-outline",
					"catalogo_Observacion": "asd"
				}
			],
			"siesta": [
				{
					"id_Libreta_Registro_Siesta": 12,
					"id_Alumno": 5474,
					"inicio": "2022-10-29T12:00:00",
					"final": "2022-10-29T12:10:00",
					"id_Libreta_Estado_Animo": 8,
					"id_Libreta_Observacion": 19,
					"observacion": "",
					"fecha": "2022-10-29T15:06:40",
					"borrado": false,
					"duracionMinutos": 10,
					"catalogo_Nombre_Estado_Animo": "Exitado",
					"catalogo_Icono_Estado_Animo": "mdi mdi-emoticon-excited-outline",
					"catalogo_Observacion": "Durmió un poco intranquilo"
				},
				{
					"id_Libreta_Registro_Siesta": 12,
					"id_Alumno": 5474,
					"inicio": "2022-10-29T12:00:00",
					"final": "2022-10-29T12:10:00",
					"id_Libreta_Estado_Animo": 8,
					"id_Libreta_Observacion": 19,
					"observacion": "sdasdfasdf",
					"fecha": "2022-10-29T15:06:40",
					"borrado": false,
					"duracionMinutos": 10,
					"catalogo_Nombre_Estado_Animo": "Exitado",
					"catalogo_Icono_Estado_Animo": "mdi mdi-emoticon-excited-outline",
					"catalogo_Observacion": "Durmió un poco intranquilo"
				}
			],
			"bath": [
				{
					"id_Libreta_Registro_Bath": 12,
					"id_Alumno": 5474,
					"bath": true,
					"id_Libreta_Estado_Animo": 8,
					"id_Libreta_Observacion": 22,
					"observacion": "bath",
					"fecha": "2022-10-29T15:06:40",
					"borrado": false,
					"catalogo_Nombre_Estado_Animo": "Exitado",
					"catalogo_Icono_Estado_Animo": "mdi mdi-emoticon-excited-outline",
					"catalogo_Observacion": "Un poco de diarrea"
				},
				{
					"id_Libreta_Registro_Bath": 12,
					"id_Alumno": 5474,
					"bath": true,
					"id_Libreta_Estado_Animo": 8,
					"id_Libreta_Observacion": 22,
					"observacion": "bath2",
					"fecha": "2022-10-29T15:06:40",
					"borrado": false,
					"catalogo_Nombre_Estado_Animo": "Exitado",
					"catalogo_Icono_Estado_Animo": "mdi mdi-emoticon-excited-outline",
					"catalogo_Observacion": "Un poco de diarrea"
				}
			],
			"academia": [
				{
					"id_Libreta_Registro_Academia": 16,
					"id_Alumno": 5474,
					"id_Libreta_Estado_Animo": 6,
					"id_Libreta_Observacion": 25,
					"observacion": "2342345",
					"fecha": "2022-10-29T15:06:40",
					"borrado": false,
					"catalogo_Nombre_Estado_Animo": "Felíz",
					"catalogo_Icono_Estado_Animo": "mdi mdi-emoticon-happy-outline",
					"catalogo_Observacion": "Gateamos"
				},
				{
					"id_Libreta_Registro_Academia": 16,
					"id_Alumno": 5474,
					"id_Libreta_Estado_Animo": 6,
					"id_Libreta_Observacion": 25,
					"observacion": "zxzxcv",
					"fecha": "2022-10-29T15:06:40",
					"borrado": false,
					"catalogo_Nombre_Estado_Animo": "Felíz",
					"catalogo_Icono_Estado_Animo": "mdi mdi-emoticon-happy-outline",
					"catalogo_Observacion": "Gateamos"
				}
			],
			"empty": false
		}

export default registroApi
