import React, { useState, useEffect } from 'react';
import MetaTags from "react-meta-tags"
import { useDispatch } from "react-redux"
import { getoSolicitudBase } from "../../helpers/comunicabackend_helper"
import { forEach, map } from "lodash"
import Breadcrumbs from "components/Common/Breadcrumb2"
import toastr from "toastr"
import "toastr/build/toastr.min.css"

import pug from "pug"
import oldPugTemplate from './data/oldPugTemplate';

import DataComidaRow from "./tabla-registro-comida"
import CardRevision from "./cardRevision"
import CardContact from "../Contacts/card-contact"
import PropTypes from 'prop-types'
import classnames from "classnames";
import 'devextreme/dist/css/dx.light.css';
import {
  Container,
  Row,
  Col,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Button,
  Modal,
  Popover,
  PopoverHeader,
  PopoverBody,
  UncontrolledPopover,
} from "reactstrap"

import { useAuthUserGmtOffset } from "./../../store/auth/loginState/selectors"


import {
  useLibretaStatus,
  useModal_backdrop,
  useLibretaGruposAcademicos,
  useIdGrupoAcademicoSelected,
  useLibretaError,
  useLibretaLoading,
  useDisplayNotification,
  useLibretaEventoRegistrosApi,
  useLibretaEventosCatalogoEstadosAnimo,
  useLibretaEventosCatalogoComidaCantidad,
  useLibretaEventosCatalogoComidaObservaciones,
  useLibretaEventosCatalogoDiapersObservaciones,
  useLibretaEventosCatalogoSiestaObservaciones,
  useLibretaEventosCatalogoBathObservaciones,
  useLibretaEventosCatalogoAcademiaObservaciones,
  useLibretaRevisionFecha,
  useLibretaReporteEnvio,
  useLibretaOGrupoAcademicoSelected,
} from "../../store/libreta/selectors"
import {
  actionSetDisplayNotification as onSetDisplayNotification,
  actionUnSetDisplayNotification as onUnSetDisplayNotification,
  setIdGrupoAcademicoLibretaSelected as onSetIdGrupoAcademicoLibretaSelected,
  setLibretaOGrupoAcademicoSelected as onSetLibretaOGrupoAcademicoSelected,
  actionGetRegistroEventos as onActionGetRegistroEventos,
  actionSetEstadoRevision as onActionSetEstadoRevision,
  getGruposAcademicosLibreta as onGruposAcademicosLibreta,
  actionSetFecha as onSetFecha,
  actionSetFechaMasUnDia as onSetFechaMasUnDia,
  actionSetFechaMenosUnDia as onSetFechaMenosUnDia,
  actionEnvio as onRegistroEventos,
  actionClearRegistroEventos as onClearRegistroEventos,
  getLibretaCatalogosEventos as onGetLibretaCatalogosEventos,
} from "../../store/libreta/actions"


const LibretaRevision = () => {

  const formatDate = new Intl.DateTimeFormat('es-MX').format;
  const registroInit = { oAlumno: { lista_Distribucion_Mama: { lista_Contactos: [] } } }

  const [html, setHtml] = useState("");
  const [modal_standard, setmodal_standard] = useState(false);
  const [popoverdismiss, setpopoverdismiss] = useState(false);
  const [registroSelected, setRegistroSelected] = useState(registroInit);
  const [multipleRegisters, setMultipleRegisters] = useState(false);

  const dispatch = useDispatch()

  const moverseEntreFechas = true
  const edicion = true

  // Constantes
  const libretaStatus = useLibretaStatus()
  const LibretaLoading = useLibretaLoading()
  const gruposAcademicos = useLibretaGruposAcademicos()
  const idGrupoAcademicoSelected = useIdGrupoAcademicoSelected();
  const displayNotification = useDisplayNotification()
  const registrosApi = useLibretaEventoRegistrosApi()
  const EventosCatalogoEstadosAnimo = useLibretaEventosCatalogoEstadosAnimo()
  const EventosCatalogoComidaCantidad = useLibretaEventosCatalogoComidaCantidad()

  const EventosCatalogoComidaObservaciones = useLibretaEventosCatalogoComidaObservaciones()
  const EventosCatalogoDiapersObservaciones = useLibretaEventosCatalogoDiapersObservaciones()
  const EventosCatalogoSiestaObservaciones = useLibretaEventosCatalogoSiestaObservaciones()
  const EventosCatalogoBathObservaciones = useLibretaEventosCatalogoBathObservaciones()
  const EventosCatalogoAcademiaObservaciones = useLibretaEventosCatalogoAcademiaObservaciones()
  const Revision_Fecha = useLibretaRevisionFecha()
  const authUserGmtOffset = useAuthUserGmtOffset()
  const ReporteEnvio = useLibretaReporteEnvio()
  const oGrupoAcademicoSelected = useLibretaOGrupoAcademicoSelected()

  const EventosCatalogoObservaciones = {
    Comida: EventosCatalogoComidaObservaciones,
    Diapers: EventosCatalogoDiapersObservaciones,
    Siesta: EventosCatalogoSiestaObservaciones,
    Bath: EventosCatalogoBathObservaciones,
    Academia: EventosCatalogoAcademiaObservaciones,
  }


  console.log("--------------| iniciando revision|--------------")
  // console.log("authUserGmtOffset:", authUserGmtOffset)
  // console.log("libretaStatus: ", libretaStatus)
  // console.log("gruposAcademicos: ", gruposAcademicos)
  // console.log("registrosApi: ", registrosApi)
  // console.log("EventosCatalogoEstadosAnimo: ", EventosCatalogoEstadosAnimo)
  // console.log("EventosCatalogoComidaCantidad: ", EventosCatalogoComidaCantidad)
  // console.log("EventosCatalogoComidaObservaciones:", EventosCatalogoComidaObservaciones)
  // console.log("EventosCatalogoObservaciones: ", EventosCatalogoObservaciones)
  // console.log("Revision_Fecha: ", Revision_Fecha)
  // console.log("ReporteEnvio: ", ReporteEnvio)
  //console.log("oGrupoAcademicoSelected: ", oGrupoAcademicoSelected)

  const onClickTabGrupoAcademico = tab => {
    if (oGrupoAcademicoSelected.id_Grupo_Academico !== tab)

      dispatch(onClearRegistroEventos())
    dispatch(onSetIdGrupoAcademicoLibretaSelected(tab))
    dispatch(onSetLibretaOGrupoAcademicoSelected(gruposAcademicos.filter(ogrupoAcademico => ogrupoAcademico.id_Grupo_Academico == tab)[0]))

    const oSolicitud_Registros_xGrupoAcademico = getoSolicitudBase(
      "Libreta::revision::onClickTabGrupoAcademico",
      "Registros_xGrupoAcademico",
      [],
      [{ nombre: "id_Grupo_Academico", valor: tab },
      { nombre: "Fecha", valor: new Date(Revision_Fecha).toISOString() },
      { nombre: "IncluyeBorrados", valor: "false" }
      ]
    );
    dispatch(onActionGetRegistroEventos(oSolicitud_Registros_xGrupoAcademico))
  }

  const onClickSetFechaMenos = () => {
    dispatch(onSetFechaMenosUnDia())
  }

  const onClickSetFechaMas = () => {

    dispatch(onSetFechaMasUnDia())
  }

  const preview = (registro) => {

    tog_standard();

    setMultipleRegisters(false)

    /* Para conseguir el nombre del profesor */
    registro.nombre_Profesor = 'sin definir'
    const oGrupo_Academico = gruposAcademicos.filter((x) => x.id_Grupo_Academico == idGrupoAcademicoSelected)
    if (oGrupo_Academico.length > 0) {
      if (oGrupo_Academico[0].profesores.length > 0) {
        registro.nombre_Profesor = oGrupo_Academico[0].profesores[0].campo1 + ' ' + oGrupo_Academico[0].profesores[0].nombres
      }
    }

    /* Para conseguir la cadena de pipi o popo */
    if (registro.diapers) {
      if (registro.diapers.length > 0) {
        registro.diapers.forEach(cambio => {
          cambio.cadena = ""
          if (cambio.pipi && cambio.popo) {
            cambio.cadena = "Pipi y Popo"
          } else {
            if (cambio.pipi) {
              cambio.cadena = "Pipi"
            } else {
              cambio.cadena = "Popo"
            }
          }
        });
      }
    }


    console.log("************************************")

    console.log("registro: ", registro)
    setRegistroSelected(registro)

    console.log("************************************")

    var fn = pug.compile(oldPugTemplate);
    var html = fn(registro);
    setHtml(html)
    // console.log('html')
    // console.log(html)
  }

  const previewRegistros = (registros) => {

    tog_standard();

    setMultipleRegisters(true)

    var htmls = "";

    var fn = pug.compile(oldPugTemplate);

    registros.forEach(registro => {

      if (!registro.empty) {

        /* Para conseguir el nombre del profesor */
        registro.nombre_Profesor = 'sin definir'
        const oGrupo_Academico = gruposAcademicos.filter((x) => x.id_Grupo_Academico == idGrupoAcademicoSelected)
        if (oGrupo_Academico.length > 0) {
          if (oGrupo_Academico[0].profesores.length > 0) {
            registro.nombre_Profesor = oGrupo_Academico[0].profesores[0].campo1 + ' ' + oGrupo_Academico[0].profesores[0].nombres
          }
        }

        /* Para conseguir la cadena de pipi o popo */
        if (registro.diapers) {
          if (registro.diapers.length > 0) {
            registro.diapers.forEach(cambio => {
              cambio.cadena = ""
              if (cambio.pipi && cambio.popo) {
                cambio.cadena = "Pipi y Popo"
              } else {
                if (cambio.pipi) {
                  cambio.cadena = "Pipi"
                } else {
                  cambio.cadena = "Popo"
                }
              }
            });
          }
        }

        var html = fn(registro);

        htmls = htmls + html;
      }
    })
    setHtml(htmls)

  }

  //set Estado Revision y hora correcta
  useEffect(() => {
    dispatch(onActionSetEstadoRevision())
    // const fechaLocal = subtractHours(authUserGmtOffset)
    // dispatch(onSetFecha(fechaLocal))
  }, [onActionSetEstadoRevision, onSetFecha])

  // Cada que cambie la fecha
  useEffect(() => {
    const oSolicitud_Registros_xGrupoAcademico = getoSolicitudBase(
      "Libreta::revision::onClickTabGrupoAcademico",
      "Registros_xGrupoAcademico",
      [],
      [{ nombre: "id_Grupo_Academico", valor: oGrupoAcademicoSelected.id_Grupo_Academico },
      { nombre: "Fecha", valor: Revision_Fecha.toISOString() },
      { nombre: "IncluyeBorrados", valor: "false" }
      ]
    );
    dispatch(onActionGetRegistroEventos(oSolicitud_Registros_xGrupoAcademico))
  }, [dispatch, Revision_Fecha])

  // GruposAcademicos
  useEffect(() => {
    console.log('useEffect::GruposAcademicos: ', gruposAcademicos)
    const oSolicitudBase_GruposAcademicos = getoSolicitudBase(
      "Libreta::index::useEffect",
      "getgruposacademicoslibreta",
      [],
      []
    )
    if (gruposAcademicos && !gruposAcademicos.length) {
      dispatch(onGruposAcademicosLibreta(oSolicitudBase_GruposAcademicos))
    }
  }, [])

  // Catálogos
  useEffect(() => {
    const oSolicitudBase_Catalogos = getoSolicitudBase(
      "Libreta::useEffect",
      "onGetLibretaCatalogosEventos",
      [],
      []
    );
    if (EventosCatalogoEstadosAnimo.length == 0) {
      dispatch(onGetLibretaCatalogosEventos(oSolicitudBase_Catalogos));
    }
  }, [])

  // DisplayNotification
  useEffect(() => {
    console.log("useEffect::displayNotification: ", displayNotification)
    if (displayNotification !== undefined) {
      if (displayNotification.tipo) {
        switch (displayNotification.tipo) {
          case "success":
            toastr.success(
              displayNotification.mensaje,
              displayNotification.title
            )
            break
          case "info":
            toastr.info(displayNotification.mensaje, displayNotification.titulo)
            break
          case "warning":
            toastr.warning(
              displayNotification.mensaje,
              displayNotification.titulo
            )
            break
          case "error":
            toastr.error(
              displayNotification.mensaje,
              displayNotification.titulo
            )
            break
          default:
            break
        }
      }
      dispatch(onUnSetDisplayNotification());
    }
  }, [dispatch, onUnSetDisplayNotification, displayNotification])

  function tog_standard() {
    console.log('tog_standard');
    setmodal_standard(!modal_standard);
    removeBodyCss();
  }

  function registrarNotificacion() {
    const oSolicitud = getoSolicitudBase(
      "Libreta::revision::registrarNotificacion",
      "registrarNotificacion",
      [{ id_Lista_Distribucion: registroSelected.oAlumno.id_Lista_Distribucion_Mama, mensaje: html }],
      [{ nombre: "tema", valor: "Resumen de mi día." }]
    )
    dispatch(onRegistroEventos(oSolicitud))
  }

  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Comunica | Libreta - Revisión</title>
        </MetaTags>
        <Container fluid={true} >
        <Breadcrumbs title="Libreta" 
              breadcrumbItems={[
                { title: "Eventos", link: "/libreta" },
                { title: "Revisión", link: "/libreta/revision" },
                { title: "Envío", link: "/libreta/envio" },
                { title: "Configuración", link: "/libreta/configuracion" }
              ]}
              activo="Revisión" />
          <Row className="no-gutters">
            <Col xs="12">
              <div className="d-flex align-items-center justify-content-between">
                <h4 className="mb-0 font-size-18">
                  {LibretaLoading ? (
                    <span className="avatar-title rounded-circle bg-light">
                      <div className="spinner-border text-primary m-1" role="status"><span className="sr-only">Loading...</span></div>
                    </span>
                  ) : ((<></>))}
                </h4>
                <div className="page-title-right">
                  <Button
                    type="button"
                    color="success"
                    size="sm"
                    onClick={onClickSetFechaMenos}
                    className={classnames({ 'd-none': !moverseEntreFechas }, "m-1")}
                  >
                    <i className="fa fa-chevron-left" />
                  </Button>
                  {' '} {formatDate(Revision_Fecha)} {' '}
                  <Button
                    type="button"
                    color="success"
                    size="sm"
                    onClick={onClickSetFechaMas}
                    className={classnames({ 'd-none': !moverseEntreFechas }, "m-1")}
                  >
                    <i className="fa fa-chevron-right" />
                  </Button>
                  {' '}
                  <Button
                    color="danger"
                    size="sm"
                    className={classnames({ 'd-none': moverseEntreFechas }, "btn btn-danger m-0 ")}
                  >
                    <i className="mdi mdi-email-multiple-outline font-size-16 align-middle me-2"></i>{" "}
                    Enviar todo.
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Nav tabs className="nav-tabs-custom nav-justified">
              {map(gruposAcademicos, (o, keyga) => (
                <NavItem
                  key={keyga}>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      active: oGrupoAcademicoSelected.id_Grupo_Academico === o.id_Grupo_Academico,
                    })}
                    onClick={() => {
                      onClickTabGrupoAcademico(o.id_Grupo_Academico);
                    }}
                  >
                    <span className="d-block d-sm-none">
                      <i className=""> {o.nombre_Grupo_Academico}</i>
                    </span>
                    <span className="d-none d-sm-block">{o.nombre_Grupo_Academico}</span>
                  </NavLink>
                </NavItem>
              ))
              }
            </Nav>
            <TabContent
              activeTab={oGrupoAcademicoSelected.id_Grupo_Academico}
              className="p-2 text-muted "
            >
              <TabPane tabId={oGrupoAcademicoSelected.id_Grupo_Academico}>
                <Button
                  color="secondary"
                  size="sm"
                  hidden={registrosApi.length}
                  onClick={() => {
                    previewRegistros(registrosApi);
                  }}
                  className={classnames("btn btn-rounded m-1 ")}
                >
                  <i className="mdi mdi-clipboard-search-outline font-size-16 align-middle me-2"></i>{" "}
                  Preview {oGrupoAcademicoSelected.nombre_Grupo_Academico}
                </Button>
                {map(registrosApi, (registroAlumno, keyR) => (
                  <CardRevision
                    key={"_alumno_" + keyR}
                    registroAlumno={registroAlumno}
                    estadosAnimo={EventosCatalogoEstadosAnimo}
                    cantidades={EventosCatalogoComidaCantidad}
                    observaciones={EventosCatalogoObservaciones}
                    edicion={edicion}
                    onpreview={preview}
                  />
                ))
                }
              </TabPane>
            </TabContent>
          </Row>

          <Modal
            isOpen={modal_standard}
            toggle={() => {
              tog_standard();
            }}
          >
            <div className="modal-header">
              <h5 className="modal-title mt-0" id="myModalLabel">
                {/* {registroAlumno.nombres} */}
              </h5>
              <button
                type="button"
                onClick={() => {
                  setmodal_standard(false);
                }}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">

              <Row>
                <Col className="col-12">
                  {multipleRegisters ? (<></>) : ((<>Este reporte se entregará a la lista de distribución llamada: {registroSelected.oAlumno.lista_Distribucion_Mama.nombre}</>))}
                  <div dangerouslySetInnerHTML={{ __html: html }} />
                </Col>
              </Row>

              {multipleRegisters ? (<></>) : ((
                <Row>
                  <Col className="col-12">
                    &nbsp;
                    <Button
                      id="Popoverdismiss"
                      className="btn btn-success"
                      onClick={() => {
                        setpopoverdismiss(!popoverdismiss);
                      }}
                    >
                      Se entregará a:
                    </Button>
                    <UncontrolledPopover
                      trigger="focus"
                      target="Popoverdismiss"
                      placement="right"
                    >
                      <PopoverHeader>Miembros</PopoverHeader>
                      <PopoverBody>

                        {registroSelected.oAlumno.lista_Distribucion_Mama ? map(registroSelected.oAlumno.lista_Distribucion_Mama.lista_Contactos, (oContacto, keyga) => (
                          <div className="d-flex">
                            <div className="align-self-center me-3">
                              {/* <img
                              src="avatar1"
                              className="rounded-circle avatar-xs"
                              alt=""
                            /> */}
                            </div>
                            <div className="flex-grow-1 overflow-hidden">
                              <h5 className="text-truncate font-size-14 mb-0">
                                {oContacto.nombres} {' '} {oContacto.apellido_Paterno}
                              </h5>
                              <p className="text-truncate mb-2">
                                {oContacto.email}
                              </p>
                            </div>
                          </div>
                        )) : (<></>)}

                      </PopoverBody>
                    </UncontrolledPopover>
                  </Col>
                </Row>))}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                onClick={() => {
                  tog_standard();
                }}
                className="btn btn-secondary "
                data-dismiss="modal"
              >
                Close
              </button>
              {/* <button
                type="button"
                className="btn btn-primary "
                onClick={() => {
                  registrarNotificacion();
                }}
              >
                Enviar
              </button> */}
            </div>
          </Modal>

        </Container>
      </div>
    </React.Fragment>
  );
}


LibretaRevision.propTypes = {
  employee: PropTypes.any,
}

export default LibretaRevision

