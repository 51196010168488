import axios from "axios"
import getaccessToken from "./jwt-token-access/accessToken"

import {API_URL} from '../constants/config'

//apply base url for axios
// const API_URL = "https://comunicaapi-beta.azurewebsites.net"
// const API_URL = "http://192.168.1.22:62856"
//const API_URL = "http://localhost:62856" 

const axiosApi = axios.create({
  baseURL: API_URL,
})

axiosApi.defaults.headers.common["Authorization"] = getaccessToken()

axiosApi.interceptors.response.use(
  response => response,
  error => Promise.reject(error)
)

export async function get(url, config = {}) {
  axiosApi.defaults.headers.common["Authorization"] = getaccessToken()
  return await axiosApi.get(url, { ...config }).then(response => response.data)
}

export async function post(url, data, config = {}) {
  axiosApi.defaults.headers.common["Authorization"] = getaccessToken()
  return axiosApi
    .post(url, { ...data }, { ...config })
    .then(response => response.data)
}

export async function put(url, data, config = {}) {
  axiosApi.defaults.headers.common["Authorization"] = getaccessToken()
  return axiosApi
    .put(url, { ...data }, { ...config })
    .then(response => response.data)
}

export async function del(url, config = {}) {
  axiosApi.defaults.headers.common["Authorization"] = getaccessToken()
  return await axiosApi
    .delete(url, { ...config })
    .then(response => response.data)
}
