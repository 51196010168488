import React, { useEffect, useState, useRef } from "react";
import MetaTags from "react-meta-tags";
import { withRouter, Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import classnames from "classnames";
import Select from "react-select"
import Modal_Alumno from "../../components/Common/Formulario-Alta-Alumno"
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  Button,
  ModalHeader,
  ModalBody,
  Label,
  Nav,
  NavItem,
  NavLink,
  FormFeedback,
  Input,
  Form,
  TabContent,
  TabPane,
} from "reactstrap";
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import * as Yup from "yup";
import { useFormik } from "formik";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import Switch from "react-switch";

import images from "assets/images";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb2";
import DeleteModal from "components/Common/DeleteModal";

import {
  // getAlumnos as onGetUsers,
  // addNewUser as onAddNewUser,
  // updateUser as onUpdateUser,
  // deleteUser as onDeleteUser,
} from "store/contacts/actions";

import {
  getAlumnos as onGetUsers,
  addNewAlumno as onAddNewUser,
  updateAlumno as onUpdateUser,
  deleteAlumno as onDeleteUser,
} from "../../store/alumnos/actions"

import {
  getGruposAcademicos as onGruposAcademicos,
} from "../../store/grupos-academicos/actions"

import { useGruposAcademicos, useGruposAcademicosOptions } from "../../store/grupos-academicos/selectors"

import {
  getoSolicitudBase,
} from "../../helpers/comunicabackend_helper"

import { isEmpty, size, map } from "lodash";

//redux
import { useSelector, useDispatch } from "react-redux";
import contactos from "store/contacts/reducer";

const AcademiaAlumnos = props => {

  const dispatch = useDispatch();

  const init_oAlumno = {
    hermano: false,
    grupoAcademico: { value: -1, label: 'Selecciona...' },

    apellido_Materno: "",
    apellido_Paterno: "",
    campo1: "",
    campo2: "",
    campo3: "",
    campo4: "",
    campo5: "",
    celular: "",
    celular2: "",
    email: "",
    email2: "",
    fecha_Nacimiento: "",
    grupos: [{
      alumnos: [],
      iError: 0,
      id_Colegio: -1,
      id_Grado: -1,
      id_Grupo: -1,
      id_Grupo_Academico: -1,
      id_Nivel_Educativo: -1,
      id_UniqueIdentifier: "",
      mensaje_Error: "",
      nombre_Grado: "",
      nombre_Grupo: "",
      nombre_Grupo_Academico: "",
      nombre_Nivel_Educativo: "",
      profesores: [],
      rowsAffected: 0
    }],
    id_Alumno: -1,
    id_Colegio: -1,
    id_Contacto: -1,
    id_Externo: -1,
    id_Familia: -1,
    id_Familia_Externo: -1,
    id_Grado: -1,
    id_Lista_Distribucion_Mama: -1,
    id_Persona: -1,
    id_Relacion_Conversacion_Contacto: -1,
    id_Relacion_Lista_Distribucion_Contacto: -1,
    lista_Distribucion_Mama: {
      campo1: "",
      campo2: "",
      campo3: "",
      campo4: "",
      campo5: "",
      iError: -1,
      id_Colegio: -1,
      id_Lista_Distribucion: -1,
      id_UniqueIdentifier: "",
      lista_Contactos: [],
      mensaje_Error: "",
      no_Contactos: 0,
      nombre: "",
      nombre_No_Contactos: " - 0",
      rowsAffected: 0,
    },
    lista_Listas_Distribucion_Permiso_Enviar: [],
    nombre: "",
    nombre_Conversacion: "",
    nombre_Familia: "",
    nombres: "",
    oFamilia: {
      iError: 0,
      id_Colegio: -1,
      id_Contacto_Mama: -1,
      id_Contacto_Papa: -1,
      id_Externo: -1,
      id_Familia: -1,
      id_UniqueIdentifier: "",
      mensaje_Error: "",
      nombre_Familia: "",
      oContacto_Madre: {
        apellido_Materno: "",
        apellido_Paterno: "",
        campo1: "",
        campo2: "",
        campo3: "",
        campo4: "",
        campo5: "",
        celular: "",
        celular2: "",
        email: "",
        email2: "",
        fecha_Nacimiento: "",
        id_Colegio: -1,
        id_Contacto: -1,
        id_Familia: -1,
        id_Familia_Externo: -1,
        id_Persona: -1,
        id_Relacion_Conversacion_Contacto: -1,
        id_Relacion_Lista_Distribucion_Contacto: -1,
        lista_Listas_Distribucion_Permiso_Enviar: [],
        nombre: "",
        nombre_Conversacion: "",
        nombre_Familia: "",
        nombres: "",
        osToken: "",
        osToken2: "",
        osToken3: "",
        roles: [],
        token: "",
        token2: "",
        token3: "",
        url_Fotografia: "",
      },
      oContacto_Padre: {
        apellido_Materno: "",
        apellido_Paterno: "",
        campo1: "",
        campo2: "",
        campo3: "",
        campo4: "",
        campo5: "",
        celular: "",
        celular2: "",
        email: "",
        email2: "",
        fecha_Nacimiento: "",
        id_Colegio: -1,
        id_Contacto: -1,
        id_Familia: -1,
        id_Familia_Externo: -1,
        id_Persona: -1,
        id_Relacion_Conversacion_Contacto: -1,
        id_Relacion_Lista_Distribucion_Contacto: -1,
        lista_Listas_Distribucion_Permiso_Enviar: [],
        nombre: "",
        nombre_Conversacion: "",
        nombre_Familia: "",
        nombres: "",
        osToken: "",
        osToken2: "",
        osToken3: "",
        roles: [],
        token: "",
        token2: "",
        token3: "",
        url_Fotografia: "",
      },
      rowsAffected: 0,
    },
    osToken: "",
    osToken2: "",
    osToken3: "",
    roles: [],
    token: "",
    token2: "",
    token3: "",
    url_Fotografia: "",
  }

  const [contact, setContact] = useState();
  const [userList, setUserList] = useState([]);
  const [modal, setModal] = useState(false);
  const [isNew, setIsNew] = useState(true);
  //const [isEdit, setIsEdit] = useState(false);
  const [name, setName] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [switch1, setswitch1] = useState(false);
  const [activeTab, setactiveTab] = useState("Alumno")
  const [startDateAlumno, setStartDateAlumno] = useState(new Date());
  const [startDateMama, setStartDateMama] = useState(new Date());
  const [startDatePapa, setStartDatePapa] = useState(new Date());
  const [id_Grupo_Academico_Selected, set_id_Grupo_Academico_Selected] = useState(-1);

  const gruposAcademicos = useGruposAcademicos()
  const GrupoAcademicosOptions = useGruposAcademicosOptions()



  const onDropdownChange = (event) => {
    if (event) {
      console.log("event.value: ", event.value, "-", event.label)
      set_id_Grupo_Academico_Selected(event.value)
    }
  }

  const Offsymbol = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2
        }}
      >
        {" "}
        No
      </div>
    );
  };

  const OnSymbol = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2
        }}
      >
        {" "}
        Sí
      </div>
    );
  };

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: (contact && contact.name) || '',
      designation: (contact && contact.designation) || '',
      tags: (contact && contact.tags) || '',
      email: (contact && contact.email) || '',
      projects: (contact && contact.projects) || '',
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please Enter Your Name"),
      designation: Yup.string().required("Please Enter Your Designation"),
      tags: Yup.array().required("Please Enter Tag"),
      email: Yup.string().required("Please Enter Your Email"),
      projects: Yup.number().required("Please Enter Your Project"),

    }),
    onSubmit: (values) => {
      if (isEdit) {
        const updateUser = {
          id: contact.id,
          name: values.name,
          designation: values.designation,
          tags: values.tags,
          email: values.email,
          projects: values.projects,
        };

        // update user
        dispatch(onUpdateUser(updateUser));
        validation.resetForm();
        //setIsEdit(false);
      } else {
        const newUser = {
          id: Math.floor(Math.random() * (30 - 20)) + 20,
          name: values["name"],
          designation: values["designation"],
          email: values["email"],
          tags: values["tags"],
          projects: values["projects"],
        };
        // save new user
        dispatch(onAddNewUser(newUser));
      }
      toggle();
    },
  });

  const { users } = useSelector(state => ({
    users: state.alumnos.alumnos,
  }));

  const { SearchBar } = Search;
  const sizePerPage = 10;
  const pageOptions = {
    sizePerPage: sizePerPage,
    totalSize: users.length, // replace later with size(users),
    custom: true,
  };
  const defaultSorted = [
    {
      dataField: "id", // if dataField is not match to any column you defined, it will be ignored.
      order: "desc", // desc or asc
    },
  ];

  const selectRow = {
    mode: "checkbox",
  };

  const contactListColumns = [
    {
      text: "id",
      key: "id_Alumno",
      sort: true,
      hidden: true,
      // eslint-disable-next-line react/display-name
      formatter: user => <>{user.id_Alumno}</>,
    },
    {
      dataField: "img",
      text: "#",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, user) => (
        <>
          {user.url_Fotografia.length == 0 ? (
            <div className="avatar-xs">
              <span className="avatar-title rounded-circle">
                {user.nombres.charAt(0)}
              </span>
            </div>
          ) : (
            <div>
              <img
                className="rounded-circle avatar-xs"
                src={user.url_Fotografia}
                alt=""
              />
            </div>
          )}
        </>
      ),
    },
    {
      text: "Nombre",
      dataField: "nombre",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, user) => (
        <>
          <h5 className="font-size-14 mb-1">
            <Link to="#" className="text-dark">
              {user.nombre}
            </Link>
          </h5>
          {/* <p className="text-muted mb-0">{user.designation}</p> */}
        </>
      ),
    },
    {
      dataField: "email",
      isDummyField: true,
      editable: false,
      text: "Familia",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, user) => (
        <div className="d-flex gap-3">
          {user.oFamilia.id_Contacto_Mama > 0 ? (
            <Link className="text-success" to="#">
              <i
                className="mdi mdi-mother-heart font-size-18"
                id="edittooltip"
              // onClick={() => handleUserClick(user)}
              ></i>
            </Link>
          ) : null}
          {user.oFamilia.id_Contacto_Papa > 0 ? (
            <Link className="text-danger" to="#">
              <i
                className="mdi mdi-face font-size-18"
                id="deletetooltip"
              // onClick={() => onClickDelete(user)}
              ></i>
            </Link>
          ) : null}

        </div>
      ),
    },
    {
      text: "Grupos",
      dataField: "grupos",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, user) => (
        <>
          {map(
            user.grupos,
            (oGrupoAcademico, index) =>
              index < 2 && (
                <Link
                  to="#"
                  className="badge badge-soft-primary font-size-11 m-1"
                  key={"_skill_" + user.id + index}
                >
                  {oGrupoAcademico.nombre_Grupo_Academico}
                </Link>
              )
          )}
          {size(user.grupos) > 2 && (
            <Link
              to="#"
              className="badge badge-soft-primary font-size-11 m-1"
              key={"_skill_" + user.id}
            >
              {size(user.grupos) - 1} + more
            </Link>
          )}
        </>
      ),
    },
    // {
    //   dataField: "projects",
    //   text: "Projects",
    //   sort: true,
    // },
    {
      dataField: "menu",
      isDummyField: true,
      editable: false,
      text: "Action",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, user) => (
        <div className="d-flex gap-3">
          <Link className="text-success" to="#">
            <i
              className="mdi mdi-pencil font-size-18"
              id="edittooltip"
              onClick={() => handleUserClick(user)}
            ></i>
          </Link>
          <Link className="text-danger" to="#">
            <i
              className="mdi mdi-delete font-size-18"
              id="deletetooltip"
              onClick={() => onClickDelete(user)}
            ></i>
          </Link>
        </div>
      ),
    },
  ];


  // Grupos Academicos
  useEffect(() => {
    const oSolicitudBase_GruposAcademicos = getoSolicitudBase(
      "Libreta::index::useEffect",
      "getgruposacademicos",
      [],
      []
    )
    if (gruposAcademicos && !gruposAcademicos.length) {
      dispatch(onGruposAcademicos(oSolicitudBase_GruposAcademicos))
    }
  }, [dispatch, gruposAcademicos])

  useEffect(() => {
    if (users && !users.length) {

      const oSolicitudBase_Alumnos = getoSolicitudBase(
        "Academia::alumnos-list::useEffect",
        "getalumnos",
        [],
        []
      );
      dispatch(onGetUsers(oSolicitudBase_Alumnos));
      //setIsEdit(false);
    }
  }, [dispatch, users]);

  useEffect(() => {
    setContact(users);
    //setIsEdit(false);
  }, [users]);

  useEffect(() => {
    if (!isEmpty(users)) { //&& !!isEdit
      setContact(users);
      //setIsEdit(false);
    }
  }, [users]);

  const submitForm = () => {

    const formData = new FormData();
    formData.append("name", name);
    formData.append("file", selectedFile);

    console.log('formData: ', formData)

  };

  const toggle = () => {
    setModal(!modal);
    if (modal === true) {
      setIsNew(false)
    };
  };

  const handleUserClick = oUser => {

    if (oUser.nombres.length > 0) {

      oUser.hermano = false
      // oUser.grupoAcademico = { value: oUser.grupo[0] ? oUser.grupo[0].id_Grupo_Academico : -1, 
      //   label: oUser.grupo[0] ? oUser.grupo[0].nombre_Grupo_Academico : 'Selecciona ...' }
      

      console.log('oUser', oUser)
      const user = oUser;
      setContact(oUser);
      setIsNew(false);
      toggle();
    } else {
      setContact(init_oAlumno);
      setIsNew(true);
      toggle();
    }
  };

  var node = useRef();
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };

  //delete customer
  const [deleteModal, setDeleteModal] = useState(false);

  const onClickDelete = (users) => {
    setContact(users);
    setDeleteModal(true);
  };

  const handleDeleteUser = () => {

    const oSolicitudBase = getoSolicitudBase(
      "Academia::alumnos-List",
      "handleDeleteUser",
      [],
      [{ nombre: "id_Alumno", valor: contact.id_Alumno }]
    )
    dispatch(onDeleteUser(oSolicitudBase));
    onPaginationPageChange(1);
    setDeleteModal(false);
  };

  const handleUserClicks = () => {
    setUserList("");
    //setIsEdit(false);
    setIsNew(true);
    toggle();
  };

  const keyField = "id_Alumno";

  console.log('isNew:', isNew)

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteUser}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <MetaTags>
          <title>User List | Comunica - CampusVirtual</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Academia"
            breadcrumbItems={[
              { title: "Alumnos", link: "/academia" },
              { title: "Profesores", link: "/academia/profesores" },
              { title: "Grupos Académicos", link: "/academia/" },
              { title: "Configuración", link: "/academia/configuracion" },
            ]}
            activo="Alumnos" />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    keyField={keyField}
                    columns={contactListColumns}
                    data={users}
                  >
                    {({ paginationProps, paginationTableProps }) => {
                      return (
                        <ToolkitProvider
                          keyField={keyField}
                          data={users}
                          columns={contactListColumns}
                          bootstrap4
                          search
                        >
                          {toolkitProps => (
                            <React.Fragment>
                              <Row className="mb-2">
                                <Col sm="4">
                                  <div className="search-box ms-2 mb-2 d-inline-block">
                                    <div className="position-relative">
                                      <SearchBar {...toolkitProps.searchProps} />
                                      <i className="bx bx-search-alt search-icon" />
                                    </div>
                                  </div>
                                </Col>
                                <Col sm="8">
                                  <div className="text-sm-end">
                                    <Button
                                      color="primary"
                                      className="font-16 btn-block btn btn-primary"
                                      onClick={() => {
                                        setIsNew(true)
                                        handleUserClick(init_oAlumno)
                                      }}
                                    >
                                      <i className="mdi mdi-plus-circle-outline me-1" />
                                      Alta Nuevo Alumno
                                    </Button>
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col xl="12">
                                  <div className="table-responsive">
                                    <BootstrapTable
                                      keyField={keyField}
                                      {...toolkitProps.baseProps}
                                      {...paginationTableProps}
                                      selectRow={selectRow}
                                      defaultSorted={defaultSorted}
                                      classes={
                                        "table align-middle table-nowrap table-hover"
                                      }
                                      bordered={false}
                                      striped={false}
                                      responsive
                                      ref={node}
                                    />

                                    <Modal_Alumno
                                      isOpen={modal}
                                      fnToggle={toggle}
                                      oAlumno={isNew ? init_oAlumno : contact}
                                      //isEdit={isEdit}
                                      isNew={isNew} />


                                  </div>
                                </Col>
                              </Row>
                              <Row className="align-items-md-center mt-30">
                                <Col className="pagination pagination-rounded justify-content-end mb-2">
                                  <PaginationListStandalone
                                    {...paginationProps}
                                  />
                                </Col>
                              </Row>
                            </React.Fragment>
                          )}
                        </ToolkitProvider>
                      );
                    }}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(AcademiaAlumnos);