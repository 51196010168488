import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

// Login Redux States
import {
  LIBRETA_EVENT,

  LIBRETA_GET_GRUPOS_ACADEMICOS,

  LIBRETA_GET_ALUMNOS_LIBRETA,
  LIBRETA_EVENTOS_GET_CATALOGOS,

  /* RegistraEvento */
  LIBRETA_EVENT_REGISTRA,
  LIBRETA_EVENT_UPDATE_EVENT,
  LIBRETA_EVENT_DELETE_EVENT,

  LIBRETA_GET_REGISTRO_EVENTOS_LIBRETA,

  LIBRETA_EVENT_OPENMODAL,
  LIBRETA_EVENT_CLOSEMODAL,
  LIBRETA_EVENT_SELECTACADEMICGROUP,
  LIBRETA_EVENT_SELECTUSER,
  LIBRETA_EVENT_COMIDA,
  LIBRETA_EVENT_COMIDA_FECHAHORA,
  LIBRETA_EVENT_COMIDA_HUMOR,
  LIBRETA_EVENT_COMIDA_CANTIDAD,
  LIBRETA_EVENT_COMIDA_OBSERVACIONES,
  LIBRETA_EVENT_COMIDA_CANCEL,
  LIBRETA_EVENT_COMIDA_REGISTRA,

  LIBRETA_ENVIO,
  LIBRETA_CONFIG_ADD_OBSERVACION,
  LIBRETA_CONFIG_UPDATE_OBSERVACION,
  LIBRETA_CONFIG_DELETE_OBSERVACION,

} from "./actionTypes"

import {

  actionSetDisplayNotification,
  actionUnSetDisplayNotification,

  actionDisplayReporteEnvio,


  eventAction,
  getGruposAcademicosLibretaSuccess,
  getGruposAcademicosLibretaFail,
  getAlumnosLibretaSuccess,
  getAlumnosLibretaFail,
  getLibretaCatalogosEventosSuccess,
  getLibretaCatalogosEventosFail,
  eventRegisterEventComidaActionSuccess,
  eventRegisterEventDiapersActionSuccess,
  eventRegisterEventSiestaActionSuccess,
  eventRegisterEventBathActionSuccess,
  eventRegisterEventAcademicActionSuccess,

  eventRegisterEventActionFail,

  actionUpdateEventSuccess,
  actionUpdateEventFail,

  actionDeleteEventSuccess,
  actionDeleteEventFail,

  actionGetRegistroEventosSuccess,
  actionGetRegistroEventosFail,

  actionEnvioSuccess,
  actionEnvioFail,

  insertObservacionSuccess,
  insertObservacionFail,

  updateObservacionSuccess,
  updateObservacionFail,

  deleteObservacionSuccess,
  deleteObservacionFail,


} from "./actions"

//Include Both Helper File with needed methods
import {
  getApiGruposAcademicosLibreta,
  getApiAlumnosLibreta,
  getApiLibretaCatalogos,
  postApiLibretaRegistraEventoComida,
  postApiLibretaRegistraEventoDiapers,
  postApiLibretaRegistraEventoSiesta,
  postApiLibretaRegistraEventoBath,
  postApiLibretaRegistraEventoAcademia,
  putApiLibretaUpdateEventoComida,
  putApiLibretaUpdateEventoDiapers,
  putApiLibretaUpdateEventoSiesta,
  putApiLibretaUpdateEventoBath,
  putApiLibretaUpdateEventoAcademia,
  getApiLibretaRegistroEventos,

  delApiLibretaDeleteEventoComida,
  delApiLibretaDeleteEventoDiapers,
  delApiLibretaDeleteEventoSiesta,
  delApiLibretaDeleteEventoBath,
  delApiLibretaDeleteEventoAcademia,

  postApiLibretaRegistraNotificaciones,

  postApiLibretaAddObservacion,
  putApiLibretaAddObservacion,
  delApiLibretaAddObservacion,



} from "../../helpers/comunicabackend_helper"

function* fireEventAction() {
  yield put(eventAction())
}

function* fetchGruposAcademicosLibreta(action) {
  try {
    // console.log('fetchGruposAcademicosLibreta')
    // console.log('oSolicitudBase: ', action.payload)
    const response = yield call(getApiGruposAcademicosLibreta, action.payload)
    //console.log('response: ', response)
    yield put(getGruposAcademicosLibretaSuccess(response.datos))
  } catch (error) {
    yield put(getGruposAcademicosLibretaFail('Hubo un error al traer de la base de datos a los alumnos de la libreta.' + error))
  }
}

function* fetchGetAlumnosLibreta(action) {
  try {
    if (action !== null) {
      // console.log("------------------------------------------------")
      // console.log("----- fetchAlumnosLibreta; ", action)
      const response = yield call(getApiAlumnosLibreta, action.payload)
      // console.log("response.datos: ", JSON.stringify(response))
      yield put(getAlumnosLibretaSuccess(response.datos))
      // console.log("------------------------------------------------")
    }
  } catch (error) {
    yield put(getAlumnosLibretaFail('Hubo un error al traer de la base de datos a los alumnos de la libreta.' + JSON.stringify(error)))
  }
}

function* fetchGetLibretaCatalogos(action) {
  try {
    const response = yield call(getApiLibretaCatalogos, action.payload)
    if (response.status !== 'success') {
      yield put(getLibretaCatalogosEventosFail(response.mensaje_Detalle))
      yield put(actionSetDisplayNotification({ tipo: "error", titulo: "Error al conseguir los catalogos.", mensaje: "Imposible conseguir los catalogos." }))
    } else {
      yield put(getLibretaCatalogosEventosSuccess(response.datos))
    }
  } catch (error) {
    yield put(getLibretaCatalogosEventosFail('Hubo un error al traer de la base de datos el catálogo.' + error.message))
    yield put(actionSetDisplayNotification({ tipo: "error", titulo: "Error al conseguir los catalogos.", mensaje: "Imposible conseguir los catalogos." }))
  }
}

function* fetchLibretaRegistraEvento(action) {
  try {
    let response = null
    switch (action.payload.Parametros[0].valor.toString()) {

      case "comida":
        response = yield call(postApiLibretaRegistraEventoComida, action.payload)
        if (response.status === 'success') {
          yield put(eventRegisterEventComidaActionSuccess(response.datos))
          yield put(actionSetDisplayNotification({ tipo: "success", titulo: "Registro correcto", mensaje: "Registro almacenado correctamente." }))
        } else {
          yield put(eventRegisterEventActionFail(response.mensaje_Detalle))
          yield put(actionSetDisplayNotification({ tipo: "error", titulo: "Error al registrar el evento.", mensaje: "No se pudo registar el evento, intenta más tarde." }))
        }
        break;
      case "pañal":
        response = yield call(postApiLibretaRegistraEventoDiapers, action.payload)
        if (response.status === 'success') {
          yield put(eventRegisterEventDiapersActionSuccess(response.datos))
          yield put(actionSetDisplayNotification({ tipo: "success", titulo: "Registro correcto", mensaje: "Registro almacenado correctamente." }))
        } else {
          yield put(eventRegisterEventActionFail(response.mensaje_Detalle))
          yield put(actionSetDisplayNotification({ tipo: "error", titulo: "Error al registrar el evento.", mensaje: "No se pudo registar el evento, intenta más tarde." }))
        }
        break;
      case "siesta":
        response = yield call(postApiLibretaRegistraEventoSiesta, action.payload)
        if (response.status === 'success') {
          yield put(eventRegisterEventSiestaActionSuccess(response.datos))
          yield put(actionSetDisplayNotification({ tipo: "success", titulo: "Registro correcto", mensaje: "Registro almacenado correctamente." }))
        } else {
          yield put(eventRegisterEventActionFail(response.mensaje_Detalle))
          yield put(actionSetDisplayNotification({ tipo: "error", titulo: "Error al registrar el evento.", mensaje: "No se pudo registar el evento, intenta más tarde." }))
        }
        break;
      case "baño":
        response = yield call(postApiLibretaRegistraEventoBath, action.payload)
        if (response.status === 'success') {
          yield put(eventRegisterEventBathActionSuccess(response.datos))
          yield put(actionSetDisplayNotification({ tipo: "success", titulo: "Registro correcto", mensaje: "Registro almacenado correctamente." }))
        } else {
          yield put(eventRegisterEventActionFail(response.mensaje_Detalle))
          yield put(actionSetDisplayNotification({ tipo: "error", titulo: "Error al registrar el evento.", mensaje: "No se pudo registar el evento, intenta más tarde." }))
        }
        break;
      case "academia":
        response = yield call(postApiLibretaRegistraEventoAcademia, action.payload)
        if (response.status === 'success') {
          yield put(eventRegisterEventAcademicActionSuccess(response.datos))
          yield put(actionSetDisplayNotification({ tipo: "success", titulo: "Registro correcto", mensaje: "Registro almacenado correctamente." }))
        } else {
          yield put(eventRegisterEventActionFail(response.mensaje_Detalle))
          yield put(actionSetDisplayNotification({ tipo: "error", titulo: "Error al registrar el evento.", mensaje: "No se pudo registar el evento, intenta más tarde." }))
        }
    }
  } catch (error) {
    yield put(eventRegisterEventActionFail(error))
    yield put(actionSetDisplayNotification({ tipo: "error", titulo: "Error al registrar el evento.", mensaje: "No se pudo registar el evento, intenta más tarde." }))
  }
}

function* fetchLibretaUpdateEvento({ payload: { registro, tipoEvento } }) {
  try {
    let response = null
    switch (tipoEvento) {
      case "comida":
        response = yield call(putApiLibretaUpdateEventoComida, registro)
        if (response.status === 'success') {
          yield put(actionUpdateEventSuccess(response.datos))
          yield put(actionSetDisplayNotification({ tipo: "success", titulo: "Registro actualizado", mensaje: "Registro actualizado correctamente." }))
        } else {
          yield put(actionUpdateEventFail(response.mensaje_Detalle))
          yield put(actionSetDisplayNotification({ tipo: "error", titulo: "Error al actualizar el evento.", mensaje: "No se pudo actualizar el evento, intenta más tarde." }))
        }
        break;
      case "pañal":
        response = yield call(putApiLibretaUpdateEventoDiapers, registro)
        if (response.status === 'success') {
          yield put(actionUpdateEventSuccess(response.datos))
          yield put(actionSetDisplayNotification({ tipo: "success", titulo: "Registro actualizado", mensaje: "Registro actualizado correctamente." }))
        } else {
          yield put(actionUpdateEventFail(response.mensaje_Detalle))
          yield put(actionSetDisplayNotification({ tipo: "error", titulo: "Error al actualizar el evento.", mensaje: "No se pudo actualizar el evento, intenta más tarde." }))
        }

        break;
      case "siesta":
        response = yield call(putApiLibretaUpdateEventoSiesta, registro)
        if (response.status === 'success') {
          yield put(actionUpdateEventSuccess(response.datos))
          yield put(actionSetDisplayNotification({ tipo: "success", titulo: "Registro actualizado", mensaje: "Registro actualizado correctamente." }))
        } else {
          yield put(actionUpdateEventFail(response.mensaje_Detalle))
          yield put(actionSetDisplayNotification({ tipo: "error", titulo: "Error al actualizar el evento.", mensaje: "No se pudo actualizar el evento, intenta más tarde." }))
        }

        break;
      case "bath":
        response = yield call(putApiLibretaUpdateEventoBath, registro)
        if (response.status === 'success') {
          yield put(actionUpdateEventSuccess(response.datos))
          yield put(actionSetDisplayNotification({ tipo: "success", titulo: "Registro actualizado", mensaje: "Registro actualizado correctamente." }))
        } else {
          yield put(actionUpdateEventFail(response.mensaje_Detalle))
          yield put(actionSetDisplayNotification({ tipo: "error", titulo: "Error al actualizar el evento.", mensaje: "No se pudo actualizar el evento, intenta más tarde." }))
        }
        break;
      case "academia":
        response = yield call(putApiLibretaUpdateEventoAcademia, registro)
        if (response.status === 'success') {
          yield put(actionUpdateEventSuccess(response.datos))
          yield put(actionSetDisplayNotification({ tipo: "success", titulo: "Registro actualizado", mensaje: "Registro actualizado correctamente." }))
        } else {
          yield put(actionUpdateEventFail(response.mensaje_Detalle))
          yield put(actionSetDisplayNotification({ tipo: "error", titulo: "Error al actualizar el evento.", mensaje: "No se pudo actualizar el evento, intenta más tarde." }))
        }
    }

  } catch (error) {
    yield put(actionUpdateEventFail(error))
    yield put(actionSetDisplayNotification({ tipo: "error", titulo: "Error al actualizar el evento.", mensaje: "No se pudo actualizar el evento, intenta más tarde." }))
  }
}

function* fetchLibretaDeleteEvento({ payload: { registro, tipoEvento } }) {
  try {
    let response = null
    switch (tipoEvento) {
      case "comida":
        response = yield call(delApiLibretaDeleteEventoComida, registro)
        if (response.status !== 'success') {
          yield put(actionDeleteEventFail(response.mensaje_Detalle))
          yield put(actionSetDisplayNotification({ tipo: "error", titulo: "Error al borrar el evento.", mensaje: "No se pudo borrar el evento, intenta más tarde." }))
        } else {
          yield put(actionDeleteEventSuccess(response.datos))
          yield put(actionSetDisplayNotification({ tipo: "success", titulo: "Registro eliminado", mensaje: "Registro eliminado correctamente." }))
        }
        break;
      case "pañal":
        response = yield call(delApiLibretaDeleteEventoDiapers, registro)
        if (response.status !== 'success') {
          yield put(actionDeleteEventFail(response.mensaje_Detalle))
          yield put(actionSetDisplayNotification({ tipo: "error", titulo: "Error al borrar el evento.", mensaje: "No se pudo borrar el evento, intenta más tarde." }))
        } else {
          yield put(actionDeleteEventSuccess(response.datos))
          yield put(actionSetDisplayNotification({ tipo: "success", titulo: "Registro eliminado", mensaje: "Registro eliminado correctamente." }))
        }
        break;
      case "siesta":
        response = yield call(delApiLibretaDeleteEventoSiesta, registro)
        if (response.status !== 'success') {
          yield put(actionDeleteEventFail(response.mensaje_Detalle))
          yield put(actionSetDisplayNotification({ tipo: "error", titulo: "Error al borrar el evento.", mensaje: "No se pudo borrar el evento, intenta más tarde." }))
        } else {
          yield put(actionDeleteEventSuccess(response.datos))
          yield put(actionSetDisplayNotification({ tipo: "success", titulo: "Registro eliminado", mensaje: "Registro eliminado correctamente." }))

        }
        break;
      case "baño":
        response = yield call(delApiLibretaDeleteEventoBath, registro)
        if (response.status !== 'success') {
          yield put(actionDeleteEventFail(response.mensaje_Detalle))
          yield put(actionSetDisplayNotification({ tipo: "error", titulo: "Error al borrar el evento.", mensaje: "No se pudo borrar el evento, intenta más tarde." }))
        } else {
          yield put(actionDeleteEventSuccess(response.datos))
          yield put(actionSetDisplayNotification({ tipo: "success", titulo: "Registro eliminado", mensaje: "Registro eliminado correctamente." }))

        }
        break;
      case "academia":
        response = yield call(delApiLibretaDeleteEventoAcademia, registro)
        if (response.status !== 'success') {
          yield put(actionDeleteEventFail(response.mensaje_Detalle))
          yield put(actionSetDisplayNotification({ tipo: "error", titulo: "Error al borrar el evento.", mensaje: "No se pudo borrar el evento, intenta más tarde." }))
        } else {
          yield put(actionDeleteEventSuccess(response.datos))
          yield put(actionSetDisplayNotification({ tipo: "success", titulo: "Registro eliminado", mensaje: "Registro eliminado correctamente." }))

        }
    }

  } catch (error) {
    yield put(actionDeleteEventFail(error))
    yield put(actionSetDisplayNotification({ tipo: "error", titulo: "Error al borrar el evento.", mensaje: "No se pudo borrar el evento, intenta más tarde." }))
  }
}

function* fetchRegistroEventos(action) {

  try {
    console.log('fetchRegistroEventos')
    console.log('oSolicitudBase: ', action.payload)
    const response = yield call(getApiLibretaRegistroEventos, action.payload)
    console.log('response: ', response)
    if (response.status !== 'success') {
      yield put(actionGetRegistroEventosFail(response.mensaje_Detalle,
        { tipo: "error", titulo: "Error al conseguir los eventos.", mensaje: "Imposible conseguir los registros." }))
    } else {
      yield put(actionGetRegistroEventosSuccess(response.datos))
    }
  } catch (error) {
    yield put(actionGetRegistroEventosFail(error,
      { tipo: "error", titulo: "Error al conseguir los eventos.", mensaje: "Imposible conseguir los registros." }))
  }
}

function* fetchRegistraNotificaciones(action) {
  try {
    const response = yield call(postApiLibretaRegistraNotificaciones, action.payload)
    if (response.status === 'success') {
      yield put(actionEnvioSuccess(response.datos))
      yield put(actionSetDisplayNotification({ tipo: "success", titulo: "Mensajes enviados", mensaje: "Re registraron los eventos para su distribución" }))
      yield put(actionDisplayReporteEnvio(true))
    } else {
      yield put(actionEnvioFail(response.mensaje_Detalle))
      yield put(actionSetDisplayNotification({ tipo: "error", titulo: "Error al enviar mensajes.", mensaje: "No se pudiron registrar los eventos para su distribución." }))
      yield put(actionDisplayReporteEnvio(true))
    }

  } catch (error) {
    yield put(actionUpdateEventFail(error))
    yield put(actionSetDisplayNotification({ tipo: "error", titulo: "Error al actualizar el evento.", mensaje: "No se pudo actualizar el evento, intenta más tarde." }))
    yield put(actionDisplayReporteEnvio(true))
  }
}

function* InsertaObservacion(action) {

  try {
    console.log('InsertaObservacion')
    console.log('oSolicitudBase: ', action.payload)
    const response = yield call(postApiLibretaAddObservacion, action.payload)
    console.log('response: ', response)
    if (response.status !== 'success') {
      yield put(insertObservacionFail(response.mensaje_Detalle))
      yield put(actionSetDisplayNotification({ tipo: "error", titulo: "Error al insertar la observacion.", mensaje: "No se pudo insertar la observacion, intenta más tarde." }))
    } else {
      yield put(insertObservacionSuccess(response.datos))
      yield put(actionSetDisplayNotification({ tipo: "success", titulo: "Observación Agregada", mensaje: "Observación agregada correctamente." }))
    }
  } catch (error) {
    yield put(insertObservacionFail(error))
    yield put(actionSetDisplayNotification({ tipo: "error", titulo: "Error al insertar la observacion.", mensaje: "No se pudo insertar la observacion, intenta más tarde." }))
  }
}

function* ActualizaObservacion(action) {

  try {
    //console.log('ActualizaObservacion')
    //console.log('oSolicitudBase: ', action.payload)
    const response = yield call(putApiLibretaAddObservacion, action.payload)
    //console.log('response: ', response)
    if (response.status !== 'success') {
      yield put(updateObservacionFail(response.mensaje_Detalle))
      yield put(actionSetDisplayNotification({ tipo: "error", titulo: "Error al actualizar la observacion.", mensaje: "No se pudo actualizar la observacion, intenta más tarde." }))
    } else {
      yield put(updateObservacionSuccess(response.datos))
      yield put(actionSetDisplayNotification({ tipo: "success", titulo: "Observación actualizada", mensaje: "Observación actualizada correctamente." }))
    }
  } catch (error) {
    yield put(updateObservacionFail(error))
    yield put(actionSetDisplayNotification({ tipo: "error", titulo: "Error al actualizar la observacion.", mensaje: "No se pudo actualizar la observacion, intenta más tarde." }))
  }
}

function* BorraObservacion(action) {

  try {
    console.log('BorraObservacion')
    console.log('oSolicitudBase: ', action.payload)
    const response = yield call(delApiLibretaAddObservacion, action.payload)
    console.log('response: ', response)
    if (response.status !== 'success') {
      yield put(deleteObservacionFail(response.mensaje_Detalle))
      yield put(actionSetDisplayNotification({ tipo: "error", titulo: "Error al borrar la observacion.", mensaje: "No se pudo borrar la observacion, intenta más tarde." }))
    } else {
      yield put(deleteObservacionSuccess(response.datos))
      yield put(actionSetDisplayNotification({ tipo: "success", titulo: "Observación borrada", mensaje: "Observación borrada correctamente." }))
    }
  } catch (error) {
    yield put(deleteObservacionFail(error))
    yield put(actionSetDisplayNotification({ tipo: "error", titulo: "Error al borrar la observacion.", mensaje: "No se pudo borrar la observacion, intenta más tarde." }))
  }
}



function* LibretaSaga() {
  yield takeEvery(LIBRETA_EVENT, fireEventAction)
  yield takeEvery(LIBRETA_GET_GRUPOS_ACADEMICOS, fetchGruposAcademicosLibreta)
  yield takeEvery(LIBRETA_GET_ALUMNOS_LIBRETA, fetchGetAlumnosLibreta)
  yield takeEvery(LIBRETA_EVENTOS_GET_CATALOGOS, fetchGetLibretaCatalogos)
  yield takeEvery(LIBRETA_EVENT_REGISTRA, fetchLibretaRegistraEvento)
  yield takeEvery(LIBRETA_EVENT_UPDATE_EVENT, fetchLibretaUpdateEvento)
  yield takeEvery(LIBRETA_EVENT_DELETE_EVENT, fetchLibretaDeleteEvento)
  yield takeEvery(LIBRETA_GET_REGISTRO_EVENTOS_LIBRETA, fetchRegistroEventos)
  yield takeEvery(LIBRETA_ENVIO, fetchRegistraNotificaciones)


  yield takeEvery(LIBRETA_CONFIG_ADD_OBSERVACION, InsertaObservacion)
  yield takeEvery(LIBRETA_CONFIG_UPDATE_OBSERVACION, ActualizaObservacion)
  yield takeEvery(LIBRETA_CONFIG_DELETE_OBSERVACION, BorraObservacion)

}

export default LibretaSaga