import { call, put, takeEvery } from "redux-saga/effects"

// Importamos tipos de acciones que van a hacer consultas al API
import {
  CONTACTOS_GET_CONTACTO,
  CONTACTOS_GET_CONTACTOS,
  CONTACTOS_ADD_CONTACTO,
  CONTACTOS_UPDATE_CONTACTO,
  CONTACTOS_DELETE_CONTACTO,
} from "./actionTypes"

// Importamos las acciones que levantaremos derivado del resultado de la llamada al api
import {
  getContactoSuccess,
  getContactoFail,
  getContactosSuccess,
  getContactosFail,
  addContactoSuccess,
  addContactoFail,
  updateContactoSuccess,
  updateContactoFail,
  deleteContactoSuccess,
  deleteContactoFail,
} from "./actions"

// Importamos las funciones que hacen la llamada al API
import {
  getApiContacto,
  getApiContactos,
  getApiAddContacto,
  getApiUpdateContacto,
  getApiDeleteContacto,
} from "../../helpers/comunicabackend_helper"

// **********************************************************
// Funciones que se disparan al recibir una acción

// Tipo de Accion Recibida: CONTACTOS_GET_CONTACTO
export function* watchOnGetContacto(action) {
  try {
    const response = yield call(getApiContacto, action.payload)
    response.status == "success" ? 
    yield put(getContactoSuccess(response.datos))
    : yield put(getContactoFail(response.Mensaje_Detalle))
  } catch (error) {
    yield put(getContactoFail(error))
  }
}

// Tipo de Accion Recibida: CONTACTOS_GET_CONTACTOS
export function* watchOnGetContactos(action) {
  try {
    const response = yield call(getApiContactos, action.payload)
    response.status == "success" ? 
    yield put(getContactosSuccess(response.datos))
    : yield put(getContactosFail(response.Mensaje_Detalle))
  } catch (error) {
    yield put(getContactosFail(error))
  }
}

// Tipo de Accion Recibida: ADD_CONTACTO
export function* watchOnAddContacto(action) {
  try {
    const response = yield call(getApiAddContacto, action.payload)
    response.status == "success" ? 
    yield put(addContactoSuccess(response.datos))
    : yield put(addContactoFail(response.Mensaje_Detalle))
  } catch (error) {
    yield put(addContactoFail(error))
  }
}

// Tipo de Accion Recibida: UPDATE_CONTACTO
export function* watchOnUpdateContacto(action) {
  try {
    const response = yield call(getApiUpdateContacto, action.payload)
    response.status == "success" ? 
    yield put(updateContactoSuccess(response.datos))
    : yield put(updateContactoFail(response.Mensaje_Detalle))
  } catch (error) {
    yield put(updateContactoFail(error))
  }
}

// Tipo de Accion Recibida: DELETE_CONTACTO
export function* watchOnDeleteContacto(action) {
  try {
    const response = yield call(getApiDeleteContacto, action.payload)
    response.status == "success" ? 
    yield put(deleteContactoSuccess(response.datos))
    : yield put(deleteContactoFail(response.Mensaje_Detalle))
  } catch (error) {
    yield put(deleteContactoFail(error))
  }
}


function* familiasSaga() {
  yield takeEvery(CONTACTOS_GET_CONTACTO, watchOnGetContacto)
  yield takeEvery(CONTACTOS_GET_CONTACTOS, watchOnGetContactos)
  yield takeEvery(CONTACTOS_ADD_CONTACTO, watchOnAddContacto)
  yield takeEvery(CONTACTOS_UPDATE_CONTACTO, watchOnUpdateContacto)
  yield takeEvery(CONTACTOS_DELETE_CONTACTO, watchOnDeleteContacto)
}

export default familiasSaga 
