module.exports = {
  google: {
    API_KEY: "AIzaSyDPHFO3iUf_6_TuFPAOKnPanO6yu_ZuFNY",
    CLIENT_ID:
      "193797901118-qck5q9qt3vbkuuaar841nopg5mc45lmd.apps.googleusercontent.com",
    SECRET: "44Mgw3R4lENkbvyNgjMwSB6b",
  },
  facebook: {
    APP_ID: "244418345967073",
    APP_SECRET: "5d15f0130d07226719a762d43a6d3327",
  },
}
