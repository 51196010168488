import { call, put, takeEvery } from "redux-saga/effects"

import { getSeccionesPaginas } from "../../helpers/comunicabackend_helper"

import {
  get_secciones_paginas_Success,
  get_secciones_paginas_Fail,
} from "./actions"

import { GET_SECCIONES_PAGINAS } from "./actionTypes"

function* fetchPaginas({ jsonparameters }) {
  try {
    const response = yield call(getSeccionesPaginas, jsonparameters)
    yield put(get_secciones_paginas_Success(response.datos))
  } catch (error) {
    yield put(get_secciones_paginas_Fail(error))
  }
}

function* seccionesSaga() {
  yield takeEvery(GET_SECCIONES_PAGINAS, fetchPaginas)
}

export default seccionesSaga
