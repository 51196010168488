


//apply base url for axios
export const API_URL = "https://comunicaapi-beta.azurewebsites.net"
// export const API_URL = "http://localhost:62856" 

// Esta variable la uso para mostrar los menus de las páginas que están en desarrollo
// False = No se muestra lo que se está desarrollando.
export const DEVELOPING = false
  
//   export default DEVELOPING
//   export  API_URL
