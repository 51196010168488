

const oldPugTemplate = `

div
  div(style='clear:both;width:100%;float:left')
    div(style='clear:both')
      div(style='clear:both;width:100%;float:left')
        div(style='display:flex;clear:both;zoom:1;border:4px solid #75706b;border-radius:4px;background:#fff;padding:8px;opacity:1')
          div(style='clear:both;width:100%;float:left')
            div
              span(style='margin-right:10px;float:right')
                img.CToWUd(title='Logo' alt='Tz' width='55' height='50' style='display:block' src='https://comunika.blob.core.windows.net/data/8/monogram-Tz.png' data-bit='iit')
            div(style='display:flex')
              span(style='font-size:1.2em') #{oAlumno.nombres} #{oAlumno.apellido_Paterno} #{oAlumno.apellido_Materno}
            div(style='display:flex')
              span(style='font-size:1.2em')
                | #{oAlumno.grupos[0].nombre_Grupo_Academico} / #{nombre_Profesor}
            div
            div
            if comida.length
              div
                span(style='display:block;margin:10px 0 5px 0;font-weight:700;font-size:15px;color:#ec2060;text-align:center') Hoy com&iacute;...
                div
                  table(align='center' border='1' style='border-collapse:collapse;border-spacing:0;border:1px solid #fff;width:100%')
                    tbody
                      tr(style='background-color:#ec2060;color:#fff;border-color:#fff')
                        th(style='border:1px solid #fff;padding:4px 8px')
                          div(style='text-align:center')
                            span &Aacute;nimo
                        th(style='border:1px solid #fff;padding:4px 8px')
                          div(style='text-align:center')
                            span Cantidad
                        th(style='border:1px solid #fff;padding:4px 8px')
                          div(style='text-align:center')
                            span Observaciones
                        th(style='border:1px solid #fff;padding:4px 8px')
                          div(style='text-align:center')
                            span Notas
                      each oComida in comida
                        tr(style='text-align:center')
                          td(style='border:1px solid #fff;padding:4px 8px') #{oComida.catalogo_Nombre_Estado_Animo}
                          td(style='border:1px solid #fff;padding:4px 8px') #{oComida.catalogo_Cantidad}
                          td(style='border:1px solid #fff;padding:4px 8px') #{oComida.catalogo_Observacion}
                          td(style='border:1px solid #fff;padding:4px 8px') #{oComida.observacion}
            if siesta.length
              div
              div(style='margin-top:15px')
                span(style='display:block;margin:10px 0 5px 0;font-weight:700;font-size:15px;color:#27b59d;text-align:center') Hoy dorm&iacute;...
                div
                  table(align='center' border='1' style='border-collapse:collapse;border-spacing:0;border:1px solid #fff;width:100%')
                    tbody
                      tr(style='background-color:#27b59d;color:#fff;border-color:#fff')
                        th(style='border:1px solid #fff;padding:4px 8px')
                          div(style='text-align:center')
                            span &Aacute;nimo
                        th(style='border:1px solid #fff;padding:4px 8px')
                          div(style='text-align:center')
                            span Inicio
                        th(style='border:1px solid #fff;padding:4px 8px')
                          div(style='text-align:center')
                            span Duraci&oacute;n
                        th(style='border:1px solid #fff;padding:4px 8px')
                          div(style='text-align:center')
                            span Observaciones
                        th(style='border:1px solid #fff;padding:4px 8px')
                          div(style='text-align:center')
                            span Notas
                      each oSiesta in siesta
                        tr(style='text-align:center')
                          td(style='border:1px solid #fff;padding:4px 8px') #{oSiesta.catalogo_Nombre_Estado_Animo}
                          td(style='border:1px solid #fff;padding:4px 8px') #{new Date(oSiesta.inicio).toLocaleTimeString()}
                          td(style='border:1px solid #fff;padding:4px 8px') #{oSiesta.duracionMinutos}
                          td(style='border:1px solid #fff;padding:4px 8px') #{oSiesta.catalogo_Observacion}
                          td(style='border:1px solid #fff;padding:4px 8px') #{oSiesta.observacion}
            if diapers.length
              div
              div(style='margin-top:15px')
                span(style='display:block;margin:10px 0 5px 0;font-weight:700;font-size:15px;color:#e0aeec;text-align:center') Mis cambios de pa&ntilde;ales...
                div
                  table(align='center' border='1' style='border-collapse:collapse;border-spacing:0;border:1px solid #fff;width:100%')
                    tbody
                      tr(style='background-color:#e0aeec;color:#fff;border-color:#fff')
                        th(style='border:1px solid #fff;padding:4px 8px')
                          div(style='text-align:center')
                            span &Aacute;nimo
                        th(style='border:1px solid #fff;padding:4px 8px')
                          div(style='text-align:center')
                            span Hizo
                        th(style='border:1px solid #fff;padding:4px 8px')
                          div(style='text-align:center')
                            span Observaciones
                        th(style='border:1px solid #fff;padding:4px 8px')
                          div(style='text-align:center')
                            span Notas
                      each oDiapers in diapers
                        tr(style='text-align:center')
                          td(style='border:1px solid #fff;padding:4px 8px') #{oDiapers.catalogo_Nombre_Estado_Animo}
                          td(style='border:1px solid #fff;padding:4px 8px') #{oDiapers.cadena}
                          td(style='border:1px solid #fff;padding:4px 8px') #{oDiapers.catalogo_Observacion}
                          td(style='border:1px solid #fff;padding:4px 8px') #{oDiapers.observacion}
            if bath.length
              div(style='margin-top:15px')
                span(style='display:block;margin:10px 0 5px 0;font-weight:700;font-size:15px;color:#2c82c9;text-align:center') Hoy hice...
                div
                  table(align='center' border='1' style='border-collapse:collapse;border-spacing:0;border:1px solid #fff;width:100%')
                    tbody
                      tr(style='background-color:#2c82c9;color:#fff;border-color:#fff')
                        th(style='border:1px solid #fff;padding:4px 8px')
                          div(style='text-align:center')
                            span &Aacute;nimo
                        th(style='border:1px solid #fff;padding:4px 8px')
                          div(style='display:flex;text-align:center')
                            span Hora
                        th(style='border:1px solid #fff;padding:4px 8px')
                          div(style='display:flex;text-align:center')
                            span Observaciones
                        th(style='border:1px solid #fff;padding:4px 8px')
                          div(style='display:flex;text-align:center')
                            span Notas
                      each oBath in bath
                        tr
                          td(style='border:1px solid #fff;padding:4px 8px') #{oBath.catalogo_Nombre_Estado_Animo}
                          td(style='border:1px solid #fff;padding:4px 8px') #{new Date(oBath.fecha).toLocaleTimeString()}
                          td(style='border:1px solid #fff;padding:4px 8px') #{oBath.catalogo_Observacion}
                          td(style='border:1px solid #fff;padding:4px 8px') #{oBath.observacion}
            div
            if academia.length
              div(style='margin-top:15px')
                span(style='display:block;margin:10px 0 5px 0;font-weight:700;font-size:15px;color:#ccce5e;text-align:center') Academia
                div
                  table(align='center' border='1' style='border-collapse:collapse;border-spacing:0;border:1px solid #fff;width:100%')
                    tbody
                      tr(style='background-color:#ccce5e;color:#fff;border-color:#fff')
                        th(style='border:1px solid #fff;padding:4px 8px')
                          div(style='text-align:center')
                            span &Aacute;nimo
                        th(style='border:1px solid #fff;padding:4px 8px')
                          div(style='display:flex;text-align:center')
                            span Observaciones
                        th(style='border:1px solid #fff;padding:4px 8px')
                          div(style='display:flex;text-align:center')
                            span Notas
                      each oAcademia in academia
                        tr
                          td(style='border:1px solid #fff;padding:4px 8px') #{oAcademia.catalogo_Nombre_Estado_Animo}
                          td(style='border:1px solid #fff;padding:4px 8px') #{oAcademia.catalogo_Observacion}
                          td(style='border:1px solid #fff;padding:4px 8px') #{oAcademia.observacion}
  img.CToWUd(alt='' width='1' height='1' border='0' style='height:1px!important;width:1px!important;border-width:0!important;margin-top:0!important;margin-bottom:0!important;margin-right:0!important;margin-left:0!important;padding-top:0!important;padding-bottom:0!important;padding-right:0!important;padding-left:0!important' src='https://u1683827.ct.sendgrid.net/wf/open?upn=k4Yz5nj5RgVYwQYY6WUV99ecSYBthJGlXiR7QEuevqbVNUzDpXlMMUewUF3uxg7oaVR74nRrYhCnTrf-2F3iwarmgCzov67w-2BL7Ag3VKS-2B9arUGmeWQOmQU6sC3vuaTNVxQoxP8Q06JYYIgKLdYFxSsigUsnBgQNnVzj94pAsQ-2FLC-2Bmwr3qjr4vAG6up9j-2BYQ-2FmrNf6LOOBXbzzE18LJ1yasdR4Ap2r-2Fr0umYrATPlAW3c-2BQW7kKiXC-2BVb2oUQPdGWL9ow9Ds2KrXZPORwJGnJ6g-3D-3D' data-bit='iit')


`


export default oldPugTemplate