// @flow
import { all, call, fork, takeEvery, put } from "redux-saga/effects"

import {
  GET_FILE,

  ADD_FILE,
  ADD_FILE_BASE64,

  UPDATE_FILE,
  UPDATE_FILE_BASE64,

  DELETE_FILE,

  GET_REPORTE_ENVIOS,
  GET_REPORTE_ENVIO_DETALLE,

} from "./actionTypes"

import {
  getFileSuccess as getFileSuccessAction,
  getFileFail as getFileFailAction,

  addFileSuccess as addFileSuccessAction,
  addFileFail as addFileFailAction,

  addFileBase64Success as addFileBase64SuccessAction,
  addFileBase64Fail as addFileBase64FailAction,

  updateFileSuccess as updateFileSuccessAction,
  updateFileFail as updateFileFailAction,

  updateFileBase64Success as updateFileBase64SuccessAction,
  updateFileBase64Fail as updateFileBase64FailAction,

  deleteFileSuccess as deleteFileSuccessAction,
  deleteFileFail as deleteFileFailAction,

  getReporteEnviosSuccess as getReporteEnviosSuccessAction,
  getReporteEnviosFail as getReporteEnviosFailAction,

  getReporteEnvioDetalleSuccess as getReporteEnvioDetalleSuccessAction,
  getReporteEnvioDetalleFail as getReporteEnvioDetalleFailAction,
} from "./actions"

import {
  getApiFile,
  postApiFile,
  postApiFileBase64,
  delApiFile,
  getApiReporteEnvios,
  getApiReporteEnvioDetalle,
} from "../../helpers/comunicabackend_helper"

/**
 * Watchers
 */
export function* watchOnGetFile() {
  try {
    const response = yield call(getApiFile, oSolicitudBase)
    response.status == "success" ? 
    yield put(getFileSuccessAction(response.datos))
    : yield put(getFileFailAction(response.Mensaje_Detalle))
  } catch (error) {
    yield put(getFileFailAction(error))
  }
}

export function* watchOnAddFile() {
  try {
    const response = yield call(postApiFile, oSolicitudBase)
    response.status == "success" ? 
    yield put(addFileSuccessAction(response.datos))
    : yield put(addFileFailAction(response.Mensaje_Detalle))
  } catch (error) {
    yield put(addFileFailAction(error))
  }
}

export function* watchOnAddFileBase64(action) {
  try {
    const response = yield call(postApiFileBase64, action.payload)
    response.status == "success" ? 
    yield put(addFileBase64SuccessAction(response.datos))
    : yield put(addFileBase64FailAction(response.Mensaje_Detalle))
  } catch (error) {
    yield put(addFileBase64FailAction(error))
  }
}

export function* watchOnUpdateFile() {
  // try {
  //   const response = yield call(, event)
  //   yield put((response))
  // } catch (error) {
  //   yield put((error))
  // }
}

export function* watchOnUpdateFileBase64() {
  // try {
  //   const response = yield call(, )
  //   yield put((response))
  // } catch (error) {
  //   yield put((error))
  // }
}

export function* watchOnDeleteFile() {
  try {
    const response = yield call(delApiFile, oSolicitudBase)
    yield put(deleteFileSuccessAction(response.datos))
  } catch (error) {
    yield put(deleteFileFailAction(error))
  }
}

export function* watchOnGetReporteEnvios(action) {
  try {
    const response = yield call(getApiReporteEnvios, action.payload)
    response.status == "success" ? 
    yield put(getReporteEnviosSuccessAction(response.datos))
    : yield put(getReporteEnviosFailAction(response.Mensaje_Detalle))
  } catch (error) {
    yield put(getReporteEnviosFailAction(error))
  }
}

export function* watchOnGetReporteEnvioDetalles(action) {
  try {
    const response = yield call(getApiReporteEnvioDetalle, action.payload)
    response.status == "success" ? 
    yield put(getReporteEnvioDetalleSuccessAction(response.datos))
    : yield put(getReporteEnvioDetalleFailAction(response.Mensaje_Detalle))
  } catch (error) {
    yield put(getReporteEnvioDetalleFailAction(error))
  }
}


function* CommonSaga() {
  yield takeEvery(GET_FILE, watchOnGetFile)
  yield takeEvery(ADD_FILE, watchOnAddFile)
  yield takeEvery(ADD_FILE_BASE64, watchOnAddFileBase64)
  yield takeEvery(UPDATE_FILE, watchOnUpdateFile)
  yield takeEvery(UPDATE_FILE_BASE64, watchOnUpdateFileBase64)
  yield takeEvery(DELETE_FILE, watchOnDeleteFile)
  yield takeEvery(GET_REPORTE_ENVIOS, watchOnGetReporteEnvios)
  yield takeEvery(GET_REPORTE_ENVIO_DETALLE, watchOnGetReporteEnvioDetalles)
}

export default CommonSaga
