import {
  AUTH_LOGIN,
  AUTH_LOGIN_SUCCESS,
  AUTH_LOGIN_ERROR,
  AUTH_LOGOUT,
  AUTH_SOCIAL_LOGIN,
  AUTH_SET_IDCOLEGIO
} from "./actionTypes"

export const authLoginUser = (user, history) => {
  return {
    type: AUTH_LOGIN,
    payload: { user, history },
  }
}

export const authLoginSuccess = user => {
  return {
    type: AUTH_LOGIN_SUCCESS,
    payload: user,
  }
}

export const authLoginFail = error => {
  console.log('actions.js::linea24::error:', error);
  return {
    type: AUTH_LOGIN_ERROR,
    payload: error,
  }
}

export const authLogoutUser = history => {
  return {
    type: AUTH_LOGOUT,
    payload: { history },
  }
}

export const authLogoutUserSuccess = history => {
  return {
    type: AUTH_LOGOUT,
    payload: { history },
  }
}

export const authSocialLogin = (data, history, type) => {
  return {
    type: AUTH_SOCIAL_LOGIN,
    payload: { data, history, type },
  }
}

export const authSetIdColegio = id => {
  return {
    type: AUTH_SET_IDCOLEGIO,
    payload: id,
  }
}
