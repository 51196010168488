import React, { useState, useEffect } from 'react';
import { useDispatch } from "react-redux"
import { getoSolicitudBase } from "../../helpers/comunicabackend_helper"
import { map } from "lodash"
import registrosApi from "./data/registrosApi"
import TablaComida from "./tabla-registro-comida"
import TablaDiapers from "./tabla-registro-diapers"
import TablaSiesta from "./tabla-registro-siesta"
import TablaBath from "./tabla-registro-bath"
import TablaAcademia from "./tabla-registro-academia"
import CardContact from "../Contacts/card-contact"
import PropTypes from 'prop-types'
import classnames from "classnames";
import 'devextreme/dist/css/dx.light.css';
import {
  Container,
  Row,
  Col,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Card,
  CardBody,
  CardTitle,
  Button,
} from "reactstrap"

const CardRevision = (props) => {

  const catalogoMinutos = [
    { id: 5, minutos: '5 min' },
    { id: 10, minutos: '10 min' },
    { id: 15, minutos: '15 min' },
    { id: 20, minutos: '20 min' },
    { id: 25, minutos: '25 min' },
    { id: 30, minutos: '30 min' },
    { id: 40, minutos: '40 min' },
    { id: 50, minutos: '50 min' },
    { id: 60, minutos: '60 min' },
    { id: 70, minutos: '70 min' },
    { id: 80, minutos: '80 min' },
    { id: 90, minutos: '90 min' },
  ]

  return (
    <React.Fragment>
      {!props.registroAlumno.empty ? (
        <Card>
          <CardBody className='p-1'>
            <CardTitle >
              <Row className="">
                <Col xs="8">
                  {props.registroAlumno.oAlumno.nombres} {props.registroAlumno.oAlumno.apellido_Paterno}
                </Col>
                <Col xs="4" className='text-end'>
                    <Button
                      color="secondary"
                      size="sm"
                      onClick={()=>{
                        props.onpreview(props.registroAlumno);
                      }}
                      className={classnames("btn btn-rounded m-0 ")}
                    >
                      <i className="mdi mdi-clipboard-search-outline font-size-16 align-middle me-2"></i>{" "}
                      Preview {props.registroAlumno.oAlumno.nombres}
                    </Button>
                </Col>
              </Row>
            </CardTitle>
            {props.registroAlumno.comida.length ? (
              <TablaComida registro={props.registroAlumno.comida}
                estadosAnimo={props.estadosAnimo}
                cantidades={props.cantidades}
                observaciones={props.observaciones.Comida}
                edicion={props.edicion} />
            ) : (<></>)}

            {props.registroAlumno.diapers.length ? (
              <TablaDiapers registro={props.registroAlumno.diapers}
                estadosAnimo={props.estadosAnimo}
                observaciones={props.observaciones.Diapers}
                edicion={props.edicion} />
            ) : (<></>)}

            {props.registroAlumno.siesta.length ? (
              <TablaSiesta registro={props.registroAlumno.siesta}
                estadosAnimo={props.estadosAnimo}
                minutos={catalogoMinutos}
                observaciones={props.observaciones.Siesta}
                edicion={props.edicion} />
            ) : (<></>)}

            {props.registroAlumno.bath.length ? (
              <TablaBath registro={props.registroAlumno.bath}
                estadosAnimo={props.estadosAnimo}
                observaciones={props.observaciones.Bath}
                edicion={props.edicion} />
            ) : (<></>)}

            {props.registroAlumno.academia.length ? (
              <TablaAcademia registro={props.registroAlumno.academia}
                estadosAnimo={props.estadosAnimo}
                observaciones={props.observaciones.Academia}
                edicion={props.edicion} />
            ) : (<></>)}
          </CardBody>
          {/* <CardImg bottom className="img-fluid" src={img7} alt="Skote" /> */}
        </Card>) : (<></>)}
    </React.Fragment >

  );
}


CardRevision.propTypes = {
  registroAlumno: PropTypes.any,
  employee: PropTypes.array,
  estadosAnimo: PropTypes.array,
  cantidades: PropTypes.array,
  minutos: PropTypes.array,
  observaciones: PropTypes.object,
  edicion: PropTypes.bool,
  onpreview: PropTypes.func,
}

export default CardRevision

