import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

// Importamos tipos de acciones que van a hacer consultas al API
import {
  FAMILIAS_GET_FAMILIA,
  FAMILIAS_GET_FAMILIAS,
  FAMILIAS_ADD_FAMILIA,
  FAMILIAS_UPDATE_FAMILIA,
  FAMILIAS_DELETE_FAMILIA,
} from "./actionTypes"

// Importamos las acciones que levantaremos derivado del resultado de la llamada al api
import {
  getFamiliaSuccess,
  getFamiliaFail,
  getFamiliasSuccess,
  getFamiliasFail,
  addFamiliaSuccess,
  addFamiliaFail,
  updateFamiliaSuccess,
  updateFamiliaFail,
  deleteFamiliaSuccess,
  deleteFamiliaFail,
} from "./actions"

// Importamos las funciones que hacen la llamada al API
import {
  getApiFamilia,
  getApiFamilias,
  getApiAddFamilia,
  getApiUpdateFamilia,
  getApiDeleteFamilia,
} from "../../helpers/comunicabackend_helper"

// **********************************************************
// Funciones que se disparan al recibir una acción

// Tipo de Accion Recibida: FAMILIAS_GET_FAMILIA
export function* watchOnGetFamilia(action) {
  try {
    const response = yield call(getApiFamilia, action.payload)
    response.status == "success" ? 
    yield put(getFamiliaSuccess(response.datos))
    : yield put(getFamiliaFail(response.Mensaje_Detalle))
  } catch (error) {
    yield put(getFamiliaFail(error))
  }
}

// Tipo de Accion Recibida: FAMILIAS_GET_FAMILIAS
export function* watchOnGetFamilias(action) {
  try {
    const response = yield call(getApiFamilias, action.payload)
    response.status == "success" ? 
    yield put(getFamiliasSuccess(response.datos))
    : yield put(getFamiliasFail(response.Mensaje_Detalle))
  } catch (error) {
    yield put(getFamiliasFail(error))
  }
}

// Tipo de Accion Recibida: ADD_FAMILIA
export function* watchOnAddFamilia(action) {
  try {
    const response = yield call(getApiAddFamilia, action.payload)
    response.status == "success" ? 
    yield put(addFamiliaSuccess(response.datos))
    : yield put(addFamiliaFail(response.Mensaje_Detalle))
  } catch (error) {
    yield put(addFamiliaFail(error))
  }
}

// Tipo de Accion Recibida: UPDATE_FAMILIA
export function* watchOnUpdateFamilia(action) {
  try {
    const response = yield call(getApiUpdateFamilia, action.payload)
    response.status == "success" ? 
    yield put(updateFamiliaSuccess(response.datos))
    : yield put(updateFamiliaFail(response.Mensaje_Detalle))
  } catch (error) {
    yield put(updateFamiliaFail(error))
  }
}

// Tipo de Accion Recibida: DELETE_FAMILIA
export function* watchOnDeleteFamilia(action) {
  try {
    const response = yield call(getApiDeleteFamilia, action.payload)
    response.status == "success" ? 
    yield put(deleteFamiliaSuccess(response.datos))
    : yield put(deleteFamiliaFail(response.Mensaje_Detalle))
  } catch (error) {
    yield put(deleteFamiliaFail(error))
  }
}


function* familiasSaga() {
  yield takeEvery(FAMILIAS_GET_FAMILIA, watchOnGetFamilia)
  yield takeEvery(FAMILIAS_GET_FAMILIAS, watchOnGetFamilias)
  yield takeEvery(FAMILIAS_ADD_FAMILIA, watchOnAddFamilia)
  yield takeEvery(FAMILIAS_UPDATE_FAMILIA, watchOnUpdateFamilia)
  yield takeEvery(FAMILIAS_DELETE_FAMILIA, watchOnDeleteFamilia)
}

export default familiasSaga 
