//redux
import { useSelector } from "react-redux"

// *************************************************************
//     Misc

  export function useError() {
    return useSelector(state => state.alumnos.error);
  }

  export function useAlumno() {
    return useSelector(state => state.alumnos.alumno);
  }

  export function useAlumnos() {
    return useSelector(state => state.alumnos.alumnos);
  }