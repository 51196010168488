import React, { useState } from "react";
import './styles/columns.css';

const TestColumns = () => {

    return (
        <React.Fragment>
            <div className="page-content">
                <div className="">
                    <h2 className="component display-4">Columns</h2>
                    <hr />

                    <div className="code-example mb-5">
                        <div className="container">
                            <h3 className="component-variation text-muted">Equal width</h3>
                            <p>Grid with auto-layout and equal width</p>
                        </div>
                        <div className="code-live">
                            <div className="container">
                                <div className="row">
                                    <div className="col">.col</div>
                                    <div className="col">.col</div>
                                </div>
                                <div className="row">
                                    <div className="col">.col</div>
                                    <div className="col">.col</div>
                                    <div className="col">.col</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="code-example mb-5">
                        <div className="container">
                            <h3 className="component-variation text-muted">One column width</h3>
                            <p>Grid with auto-layout around one column with set width</p>
                        </div>
                        <div className="code-live">
                            <div className="container">
                                <div className="row">
                                    <div className="col">.col</div>
                                    <div className="col-7">.col-7</div>
                                    <div className="col">.col</div>
                                </div>
                                <div className="row">
                                    <div className="col-3">.col-3</div>
                                    <div className="col">.col</div>
                                    <div className="col">.col</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="code-example mb-5">
                        <div className="container">
                            <h3 className="component-variation text-muted">Variable width content</h3>
                            <p>Grid with auto-layout around columns with set width and natural (auto) width</p>
                        </div>
                        <div className="code-live">
                            <div className="container">
                                <div className="row">
                                    <div className="col">.col</div>
                                    <div className="col-auto">.col-auto</div>
                                    <div className="col">.col</div>
                                </div>
                                <div className="row">
                                    <div className="col">.col</div>
                                    <div className="col-auto">.col-auto</div>
                                    <div className="col-2">.col-2</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="code-example mb-5">
                        <h3 className="component-variation text-muted container">Responsive classes</h3>
                        <div className="code-live">
                            <div className="container">
                                <h4 className="h5 text-muted mt-3">All breakpoints</h4>
                                <p>Same grid across all devices: use .col and .col-*</p>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <div className="col">.col</div>
                                    <div className="col">.col</div>
                                    <div className="col">.col</div>
                                    <div className="col">.col</div>
                                </div>
                                <div className="row">
                                    <div className="col-6">.col-6</div>
                                    <div className="col-3">.col-3</div>
                                    <div className="col-3">.col-3</div>
                                </div>
                            </div>
                            <div className="container">
                                <h4 className="h5 text-muted mt-3">Responsive</h4>
                                <p>Advanced grid changing layout across different device sizes: use device prefixes</p>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <div className="col-12 col-md-6 col-lg-3">.col-12 .col-md-6 .col-lg-3</div>
                                    <div className="col-12 col-md-6 col-lg-3">.col-12 .col-md-6 .col-lg-3</div>
                                    <div className="col-6 col-lg-3">.col-6 .col-lg-3</div>
                                    <div className="col-6 col-lg-3">.col-6 .col-lg-3</div>
                                </div>
                                <div className="row">
                                    <div className="col-6 col-sm-4">.col-6 .col-sm-4</div>
                                    <div className="col-6 col-sm-4">.col-6 .col-sm-4</div>
                                    <div className="col-6 col-sm-4">.col-6 .col-sm-4</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="code-example mb-5">
                        <div className="container">
                            <h3 className="component-variation text-muted">Row columns</h3>
                        </div>
                        <div className="code-live">
                            <h4 className="h5 text-muted mt-3 container">Auto</h4>
                            <div className="container">
                                <div className="row row-cols-auto">
                                    <div className="col">Column</div>
                                    <div className="col">Column</div>
                                    <div className="col">Column</div>
                                    <div className="col">Column</div>
                                </div>
                            </div>
                            <h4 className="h5 text-muted mt-3 container">2</h4>
                            <div className="container">
                                <div className="row row-cols-2">
                                    <div className="col">Column</div>
                                    <div className="col">Column</div>
                                    <div className="col">Column</div>
                                    <div className="col">Column</div>
                                </div>
                            </div>
                            <h4 className="h5 text-muted mt-3 container">3</h4>
                            <div className="container">
                                <div className="row row-cols-3">
                                    <div className="col">Column</div>
                                    <div className="col">Column</div>
                                    <div className="col">Column</div>
                                    <div className="col">Column</div>
                                </div>
                            </div>
                            <h4 className="h5 text-muted mt-3 container">4</h4>
                            <div className="container">
                                <div className="row row-cols-4">
                                    <div className="col">Column</div>
                                    <div className="col">Column</div>
                                    <div className="col">Column</div>
                                    <div className="col">Column</div>
                                </div>
                            </div>
                            <h4 className="h5 text-muted mt-3 container">Responsive</h4>
                            <p>Advanced grid changing layout across different device sizes: use device prefixes</p>
                            <div className="container">
                                <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 row-cols-xxl-4">
                                    <div className="col">Column</div>
                                    <div className="col">Column</div>
                                    <div className="col">Column</div>
                                    <div className="col">Column</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="code-example mb-5">
                        <h3 className="component-variation text-muted container">Nesting</h3>
                        <div className="code-live">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-8">
                                        <div className="row">
                                            <div className="col-md-12 col-lg-6">.col-md-12 .col-lg-6</div>
                                            <div className="col-md-6 col-lg-3">.col-md-12 .col-lg-3</div>
                                            <div className="col-md-6 col-lg-3">.col-md-12 .col-lg-3</div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">.col-md-4</div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div className="">
            <h2 className="component display-4">Columns</h2>
            <hr/>

            <div className="code-example mb-5">
              <h3 className="component-variation text-muted container">Vertical alignment</h3>
              <div className="code-live">
                <div className="container">
                  <h4 className="h5 text-muted mt-3">Row</h4>
                  <p>Grid with vertical alignment on .row using flex utilities</p>
                </div>
                <div className="container" data-example="row-height">
                  <div className="row">
                    <div className="col">.col</div>
                    <div className="col">.col</div>
                    <div className="col">.col</div>
                  </div>
                  <div className="row">
                    <div className="col">.col</div>
                    <div className="col">.col</div>
                    <div className="col">.col</div>
                  </div>
                  <div className="row">
                    <div className="col">.col</div>
                    <div className="col">.col</div>
                    <div className="col">.col</div>
                  </div>
                </div>
                <div className="container">
                  <h4 className="h5 text-muted mt-3">Column</h4>
                  <p>Grid with vertical alignment on .col using flex utilities</p>
                </div>
                <div className="container" data-example="row-height">
                  <div className="row">
                    <div className="col">.col .align-self-start</div>
                    <div className="col">.col .align-self-center</div>
                    <div className="col">.col .align-self-end</div>
                  </div>
                </div>
              </div>
            </div>

            <div className="code-example mb-5">
              <div className="container">
                <h3 className="component-variation text-muted">Horizontal alignment</h3>
                <p>Grid with horizontal alignment on .row using flex utilities</p>
              </div>
              <div className="code-live">
                <div className="container">
                  <div className="row">
                    <div className="col-3">.col-3</div>
                    <div className="col-3">.col-3</div>
                    <div className="col-3">.col-3</div>
                  </div>
                  <div className="row">
                    <div className="col-3">.col-3</div>
                    <div className="col-3">.col-3</div>
                    <div className="col-3">.col-3</div>
                  </div>
                  <div className="row">
                    <div className="col-3">.col-3</div>
                    <div className="col-3">.col-3</div>
                    <div className="col-3">.col-3</div>
                  </div>
                  <div className="row">
                    <div className="col-3">.col-3</div>
                    <div className="col-3">.col-3</div>
                    <div className="col-3">.col-3</div>
                  </div>
                  <div className="row">
                    <div className="col-3">.col-3</div>
                    <div className="col-3">.col-3</div>
                    <div className="col-3">.col-3</div>
                  </div>
                  <div className="row">
                    <div className="col-3">.col-3</div>
                    <div className="col-3">.col-3</div>
                    <div className="col-3">.col-3</div>
                  </div>
                </div>
              </div>
            </div>

            <div className="code-example mb-5">
              <h3 className="component-variation text-muted container">Column wrapping</h3>
              <div className="code-live">
                <div className="container">
                  <div className="row">
                    <div className="col-8">.col-8</div>
                    <div className="col-5">.col-5</div>
                    <div className="col-4">.col-4</div>
                  </div>
                </div>
              </div>
            </div>

            <div className="code-example mb-5">
              <h3 className="component-variation text-muted container">Column breaks</h3>
              <div className="code-live">
                <h4 className="h5 text-muted mt-3 container">All breakpoints</h4>
                <p>Grid with column break using sizing utility className</p>
                <div className="container">
                  <div className="row">
                    <div className="col-6 col-sm-3">.col-6 .col-sm-3</div>
                    <div className="col-6 col-sm-3">.col-6 .col-sm-3</div>
                    <div className="col-6 col-sm-3">.col-6 .col-sm-3</div>
                    <div></div>
                    <div className="col-6 col-sm-3">.col-6 .col-sm-3</div>
                    <div className="col-6 col-sm-3">.col-6 .col-sm-3</div>
                    <div className="col-6 col-sm-3">.col-6 .col-sm-3</div>
                  </div>
                </div>
                <h4 className="h5 text-muted mt-3 container">Responsive</h4>
                <p>Grid with responsive column break using sizing and display utility classes</p>
                <div className="container">
                  <div className="row">
                    <div className="col-6 col-sm-3">.col-6 .col-sm-3</div>
                    <div className="col-6 col-sm-3">.col-6 .col-sm-3</div>
                    <div className="col-6 col-sm-3">.col-6 .col-sm-3</div>
                    <div></div>
                    <div className="col-6 col-sm-3">.col-6 .col-sm-3</div>
                    <div className="col-6 col-sm-3">.col-6 .col-sm-3</div>
                    <div className="col-6 col-sm-3">.col-6 .col-sm-3</div>
                  </div>
                </div>
              </div>
            </div>

            <div className="code-example mb-5">
              <div className="container">
                <h3 className="component-variation text-muted">Reordering</h3>
                <p>Grid reordering with flex utilities: order-first (-1), order-0 to order-5 and order-last (6)</p>
              </div>
              <div className="code-live">
                <div className="container">
                  <div className="row">
                    <div className="col">First, but last</div>
                    <div className="col">Second, but number 5</div>
                    <div className="col">Third, but number 4</div>
                    <div className="col">Fourth, but number 3</div>
                    <div className="col">Fifth, but first</div>
                    <div className="col">Last, but unordered, so number 2</div>
                  </div>
                </div>
              </div>
            </div>

            <div className="code-example mb-5">
              <h3 className="component-variation text-muted container">Offsetting</h3>
              <div className="code-live">
                <h4 className="h5 text-muted mt-3 container">Offset classes</h4>
                <div className="container">
                  <div className="row">
                    <div className="col-md-5">.col-md-5</div>
                    <div className="col-md-5">.col-md-5 .offset-md-2</div>
                  </div>
                  <div className="row">
                    <div className="col-lg-4">.col-lg-4 .offset-lg-3</div>
                    <div className="col-lg-2">.col-lg-2 .offset-lg-3</div>
                  </div>
                  <div className="row">
                    <div className="col-sm-6 col-md-5 col-lg-6">.col-sm-6 .col-md-5 .col-lg-6</div>
                    <div className="col-sm-6 col-md-5 col-lg-6">.col-sm-6 .col-md-5 .offset-md-2 .col-lg-6 .offset-lg-0</div>
                  </div>
                </div>
                <h4 className="h5 text-muted mt-3 container">Spacing utilities</h4>
                <div className="container">
                  <div className="row">
                    <div className="col-md-5">.col-md-5</div>
                    <div className="col-md-5">.col-md-5 .ms-auto</div>
                  </div>
                  <div className="row">
                    <div className="col-lg-4">.col-lg-4 .ms-lg-auto</div>
                    <div className="col-lg-2">.col-lg-2 .ms-lg-auto</div>
                  </div>
                  <div className="row">
                    <div className="col-sm-6">.col-sm-6 .me-lg-auto</div>
                    <div className="col-sm-3">.col-sm-3</div>
                  </div>
                </div>
              </div>
            </div>

            <div className="code-example mb-5">
              <h3 className="component-variation text-muted container">Standalone column classes</h3>
              <div className="code-live">
                <div>8.333333%</div>
                <div>16.666667%</div>
                <div>25%</div>
                <div>33.333333%</div>
                <div>41.666667%</div>
                <div>50%</div>
                <div>58.333333%</div>
                <div>66.666667%</div>
                <div>75%</div>
                <div>83.333333%</div>
                <div>91.666667%</div>
                <div>100%</div>
              </div>
            </div>

          </div>
            </div>
        </React.Fragment>
    )

}

export default TestColumns