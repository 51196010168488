import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import PropTypes from 'prop-types'
import ListadoReporteEnvios from "./listado-reportes-envio"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb2"
import Calendar from 'devextreme-react/calendar';
import { useAuthUser } from "../../store/auth/loginState/selectors"
import { useReporteEnvios } from "../../store/common/selectors"
import { useLibretaReportesFechaInicio, useLibretaReportesFechaFinal } from "../../store/libreta/selectors"

import classnames from "classnames";
import {
    Container,
    Row,
    Col,
    NavItem,
    NavLink,
    Nav,
    TabContent,
    TabPane,
    Card,
    CardBody,
    CardText,
} from "reactstrap"

import { getReporteEnvios as getReporteEnviosAction } from "../../store/common/actions"
import { getoSolicitudBase } from "../../helpers/comunicabackend_helper"
import {
    actionSetEstadoReportes as actionSetEstadoReportesAction,
    setLibretaReportesFechaInicio as setLibretaReportesFechaInicioAction,
    setLibretaReportesFechaFinal as setLibretaReportesFechaFinalAction,
} from "../../store/libreta/actions"
import { map } from "lodash"

const LibretaReportes = () => {

    console.log("--------------| iniciando reportes|--------------")

    const dispatch = useDispatch()
    const reporteEnvios = useReporteEnvios()
    const authUser = useAuthUser()
    const fecha_inicio = useLibretaReportesFechaInicio()
    const fecha_final = useLibretaReportesFechaFinal()

    const [customActiveTab, setcustomActiveTab] = useState("1");
    const [fechasConReporte, setFechasConReporte] = useState([]);
    const [reporteEnviosFiltrados, setReporteEnviosFiltrados] = useState([]);
    const [fechaSeleccionadaCalendario, setFechaSeleccionadaCalendario] = useState(new Date());

    console.log("authUser: ", authUser)

    function getFirstDayOfMonth(year, month) {
        return new Date(year, month, 1);
    }

    function getLastDayOfMonth(year, month) {
        return new Date(year, month + 1, 0);
    }

    const FechaInicio_FechaFinal = (fecha) => {

        const date = new Date(fecha);
        const firstDayCurrentMonth = getFirstDayOfMonth(
            date.getFullYear(),
            date.getMonth(),
        );
        const lastDayCurrentMonth = getLastDayOfMonth(
            date.getFullYear(),
            date.getMonth(),
        );

        console.log("firstDayCurrentMonth: ", firstDayCurrentMonth)
        console.log("lastDayCurrentMonth: ", lastDayCurrentMonth)

        return { fechaInicio: firstDayCurrentMonth, fechaFinal: lastDayCurrentMonth }
    }

    const CustomCell = (cell) => {
        return (
            <span className={getCellCssClass(cell.date, cell.view)}>
                {cell.text}
            </span>
        );
    };

    const getCellCssClass = (date, view) => {
        let cssClass = '';
        fechasConReporte.forEach((item) => {
            var fecha = new Date(item)
            if (date.getDate() === fecha.getDate() && date.getMonth() === fecha.getMonth() && view !== 'year') {
                cssClass = 'bg-danger';
            }
        });
        return cssClass;
    };

    //set Estado Reportes
    useEffect(() => {

        console.log("--------------------------------------")
        console.log("useEffect :: set Estado Reportes")

        dispatch(actionSetEstadoReportesAction())

        var fecha_temp = FechaInicio_FechaFinal(new Date())
        const fecha_inicio_temp = new Date(fecha_temp.fechaInicio);
        const fecha_final_temp = new Date(fecha_temp.fechaFinal);

        console.log("fecha_inicio_temp: ", fecha_inicio_temp)
        console.log("fecha_final_temp: ", fecha_final_temp)

        // Conseguir todos los eventos del mes
        dispatch(setLibretaReportesFechaInicioAction(fecha_inicio_temp))
        dispatch(setLibretaReportesFechaFinalAction(fecha_final_temp))

        getListado(fecha_inicio_temp, fecha_final_temp)
    }, [])

    // genero la lista de Fechas que vienen en el reporte
    useEffect(() => {
        let fechas = []
        map(reporteEnvios, (reporte, key) => {
            fechas.push(reporte.fecha)
        })
        setFechasConReporte(fechas)
        console.log("useEffect::reporteEnvios::originales ", reporteEnvios.length)
        setReporteEnviosFiltrados(reporteEnvios)
    }, [reporteEnvios])

    const filtraReportesxFecha = (fecha) => {

        var date = new Date(fecha)

        let ReportesFiltrados = []

        map(reporteEnvios, (reporte, key) => {

            if (date.getDate() === new Date(reporte.fecha).getDate() && date.getMonth() === new Date(reporte.fecha).getMonth()) {
                ReportesFiltrados.push(reporte)
            }
        })
        console.log("ReportesFiltradosxFecha: ", ReportesFiltrados.length)
        setReporteEnviosFiltrados(ReportesFiltrados)
    }

    const getListado = (fecha_inicio, fecha_final) => {
        const oSolicitudBase = getoSolicitudBase(
            "Reportes::useEffect",
            "getlistareporteenvios",
            [],
            [
                { nombre: "PageNumber", valor: "1" },
                { nombre: "RowsOfPage", valor: "1000000" },
                { nombre: "fromid", valor: authUser.idPersona.toString() },
                { nombre: "fecha_inicio", valor: new Date(fecha_inicio).toISOString() },
                { nombre: "fecha_final", valor: new Date(fecha_final).toISOString() }
            ]
        )
        dispatch(getReporteEnviosAction(oSolicitudBase))
    }

    const toggleCustom = tab => {
        if (customActiveTab !== tab) {
            setcustomActiveTab(tab);
        }
        //getListado()
    };

    const onCurrentValueChanged = (e) => {
        setFechaSeleccionadaCalendario(new Date(e.value))
        filtraReportesxFecha(new Date(e.value))
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>

                    <Breadcrumbs title="Libreta"
                        breadcrumbItems={[
                            { title: "Eventos", link: "/libreta" },
                            { title: "Revisión", link: "/libreta/revision" },
                            { title: "Envío", link: "/libreta/envio" },
                            { title: "Configuración", link: "/libreta/configuracion" },
                        ]}
                        activo="" />
                    <Row>
                        <TabContent
                            activeTab={customActiveTab}
                            className="p-3 text-muted"
                        >
                            <TabPane tabId="1">
                                <Row className="">
                                    <Col className="g-0 col-auto ">
                                        <Calendar
                                            id="calendar-container"
                                            value={fechaSeleccionadaCalendario}
                                            onValueChanged={onCurrentValueChanged}
                                            cellRender={CustomCell}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="g-0 col-auto ">
                                        <ListadoReporteEnvios reportes={reporteEnviosFiltrados}></ListadoReporteEnvios>
                                    </Col>
                                </Row>
                            </TabPane>
                        </TabContent>
                    </Row>

                </Container>
            </div>
        </React.Fragment>
    );
}

LibretaReportes.propTypes = {
    employee: PropTypes.any,
}

export default LibretaReportes

