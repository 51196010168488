


/* GRUPOS_ACADEMICOS */
export const GET_GRUPOS_ACADEMICOS = "GET/GRUPOS_ACADEMICOS"
export const GET_GRUPOS_ACADEMICOS_SUCCESS = "GET/GRUPOS_ACADEMICOS/SUCCESS"
export const GET_GRUPOS_ACADEMICOS_FAIL = "GET/GRUPOS_ACADEMICOS/FAIL"




