import {

  CONTACTOS_GET_CONTACTO_SUCCESS,
  CONTACTOS_GET_CONTACTO_FAIL,

  CONTACTOS_GET_CONTACTOS_SUCCESS,
  CONTACTOS_GET_CONTACTOS_FAIL,
  
  CONTACTOS_ADD_CONTACTO_SUCCESS,
  CONTACTOS_ADD_CONTACTO_FAIL,
  
  CONTACTOS_UPDATE_CONTACTO_SUCCESS,
  CONTACTOS_UPDATE_CONTACTO_FAIL,
  
  CONTACTOS_DELETE_CONTACTO_SUCCESS,
  CONTACTOS_DELETE_CONTACTO_FAIL,

} from "./actionTypes"


const INIT_STATE = {
  contacto: {},
  contactos: [],
  error: {},
}

const contactos = (state = INIT_STATE, action) => {
  switch (action.type) {

    case CONTACTOS_GET_CONTACTO_SUCCESS:
      return {
        ...state,
        error: {},
        contacto: action.payload,
      }

    case CONTACTOS_GET_CONTACTO_FAIL:
      return {
        ...state,
        contacto: {},
        error: action.payload
      }

    case CONTACTOS_GET_CONTACTOS_SUCCESS:
      return {
        ...state,
        error: {},
        contactos: action.payload,
      }

    case CONTACTOS_GET_CONTACTOS_FAIL:
      return {
        ...state,
        contactos: [],
        error: action.payload
      }

    case CONTACTOS_ADD_CONTACTO_SUCCESS:
      return {
        ...state,
        contactos: [...state.contactos, action.payload],
      }

    case CONTACTOS_ADD_CONTACTO_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case CONTACTOS_UPDATE_CONTACTO_SUCCESS:
      return {
        ...state,
        contactos: state.contactos.map(oContacto =>
          oContacto.id_Contacto.toString() === action.payload.id_Contacto.toString()
            ? { oContacto, ...action.payload }
            : oContacto
        ),
      }

    case CONTACTOS_UPDATE_CONTACTO_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case CONTACTOS_DELETE_CONTACTO_SUCCESS:
      return {
        ...state,
        contactos: state.contactos.filter(
          user => user.id_Contacto.toString() !== action.payload.id_Contacto.toString()
        ),
      }

    case CONTACTOS_DELETE_CONTACTO_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    default:
      return state
  }
}

export default contactos
