import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import React, { useEffect, useState } from "react"
import Select from "react-select"

import {
  Row,
  Col,
  CardBody,
  Card,
  Container,
  Form,
  Input,
  FormFeedback,
  Label,
  UncontrolledAlert
} from "reactstrap"

//redux
import { useDispatch } from "react-redux"
import {
  useAuthStatus,
  useAuthUser,
  useAuthError,
  useAuthLoading,
  useAuthUserGmtOffset,
  useAuthIdColegio
} from "../../store/auth/loginState/selectors"

//i18n
import { withTranslation } from "react-i18next"

import { withRouter, Link } from "react-router-dom"

// Formik validation
import * as Yup from "yup"
import { useFormik } from "formik"

//Social Media Imports
import { GoogleLogin } from "react-google-login"
// import TwitterLogin from "react-twitter-auth"
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props"

// actions
import { authLoginUser, authSocialLogin, authSetIdColegio as authSetIdColegioAction } from "../../store/actions"

// import images
import profile from "assets/images/profile-img.png"
import logo from "assets/images/favicon.ico"

//Import config
import { facebook, google } from "../../config"

const Login = props => {

  const [idColegioValido, setIdColegioValido] = useState(false)

  const authStatus = useAuthStatus();
  const authUser = useAuthUser();
  const authError = useAuthError();
  const authLoading = useAuthLoading();
  const authUserGmtOffset = useAuthUserGmtOffset()
  const idColegio = useAuthIdColegio()

  const colegiosOptions = [
    { value: 9, label: "Cd Granja" },
    { value: 11, label: "Ermita" },
    { value: 7, label: "Kingdom" },
    { value: 8, label: "Modena" },
    { value: 10, label: "Montevideo" },
  ]

  console.log("--------------")
  console.log("authStatus:", authStatus)
  console.log("authUser:", authUser)
  console.log("authError:", authError)
  console.log("authLoading:", authLoading)
  console.log("authUserGmtOffset:", authUserGmtOffset)
  console.log("idColegio:", idColegio)

  const dispatch = useDispatch()

  //set Estado Eventos
  useEffect(() => {
    if (idColegio > 0) { setIdColegioValido(true) }
    else { setIdColegioValido(false) }
  }, [idColegio])

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: "" || "",
      password: "" || "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your Email"),
      password: Yup.string().required("Please Enter Your Password"),
    }),
    onSubmit: values => {
      dispatch(authLoginUser(values, props.history))
    },
  })

  const onDropdownChange = (event) => {
    if (event) {
      console.log("event.value: ", event.value, "-", event.label)
      dispatch(authSetIdColegioAction(event.value))
    }
  }

  // handleValidSubmit
  const handleValidSubmit = (event, values) => {
    dispatch(authLoginUser(values, props.history))
  }

  const signIn = (res, type) => {
    if (type === "google" && res) {
      const postData = {
        id_Contacto: "-1",
        Descripcion_Bitacora: "socialLogin Login",
        Datos: {},
        Parametros: [
          {
            nombre: "provider",
            valor: "google",
          },
          {
            nombre: "id_colegio",
            valor: idColegio,
          },
          {
            nombre: "access_token",
            valor: res.tokenObj.access_token,
          },
          {
            nombre: "appversion",
            valor: "",
          },
          {
            nombre: "device_token",
            valor: "",
          },
          {
            nombre: "GMT_Offset",
            valor: (new Date().getTimezoneOffset()) / 60,
          },
        ],
      }
      dispatch(authSocialLogin(postData, props.history, type))
    } else if (type === "facebook" && res) {
      const postData = {
        name: res.name,
        email: res.email,
        token: res.accessToken,
        idToken: res.tokenId,
      }
      dispatch(authSocialLogin(postData, props.history, type))
    }
  }

  //handleGoogleLoginResponse
  const googleResponse = response => {
    signIn(response, "google")
  }

  //handleTwitterLoginResponse
  // const twitterResponse = e => {}

  //handleFacebookLoginResponse
  const facebookResponse = response => {
    signIn(response, "facebook")
  }

  return (
    <React.Fragment>
      <MetaTags>
        <title>Login | Comunica</title>
      </MetaTags>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2" />
        </Link>
      </div>
      <div className="account-pages my-4 pt-sm-4">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary bg-soft">
                  <Row>
                    <Col xs={7}>
                      <div className="text-primary p-4">
                        <h5 className="text-primary">Bienvenido !</h5>
                        <p>Escribe tus credenciales para entrar.</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      {/* <img src={profile} alt="" className="img-fluid" /> */}
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  {authLoading ? (
                    <div className="avatar-md profile-user-wid mb-4">
                      <span className="avatar-title rounded-circle bg-light">
                        <div className="spinner-border text-primary m-1" role="status"><span className="sr-only">Loading...</span></div>
                      </span>
                    </div>
                  ) : (
                    <div className="avatar-md profile-user-wid mb-4">
                      <span className="avatar-title rounded-circle bg-light">
                        <img
                          src={logo}
                          alt=""
                          className="rounded-circle"
                          height="34"
                        />
                      </span>
                    </div>
                  )}

                  <div className="p-1">
                    <Form
                      className="form-horizontal"
                      onSubmit={e => {
                        e.preventDefault()
                        validation.handleSubmit()
                        return false
                      }}
                    >
                      {authError ? (
                        <UncontrolledAlert color="danger">
                          {authError}
                        </UncontrolledAlert>
                      ) : null}

                      <div className="mb-2 ">
                        <Label className="form-label">Colegio</Label>
                        <Select
                          id="idColegio"
                          options={colegiosOptions}
                          onChange={onDropdownChange}
                          placeholder="Selecciona tu colegio...">
                        </Select>
                      </div>

                      <div className="mb-2">
                        <Label className="form-label"
                          hidden={!idColegioValido} >{props.t("Email")}</Label>
                        <Input
                          name="email"
                          hidden={!idColegioValido}
                          className="form-control"
                          placeholder={props.t("Enter Email")}
                          type="email"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.email || ""}
                          invalid={
                            validation.touched.email && validation.errors.email
                              ? true
                              : false
                          }
                        />
                        {validation.touched.email && validation.errors.email ? (
                          <FormFeedback type="invalid">
                            {validation.errors.email}
                          </FormFeedback>
                        ) : null}
                      </div>

                      <div className="mb-2">
                        <Label className="form-label"
                          hidden={!idColegioValido} >{props.t("Password")}</Label>
                        <Input
                          name="password"
                          hidden={!idColegioValido}
                          value={validation.values.password || ""}
                          type="password"
                          placeholder={props.t("Enter Password")}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          invalid={
                            validation.touched.password &&
                              validation.errors.password
                              ? true
                              : false
                          }
                        />
                        {validation.touched.password &&
                          validation.errors.password ? (
                          <FormFeedback type="invalid">
                            {validation.errors.password}
                          </FormFeedback>
                        ) : null}
                      </div>

                      {/* <div className="form-check">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="customControlInline"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="customControlInline"
                        >
                          {props.t("Remember me")}
                        </label>
                      </div> */}

                      <div className="mt-2 d-grid">
                        <button
                          className="btn btn-primary btn-block"
                          type="submit"
                          hidden={!idColegioValido}
                        >
                          {props.t("Log In")}
                        </button>
                      </div>

                      <div className="mt-4 text-center">
                        <h5 className="font-size-14 mb-3"
                          hidden={!idColegioValido}>
                          {props.t("Sign in with")}
                        </h5>

                        <ul className="list-inline">
                          <li className="list-inline-item">
                            {idColegioValido ? (
                              <FacebookLogin
                                appId={facebook.APP_ID}
                                autoLoad={false}
                                callback={facebookResponse}
                                render={renderProps => (
                                  <Link
                                    to="#"
                                    className="social-list-item bg-primary text-white border-primary"
                                    onClick={renderProps.onClick}
                                  >
                                    <i className="mdi mdi-facebook" />
                                  </Link>
                                )}
                              />
                            ) : null}

                          </li>
                          {/*<li className="list-inline-item">*/}
                          {/*  <TwitterLogin*/}
                          {/*    loginUrl={*/}
                          {/*      "http://localhost:4000/api/v1/auth/twitter"*/}
                          {/*    }*/}
                          {/*    onSuccess={this.twitterResponse}*/}
                          {/*    onFailure={this.onFailure}*/}
                          {/*    requestTokenUrl={*/}
                          {/*      "http://localhost:4000/api/v1/auth/twitter/revers"*/}
                          {/*    }*/}
                          {/*    showIcon={false}*/}
                          {/*    tag={"div"}*/}
                          {/*  >*/}
                          {/*    <a*/}
                          {/*      href=""*/}
                          {/*      className="social-list-item bg-info text-white border-info"*/}
                          {/*    >*/}
                          {/*      <i className="mdi mdi-twitter"/>*/}
                          {/*    </a>*/}
                          {/*  </TwitterLogin>*/}
                          {/*</li>*/}
                          <li className="list-inline-item">
                            {idColegioValido ? (
                              <GoogleLogin
                                clientId={google.CLIENT_ID}
                                render={renderProps => (
                                  <Link
                                    to="#"
                                    className="social-list-item bg-danger text-white border-danger"
                                    onClick={renderProps.onClick}
                                  >
                                    <i className="mdi mdi-google" />
                                  </Link>
                                )}
                                onSuccess={googleResponse}
                                onFailure={() => { }}
                              />
                            ) : null}

                          </li>
                        </ul>
                      </div>

                      {/* <div className="mt-4 text-center">
                        <Link to="/forgot-password" className="text-muted">
                          <i className="mdi mdi-lock me-1" />
                          {props.t("Forgot your password?")}
                        </Link>
                      </div> */}
                    </Form>
                    {!idColegioValido ? (<div>
                      <div className="mb-5 text-white">_</div>
                      <div className="mb-5 text-white">_</div>
                      <div className="mb-5 text-white">_</div>
                    </div>) : null}
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                {/* <p>
                  Don&#39;t have an account ?{" "}
                  <Link to="/register" className="fw-medium text-primary">
                    {" "}
                    {props.t("Signup now")}
                    {" "}
                  </Link>{" "}
                </p> */}
                <p>
                  © {new Date().getFullYear()} Comunica. Diseñado con mucho{" "}
                  <i className="mdi mdi-heart text-danger" /> por CampusVirtual
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(withTranslation()(Login))

Login.propTypes = {
  history: PropTypes.object,
  t: PropTypes.any,
}
