import {
  CONTACTOS_GET_CONTACTO,
  CONTACTOS_GET_CONTACTO_SUCCESS,
  CONTACTOS_GET_CONTACTO_FAIL,

  CONTACTOS_GET_CONTACTOS,
  CONTACTOS_GET_CONTACTOS_SUCCESS,
  CONTACTOS_GET_CONTACTOS_FAIL,
  
  CONTACTOS_ADD_CONTACTO,
  CONTACTOS_ADD_CONTACTO_SUCCESS,
  CONTACTOS_ADD_CONTACTO_FAIL,
  
  CONTACTOS_UPDATE_CONTACTO,
  CONTACTOS_UPDATE_CONTACTO_SUCCESS,
  CONTACTOS_UPDATE_CONTACTO_FAIL,
  
  CONTACTOS_DELETE_CONTACTO,
  CONTACTOS_DELETE_CONTACTO_SUCCESS,
  CONTACTOS_DELETE_CONTACTO_FAIL,

} from "./actionTypes"

export const getContacto = oSolicitudBase => ({
  type: CONTACTOS_GET_CONTACTO,
  payload: oSolicitudBase,
})

export const getContactoSuccess = alumnos => ({
  type: CONTACTOS_GET_CONTACTO_SUCCESS,
  payload: alumnos,
})

export const getContactoFail = error => ({
  type: CONTACTOS_GET_CONTACTO_FAIL,
  payload: error,
})

export const getContactos = oSolicitudBase => ({
  type: CONTACTOS_GET_CONTACTOS,
  payload: oSolicitudBase,
})

export const getContactosSuccess = alumnos => ({
  type: CONTACTOS_GET_CONTACTOS_SUCCESS,
  payload: alumnos,
})

export const getContactosFail = error => ({
  type: CONTACTOS_GET_CONTACTOS_FAIL,
  payload: error,
})

export const addContacto = oSolicitudBase => ({
  type: CONTACTOS_ADD_CONTACTO,
  payload: oSolicitudBase,
})

export const addContactoSuccess = alumnos => ({
  type: CONTACTOS_ADD_CONTACTO_SUCCESS,
  payload: alumnos,
})

export const addContactoFail = error => ({
  type: CONTACTOS_ADD_CONTACTO_FAIL,
  payload: error,
})

export const updateContacto = oSolicitudBase => ({
  type: CONTACTOS_UPDATE_CONTACTO,
  payload: oSolicitudBase,
})

export const updateContactoSuccess = alumnos => ({
  type: CONTACTOS_UPDATE_CONTACTO_SUCCESS,
  payload: alumnos,
})

export const updateContactoFail = error => ({
  type: CONTACTOS_UPDATE_CONTACTO_FAIL,
  payload: error,
})

export const deleteContacto = oSolicitudBase => ({
  type: CONTACTOS_DELETE_CONTACTO,
  payload: oSolicitudBase,
})

export const deleteContactoSuccess = alumnos => ({
  type: CONTACTOS_DELETE_CONTACTO_SUCCESS,
  payload: alumnos,
})

export const deleteContactoFail = error => ({
  type: CONTACTOS_DELETE_CONTACTO_FAIL,
  payload: error,
})




