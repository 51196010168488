import {
GET_GRUPOS_ACADEMICOS,
GET_GRUPOS_ACADEMICOS_SUCCESS,
GET_GRUPOS_ACADEMICOS_FAIL,
} from "./actionTypes"

export const getGruposAcademicos = oSolicitudBase => ({
  type: GET_GRUPOS_ACADEMICOS,
  payload: oSolicitudBase,
})

export const getGruposAcademicosSuccess = gruposAcademicos => ({
  type: GET_GRUPOS_ACADEMICOS_SUCCESS,
  payload: gruposAcademicos,
})

export const getGruposAcademicosFail = error => ({
  type: GET_GRUPOS_ACADEMICOS_FAIL,
  payload: error,
})
