import {

  ALUMNOS_GET_ALUMNO_SUCCESS,
  ALUMNOS_GET_ALUMNO_FAIL,

  ALUMNOS_GET_ALUMNOS_SUCCESS,
  ALUMNOS_GET_ALUMNOS_FAIL,

  ADD_ALUMNO_SUCCESS,
  ADD_ALUMNO_FAIL,

  UPDATE_ALUMNO_SUCCESS,
  UPDATE_ALUMNO_FAIL,

  DELETE_ALUMNO_SUCCESS,
  DELETE_ALUMNO_FAIL,

} from "./actionTypes"


const INIT_STATE = {
  alumno: {},
  alumnos: [],
  error: {},
}

const alumnos = (state = INIT_STATE, action) => {
  switch (action.type) {

    case ALUMNOS_GET_ALUMNO_SUCCESS:
      return {
        ...state,
        error: {},
        alumno: action.payload,
      }

    case ALUMNOS_GET_ALUMNO_FAIL:
      return {
        ...state,
        alumno: {},
        error: action.payload
      }

    case ALUMNOS_GET_ALUMNOS_SUCCESS:
      return {
        ...state,
        error: {},
        alumnos: action.payload,
      }

    case ALUMNOS_GET_ALUMNOS_FAIL:
      return {
        ...state,
        alumnos: [],
        error: action.payload
      }

    case ADD_ALUMNO_SUCCESS:
      return {
        ...state,
        alumnos: [...state.alumnos, action.payload],
      }

    case ADD_ALUMNO_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case UPDATE_ALUMNO_SUCCESS:
      return {
        ...state,
        alumnos: state.alumnos.map(user =>
          user.id_Alumno.toString() === action.payload.id_Alumno.toString()
            ? { user, ...action.payload }
            : user
        ),
      }

    case UPDATE_ALUMNO_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case DELETE_ALUMNO_SUCCESS:
      return {
        ...state,
        alumnos: state.alumnos.filter(
          user => user.id.toString() !== action.payload.id.toString()
        ),
      }

    case DELETE_ALUMNO_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    default:
      return state
  }
}

export default alumnos
