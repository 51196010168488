
import libretaMachine from './machine';
export const { initialState } = libretaMachine;

const libreta = (state = initialState, action) => {
  
  const actionsNoCare = [
    'CHANGE_LAYOUT',
    'CHANGE_TOPBAR_THEME',
    'CHANGE_SIDEBAR_THEME',
    'CHANGE_SIDEBAR_THEME_IMAGE',
    'CHANGE_LAYOUT_WIDTH',
    'CHANGE_SIDEBAR_TYPE',
    'SHOW_RIGHT_SIDEBAR',
  ]

  if (!actionsNoCare.includes(action.type) && !action.type.startsWith("@@")) {
  console.log("store/libreta/reducer.js::state: ", state.value);
  console.log("store/libreta/reducer.js::context: ", state.context);
  console.log("store/libreta/reducer.js::action: ", action);
}
  let nuevoState = libretaMachine.transition(state, action);
  if (!actionsNoCare.includes(action.type) && !action.type.startsWith("@@")) {
    console.log("store/libreta/reducer.js::nuevoState: ", nuevoState)
  };

  return nuevoState;
}

export default libreta
