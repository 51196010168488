import { assign, Assigner, createMachine, PropertyAssigner } from "xstate";
import { date } from "yup";

const libretaMachine =
  /** @xstate-layout */
  createMachine(
    {
      id: 'libretaMachine',
      initial: 'eventos',
      context: {
        //
        alumnos: [],
        displayNotification: { tipo: "", titulo: "", mensaje: "", },
        error: "",
        evento_catalogo_observaciones: [],
        eventos_academia_observacion: "",
        eventos_bath_observacion: "",
        eventos_catalogo_comida_cantidad: [],
        eventos_catalogo_estadosAnimo: [],
        eventos_catalogos: [],
        eventos_comida_observacion: "",
        eventos_diapers_observacion: "",
        eventos_estadoAnimo: -1,
        eventos_EventoRegistrado: -1,
        eventos_fechaHora: new Date().toISOString().split('.')[0],
        eventos_siesta_observacion: "",
        eventos_tipoEvento: "comida",
        fuealBath: false,
        gruposAcademicos: [],
        idCantidad: -1,
        idGrupoAcademicoSelected: -1,
        idLibretaObservacion: -1,
        loading: false,
        modal_backdrop: false,
        modal_envio: false,
        oAlumnoSelected: {},
        oGrupoAcademicoSelected: { "id_Grupo_Academico": 0, "nombre_Grupo_Academico": "" },
        pipi: false,
        popo: false,
        registrosApi: [],
        reporteEnvio: {},
        revision_fecha: new Date(),
        siestaDuracion: "",
        siestaHora: "",
        siestaMinutos: "",
        refreshReporteEnvioDetalles: false,
        reportes_fecha_inicio: '2022-11-01',
        reportes_fecha_final: '2022-12-01',
      },
      states: {
        eventos: {
          on: {
            "auth/LOGOUT": {
              actions: ["clearAll"],
            },

            "LIBRETA/SET/EVENTOS": {
              target: "eventos",
            },
            "LIBRETA/SET/REVISION": {
              target: "revision",
            },
            "LIBRETA/SET/ENVIO": {
              target: "envios",
            },
            "LIBRETA/SET/CONFIGURACION": {
              target: "configuracion",
            },
            "LIBRETA/SET/REPORTES": {
              target: "reportes",
            },

            "LIBRETA/SET/DISPLAY_NOTIFICATION": {
              target: "eventos",
              actions: ["setOnlyDisplayNotification", "unsetError", "unsetLoading"],
            },
            "LIBRETA/UNSET/DISPLAY_NOTIFICATION": {
              target: "eventos",
              actions: ["unsetDisplayNotification", "unsetError", "unsetLoading"],
            },
            "LIBRETA/EVENTOS/SET/REVISION": {
              target: "revision",
            },
            "LIBRETA/EVENTOS/SET/ENVIO": {
              target: "envios",
            },
            "LIBRETA/EVENTOS/SET/REPORTES": {
              target: "reportes",
            },
            "LIBRETA/GET/GRUPOS_ACADEMICOS": {
              target: "eventos",
              actions: ["setLoading"],
            },
            "LIBRETA/GET/GRUPOS_ACADEMICOS/SUCCESS": {
              target: "eventos",
              actions: ["setGruposAcademicos", "unsetError", "unsetLoading"],
            },
            "LIBRETA/GET/GRUPOS_ACADEMICOS/FAIL": {
              target: "eventos",
              actions: ["setError", "unsetGruposAcademicos", "unsetLoading"],
            },
            "LIBRETA/SET/ID/GRUPO_ACADEMICO": {
              target: "eventos",
              actions: ["setGrupoAcademico"],
            },
            "LIBRETA/SET/OGRUPO_ACADEMICO": {
              target: "eventos",
              actions: ["setoGrupoAcademicoSelected"],
            },
            "LIBRETA/GET/ALUMNOS_LIBRETA/SUCCESS": {
              target: "eventos",
              actions: ["setAlumnos", "unsetError", "unsetLoading"],
            },
            "LIBRETA/GET/ALUMNOS_LIBRETA/FAIL": {
              target: "eventos",
              actions: ["setError", "unsetGruposAcademicos", "unsetLoading"],
            },
            "LIBRETA/SET/ID/ALUMNO": {
              target: "eventos",
              actions: ["setAlumnoSelected"],
            },
            "LIBRETA/EVENT/OPENMODAL": {
              target: "capturaEvento",
              actions: ["openModal"],
            },
            "LIBRETA/EVENT/CLOSEMODAL": {
              target: "eventos",
              actions: ["closeModal", "resetModalFormularios"],
            },
            "LIBRETA/EVENT/SELECTUSER": {
              target: "AltaEventosEstudiante",
              actions: ["setGrupoAcademico", "openModal"],
            },
            "LIBRETA/EVENTOS/SET/FECHAHORA": {
              target: "eventos",
              actions: ["setFechaHora"],
            },
            "LIBRETA/EVENTOS/GET/CATALOGOS_SUCCESS": {
              target: "eventos",
              actions: [
                "setCatalogoEstadoAnimo",
                "setCatalogoComidaCantidad",
                "SetCatalogosObservaciones",
                "unsetIdObservacion",
                "unsetError",
                "unsetLoading"],
            },
            "LIBRETA/EVENTOS/GET/CATALOGOS_FAIL": {
              target: "eventos",
              actions: ["setError", "unsetLoading"],
            },
          },
        },
        AltaEventosEstudiante: {
          on: {
            "LIBRETA/EVENT/CLOSEMODAL": {
              target: "eventos",
              actions: ["closeModal"],
            },
            "COMIDAACTION": {
              target: "capturaEvento"
            },
          }
        },
        capturaEvento: {
          on: {
            "LIBRETA/SET/ID/ALUMNO": {
              target: "capturaEvento",
              actions: ["setAlumnoSelected"],
            },
            "LIBRETA/SET/DISPLAY_NOTIFICATION": {
              target: "capturaEvento",
              actions: ["setOnlyDisplayNotification", "unsetError", "unsetLoading"],
            },
            "LIBRETA/UNSET/DISPLAY_NOTIFICATION": {
              target: "capturaEvento",
              actions: ["unsetDisplayNotification", "unsetError", "unsetLoading"],
            },
            "LIBRETA/EVENTOS/OBSERVACIONES/SET/ID": {
              target: "capturaEvento",
              actions: [
                "setIdObservacion",
                "unsetError",
                "unsetLoading"],
            },
            "LIBRETA/EVENTOS/SET/TIPO_EVENTO": {
              target: "capturaEvento",
              actions: ["setTipoEvento",
                "unsetIdObservacion"],
            },
            "LIBRETA/EVENTOS/SET/FECHAHORA": {
              target: "capturaEvento",
              actions: ["setFechaHora"],
            },
            "LIBRETA/EVENTOS/SET/ESTADO_ANIMO": {
              target: "capturaEvento",
              actions: ["setEstadoAnimo"],
            },
            "LIBRETA/EVENTOS/COMIDA/SET/CANTIDAD": {
              target: "capturaEvento",
              actions: ["setIdCantidad"],
            },
            "LIBRETA/EVENT/CLOSEMODAL": {
              target: "eventos",
              actions: ["closeModal", "resetModalFormularios"],
            },
            "LIBRETA/EVENTOS/DIAPERS/SET/PIPI": {
              target: "capturaEvento",
              actions: ["setPipi"],
            },
            "LIBRETA/EVENTOS/DIAPERS/SET/POPO": {
              target: "capturaEvento",
              actions: ["setPopo"],
            },
            "LIBRETA/EVENTOS/SIESTA/SET/HORA": {
              target: "capturaEvento",
              actions: ["setSiestaHora"],
            },
            "LIBRETA/EVENTOS/SIESTA/SET/MINUTOS": {
              target: "capturaEvento",
              actions: ["setSiestaMinutos"],
            },
            "LIBRETA/EVENTOS/SIESTA/SET/DURACION": {
              target: "capturaEvento",
              actions: ["setSiestaDuracion"],
            },
            "LIBRETA/EVENTOS/SIESTA/SET/DURACION": {
              target: "capturaEvento",
              actions: ["setSiestaDuracion"],
            },
            "LIBRETA/EVENTOS/BATH/SET": {
              target: "capturaEvento",
              actions: ["setFuealBano"],
            },
            "LIBRETA/EVENT/REGISTRA/COMIDA_SUCCESS": {
              target: "capturaEvento",
              actions: [
                "setEventoRegistrado",
                "setDisplayNotification",
                "unsetError",
                "unsetLoading"],
            },
            "LIBRETA/EVENT/REGISTRA/DIAPERS_SUCCESS": {
              target: "capturaEvento",
              actions: [
                "setEventoRegistrado",
                "setDisplayNotification",
                "unsetError",
                "unSetPipi",
                "unSetPopo",
                "unsetLoading"],
            },
            "LIBRETA/EVENT/REGISTRA/SIESTA_SUCCESS": {
              target: "capturaEvento",
              actions: [
                "setEventoRegistrado",
                "setDisplayNotification",
                "unSetSiestaHora",
                "unSetSiestaMinutos",
                "unSetSiestaDuracion",
                "unsetError",
                "unsetLoading"],
            },
            "LIBRETA/EVENT/REGISTRA/BATH_SUCCESS": {
              target: "capturaEvento",
              actions: [
                "setEventoRegistrado",
                "setDisplayNotification",
                "unsetError",
                "unsetLoading"],
            },
            "LIBRETA/EVENT/REGISTRA/ACADEMIC_SUCCESS": {
              target: "capturaEvento",
              actions: [
                "setEventoRegistrado",
                "setDisplayNotification",
                "unsetError",
                "unsetLoading"],
            },
            "LIBRETA/EVENT/REGISTRA_FAIL": {
              target: "capturaEvento",
              actions: ["setDisplayNotification", "setError", "unsetLoading", "unSetIdEventoRegistrado"],
            },
            "LIBRETA/EVENTOS/COMIDA/SET/OBSERVACIONES": {
              target: "capturaEvento",
              actions: ["setObservacionComida"],
            },
            "LIBRETA/EVENTOS/DIAPERS/SET/OBSERVACIONES": {
              target: "capturaEvento",
              actions: ["setObservacionDiapers"],
            },
            "LIBRETA/EVENTOS/SIESTA/SET/OBSERVACIONES": {
              target: "capturaEvento",
              actions: ["setObservacionSiesta"],
            },
            "LIBRETA/EVENTOS/BATH/SET/OBSERVACIONES": {
              target: "capturaEvento",
              actions: ["setObservacionBath"],
            },
            "LIBRETA/EVENTOS/ACADEMIA/SET/OBSERVACIONES": {
              target: "capturaEvento",
              actions: ["setObservacionAcademia"],
            },
          }
        },
        revision: {
          on: {
            "auth/LOGOUT": {
              actions: ["clearAll"],
            },


            "LIBRETA/SET/EVENTOS": {
              target: "eventos",
            },
            "LIBRETA/SET/REVISION": {
              target: "revision",
            },
            "LIBRETA/SET/ENVIO": {
              target: "envios",
            },
            "LIBRETA/SET/CONFIGURACION": {
              target: "configuracion",
            },
            "LIBRETA/SET/REPORTES": {
              target: "reportes",
            },

            "LIBRETA/EVENTOS/GET/CATALOGOS_SUCCESS": {
              target: "revision",
              actions: [
                "setCatalogoEstadoAnimo",
                "setCatalogoComidaCantidad",
                "SetCatalogosObservaciones",
                "unsetIdObservacion",
                "unsetError",
                "unsetLoading"],
            },
            "LIBRETA/EVENTOS/GET/CATALOGOS_FAIL": {
              target: "revision",
              actions: ["setError", "unsetLoading"],
            },

            "LIBRETA/EVENTOS/SET/EVENTOS": {
              target: "eventos",
            },
            "LIBRETA/EVENTOS/SET/REPORTES": {
              target: "reportes",
            },
            "LIBRETA/GET/GRUPOS_ACADEMICOS": {
              target: "revision",
              actions: ["setLoading"],
            },
            "LIBRETA/GET/GRUPOS_ACADEMICOS/SUCCESS": {
              target: "revision",
              actions: ["setGruposAcademicos", "unsetError", "unsetLoading"],
            },
            "LIBRETA/GET/GRUPOS_ACADEMICOS/FAIL": {
              target: "revision",
              actions: ["setError", "unsetGruposAcademicos", "unsetLoading"],
            },
            "LIBRETA/SET/ID/GRUPO_ACADEMICO": {
              target: "revision",
              actions: ["setGrupoAcademico"],
            },
            "LIBRETA/SET/OGRUPO_ACADEMICO": {
              target: "revision",
              actions: ["setoGrupoAcademicoSelected"],
            },
            "LIBRETA/SET/DISPLAY_NOTIFICATION": {
              target: "revision",
              actions: ["setOnlyDisplayNotification", "unsetError", "unsetLoading"],
            },
            "LIBRETA/UNSET/DISPLAY_NOTIFICATION": {
              target: "revision",
              actions: ["unsetDisplayNotification", "unsetError", "unsetLoading"],
            },
            "LIBRETA/EVENT/REVISION/SET_FECHA": {
              target: "revision",
              actions: ["setRevisionFecha"],
            },
            "LIBRETA/EVENT/REVISION/SET_FECHA/FUTURO": {
              target: "revision",
              actions: ["setRevisionFechaFuturo"],
            },
            "LIBRETA/EVENT/REVISION/SET_FECHA/PASADO": {
              target: "revision",
              actions: ["setRevisionFechaPasado"],
            },
            "LIBRETA/EVENTOS/CLEAR/REGISTROS": {
              target: "revision",
              actions: ["unSetRegistrosApi"],
            },
            "LIBRETA/EVENTOS/GET/REGISTROS": {
              target: "revision",
              actions: ["setLoading"],
            },
            "LIBRETA/EVENTOS/GET/REGISTROS_SUCCESS": {
              target: "revision",
              actions: ["setRegistrosApi", "unsetLoading"],
            },
            "LIBRETA/EVENTOS/GET/REGISTROS_FAIL": {
              target: "revision",
              actions: ["setDisplayNotification", "setError", "unsetLoading", "unSetIdEventoRegistrado"],
            },
            "LIBRETA/SET/ID/GRUPO_ACADEMICO": {
              target: "revision",
              actions: ["setGrupoAcademico"],
            },
            "LIBRETA/EVENT/UPDATE_SUCCESS": {
              target: "revision",
              actions: [
                "updateRegistrosApi",
                "setDisplayNotification",
                "unsetError",
                "unsetLoading"],
            },
            "LIBRETA/EVENT/UPDATE_FAIL": {
              target: "revision",
              actions: ["setDisplayNotification", "setError", "unsetLoading", "unSetIdEventoRegistrado"],
            },
            "LIBRETA/ENVIO_SUCCESS": {
              target: "revision",
              actions: ["setDisplayNotification", "setReporteEnvio", "setDisplayReporteEnvio", "unsetLoading"],
            },
            "LIBRETA/ENVIO_FAIL": {
              target: "revision",
              actions: ["setDisplayNotification", "setReporteEnvio", "setDisplayReporteEnvio", "setError", "unsetLoading"],
            },
            "LIBRETA/SET/DISPLAY_REPORTE_ENVIO": {
              target: "revision",
              actions: ["setDisplayReporteEnvio"],
            },
            "LIBRETA/UNSET/DISPLAY_REPORTE_ENVIO": {
              target: "revision",
              actions: ["unsetDisplayReporteEnvio"],
            },
            "LIBRETA/EVENTOS/SET/ENVIO": {
              target: "envios",
            }
          }
        },
        envios: {
          on: {
            "auth/LOGOUT": {
              actions: ["clearAll"],
            },


            "LIBRETA/SET/EVENTOS": {
              target: "eventos",
            },
            "LIBRETA/SET/REVISION": {
              target: "revision",
            },
            "LIBRETA/SET/ENVIO": {
              target: "envios",
            },
            "LIBRETA/SET/CONFIGURACION": {
              target: "configuracion",
            },
            "LIBRETA/SET/REPORTES": {
              target: "reportes",
            },

            "LIBRETA/EVENTOS/GET/CATALOGOS_SUCCESS": {
              target: "envios",
              actions: [
                "setCatalogoEstadoAnimo",
                "setCatalogoComidaCantidad",
                "SetCatalogosObservaciones",
                "unsetIdObservacion",
                "unsetError",
                "unsetLoading"],
            },
            "LIBRETA/EVENTOS/GET/CATALOGOS_FAIL": {
              target: "envios",
              actions: ["setError", "unsetLoading"],
            },
            "LIBRETA/GET/GRUPOS_ACADEMICOS/SUCCESS": {
              target: "envios",
              actions: ["setGruposAcademicos", "unsetError", "unsetLoading"],
            },
            "LIBRETA/GET/GRUPOS_ACADEMICOS/FAIL": {
              target: "envios",
              actions: ["setError", "unsetGruposAcademicos", "unsetLoading"],
            },

            "LIBRETA/EVENTOS/SET/EVENTOS": {
              target: "eventos",
            },
            "LIBRETA/EVENTOS/SET/REVISION": {
              target: "revision",
            },
            "LIBRETA/EVENTOS/CLEAR/REGISTROS": {
              target: "envios",
              actions: ["unSetRegistrosApi"],
            },
            "LIBRETA/EVENTOS/GET/REGISTROS": {
              target: "envios",
              actions: ["setLoading"],
            },
            "LIBRETA/EVENTOS/GET/REGISTROS_SUCCESS": {
              target: "envios",
              actions: ["setRegistrosApi", "unsetLoading"],
            },
            "LIBRETA/EVENTOS/GET/REGISTROS_FAIL": {
              target: "envios",
              actions: ["setDisplayNotification", "setError", "unsetLoading", "unSetIdEventoRegistrado"],
            },
            "LIBRETA/SET/ID/GRUPO_ACADEMICO": {
              target: "envios",
              actions: ["setGrupoAcademico"],
            },
            "LIBRETA/SET/OGRUPO_ACADEMICO": {
              target: "envios",
              actions: ["setoGrupoAcademicoSelected"],
            },
            "LIBRETA/ENVIO": {
              target: "envios",
              actions: ["setLoading"],
            },
            "LIBRETA/ENVIO_SUCCESS": {
              target: "envios",
              actions: ["setDisplayNotification", "setReporteEnvio", "setDisplayReporteEnvio", "unsetLoading"],
            },
            "LIBRETA/ENVIO_FAIL": {
              target: "envios",
              actions: ["setDisplayNotification", "setReporteEnvio", "setDisplayReporteEnvio", "setError", "unsetLoading"],
            },
            "LIBRETA/SET/DISPLAY_NOTIFICATION": {
              target: "envios",
              actions: ["setOnlyDisplayNotification"],
            },
            "LIBRETA/UNSET/DISPLAY_NOTIFICATION": {
              target: "envios",
              actions: ["unsetDisplayNotification"],
            },
            "LIBRETA/SET/DISPLAY_REPORTE_ENVIO": {
              target: "envios",
              actions: ["setDisplayReporteEnvio"],
            },
            "LIBRETA/UNSET/DISPLAY_REPORTE_ENVIO": {
              target: "envios",
              actions: ["unsetDisplayReporteEnvio"],
            },
          }
        },
        configuracion: {
          on: {
            "ALUMNOS/GET/ALUMNO": {
              target: "configuracion",
              actions: [],
            },
            "ALUMNOS/GET/ALUMNOS": {
              target: "configuracion",
              actions: [],
            },
            "LIBRETA/CONFIG/UPDATE/OBSERVACION_SUCCESS" : {
              target: "configuracion",
              actions: ["unSetCatalogosObservaciones", "unsetError", "unsetLoading"],
            },

            "LIBRETA/SET/DISPLAY_NOTIFICATION": {
              actions: ["setOnlyDisplayNotification", "unsetError", "unsetLoading"],
            },

            "LIBRETA/UNSET/DISPLAY_NOTIFICATION" : {
              actions: ["unsetDisplayNotification", "unsetError", "unsetLoading"],
            },

            "LIBRETA/EVENTOS/GET/CATALOGOS_SUCCESS": {
              actions: [
                "setCatalogoEstadoAnimo",
                "setCatalogoComidaCantidad",
                "SetCatalogosObservaciones",
                "unsetIdObservacion",
                "unsetError",
                "unsetLoading"],
            },
            "LIBRETA/EVENTOS/GET/CATALOGOS_FAIL": {
              actions: ["setError", "unsetLoading"],
            },

            "LIBRETA/SET/EVENTOS": {
              target: "eventos",
            },
            "LIBRETA/SET/REVISION": {
              target: "revision",
            },
            "LIBRETA/SET/ENVIO": {
              target: "envios",
            },
            "LIBRETA/SET/CONFIGURACION": {
              target: "configuracion",
            },
            "LIBRETA/SET/REPORTES": {
              target: "reportes",
            },

            "LIBRETA/EVENTOS/SET/REPORTES": {
              target: "reportes",
            },
            "EVENTACTION": {
              target: "eventos",
            },
            "REVISIONACTION": {
              target: "revision"
            },
            "ENVIOACTION": {
              target: "envios"
            },
            "CONFIGACTION": {
              target: "configuracion"
            },
          }
        },
        reportes: {
          on: {
            "auth/LOGOUT": {
              actions: ["clearAll"],
            },


            "LIBRETA/SET/EVENTOS": {
              target: "eventos",
            },
            "LIBRETA/SET/REVISION": {
              target: "revision",
            },
            "LIBRETA/SET/ENVIO": {
              target: "envios",
            },
            "LIBRETA/SET/CONFIGURACION": {
              target: "configuracion",
            },
            "LIBRETA/SET/REPORTES": {
              target: "reportes",
            },

            "DELETE_INBOX_MAIL_SUCCESS": {
              target: "reportes",
              actions: ["setRefreshReporteEnvioDetalles"],
            }, "LIBRETA/CLEAR/REFRESH/LISTA_STATUS": {
              target: "reportes",
              actions: ["unsetRefreshReporteEnvioDetalles"],
            }, "LIBRETA/REPORTES/SET/FECHA_INICIO": {
              target: "reportes",
              actions: ["set_reportes_fecha_inicio"],
            }, "LIBRETA/REPORTES/SET/FECHA_FINAL": {
              target: "reportes",
              actions: ["set_reportes_fecha_final"],
            },
          },
        },
      }
    }, {
    actions: {
      clearAll: assign({
        alumnos: [],
        displayNotification: { tipo: "", titulo: "", mensaje: "", },
        error: "",
        evento_catalogo_observaciones: [],
        eventos_academia_observacion: "",
        eventos_bath_observacion: "",
        eventos_catalogo_comida_cantidad: [],
        eventos_catalogo_estadosAnimo: [],
        eventos_catalogos: [],
        eventos_comida_observacion: "",
        eventos_diapers_observacion: "",
        eventos_estadoAnimo: -1,
        eventos_EventoRegistrado: -1,
        eventos_fechaHora: new Date().toISOString().split('.')[0],
        eventos_siesta_observacion: "",
        eventos_tipoEvento: "comida",
        fuealBath: false,
        gruposAcademicos: [],
        idCantidad: -1,
        idGrupoAcademicoSelected: -1,
        idLibretaObservacion: -1,
        loading: false,
        modal_backdrop: false,
        modal_envio: false,
        oAlumnoSelected: {},
        oGrupoAcademicoSelected: { "id_Grupo_Academico": 0, "nombre_Grupo_Academico": "" },
        pipi: false,
        popo: false,
        registrosApi: [],
        reporteEnvio: {},
        revision_fecha: new Date(),
        siestaDuracion: "",
        siestaHora: "",
        siestaMinutos: "",
        refreshReporteEnvioDetalles: false,
        reportes_fecha_inicio: '2022-11-01',
        reportes_fecha_final: '2022-12-01',
      }),
      resetModalFormularios: assign({
        idCantidad: -1,
        pipi: false,
        popo: false,
        error: "",
        eventos_tipoEvento: "comida",
        eventos_estadoAnimo: -1,
        eventos_EventoRegistrado: -1,
        displayNotification: { tipo: "", titulo: "", mensaje: "", },
        siestaHora: "",
        siestaMinutos: "",
        siestaDuracion: "",
        fuealBath: false,
        idLibretaObservacion: -1,
        eventos_comida_observacion: "",
        eventos_diapers_observacion: "",
        eventos_siesta_observacion: "",
        eventos_bath_observacion: "",
        eventos_academia_observacion: "",
      }),
      setIdObservacion: assign({
        idLibretaObservacion: (_, event) => {
          return event.payload
        },
      }),
      unsetIdObservacion: assign({
        idLibretaObservacion: (_, event) => {
          return -1
        },
      }),
      setGruposAcademicos: assign({
        gruposAcademicos: (_, event) => {
          return (event.type === "LIBRETA/GET/GRUPOS_ACADEMICOS/SUCCESS"
            ? event.payload
            : null)
        },
      }),
      setAlumnos: assign({
        alumnos: (_, event) => {
          return (event.type === "LIBRETA/GET/ALUMNOS_LIBRETA/SUCCESS"
            ? event.payload
            : null)
        },
      }),
      unsetGruposAcademicos: assign({
        gruposAcademicos: () => [],
      }),
      openModal: assign({
        modal_backdrop: true,
      }),
      closeModal: assign({
        modal_backdrop: false,
      }),
      setGrupoAcademico: assign({
        idGrupoAcademicoSelected: (_, event) => {
          console.log(event)
          return event.payload ? event.payload : -1
        },
      }),
      setAlumnoSelected: assign({
        oAlumnoSelected: (_, event) => {
          return event.payload ? event.payload : {}
        },
      }),
      setTipoEvento: assign({
        eventos_tipoEvento: (_, event) => {
          console.log("event.payload: ", event.payload)
          return event.payload
        },
      }),
      setCatalogoEstadoAnimo: assign({
        eventos_catalogo_estadosAnimo: (_, event) => {
          return event.payload.oListaEstadosAnimo
        },
      }),
      setCatalogoComidaCantidad: assign({
        eventos_catalogo_comida_cantidad: (_, event) => {
          return event.payload.oListaCantidades
        },
      }),
      SetCatalogosObservaciones: assign({
        evento_catalogo_observaciones: (_, event) => {
          const opciones_comida = [{ value: -1, label: "Selecciona..." }]
          const opciones_diapers = [{ value: -1, label: "Selecciona..." }]
          const opciones_siesta = [{ value: -1, label: "Selecciona..." }]
          const opciones_bath = [{ value: -1, label: "Selecciona..." }]
          const opciones_academia = [{ value: -1, label: "Selecciona..." }]
          event.payload.oListaObservaciones.forEach(observa => {
            // console.log("observa.id_Libreta_Area: ", observa.id_Libreta_Area)
            // console.log("observa.borrado: ", observa.borrado)
            switch (observa.id_Libreta_Area) {
              case 1:
                const opcion_comida = { value: observa.id_Libreta_Observacion, label: observa.observacion }
                if (!observa.borrado) { opciones_comida.push(opcion_comida) }
                break;
              case 2:
                const opcion_diapers = { value: observa.id_Libreta_Observacion, label: observa.observacion }
                if (!observa.borrado) { opciones_diapers.push(opcion_diapers) }
                break;
              case 3:
                const opcion_siesta = { value: observa.id_Libreta_Observacion, label: observa.observacion }
                if (!observa.borrado) { opciones_siesta.push(opcion_siesta) }
                break;
              case 4:
                const opcion_bath = { value: observa.id_Libreta_Observacion, label: observa.observacion }
                if (!observa.borrado) { opciones_bath.push(opcion_bath) }
                break;
              case 5:
                const opcion_academia = { value: observa.id_Libreta_Observacion, label: observa.observacion }
                if (!observa.borrado) { opciones_academia.push(opcion_academia) }
                break;

              default:
                break;
            }

          });
          const observaciones = []
          observaciones.push(opciones_comida)
          observaciones.push(opciones_diapers)
          observaciones.push(opciones_siesta)
          observaciones.push(opciones_bath)
          observaciones.push(opciones_academia)
          observaciones.push(event.payload.oListaObservaciones)
          return observaciones
        },
      }),
      unSetCatalogosObservaciones: assign({
        evento_catalogo_observaciones: (_, event) => {
          console.log('unSetCatalogosObservaciones::evento_catalogo_observaciones:[]')
          return []
        },
      }),
      setFechaHora: assign({
        eventos_tipoEvento: (_, event) => {
          return "capturaEvento"
        },
      }),
      setEstadoAnimo: assign({
        eventos_estadoAnimo: (_, event) => {
          console.log("event.payload: ", event.payload)
          return event.payload
        },
      }),
      setError: assign({
        error: (_, event) => {
          return event.payload;
        },
      }),
      unsetError: assign({
        error: null,
      }),
      setLoading: assign({
        loading: true,
      }),
      unsetLoading: assign({
        loading: false,
      }),
      setPipi: assign({ pipi: (_, event) => !_.pipi }),
      unSetPipi: assign({ pipi: false }),
      setPopo: assign({ popo: (_, event) => !_.popo }),
      unSetPopo: assign({ popo: false }),
      setFechaHora: assign({
        eventos_fechaHora: (_, event) => {
          return event.payload
        },
      }),
      setSiestaHora: assign({ siestaHora: (_, event) => event.payload }),
      unSetSiestaHora: assign({ siestaHora: 0 }),
      setSiestaMinutos: assign({ siestaMinutos: (_, event) => event.payload }),
      unSetSiestaMinutos: assign({ siestaMinutos: -1 }),
      setSiestaDuracion: assign({ siestaDuracion: (_, event) => event.payload }),
      unSetSiestaDuracion: assign({ siestaDuracion: 0 }),
      setFuealBano: assign({ fuealBath: (_, event) => !_.fuealBath }),
      setDisplayNotification: assign({
        displayNotification: (_, { payload: { registroInsertado, displayNotification } }) => {
          console.log("setDisplayNotification::registroInsertado/error: ", registroInsertado)
          console.log("setDisplayNotification::displayNotification: ", displayNotification)
          return displayNotification
        }
      }),
      setOnlyDisplayNotification: assign({ displayNotification: (_, event) => event.payload }),
      setReporteEnvio: assign({ reporteEnvio: (_, event) => event.payload }),
      setDisplayReporteEnvio: assign({ modal_envio: true }),
      unsetDisplayReporteEnvio: assign({ modal_envio: false }),
      unsetDisplayNotification: assign({ displayNotification: undefined }),
      setObservacionComida: assign({ eventos_comida_observacion: (_, event) => event.payload }),
      setObservacionDiapers: assign({ eventos_diapers_observacion: (_, event) => event.payload }),
      setObservacionSiesta: assign({ eventos_siesta_observacion: (_, event) => event.payload }),
      setObservacionBath: assign({ eventos_bath_observacion: (_, event) => event.payload }),
      setObservacionAcademia: assign({ eventos_academia_observacion: (_, event) => event.payload }),
      setObservacionAcademia: assign({ eventos_academia_observacion: (_, event) => event.payload }),
      setRegistrosApi: assign({ registrosApi: (_, event) => event.payload }),
      unSetRegistrosApi: assign({ registrosApi: [] }),
      setIdCantidad: assign({ idCantidad: (_, event) => event.payload }),
      setRevisionFecha: assign({ revision_fecha: (_, event) => event.payload }),
      setRevisionFechaFuturo: assign({
        revision_fecha: (_, event) => {
          const fechaTemporal = new Date(_.revision_fecha)
          var fechaTemporal2 = new Date(fechaTemporal.setDate(fechaTemporal.getDate() + 1))
          return fechaTemporal2
        }
      }),
      setRevisionFechaPasado: assign({
        revision_fecha: (_, event) => {
          const fechaTemporal = new Date(_.revision_fecha)
          var fechaTemporal2 = new Date(fechaTemporal.setDate(fechaTemporal.getDate() - 1))
          return fechaTemporal2
        }
      }),
      setoGrupoAcademicoSelected: assign({ oGrupoAcademicoSelected: (_, event) => event.payload }),
      unSetoGrupoAcademicoSelected: assign({ oGrupoAcademicoSelected: { "id_Grupo_Academico": 0, "nombre_Grupo_Academico": "" } }),
      updateRegistrosApi: assign({
        registrosApi: (_, event) => {
          console.log("setEventoRegistrado::registroUpdated: ", event.payload)
          let registroUpdated = event.payload
          let nuevoArreglo = []
          const id_Alumno = registroUpdated.id_Alumno
          console.log("_:", _)
          _.registrosApi.forEach(registro => {
            if (registro.oAlumno.id_Alumno === registroUpdated.id_Alumno) {

              // console.log("registro: ", registro)
              // console.log("registro.oAlumno.id_Alumno: ", registro.oAlumno.id_Alumno)
              // console.log("registroUpdated.id_Alumno: ", registroUpdated.id_Alumno)

              let nuevoRegistro = {}
              nuevoRegistro.oAlumno = registro.oAlumno

              if (registro.hasOwnProperty('comida')) {
                nuevoRegistro.comida = []
                registro.comida.forEach(comida => {
                  if (comida.id_Libreta_Registro_Comida === registroUpdated.id_Libreta_Registro_Comida) {
                    nuevoRegistro.comida.push(registroUpdated)
                  } else {
                    nuevoRegistro.comida.push(comida)
                  }
                })
              }
              if (registro.hasOwnProperty('diapers')) {
                nuevoRegistro.diapers = []
                registro.diapers.forEach(diapers => {
                  if (diapers.id_Libreta_Registro_Diapers === registroUpdated.id_Libreta_Registro_Diapers) {
                    nuevoRegistro.diapers.push(registroUpdated)
                  } else {
                    nuevoRegistro.diapers.push(diapers)
                  }
                })
              }
              if (registro.hasOwnProperty('siesta')) {
                nuevoRegistro.siesta = []
                registro.siesta.forEach(siesta => {
                  if (siesta.id_Libreta_Registro_Siesta === registroUpdated.id_Libreta_Registro_Siesta) {
                    nuevoRegistro.siesta.push(registroUpdated)
                  } else {
                    nuevoRegistro.siesta.push(siesta)
                  }
                })
              }
              if (registro.hasOwnProperty('bath')) {
                nuevoRegistro.bath = []
                registro.bath.forEach(bath => {
                  if (bath.id_Libreta_Registro_Bath === registroUpdated.id_Libreta_Registro_Bath) {
                    nuevoRegistro.bath.push(registroUpdated)
                  } else {
                    nuevoRegistro.bath.push(bath)
                  }
                })
              }
              if (registro.hasOwnProperty('academia')) {
                nuevoRegistro.academia = []
                registro.academia.forEach(academia => {
                  if (academia.id_Libreta_Registro_Academia === registroUpdated.id_Libreta_Registro_Academia) {
                    nuevoRegistro.academia.push(registroUpdated)
                  } else {
                    nuevoRegistro.academia.push(academia)
                  }
                })
              }

              nuevoArreglo.push(nuevoRegistro)
              console.log("nuevoRegistro: ", nuevoRegistro)

            }
            else /* Si el registro no es del alumno, solo lo pasamos */ {
              console.log("registro original: ", registro)
              nuevoArreglo.push(registro)
            }
          });
          console.log("nuevoArreglo: ", nuevoArreglo)
          return nuevoArreglo
        }
      }),
      setRefreshReporteEnvioDetalles: assign({
        refreshReporteEnvioDetalles: true,
      }),
      unsetRefreshReporteEnvioDetalles: assign({
        refreshReporteEnvioDetalles: false,
      }),
      set_reportes_fecha_inicio: assign({
        reportes_fecha_inicio: (_, event) => event.payload,
      }),
      set_reportes_fecha_final: assign({
        reportes_fecha_inicio: (_, event) => event.payload,
      }),
    }
  }

  );

export default libretaMachine;

