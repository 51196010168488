import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

// Login Redux States
import { AUTH_LOGIN, AUTH_LOGIN_ERROR, AUTH_LOGOUT, AUTH_SOCIAL_LOGIN } from "./actionTypes"
import { authLoginSuccess, authLoginFail, authLogoutUserSuccess } from "./actions"
import { useAuthUser} from "./selectors"

//Include Both Helper File with needed methods
import { getFirebaseBackend } from "../../../helpers/firebase_helper"
import {
  postFakeLogin,
  postJwtLogin,
  postSocialLogin,
} from "../../../helpers/comunicabackend_helper"

const fireBaseBackend = getFirebaseBackend()

function* authLoginUser({ payload: { user, history } }) {
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const response = yield call(
        fireBaseBackend.loginUser,
        user.email,
        user.password
      )
      yield put(authLoginSuccess(response))
    } else if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
      try {
        // Si queremos todo normal, hace la llamada
        const response = yield call(postJwtLogin, {
          id_Contacto: "-1",
          Descripcion_Bitacora: "Post Login",
          Datos: {},
          Parametros: [
            {
              nombre: "provider",
              valor: "username-password",
            },
            {
              nombre: "id_colegio",
              valor: "7",
            },
            {
              nombre: "username",
              valor: user.email,
            },
            {
              nombre: "password",
              valor: Buffer.from(user.password).toString("base64"),
            },
            {
              nombre: "appversion",
              valor: "",
            },
            {
              nombre: "device_token",
              valor: "",
            },
            {
              nombre: "GMT_Offset",
              valor: (new Date().getTimezoneOffset())/-60,
            },
          ],
        })
        console.log(response);
        yield put(authLoginSuccess(response.datos))
        localStorage.setItem("authUser", JSON.stringify(response.datos))
        // Si queremos poner algo fijo, 
      //   const response_datos = {
      //     "status": "success",
      //     "requestedAt": "2022-09-22T11:43:52.395042-05:00",
      //     "results": 0,
      //     "totalRowsCount": 0,
      //     "pageSize": 0,
      //     "currentPage": 0,
      //     "datos": {
      //         "idColegio": 7,
      //         "idPersona": 32341,
      //         "idContacto": 31094,
      //         "uid": 31094,
      //         "campo1": "Papá",
      //         "displayName": "Sanchez Medina Edmundo",
      //         "email": "edmundo.sanchez@gmail.com",
      //         "photoURL": "",
      //         "accessToken": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJDb211bmlrYSIsImlhdCI6IjE2NjM4NjUwMzMiLCJleHAiOiIxNjY2NDU3MDMzIiwiaWRfY29udGFjdG8iOiIzMTA5NCIsImVtYWlsIjoiZWRtdW5kby5zYW5jaGV6QGdtYWlsLmNvbSIsImdtdCI6Ii02IiwiaWRfY29sZWdpbyI6IjcifQ.dNnNQClNVg4Cdmk0Ub34_qSxOLvq8PiakksNa-dM4RI",
      //         "username": "Sanchez Medina Edmundo",
      //         "roles": []
      //     },
      //     "parametros": null
      // };
      //   yield put(authLoginSuccess(response_datos))
      //   localStorage.setItem("authUser", JSON.stringify(response_datos))
      } catch (error) {
        // console.log("postJwtLogin::loginUser::error: " + error)
        // console.log(error.response.data.mensaje_Detalle)
        const mensaje = (error.response ? error.response.data ? error.response.data.mensaje_Detalle ? error.response.data.mensaje_Detalle : error.message : error.message : error.message)
        yield put(authLoginFail(mensaje))
      }
    } 
    history.push("/libreta")
  } catch (error) {
    console.log("loginUser::catch::general::error: " + error)
    yield put(authLoginFail(error.mensaje_Detalle ? error.mensaje_Detalle : error))
  }
}

function* authLogoutUser({ payload: { history } }) {
  try {
    localStorage.removeItem("authUser")

    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const response = yield call(fireBaseBackend.logout)
      yield put(authLogoutUserSuccess(response))
    }
    history.push("/login")
  } catch (error) {
    yield put(authLoginFail(error))
  }
}

function* authSocialLogin({ payload: { data, history, type } }) {
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const response = yield call(
        fireBaseBackend.loginUser,
        user.email,
        user.password
      )
      yield put(authLoginSuccess(response))
    } else {
      const response = yield call(postSocialLogin, data)
      // console.log(
      //   "saga.js::socialLogin::response.datos::" +
      //     JSON.stringify(response.datos)
      // );
      localStorage.setItem("authUser", JSON.stringify(response.datos))
      yield put(authLoginSuccess(response.datos))
    }
    history.push("/libreta")
  } catch (error) {
    yield put(authLoginFail(error))
  }
}

function* AuthxStateSaga() {
  yield takeEvery(AUTH_LOGIN, authLoginUser)
  yield takeEvery(AUTH_LOGIN_ERROR, authLoginFail)
  yield takeLatest(AUTH_SOCIAL_LOGIN, authSocialLogin)
  yield takeEvery(AUTH_LOGOUT, authLogoutUser)
}

export default AuthxStateSaga
