import { isPropsEqual } from "@fullcalendar/core"
import React, { useState } from "react"
import {
  Button,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"

const EmailToolbar = props => {
  const [folder_Menu, setfolder_Menu] = useState(false)
  const [tag_Menu, settag_Menu] = useState(false)
  const [more_Menu, setmore_Menu] = useState(false)

  let barraHerramientas = ""

  if (props.activeTab === "7") {
    barraHerramientas = (
      <React.Fragment>
        <div className="btn-toolbar p-3" role="toolbar">
          <div className="btn-group me-2 mb-2 mb-sm-0 border">
            <Button
              type="button"
              color="primary"
              onClick={props.openInboxHanlder}
            >
              <i className="fa fa-inbox" />
            </Button>
            <Button
              type="button"
              color="primary"
              onClick={props.onDeleteCurrentEmailHanlder}
            >
              <i className="far fa-trash-alt" />
            </Button>
            <div className="">
              Showing 1 of {props.datainbox.totalmails}{" "}
              <div className="btn-group">
                <Button
                  type="button"
                  color="success"
                  size="sm"
                  onClick={props.onPageDecrementHandler}
                  className={props.datainbox.currentpage == 1 ? "disabled" : ""}
                >
                  <i className="fa fa-chevron-left" />
                </Button>
                <Button
                  type="button"
                  color="success"
                  size="sm"
                  onClick={props.onPageIncrementHandler}
                  className={
                    props.datainbox.pagesize * props.datainbox.currentpage >=
                    props.datainbox.totalmails
                      ? "disabled"
                      : ""
                  }
                >
                  <i className="fa fa-chevron-right" />
                </Button>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  } else {
    barraHerramientas = (
      <React.Fragment>
        <div className="btn-toolbar p-3" role="toolbar">
          <div className="btn-group me-2 mb-2 mb-sm-0">
            <Button
              type="button"
              color="primary"
              onClick={props.openInboxHanlder}
            >
              <i className="fa fa-inbox" />
            </Button>
          </div>

          {/* <Dropdown
            isOpen={folder_Menu}
            toggle={() => {
              setfolder_Menu(!folder_Menu)
            }}
            className="btn-group me-2 mb-2 mb-sm-0"
          >
            <DropdownToggle
              className="btn btn-primary  dropdown-toggle"
              tag="i"
            >
              <i className="fa fa-folder" />{" "}
              <i className="mdi mdi-chevron-down ms-1" />
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-end">
              <DropdownItem to="#">Updates</DropdownItem>
              <DropdownItem to="#">Social</DropdownItem>
              <DropdownItem to="#">Team Manage</DropdownItem>
            </DropdownMenu>
          </Dropdown>
          <Dropdown
            isOpen={tag_Menu}
            toggle={() => {
              settag_Menu(!tag_Menu)
            }}
            className="btn-group me-2 mb-2 mb-sm-0"
          >
            <DropdownToggle
              className="btn btn-primary  dropdown-toggle"
              tag="i"
            >
              <i className="fa fa-tag" />{" "}
              <i className="mdi mdi-chevron-down ms-1" />
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-end">
              <DropdownItem to="#">Updates</DropdownItem>
              <DropdownItem to="#">Social</DropdownItem>
              <DropdownItem to="#">Team Manage</DropdownItem>
            </DropdownMenu>
          </Dropdown> */}
          <Dropdown
            isOpen={more_Menu}
            toggle={() => {
              setmore_Menu(!more_Menu)
            }}
            className="btn-group me-2 mb-2 mb-sm-0"
          >
            <DropdownToggle
              className="btn btn-primary  dropdown-toggle"
              tag="div"
            >
              More <i className="mdi mdi-dots-vertical ms-2" />
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-end">
              <DropdownItem to="#">Mark as Unread</DropdownItem>
              <DropdownItem to="#">Mark as Important</DropdownItem>
              <DropdownItem to="#">Add to Tasks</DropdownItem>
              <DropdownItem to="#">Add Star</DropdownItem>
              <DropdownItem to="#">Mute</DropdownItem>
            </DropdownMenu>
          </Dropdown>
          <Button type="button" color="primary" onClick={props.onRefreshInbox}>
            {/* <span className="spinner-border spinner-border-sm"></span> */}
            <i className="mdi mdi-refresh"></i> Refresh
          </Button>
        </div>
      </React.Fragment>
    )
  }

  return barraHerramientas
}

export default EmailToolbar
