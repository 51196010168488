import React, { useState, useEffect } from 'react';
import classnames from 'classnames';
import { useFormik } from 'formik';
import avatar1 from '../../assets/images/users/avatar-1.jpg'
import { getoSolicitudBase } from "../../helpers/comunicabackend_helper"
import { useDispatch } from "react-redux"
import PropTypes from "prop-types"
import { isEqual } from "lodash"

import { getFamilias as onGetFamilias, } from "../../store/familias/actions"
import { useFamilias, useFamiliasOptions } from "../../store/familias/selectors"

import { getGruposAcademicos as onGetGruposAcademicos, } from "../../store/grupos-academicos/actions"
import { useGruposAcademicos, useGruposAcademicosOptions } from "../../store/grupos-academicos/selectors"

import { getContactos as onGetContactos, } from "../../store/contacts/actions"
import { useContactos } from "../../store/contacts/selectors"

import { addNewAlumno as onAddNewAlumno } from "../../store/alumnos/actions"

import {
    Col,
    Form,
    FormFeedback,
    FormGroup,
    Input,
    Label,
    Modal,
    Nav,
    NavItem,
    NavLink,
    Row,
    TabContent,
    TabPane,
    Tooltip,
} from 'reactstrap';

import Select from "react-select";

const Formulario_Alta_Alumno = (props) => {

    const dispatch = useDispatch()

    const init_oAlumno = {

        hermano: false,
        grupoAcademico: { value: -1, label: 'Selecciona...' },
    
        oAlumno_id_Alumno: -1,
        oAlumno_id_Familia: -1,
        oAlumno_nombres: "",
        oAlumno_apellido_Paterno: "",
        oAlumno_apellido_Materno: "",
        oAlumno_fecha_Nacimiento: "",
        oAlumno_url_Fotografia: "",
        
        oContacto_Madre_id_Contacto: -1,
        oContacto_Madre_nombres: "",
        oContacto_Madre_apellido_Paterno: "",
        oContacto_Madre_apellido_Materno: "",
        oContacto_Madre_fecha_Nacimiento: "",
        oContacto_Madre_celular: "",
        oContacto_Madre_email: "",
        oContacto_Madre_token3: "",
        oContacto_Madre_url_Fotografia: "",

        oContacto_Padre_id_Contacto: -1,
        oContacto_Padre_nombres: "",
        oContacto_Padre_apellido_Paterno: "",
        oContacto_Padre_apellido_Materno: "",
        oContacto_Padre_fecha_Nacimiento: "",
        oContacto_Padre_celular: "",
        oContacto_Padre_email: "",
        oContacto_Padre_token3: "",
        oContacto_Padre_url_Fotografia: "",

      }


    /* #region  Constantes - UseState() */
    //const [formValues, setFormValues] = useState(savedValues)
    const [activeTab, setActiveTab] = useState("alumno")
    const [foto_alumno, setFotoAlumno] = useState(null)
    const [foto_mama, setFotoMama] = useState(null)
    const [foto_papa, setFotoPapa] = useState(null)
    //const [isEditAlumno, setIsEditAlumno] = useState(props.isEdit)

    const [ttoverPhone, setTToverPhone] = useState(false);
    const [ttoverEmail, setTToverEmail] = useState(false);

    const familias = useFamilias()
    const familiaOptions = useFamiliasOptions()
    const contactos = useContactos()
    const gruposAcademicos = useGruposAcademicos()
    const gruposAcademicoOptions = useGruposAcademicosOptions()
    /* #endregion */

    /* #region  Misc switchTab */
    const switchTab = tab => {
        if (activeTab !== tab) {
            setActiveTab(tab);
        }
    }

    const getFamiliaById = (id) => {
        if (id > 0 && familias && familias.length) {
            const familiaSelected = familias.filter(fam => fam.id_Familia == id)
            if (familiaSelected && familiaSelected.length === 1) {
                return familiaSelected[0]
            } else {
                return null
            }
        } else {
            return null
        }
    }

    const esRepetidoCelular = (mobile) => {
        const items = contactos.filter((x) => x.celular == mobile)
        return items && items.length ? items[0] : null
    }

    const esRepetidoCorreo = (email) => {
        const items = contactos.filter((x) => x.email == email)
        return items && items.length ? items[0] : null
    }
    /* #endregion */

    /* #region  Formik */

    const validateEmail = (email) => {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    };

    const validateSoloNumeros = (str) => {
        if (str && str.length > 0) {
            return /^\d+$/.test(str);
        }
        else {
            return false
        }
    }

    const regresaSoloNumeros = (str) => {
        if (str && str.length > 0) {
            return str.replace(/\D/g, "")
        }
        else {
            return ''
        }
    }

    const normalizar = (str) => {
        if (str && str.length > 0) {
            const parsed = str.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
            return parsed
        }
        else {
            return str
        }
        //console.log('str -> ', parsed)

    }

    const validate = values => {

        console.log('----------------------------')
        console.log('Corriendo validate!')
        console.log('Form values', values)
        console.log('----------------------------')

        let errors = {}

        // Campos de oAlumno

        if (!values.oAlumno_nombres) { errors.oAlumno_nombres = 'Campo Requerido!' }
        if (!values.oAlumno_apellido_Paterno) { errors.oAlumno_apellido_Paterno = 'Campo Requerido!' }
        if (values.oAlumno_fecha_Nacimiento == new Date().toISOString()) { errors.oAlumno_fecha_Nacimiento = 'Campo Requerido!' }

        if (values.grupoAcademico.value == -1) { errors.grupoAcademico = 'Campo Requerido!' }

        // // Hermano ya registrado
        if (values.hermano) {
            // Familia
            // if (values.familia.value == -1) {
            //     { errors.familia = 'Campo Requerido!' }
            // }
        } else {
            // Mamá
            if (!values.sin_mama) {

                // //console.log('Corriendo validate! con_mama')
                if (!values.oContacto_Madre_nombres) { errors.oContacto_Madre_nombres = 'Campo Requerido!' }
                if (!values.oContacto_Madre_apellido_Paterno) { errors.oContacto_Madre_apellido_Paterno = 'Campo Requerido!' }
                if (!values.oContacto_Madre_celular) { errors.oContacto_Madre_celular = 'Campo Requerido!' }
                else {
                    if (values.oContacto_Madre_celular.length != 10) {
                        errors.oContacto_Madre_celular = 'El celular debe contener diez digitos!'
                    }
                    else {
                        if (!validateSoloNumeros(values.oContacto_Madre_celular)) {
                            errors.oContacto_Madre_celular = 'El celular debe contener solo digitos!'
                        } else {
                            const oContacto = esRepetidoCelular(values.oContacto_Madre_celular)
                            console.log('oContacto: ', oContacto)
                            if (oContacto && oContacto.id_Contacto != values.oContacto_Madre_id_Contacto) { errors.oContacto_Madre_celular = 'Repetido!, asignado a: ' + oContacto.nombres + ' ' + oContacto.celular }
                            else {
                                if (values.oContacto_Madre_celular === values.oContacto_Padre_celular) {
                                    errors.oContacto_Madre_celular = 'Mamá y Papá deben tener número de celulares diferentes.'
                                }
                            }
                        }
                    }
                }
                if (!values.oContacto_Madre_email) { errors.oContacto_Madre_email = 'Campo Requerido!' } else {
                    if (!validateEmail(values.oContacto_Madre_email)) { errors.oContacto_Madre_email = 'El formato de email no es válido' }
                    else {
                        const oContacto = esRepetidoCorreo(values.oContacto_Madre_email)
                        if (oContacto && oContacto.id_Contacto != values.oContacto_Madre_id_Contacto) { errors.oContacto_Madre_email = 'Repetido!, asignado a: ' + oContacto.nombres + ' ' + oContacto.apellido_Paterno }
                        else {
                            if (values.oContacto_Madre_email === values.oContacto_Padre_email) {
                                errors.oContacto_Madre_email = 'Mamá y Papá deben tener emails diferentes.'
                            }
                        }
                    }
                }
                if (values.oContacto_Madre_fecha_Nacimiento == new Date().toISOString()) { errors.oContacto_Madre_fecha_Nacimiento = 'Campo Requerido!' }
            }

            // // Papá
            if (!values.sin_papa) {
                //console.log('Corriendo validate! con_papa')
                // oContacto_Padre_nombres: "",
                // oContacto_Padre_apellido_Paterno: "",
                // oContacto_Padre_apellido_Materno: "",
                // oContacto_Padre_fecha_Nacimiento: "",
                // oContacto_Padre_celular: "",
                // oContacto_Padre_email: "",
                // oContacto_Padre_url_Fotografia: "",

                if (!values.oContacto_Padre_nombres) { errors.oContacto_Padre_nombres = 'Campo Requerido!' }
                if (!values.oContacto_Padre_apellido_Paterno) { errors.oContacto_Padre_apellido_Paterno = 'Campo Requerido!' }
                if (!values.oContacto_Padre_celular) { errors.oContacto_Padre_celular = 'Campo Requerido!' }
                else {
                    if (values.oContacto_Padre_celular.length != 10) {
                        errors.oContacto_Padre_celular = 'El celular debe contener diez digitos!'
                    }
                    else {
                        if (!validateSoloNumeros(values.oContacto_Padre_celular)) {
                            errors.oContacto_Padre_celular = 'El celular debe contener solo digitos!'
                        } else {
                            const oContacto = esRepetidoCelular(values.oContacto_Padre_celular)
                            console.log('oContacto: ', oContacto)
                            if (oContacto && oContacto.id_Contacto != values.oContacto_Padre_id_Contacto) { 
                                errors.oContacto_Padre_celular = 'Repetido!, asignado a: ' + oContacto.nombres + ' ' + oContacto.celular }
                            else {
                                if (values.oContacto_Padre_celular === values.oContacto_Madre_celular) {
                                    errors.oContacto_Padre_celular = 'Mamá y Papá deben tener número de celulares diferentes.'
                                }
                            }
                        }
                    }
                }
                if (!values.oContacto_Padre_email) { errors.oContacto_Padre_email = 'Campo Requerido!' } else {
                    if (!validateEmail(values.oContacto_Padre_email)) { errors.oContacto_Padre_email = 'El formato de email no es válido' }
                    else {
                        const oContacto = esRepetidoCorreo(values.oContacto_Padre_email)
                        if (oContacto && oContacto.id_Contacto != values.oContacto_Padre_id_Contacto) { errors.oContacto_Padre_email = 'Repetido!, asignado a: ' + oContacto.nombres + ' ' + oContacto.apellido_Paterno }
                        else {
                            if (values.oContacto_Padre_email === values.oContacto_Madre_email) {
                                errors.oContacto_Padre_email = 'Mamá y Papá deben tener emails diferentes.'
                            }
                        }
                    }
                }
                if (values.oContacto_Padre_fecha_Nacimiento == new Date().toISOString()) { errors.oContacto_Padre_fecha_Nacimiento = 'Campo Requerido!' }
            }
        }

        return errors
    }

    const onSubmit = values => {

        console.log("***************************************")
        console.log("onSubmit::values", values)

        if (foto_alumno) { values.foto = foto_alumno }
        if (foto_mama) { values.mama_foto = foto_alumno }
        if (foto_papa) { values.papa_foto = foto_alumno }

        const oSolicitudBase = getoSolicitudBase(
            "Alta-Alumno::index::onSubmit",
            "onSubmit",
            [],
            []
        )
        oSolicitudBase.Datos = values
        dispatch(onAddNewAlumno(oSolicitudBase))
    }
    
    
    const formik = useFormik({
        initialValues: init_oAlumno, //props.oAlumno,
        validate,
        onSubmit,
        //validateOnMount: true, // Super importante esta linea para que corra validate, y regrese siempre la estructura de errores, si no, regresa errors vacio
        enableReinitialize: true
    })
    /* #endregion */

    /* #region  Fotos */
    const readFile = (event) => {

        // console.log('readfile', event)
        // console.log(event.target.id)
        // console.log(event.target.files)

        const file = event.target.files[0]

        getBase64(file)
            .then(result => {
                //console.log('base64', result)
                switch (event.target.id) {
                    case 'foto':
                        setFotoAlumno(result)
                        break;
                    case 'mama_foto':
                        setFotoMama(result)
                        break;
                    case 'papa_foto':
                        setFotoPapa(result)
                        break;

                    default:
                        break;
                }
            })
            .catch(err => {
                console.log(err);
            })
    }

    const getBase64 = (file) => {
        return new Promise(resolve => {
            let fileInfo;
            let baseURL = "";
            // Make new FileReader
            let reader = new FileReader();

            // Convert the file to base64 text
            reader.readAsDataURL(file);

            // on reader load somthing...
            reader.onload = () => {
                // Make a fileInfo Object
                //console.log("Called", reader);
                baseURL = reader.result;
                //console.log(baseURL);
                resolve(baseURL);
            };
            //console.log(fileInfo);
        });
    }
    /* #endregion */

    /* #region  useEffects */
    // Familias
    useEffect(() => {
        const oSolicitudBase = getoSolicitudBase(
            "AltaAlumno::index::useEffect",
            "onGetFamilias",
            [],
            []
        )
        if (familias && !familias.length && formik.values.hermano) {
            dispatch(onGetFamilias(oSolicitudBase))
        }
    }, [dispatch, familias, formik.values.hermano])

    // Grupos Academicos
    useEffect(() => {
        const oSolicitudBase = getoSolicitudBase(
            "AltaAlumno::index::useEffect",
            "onGetGruposAcademicos",
            [],
            []
        )
        if (gruposAcademicos && !gruposAcademicos.length && props.isOpen) {
            dispatch(onGetGruposAcademicos(oSolicitudBase))
        }
    }, [dispatch, gruposAcademicos, props.isOpen])

    // Contactos solo email y celular
    useEffect(() => {
        const oSolicitudBase = getoSolicitudBase(
            "AltaAlumno::index::useEffect",
            "onGetContactos",
            [],
            [{ nombre: "TipoConsulta", valor: "SoloEmailCelular" }]
        )
        if (contactos && !contactos.length && props.isOpen) {
            dispatch(onGetContactos(oSolicitudBase))
        }
    }, [dispatch, contactos, props.isOpen])
    /* #endregion */

    // console.log('props', props)
    // console.log('modal_backdrop:', props.isOpen)
    console.log('* Form values', formik.values)
    console.log('* Form touched', formik.touched)
    console.log('* Formulario::Form errors', formik.errors)

    return (
        <React.Fragment>

            <Modal
                isOpen={props.isOpen}
                toggle={() => {
                    props.fnToggle();
                }}
                backdrop={'static'}
                id="staticBackdrop"
            >
                <Form
                    onSubmit={formik.handleSubmit}
                    className="needs-validation" >

                    <div className="modal-header">
                        <h5 className="modal-title" id="staticBackdropLabel">{props.isNew ? 'Alta Nuevo Alumno' : 'Editar Alumno'}</h5>
                        <button type="button" className="btn-close"
                            onClick={() => {
                                props.fnToggle();
                            }} aria-label="Close"></button>
                    </div>
                    <div className="modal-body">

                        {/* #region  Tab Para navegar */}
                        <Nav tabs className="nav-tabs-custom nav-justified mb-2">
                            <NavItem>
                                <NavLink
                                    style={{ cursor: "pointer" }}
                                    className={classnames({
                                        active: activeTab === "alumno",
                                    })}
                                    onClick={() => {
                                        switchTab("alumno");
                                    }}
                                >
                                    <span className="d-block d-sm-none">
                                        <i className="fas fa-home"></i>
                                    </span>
                                    <span className="d-none d-sm-block">Alumno</span>
                                </NavLink>
                            </NavItem>
                            {!formik.values.hermano ? (
                                <>
                                    <NavItem>
                                        <NavLink
                                            style={{ cursor: "pointer" }}
                                            className={classnames({
                                                active: activeTab === "mama",
                                            })}
                                            onClick={() => {
                                                switchTab("mama");
                                            }}
                                        >
                                            <span className="d-block d-sm-none">
                                                <i className="far fa-user"></i>
                                            </span>
                                            <span className="d-none d-sm-block">Mamá</span>
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            style={{ cursor: "pointer" }}
                                            className={classnames({
                                                active: activeTab === "papa",
                                            })}
                                            onClick={() => {
                                                switchTab("papa");
                                            }}
                                        >
                                            <span className="d-block d-sm-none">
                                                <i className="far fa-envelope"></i>
                                            </span>
                                            <span className="d-none d-sm-block">Papá</span>
                                        </NavLink>
                                    </NavItem>
                                </>
                            ) :
                                (
                                    <NavItem>
                                        <NavLink
                                            style={{ cursor: "pointer" }}
                                            className={classnames({
                                                active: activeTab === "familia",
                                            })}
                                            onClick={() => {
                                                switchTab("familia");
                                            }}
                                        >
                                            <span className="d-block d-sm-none">
                                                <i className="fas fa-cog"></i>
                                            </span>
                                            <span className="d-none d-sm-block">Familia</span>
                                        </NavLink>
                                    </NavItem>
                                )
                            }

                        </Nav>
                        {/* #endregion */}

                        {/* #region  Tabs de contenido */}
                        <TabContent
                            activeTab={activeTab}
                            className="p-3 text-muted"
                        >
                            <TabPane tabId="alumno">

                                {/* #region  Formulario Fotografia */}
                                <Row>
                                    <Col xs={9}>
                                        <div className='mb-3'>
                                            <Label htmlFor='foto'>Foto</Label>
                                            <Input
                                                type='file'
                                                className='form-control'
                                                id='foto'
                                                name='foto'
                                                onChange={readFile}
                                                accept=".jpg, .jpeg, .png"
                                            />
                                        </div>

                                    </Col>
                                    <Col xs={3}>
                                        <div className='text-center'>
                                            <img className='rounded avatar-md m-2'
                                                src={props.isNew ? foto_alumno ? foto_alumno : avatar1 : formik.values.url_Fotografia ? formik.values.url_Fotografia : foto_alumno ? foto_alumno : avatar1}
                                                alt='Generic placeholder image' />
                                        </div>
                                    </Col>
                                </Row>
                                {/* #endregion */}

                                {/* #region  Nombre - Apellido Paterno */}
                                <Row>
                                    <Col xs={6}>
                                        <FormGroup className="mb-3">
                                            <Label htmlFor="oAlumno_nombres">Nombre</Label>
                                            <Input
                                                type="text"
                                                className="form-control"
                                                id="oAlumno_nombres"
                                                placeholder="Nombre"
                                                name='oAlumno_nombres'
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={normalizar(formik.values.oAlumno_nombres) || ""}
                                                invalid={formik.touched.oAlumno_nombres && formik.errors.oAlumno_nombres ? true : false}
                                            />
                                            {formik.touched.oAlumno_nombres && formik.errors.oAlumno_nombres ? (
                                                <FormFeedback type="invalid">{formik.errors.oAlumno_nombres}</FormFeedback>
                                            ) : null}
                                        </FormGroup>
                                    </Col>
                                    <Col xs={6}>
                                        <FormGroup className="mb-3">
                                            <Label htmlFor='oAlumno_apellido_Paterno'>Apellido Paterno</Label>
                                            <Input
                                                type='text'
                                                id='oAlumno_apellido_Paterno'
                                                name='oAlumno_apellido_Paterno'
                                                placeholder='Apellido Paterno'
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={normalizar(formik.values.oAlumno_apellido_Paterno) || ""}
                                                invalid={formik.touched.oAlumno_apellido_Paterno && formik.errors.oAlumno_apellido_Paterno ? true : false}
                                            />
                                            {formik.touched.oAlumno_apellido_Paterno && formik.errors.oAlumno_apellido_Paterno ? (
                                                <FormFeedback type="invalid">{formik.errors.oAlumno_apellido_Paterno}</FormFeedback>
                                            ) : null}
                                        </FormGroup>
                                    </Col>
                                </Row>
                                {/* #endregion */}

                                {/* #region  Apellido Materno - Fecha de nacimiento */}
                                <Row>
                                    <Col xs={6}>
                                        <FormGroup className="mb-3">
                                            <Label htmlFor='oAlumno_apellido_Materno'>Apellido Materno</Label>
                                            <Input
                                                type='text'
                                                id='oAlumno_apellido_Materno'
                                                placeholder='Apellido Materno'
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={normalizar(formik.values.oAlumno_apellido_Materno) || ""}
                                                invalid={formik.touched.oAlumno_apellido_Materno && formik.errors.oAlumno_apellido_Materno ? true : false}
                                            />
                                            {formik.touched.oAlumno_apellido_Materno && formik.errors.oAlumno_apellido_Materno ? (
                                                <FormFeedback type="invalid">{formik.errors.oAlumno_apellido_Materno}</FormFeedback>
                                            ) : null}
                                        </FormGroup>
                                    </Col>
                                    <Col xs={6}>
                                        <FormGroup className="mb-3">
                                            <Label htmlFor='oAlumno_fecha_Nacimiento'>Fecha Nacimiento M/D/YY</Label>
                                            <Input
                                                type='date'
                                                id='oAlumno_fecha_Nacimiento'
                                                name='oAlumno_fecha_Nacimiento'
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.oAlumno_fecha_Nacimiento}
                                                invalid={formik.touched.oAlumno_fecha_Nacimiento && formik.errors.oAlumno_fecha_Nacimiento ? true : false}
                                            />
                                            {formik.touched.oAlumno_fecha_Nacimiento && formik.errors.oAlumno_fecha_Nacimiento ? (
                                                <FormFeedback type="invalid">{formik.errors.oAlumno_fecha_Nacimiento}</FormFeedback>
                                            ) : null}
                                        </FormGroup>
                                    </Col>
                                </Row>
                                {/* #endregion */}

                                {/* #region  Tengo un hermano en el colegio - Grupo Académico*/}
                                <Row className=''>
                                    <Col xs={6} className='d-flex align-items-end'>
                                        {props.isNew ? (
                                            <div className='form-check'>
                                                <Input
                                                    type='checkbox'
                                                    className='form-check-Input'
                                                    id='hermano'
                                                    name='hermano'
                                                    onChange={formik.handleChange}
                                                    value={formik.values.hermano}
                                                />
                                                <Label
                                                    className='form-check-Label'
                                                    htmlFor='hermano'
                                                >
                                                    Ya tengo un hermano registrado.
                                                </Label>
                                            </div>
                                        ) : null}
                                    </Col>
                                    <Col xs={6}>
                                        {props.isNew ? (<FormGroup className="mb-3">

                                            <Label htmlFor='grupoAcademico'>Grupo Académico</Label>
                                            <Select
                                                id='grupoAcademico'
                                                name='grupos[0].nombre_Grupo_Academico'
                                                // className='is-invalid' //Quien controla el mensaje rojo es errors.grupoacademico
                                                options={gruposAcademicoOptions}
                                                value={formik.values.grupoAcademico}
                                                onChange={(grupoAcademico) => formik.setFieldValue('grupoAcademico', grupoAcademico)}
                                                onBlur={formik.handleBlur}
                                                //invalid={false}
                                                styles={{
                                                    control: (baseStyles, state) => ({
                                                        ...baseStyles,
                                                        borderColor: formik.errors.grupoAcademico ? 'red' : 'gray',
                                                    }),
                                                }}
                                            />
                                            {formik.errors.grupoAcademico ? (
                                                <FormFeedback type="invalid">{formik.errors.grupoAcademico}</FormFeedback>
                                            ) : null}
                                        </FormGroup>) : null}

                                    </Col>
                                </Row>

                                {/* #endregion */}
                            </TabPane>

                            <TabPane tabId="mama">

                                <div className='form-check'>
                                    <Input
                                        type='checkbox'
                                        className='form-check-Input'
                                        id='sin_mama'
                                        name='sin_mama'
                                        onChange={formik.handleChange}
                                        value={formik.values.sin_mama}
                                    />
                                    <Label
                                        className='form-check-Label'
                                        htmlFor='sin_mama'
                                    >
                                        Sin registro de mamá.
                                    </Label>
                                </div>

                                {!formik.values.sin_mama ? (<>
                                    {/* #region  Formulario Fotografia */}
                                    <Row>
                                        <Col xs={9}>
                                            <div className='mb-3'>
                                                <Label htmlFor='mama_foto'>Foto</Label>
                                                <Input
                                                    type='file'
                                                    className='form-control'
                                                    id='mama_foto'
                                                    name='mama_foto'
                                                    onChange={readFile}
                                                    accept=".jpg, .jpeg, .png"
                                                />
                                            </div>
                                        </Col>
                                        <Col xs={3}>
                                            <div className='text-center'>
                                                <img className='rounded avatar-md m-2'
                                                    src={props.isNew ?
                                                        foto_mama ? foto_mama : avatar1 : formik.values.oContacto_Madre_url_Fotografia ?
                                                            formik.values.oContacto_Madre_url_Fotografia : foto_mama ?
                                                                foto_mama : avatar1}
                                                    alt='Generic placeholder image' />
                                            </div>

                                        </Col>
                                    </Row>
                                    {/* #endregion */}

                                    {/* #region  Nombre Apellido Paterno */}
                                    <Row>
                                        <Col xs={6}>
                                            <FormGroup className="mb-3">
                                                <Label htmlFor="oContacto_Madre_nombres">Nombre</Label>
                                                <Input
                                                    type="text"
                                                    className="form-control"
                                                    id="oContacto_Madre_nombres"
                                                    placeholder="Nombre"
                                                    name='oContacto_Madre_nombres'
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={normalizar(formik.values.oContacto_Madre_nombres) || ""}
                                                    invalid={
                                                        formik.touched.oContacto_Madre_nombres &&
                                                        formik.errors.oContacto_Madre_nombres ? true : false}
                                                />
                                                {
                                                    formik.touched.oContacto_Madre_nombres &&
                                                    formik.errors.oContacto_Madre_nombres ? (
                                                    <FormFeedback type="invalid">{formik.errors.oContacto_Madre_nombres}</FormFeedback>
                                                ) : null}
                                            </FormGroup>
                                        </Col>
                                        <Col xs={6}>
                                            <FormGroup className="mb-3">
                                                <Label htmlFor='oContacto_Madre_apellido_Paterno'>Apellido Paterno</Label>
                                                <Input
                                                    type='text'
                                                    id='oContacto_Madre_apellido_Paterno'
                                                    name='oContacto_Madre_apellido_Paterno'
                                                    placeholder='Apellido Paterno'
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={normalizar(formik.values.oContacto_Madre_apellido_Paterno) || ""}
                                                    invalid={
                                                        formik.touched.oContacto_Madre_apellido_Paterno &&
                                                        formik.errors.oContacto_Madre_apellido_Paterno ? true : false}
                                                />
                                                {
                                                    formik.touched.oContacto_Madre_apellido_Paterno &&
                                                    formik.errors.oContacto_Madre_apellido_Paterno ? (
                                                    <FormFeedback type="invalid">{ formik.errors.oContacto_Madre_apellido_Paterno}</FormFeedback>
                                                ) : null}
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    {/* #endregion */}

                                    {/* #region  Apellido Materno - Fecha de nacimiento */}
                                    <Row>
                                        <Col xs={6}>
                                            <FormGroup className="mb-3">
                                                <Label htmlFor='oContacto_Madre_apellido_Materno'>Apellido Materno</Label>
                                                <Input
                                                    type='text'
                                                    id='oContacto_Madre_apellido_Materno'
                                                    placeholder='Apellido Materno'
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={normalizar(formik.values.oContacto_Madre_apellido_Materno) || ""}
                                                    invalid={formik.touched.oContacto_Madre_apellido_Materno &&
                                                        formik.errors.oContacto_Madre_apellido_Materno ? true : false}
                                                />
                                                {
                                                    formik.touched.oContacto_Madre_apellido_Materno &&
                                                    formik.errors.oContacto_Madre_apellido_Materno ? (
                                                    <FormFeedback type="invalid">{formik.errors.oContacto_Madre_apellido_Materno}</FormFeedback>
                                                ) : null}
                                            </FormGroup>
                                        </Col>
                                        <Col xs={6}>
                                            <FormGroup className="mb-3">
                                                <Label htmlFor='oContacto_Madre_fecha_Nacimiento'>Fecha Nacimiento</Label>
                                                <Input
                                                    type='Date'
                                                    id='oContacto_Madre_fecha_Nacimiento'
                                                    name='oContacto_Madre_fecha_Nacimiento'
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.oContacto_Madre_fecha_Nacimiento}
                                                    invalid={
                                                        formik.touched.oContacto_Madre_fecha_Nacimiento &&
                                                        formik.errors.oContacto_Madre_fecha_Nacimiento ? true : false}
                                                />
                                                {
                                                    formik.touched.oContacto_Madre_fecha_Nacimiento &&
                                                    formik.errors.oContacto_Madre_fecha_Nacimiento ? (
                                                    <FormFeedback type="invalid">{ formik.errors.oContacto_Madre_fecha_Nacimiento}</FormFeedback>
                                                ) : null}
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    {/* #endregion */}

                                    {/* #region  Celular - Email */}
                                    <Row>
                                        <Col xs={4}>
                                            <FormGroup className="mb-3">
                                                <Row>
                                                    <Col xs={8}><Label htmlFor='oContacto_Madre_celular'>Celular</Label></Col>
                                                    <Col className='text-end'>
                                                        <Tooltip
                                                            placement="top"
                                                            isOpen={ttoverPhone}
                                                            target="TooltipMadreCel"
                                                            toggle={() => {
                                                                setTToverPhone(!ttoverPhone);
                                                            }}
                                                        >
                                                            Sin celular
                                                        </Tooltip>
                                                        <i className="mdi mdi-phone-cancel" id="TooltipMadreCel"></i>
                                                    </Col>
                                                </Row>

                                                <Input
                                                    type='text'
                                                    id='oContacto_Madre_celular'
                                                    name='oContacto_Madre_celular'
                                                    placeholder='10 Dígitos'
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={regresaSoloNumeros(formik.values.oContacto_Madre_celular)}
                                                    invalid={
                                                        formik.touched.oContacto_Madre_celular &&
                                                        formik.errors.oContacto_Madre_celular ? true : false}
                                                />


                                                {
                                                    formik.touched.oContacto_Madre_celular &&
                                                    formik.errors.oContacto_Madre_celular ? (
                                                    <FormFeedback type="invalid">{ formik.errors.oContacto_Madre_celular}</FormFeedback>
                                                ) : null}
                                            </FormGroup>
                                        </Col>
                                        <Col xs={8}>
                                            <FormGroup className="mb-3">
                                                <Row>
                                                    <Col xs={10}><Label htmlFor='oContacto_Madre_email'>Email</Label></Col>
                                                    <Col className='text-end'>
                                                        <Tooltip
                                                            placement="top"
                                                            isOpen={ttoverEmail}
                                                            target="TooltipMadreEmail"
                                                            toggle={() => {
                                                                setTToverEmail(!ttoverEmail);
                                                            }}
                                                        >
                                                            Sin Email
                                                        </Tooltip>
                                                        <i className="mdi mdi-email-remove-outline" id="TooltipMadreEmail"></i></Col>
                                                </Row>

                                                <Input
                                                    type='text'
                                                    id='oContacto_Madre_email'
                                                    placeholder='E-Mail'
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.oContacto_Madre_email || ""}
                                                    invalid={
                                                        formik.touched.oContacto_Madre_email &&
                                                        formik.errors.oContacto_Madre_email ? true : false}
                                                />
                                                {formik.touched.oContacto_Madre_email &&
                                                    formik.errors.oContacto_Madre_email ? (
                                                    <FormFeedback type="invalid">{formik.errors.oContacto_Madre_email}</FormFeedback>
                                                ) : null}
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    {/* #endregion */}

                                    {/* #region  Password */}
                                    <Row>
                                        <Col xs={6}>
                                            <FormGroup className="mb-3">
                                                <Label htmlFor='oContacto_Madre_token3'>Password</Label>
                                                <Input
                                                    type='text'
                                                    readOnly={props.isNew}
                                                    id='oContacto_Madre_token3'
                                                    name='oContacto_Madre_token3'
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.oContacto_Madre_token3.split('@')[0] || ''}
                                                    invalid={formik.touched.oContacto_Madre_token3 &&
                                                        formik.errors.oContacto_Madre_token3 ? true : false}
                                                />
                                                {formik.touched.oContacto_Madre_token3 &&
                                                    formik.errors.oContacto_Madre_token3 ? (
                                                    <FormFeedback type="invalid">{formik.errors.mama_password}</FormFeedback>
                                                ) : null}
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    {/* #endregion */}
                                </>
                                ) : null}

                            </TabPane>

                            <TabPane tabId="papa">
                                <div className='form-check'>
                                    <Input
                                        type='checkbox'
                                        className='form-check-Input'
                                        id='sin_papa'
                                        name='sin_papa'
                                        onChange={formik.handleChange}
                                        value={formik.values.sin_papa}
                                    />
                                    <Label
                                        className='form-check-Label'
                                        htmlFor='sin_papa'
                                    >
                                        Sin registro de papá.
                                    </Label>
                                </div>

                                {!formik.values.sin_papa ? (<>

                                    {/* #region  Formulario Fotografia */}
                                    <Row>
                                        <Col xs={9}>
                                            {props.isNew ?
                                                (
                                                    <div className='mb-3'>
                                                        <Label htmlFor='papa_foto'>Foto</Label>
                                                        <Input
                                                            type='file'
                                                            className='form-control'
                                                            id='papa_foto'
                                                            name='papa_foto'
                                                            onChange={readFile}
                                                            accept=".jpg, .jpeg, .png"
                                                        />
                                                    </div>
                                                )
                                                :
                                                (
                                                    <div className='mb-3'>
                                                        <Label htmlFor='fotoEditPapa'>Foto</Label>
                                                        <Input
                                                            type='text'
                                                            className='form-control'
                                                            id='fotoEditPapa'
                                                            name='oContacto_Padre_url_Fotografia'
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                            value={formik.values.oContacto_Padre_url_Fotografia || ""}
                                                            invalid={formik.touched.oContacto_Padre_url_Fotografia &&
                                                                formik.errors.oContacto_Padre_url_Fotografia ? true : false}
                                                        />
                                                        {formik.touched.oContacto_Padre_url_Fotografia &&
                                                            formik.errors.oContacto_Padre_url_Fotografia ? (
                                                            <FormFeedback type="invalid">{formik.errors.oContacto_Padre_url_Fotografia}</FormFeedback>
                                                        ) : null}
                                                    </div>
                                                )
                                            }
                                        </Col>
                                        <Col xs={3}>
                                            <div className='text-center'>
                                                <img className='rounded avatar-md m-2'
                                                    src={props.isNew ?
                                                        foto_papa ? foto_papa : avatar1 : formik.values.oContacto_Padre_url_Fotografia ?
                                                            formik.values.oContacto_Padre_url_Fotografia : foto_papa ?
                                                                foto_papa : avatar1}
                                                    alt='Generic placeholder image' />
                                            </div>

                                        </Col>
                                    </Row>
                                    {/* #endregion */}

                                    {/* #region  Nombre Apellido Paterno */}
                                    <Row>
                                        <Col xs={6}>
                                            <FormGroup className="mb-3">
                                                <Label htmlFor="oContacto_Padre_nombres">Nombre</Label>
                                                <Input
                                                    type="text"
                                                    className="form-control"
                                                    id="oContacto_Padre_nombres"
                                                    placeholder="Nombre"
                                                    name='oContacto_Padre_nombres'
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={normalizar(formik.values.oContacto_Padre_nombres) || ""}
                                                    invalid={formik.touched.oContacto_Padre_nombres &&
                                                        formik.errors.oContacto_Padre_nombres ? true : false}
                                                />
                                                {formik.touched.oContacto_Padre_nombres &&
                                                    formik.errors.oContacto_Padre_nombres ? (
                                                    <FormFeedback type="invalid">{formik.errors.oContacto_Padre_nombres}</FormFeedback>
                                                ) : null}
                                            </FormGroup>
                                        </Col>
                                        <Col xs={6}>
                                            <FormGroup className="mb-3">
                                                <Label htmlFor='oContacto_Padre_apellido_Paterno'>Apellido Paterno</Label>
                                                <Input
                                                    type='text'
                                                    id='oContacto_Padre_apellido_Paterno'
                                                    name='oContacto_Padre_apellido_Paterno'
                                                    placeholder='Apellido Paterno'
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={normalizar(formik.values.oContacto_Padre_apellido_Paterno) || ""}
                                                    invalid={formik.touched.oContacto_Padre_apellido_Paterno &&
                                                        formik.errors.oContacto_Padre_apellido_Paterno ? true : false}
                                                />
                                                {formik.touched.oContacto_Padre_apellido_Paterno &&
                                                    formik.errors.oContacto_Padre_apellido_Paterno ? (
                                                    <FormFeedback type="invalid">{ formik.errors.oContacto_Padre_apellido_Paterno}</FormFeedback>
                                                ) : null}
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    {/* #endregion */}

                                    {/* #region  Apellido Materno - Fecha de nacimiento */}
                                    <Row>
                                        <Col xs={6}>
                                            <FormGroup className="mb-3">
                                                <Label htmlFor='oContacto_Padre_apellido_Materno'>Apellido Materno</Label>
                                                <Input
                                                    type='text'
                                                    id='oContacto_Padre_apellido_Materno'
                                                    placeholder='Apellido Materno'
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={normalizar(formik.values.oContacto_Padre_apellido_Materno) || ""}
                                                    invalid={formik.touched.oContacto_Padre_apellido_Materno &&
                                                        formik.errors.oContacto_Padre_apellido_Materno ? true : false}
                                                />
                                                {formik.touched.oContacto_Padre_apellido_Materno &&
                                                    formik.errors.oContacto_Padre_apellido_Materno ? (
                                                    <FormFeedback type="invalid">{ formik.errors.oContacto_Padre_apellido_Materno}</FormFeedback>
                                                ) : null}
                                            </FormGroup>
                                        </Col>
                                        <Col xs={6}>
                                            <FormGroup className="mb-3">
                                                <Label htmlFor='oContacto_Padre_fecha_Nacimiento'>Fecha Nacimiento</Label>
                                                <Input
                                                    type='Date'
                                                    id='oContacto_Padre_fecha_Nacimiento'
                                                    name='oContacto_Padre_fecha_Nacimiento'
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.oContacto_Padre_fecha_Nacimiento}
                                                    invalid={formik.touched.oContacto_Padre_fecha_Nacimiento &&
                                                        formik.errors.oContacto_Padre_fecha_Nacimiento ? true : false}
                                                />
                                                {formik.touched.oContacto_Padre_fecha_Nacimiento &&
                                                    formik.errors.oContacto_Padre_fecha_Nacimiento ? (
                                                    <FormFeedback type="invalid">{ formik.errors.oContacto_Padre_fecha_Nacimiento}</FormFeedback>
                                                ) : null}
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    {/* #endregion */}

                                    {/* #region  Celular - Email */}
                                    <Row>
                                        <Col xs={4}>
                                            <FormGroup className="mb-3">
                                                <Row>
                                                    <Col xs={8}><Label htmlFor='oContacto_Padre_celular'>Celular</Label></Col>
                                                    <Col className='text-end'>
                                                        <Tooltip
                                                            placement="top"
                                                            isOpen={ttoverPhone}
                                                            target="TooltipPadreCel"
                                                            toggle={() => {
                                                                setTToverPhone(!ttoverPhone);
                                                            }}
                                                        >
                                                            Sin celular
                                                        </Tooltip>
                                                        <i className="mdi mdi-phone-cancel" id="TooltipPadreCel"></i>
                                                    </Col>
                                                </Row>

                                                <Input
                                                    type='text'
                                                    id='oContacto_Padre_celular'
                                                    name='oContacto_Padre_celular'
                                                    placeholder='10 Dígitos'
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={regresaSoloNumeros(formik.values.oContacto_Padre_celular)}
                                                    invalid={formik.touched.oContacto_Padre_celular &&
                                                        formik.errors.oContacto_Padre_celular ? true : false}
                                                />
                                                {formik.touched.oContacto_Padre_celular &&
                                                    formik.errors.oContacto_Padre_celular ? (
                                                    <FormFeedback type="invalid">{ formik.errors.oContacto_Padre_celular}</FormFeedback>
                                                ) : null}
                                            </FormGroup>
                                        </Col>
                                        <Col xs={8}>
                                            <FormGroup className="mb-3">
                                                <Row>
                                                    <Col xs={10}><Label htmlFor='oContacto_Padre_email'>Email</Label></Col>
                                                    <Col className='text-end'>
                                                        <Tooltip
                                                            placement="top"
                                                            isOpen={ttoverEmail}
                                                            target="TooltipPadreEmail"
                                                            toggle={() => {
                                                                setTToverEmail(!ttoverEmail);
                                                            }}
                                                        >
                                                            Sin Email
                                                        </Tooltip>
                                                        <i className="mdi mdi-email-remove-outline" id="TooltipPadreEmail"></i></Col>
                                                </Row>

                                                <Input
                                                    type='text'
                                                    id='oContacto_Padre_email'
                                                    placeholder='E-Mail'
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.oContacto_Padre_email || ""}
                                                    invalid={formik.touched.oContacto_Padre_email &&
                                                        formik.errors.oContacto_Padre_email ? true : false}
                                                />
                                                {formik.touched.oContacto_Padre_email &&
                                                    formik.errors.oContacto_Padre_email ? (
                                                    <FormFeedback type="invalid">{ formik.errors.oContacto_Padre_email}</FormFeedback>
                                                ) : null}
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    {/* #endregion */}

                                    {/* #region  Password */}
                                    <Row>
                                        <Col xs={6}>
                                            <FormGroup className="mb-3">
                                                <Label htmlFor='oContacto_Padre_token3'>Password</Label>
                                                <Input
                                                    type='text'
                                                    readOnly={props.isNew}
                                                    id='oContacto_Padre_token3'
                                                    name='oContacto_Padre_token3'
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.oContacto_Padre_token3.split('@')[0] || ''}
                                                    invalid={formik.touched.oContacto_Padre_token3 &&
                                                        formik.errors.oContacto_Padre_token3 ? true : false}
                                                />
                                                {formik.touched.oContacto_Padre_token3 &&
                                                    formik.errors.oContacto_Padre_token3 ? (
                                                    <FormFeedback type="invalid">{formik.errors.mama_password}</FormFeedback>
                                                ) : null}
                                            </FormGroup>
                                        </Col>
                                    </Row>

                                </>

                                ) : null}

                            </TabPane>

                            <TabPane tabId="familia">
                                <Row>
                                    <Col xs={12}>
                                        <FormGroup className="mb-3">
                                            <Label htmlFor='familia'>Familia</Label>
                                            {/* <Select
                                                id='familia'
                                                name='familia'
                                                options={familiaOptions}
                                                value={formik.values.familia}
                                                onChange={(familia) => formik.setFieldValue('familia', familia)}
                                                onBlur={formik.handleBlur}
                                                invalid={formik.touched.familia && formik.errors.familia ? true : false}
                                            />
                                            {formik.touched.familia && formik.errors.familia ? (
                                                <FormFeedback type="invalid">{formik.errors.familia}</FormFeedback>
                                            ) : null} */}
                                        </FormGroup>
                                    </Col>
                                    <Row>
                                        {/* <Col xs={2} className=''>
                                            <div className='text-start'>
                                                <img className='rounded avatar-sm m-2' src={avatar1} alt='Generic placeholder image' />
                                            </div>
                                        </Col>
                                        <Col xs={5} className=''>
                                            <div className='text-start'>
                                                {getFamiliaById(formik.values.familia.value) ? getFamiliaById(formik.values.familia.value).oContacto_Padre.nombre : null} {' '}
                                                {getFamiliaById(formik.values.familia.value) ? getFamiliaById(formik.values.familia.value).oContacto_Padre.celular : null}
                                            </div>
                                        </Col>
                                        <Col xs={5} className=''>
                                            <div className='text-start'>
                                                {getFamiliaById(formik.values.familia.value) ? getFamiliaById(formik.values.familia.value).oContacto_Padre.email : null}
                                            </div>
                                        </Col> */}
                                    </Row>
                                    <Row>
                                        {/* <Col xs={2} className=''>
                                            <div className='text-start'>
                                                <img className='rounded avatar-sm m-2' src={avatar1} alt='Generic placeholder image' />
                                            </div>
                                        </Col>
                                        <Col xs={5} className=''>
                                            <div className='text-start'>
                                                {getFamiliaById(formik.values.familia.value) ? getFamiliaById(formik.values.familia.value).oContacto_Padre.nombre : null} {' '}
                                                {getFamiliaById(formik.values.familia.value) ? getFamiliaById(formik.values.familia.value).oContacto_Padre.celular : null}
                                            </div>
                                        </Col>
                                        <Col xs={5} className=''>
                                            <div className='text-start'>
                                                {getFamiliaById(formik.values.familia.value) ? getFamiliaById(formik.values.familia.value).oContacto_Padre.email : null}
                                            </div>
                                        </Col> */}
                                    </Row>
                                </Row>
                            </TabPane>

                            <TabPane tabId='resultado'>
                                Todo OK
                            </TabPane>

                        </TabContent>
                        {/* #endregion */}

                        {/* <Button
                    type="button"
                    className="outline"
                    onClick={handleReset}
                    disabled={!dirty || isSubmitting}
                >
                    Reset
                </Button> */}


                    </div>
                    <div className="modal-footer">
                        <button type="reset" className="btn btn-light" onClick={() => {
                            setFotoAlumno(null)
                            setFotoMama(null)
                            setFotoPapa(null)
                            formik.handleReset()
                        }}>Reset</button>
                        <button type="button" className="btn btn-light" onClick={() => { props.fnToggle() }}>Close</button>
                        <button 
                        type="submit" 
                        className="btn btn-primary">
                            Registrar
                        </button>
                        {/* disabled={!(formik.dirty && formik.isValid)} disabled={!dirty || isSubmitting     */}
                    </div>
                </Form>
            </Modal>



        </React.Fragment>
    )
}

Formulario_Alta_Alumno.propTypes = {
    isOpen: PropTypes.bool,
    oAlumno: PropTypes.any,
    isNew: PropTypes.bool,
    isEdit: PropTypes.bool,
    children: PropTypes.any,
    name: PropTypes.any,
    selectProps: PropTypes.any,
    fnToggle: PropTypes.func,
}

export default Formulario_Alta_Alumno