import {
  GET_SECCIONES_PAGINAS,
  GET_SECCIONES_PAGINAS_SUCCESS,
  GET_SECCIONES_PAGINAS_FAIL,
} from "./actionTypes"

export const get_secciones_paginas = jsonparameters => ({
  type: GET_SECCIONES_PAGINAS,
  jsonparameters: jsonparameters,
})

export const get_secciones_paginas_Success = response => ({
  type: GET_SECCIONES_PAGINAS_SUCCESS,
  payload: response,
})

export const get_secciones_paginas_Fail = e => ({
  type: GET_SECCIONES_PAGINAS_FAIL,
  payload: e,
})
