


/* ALUMNOS */
export const ALUMNOS_GET_ALUMNO = "ALUMNOS/GET/ALUMNO"
export const ALUMNOS_GET_ALUMNO_SUCCESS = "ALUMNOS/GET/ALUMNO_SUCCESS"
export const ALUMNOS_GET_ALUMNO_FAIL = "ALUMNOS/GET/ALUMNO_FAIL"

export const ALUMNOS_GET_ALUMNOS = "ALUMNOS/GET/ALUMNOS"
export const ALUMNOS_GET_ALUMNOS_SUCCESS = "ALUMNOS/GET/ALUMNOS_SUCCESS"
export const ALUMNOS_GET_ALUMNOS_FAIL = "ALUMNOS/GET/ALUMNOS_FAIL"
/* ---------------------------------------------------------------------------------------*/



/**
 * add alumno
 */
 export const ADD_NEW_ALUMNO = "ALUMNOS/ADD/NEW_ALUMNO"
 export const ADD_ALUMNO_SUCCESS = "ALUMNOS/ADD/ALUMNO_SUCCESS"
 export const ADD_ALUMNO_FAIL = "ALUMNOS/ADD/ALUMNO_FAIL"
 
 /**
  * Edit alumno
  */
 export const UPDATE_ALUMNO = "ALUMNOS/UPDATE_ALUMNO"
 export const UPDATE_ALUMNO_SUCCESS = "ALUMNOS/UPDATE/ALUMNO_SUCCESS"
 export const UPDATE_ALUMNO_FAIL = "ALUMNOS/UPDATE/ALUMNO_FAIL"
 
 /**
  * Delete alumno
  */
 export const DELETE_ALUMNO = "ALUMNOS/DELETE_ALUMNO"
 export const DELETE_ALUMNO_SUCCESS = "ALUMNOS/DELETE/ALUMNO_SUCCESS"
 export const DELETE_ALUMNO_FAIL = "ALUMNOS/DELETE/ALUMNO_FAIL"

