import React, { useState } from "react";

const TestContainer = () => {

    return (
        <React.Fragment>
            <div className="page-content">
                <div className="">
                    <h2 className="component display-4">Container</h2>
                    <hr/>

                        <div className="code-example mb-5">
                            <h3 className="component-variation text-muted container">Default</h3>
                            <div className="code-live">
                                <div className="container">Lorem ipsum dolor sit amet, consectetur
                                    adipiscing elit. In laoreet pellentesque lorem sed elementum.
                                    Suspendisse maximus convallis ex. Etiam eleifend velit leo.
                                    Praesent nec egestas neque. Integer a ipsum et ante volutpat
                                    rhoncus. Pellentesque ultrices metus eget quam malesuada, sed
                                    faucibus nisi fringilla. Nunc eget turpis in turpis molestie
                                    malesuada a non sapien. Nullam auctor, tellus malesuada
                                    convallis accumsan, felis ex ultrices neque, quis tincidunt
                                    felis orci id nisi.
                                </div>
                            </div>

                            <div className="code-example mb-5">
                                <h3 className="component-variation text-muted container">Responsive</h3>
                                <div className="code-live">
                                    <h4 className="h5 text-muted mt-3 container">Small</h4>
                                    <div className="container-sm">Lorem ipsum dolor sit amet, consectetur
                                        adipiscing elit. In laoreet pellentesque lorem sed
                                        elementum.
                                        Suspendisse maximus convallis ex. Etiam eleifend velit leo.
                                        Praesent nec egestas neque. Integer a ipsum et ante volutpat
                                        rhoncus. Pellentesque ultrices metus eget quam malesuada,
                                        sed
                                        faucibus nisi fringilla. Nunc eget turpis in turpis molestie
                                        malesuada a non sapien. Nullam auctor, tellus malesuada
                                        convallis accumsan, felis ex ultrices neque, quis tincidunt
                                        felis orci id nisi.
                                    </div>
                                </div>

                                <h4 className="h5 text-muted mt-3 container">Medium</h4>
                                <div className="container-md">Lorem ipsum dolor sit amet, consectetur
                                    adipiscing elit. In laoreet pellentesque lorem sed
                                    elementum.
                                    Suspendisse maximus convallis ex. Etiam eleifend velit leo.
                                    Praesent nec egestas neque. Integer a ipsum et ante volutpat
                                    rhoncus. Pellentesque ultrices metus eget quam malesuada,
                                    sed
                                    faucibus nisi fringilla. Nunc eget turpis in turpis molestie
                                    malesuada a non sapien. Nullam auctor, tellus malesuada
                                    convallis accumsan, felis ex ultrices neque, quis tincidunt
                                    felis orci id nisi.
                                </div>

                                <h4 className="h5 text-muted mt-3 container">Large</h4>
                                <div className="container-lg">Lorem ipsum dolor sit amet, consectetur
                                    adipiscing elit. In laoreet pellentesque lorem sed
                                    elementum.
                                    Suspendisse maximus convallis ex. Etiam eleifend velit leo.
                                    Praesent nec egestas neque. Integer a ipsum et ante volutpat
                                    rhoncus. Pellentesque ultrices metus eget quam malesuada,
                                    sed
                                    faucibus nisi fringilla. Nunc eget turpis in turpis molestie
                                    malesuada a non sapien. Nullam auctor, tellus malesuada
                                    convallis accumsan, felis ex ultrices neque, quis tincidunt
                                    felis orci id nisi.
                                </div>

                                <h4 className="h5 text-muted mt-3 container">Extra large</h4>
                                <div className="container-xl">Lorem ipsum dolor sit amet, consectetur
                                    adipiscing elit. In laoreet pellentesque lorem sed
                                    elementum.
                                    Suspendisse maximus convallis ex. Etiam eleifend velit leo.
                                    Praesent nec egestas neque. Integer a ipsum et ante volutpat
                                    rhoncus. Pellentesque ultrices metus eget quam malesuada,
                                    sed
                                    faucibus nisi fringilla. Nunc eget turpis in turpis molestie
                                    malesuada a non sapien. Nullam auctor, tellus malesuada
                                    convallis accumsan, felis ex ultrices neque, quis tincidunt
                                    felis orci id nisi.
                                </div>

                                <h4 className="h5 text-muted mt-3 container">Extra extra large</h4>
                                <div className="container-xxl">Lorem ipsum dolor sit amet, consectetur
                                    adipiscing elit. In laoreet pellentesque lorem sed
                                    elementum.
                                    Suspendisse maximus convallis ex. Etiam eleifend velit leo.
                                    Praesent nec egestas neque. Integer a ipsum et ante volutpat
                                    rhoncus. Pellentesque ultrices metus eget quam malesuada,
                                    sed
                                    faucibus nisi fringilla. Nunc eget turpis in turpis molestie
                                    malesuada a non sapien. Nullam auctor, tellus malesuada
                                    convallis accumsan, felis ex ultrices neque, quis tincidunt
                                    felis orci id nisi.
                                </div>
                            </div>
                        </div>

                        <div className="code-example mb-5">
                            <h3 className="component-variation text-muted container">Fluid</h3>
                            <div className="code-live">
                                <div className="container-fluid">Lorem ipsum dolor sit amet, consectetur
                                    adipiscing elit. In laoreet pellentesque lorem sed
                                    elementum.
                                    Suspendisse maximus convallis ex. Etiam eleifend velit leo.
                                    Praesent nec egestas neque. Integer a ipsum et ante volutpat
                                    rhoncus. Pellentesque ultrices metus eget quam malesuada,
                                    sed
                                    faucibus nisi fringilla. Nunc eget turpis in turpis molestie
                                    malesuada a non sapien. Nullam auctor, tellus malesuada
                                    convallis accumsan, felis ex ultrices neque, quis tincidunt
                                    felis orci id nisi.
                                </div>

                            </div>
                        </div>

                </div>
            </div>
        </React.Fragment>
    )

}

export default TestContainer