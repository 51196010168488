//redux
import { useSelector } from "react-redux"

// *************************************************************
//     Misc
  export function useCommonFile() {
    return useSelector(state => state.Common.file);
  }

  export function useReporteEnvios() {
    return useSelector(state => state.Common.reporteEnvios);
  }

  export function useReporteEnvioListaStatus() {
    return useSelector(state => state.Common.reporteEnvioListaStatus);
  }