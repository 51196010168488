import { assign, Assigner, createMachine, PropertyAssigner } from "xstate";
import {AUTH_LOGIN_SUCCESS, }  from "./actionTypes";

const authMachine = 
/** @xstate-layout N4IgpgJg5mDOIC5QEMCuAXAFgOlgSygDsBJQgYjSwHoAZAeQHFiA5RUABwHt909PC2IAB6IArACZsAFgBsAZhmiA7AA4AnDKmjRUpQBoQAT0RbR2AIxS5VpeaVzRABieiAvq4OUc+IqQoZMWkYWAH0AZQBVAGEogFEwsMEuHj4BJGFEAFpzFUdpNTkVOSUpR0dxHQ0DYwQdMxUJKVKVczVlKXF3TwDcAhJyLyCmZhDYgCUxujGk7jxefkERBEyZPJa2qTVc7fFxFRlqxBypbGK5RzVzeV2y8SUukC9e3wGAqii6ZgAxYjGAWRCETC4xmKQW6SW4g62FsKiU9n2cgUBSkhwQDhkFhk2NEcPMu3M5QeTx8hEgfkG9AYdAiABVQXNUotEEpyqc9uJHDI1GpOepRGj1NgeTz1psnOJFMSesgAMayzioQjoMJgdCodj+ahU0KRGLxRLpZKM8GgJaaOTScStQryUSKcwCoyIBQnEU8nSE9TOaVYbByhVKlVqjVawI6kbjSbTI2zeZpM0mCQwxxyK66JS7FS7NEVJTCkVFa52GR2dweECETgQOCCEl9UgM+PM5Z7E7wtrlTP4uH6Z3o0TmbCrSUFFQZ626X3ePrkhMgY3NiGIBoqaRcuTiDZaTSo-tSQn5UXctRKCRpqTT-3yxXK1Xq9hNpnLhC6Ie6RSiqFn2wHfeOfN3X2Hkz3EC9pyfU0MmWc41w7CVuz2eE0TsAtRVKXZ4XKDpy1cIA */
createMachine(
  {
  context: {
    authUser: null,
    authError: null,
    authLoading: false,
    authGmtOffset: 0,
    authIdColegio: -1
  },
  id: "auth",
  initial: "signIn",
  states: {
    signIn: {
      on: {
        "auth/SETIDCOLEGIO": {
          actions: ["setAuthIdColegio"],
          target: "signIn",
      },
        "auth/LOGIN": {
          actions: ["setLoading"],
          target: "signIn",
          internal: false,
        },
        "auth/LOGIN_SUCCESS": {
          actions: ["setUser", "unsetError", "unsetLoading", "setGmt"],
          target: "signedIn",
        },
        "auth/LOGIN_ERROR": {
          actions: ["setError", "unsetUser", "unsetLoading"],
          target: "signIn",
          internal: false,
        },
        "auth/CONFIRM_USER": {
          actions: ["setUser", "unsetError", "unsetLoading"],
          target: "accountSetup",
        },
      },
    },
    signedIn: {
      on: {
        "auth/LOGOUT": {
          actions: ["unsetUser", "unsetError", "unsetLoading", "unsetAuthIdColegio"],
          target: "signIn",
        },
      },
    },
    accountSetup: {
      on: {
        "auth/LOGIN_SUCCESS": {
          actions: ["setUser", "unsetError", "unsetLoading"],
          target: "signedIn",
        },
        "auth/LOGIN_ERROR": {
          actions: ["setError", "unsetUser", "unsetLoading"],
          target: "accountSetup",
          internal: false,
        },
      },
    },
  },
},
  {
    actions: {
      setGmt: assign( {
        authGmtOffset: (_, event) =>{
          return (event.type === AUTH_LOGIN_SUCCESS
            ? event.payload.gmtOffset
            : null)},
      }),
      setUser: assign( {
        authUser: (_, event) =>{
          console.log(event)
          return (event.type === AUTH_LOGIN_SUCCESS
            ? event.payload
            : null)},
      }),
      unsetUser: assign({
        authUser: () => null,
      }),
      setError: assign({
        authError: (_, event) => {
          return event.payload;
        },
      }),
      unsetError: assign({
        authError: null,
      }),
      setLoading: assign({
        authLoading: true,
      }),
      unsetLoading: assign({
        authLoading: false,
      }),
      setAuthIdColegio:  assign({
        authIdColegio: (_, event) => event.payload
      }),
      unsetAuthIdColegio:  assign({
        authIdColegio: -1
      }),
    },
  }
);

export default authMachine;
