


/* Contacto */
export const CONTACTOS_GET_CONTACTO = "CONTACTOS/GET/CONTACTO"
export const CONTACTOS_GET_CONTACTO_SUCCESS = "CONTACTOS/GET/CONTACTO_SUCCESS"
export const CONTACTOS_GET_CONTACTO_FAIL = "CONTACTOS/GET/CONTACTO_FAIL"

export const CONTACTOS_GET_CONTACTOS = "CONTACTOS/GET/CONTACTOS"
export const CONTACTOS_GET_CONTACTOS_SUCCESS = "CONTACTOS/GET/CONTACTOS_SUCCESS"
export const CONTACTOS_GET_CONTACTOS_FAIL = "CONTACTOS/GET/CONTACTOS_FAIL"
/* ---------------------------------------------------------------------------------------*/

/**
 * add Contacto
 */
 export const CONTACTOS_ADD_CONTACTO = "CONTACTOS/ADD/CONTACTO"
 export const CONTACTOS_ADD_CONTACTO_SUCCESS = "CONTACTOS/ADD/CONTACTO_SUCCESS"
 export const CONTACTOS_ADD_CONTACTO_FAIL = "CONTACTOS/ADD/CONTACTO_FAIL"
 
 /**
  * Edit Contacto
  */
 export const CONTACTOS_UPDATE_CONTACTO = "CONTACTOS/UPDATE/CONTACTO"
 export const CONTACTOS_UPDATE_CONTACTO_SUCCESS = "CONTACTOS/UPDATE/CONTACTO_SUCCESS"
 export const CONTACTOS_UPDATE_CONTACTO_FAIL = "CONTACTOS/UPDATE/CONTACTO_FAIL"
 
 /**
  * Delete Contacto
  */
 export const CONTACTOS_DELETE_CONTACTO = "CONTACTOS/DELETE/CONTACTO"
 export const CONTACTOS_DELETE_CONTACTO_SUCCESS = "CONTACTOS/DELETE/CONTACTO_SUCCESS"
 export const CONTACTOS_DELETE_CONTACTO_FAIL = "CONTACTOS/DELETE/CONTACTO_FAIL"

