import React from 'react';
import { useDispatch } from "react-redux"
import { getoSolicitudBase } from "../../helpers/comunicabackend_helper"

import PropTypes from 'prop-types'
import 'devextreme/dist/css/dx.light.css';

import DataGrid, {
    Button,
    Column,
    Editing,
    Lookup
} from 'devextreme-react/data-grid';

import {
    actionUpdateEvent as onActionUpdateEvent,
    actionDeleteEvent as onActionDeleteEvent
} from "../../store/libreta/actions"

const formatDate = new Intl.DateTimeFormat('es-MX').format;

const TablaDiapers = (props) => {

    const dispatch = useDispatch()

    const onEditingStart = (event) => {
        console.log('onEditingStart')
        console.log('event: ', event)
    }
    const onRowUpdated = (event) => {
        console.log('onRowUpdated')
        console.log('event: ', event)
    }
    const onSaving = (event) => {
        console.log('onSaving')
        console.log('onSaving event: ', event)
    }
    const onSaved = (event) => {

        console.log('-----------------------------------------------')
        console.log('onSaved')
        console.log('onSaved event: ', event)
        console.log('-----------------------------------------------')
        let datos = {}
        const oSolicitud = getoSolicitudBase(
            "Libreta::revision::onSaved",
            "onSaved",
            datos,
            [{ nombre: "tipoEvento", valor: "pañal" }]
        );
        if (event.changes.length) {
            if (event.changes[0].type === 'update') {
                console.log("changes: ", event.changes[0])
                datos = {
                    id_Libreta_Registro_Diapers: event.changes[0].data.id_Libreta_Registro_Diapers,
                    id_Alumno: event.changes[0].data.id_Alumno,
                    id_Libreta_Estado_Animo: event.changes[0].data.id_Libreta_Estado_Animo,
                    id_Libreta_Observacion: event.changes[0].data.id_Libreta_Observacion,
                    Observacion: event.changes[0].data.observacion.toString(),
                    Fecha: event.changes[0].data.fecha.toString(),
                    Pipi: event.changes[0].data.pipi,
                    Popo: event.changes[0].data.popo,
                }
                oSolicitud.Datos = JSON.stringify(datos)
                console.log("oSolicitud: ", oSolicitud);
                dispatch(onActionUpdateEvent(oSolicitud, "pañal"));
            } else {
                console.log("remove?: ", event.changes[0])
                if (event.changes[0].type === 'remove') {

                    oSolicitud.Parametros.push({ nombre: "id_Libreta_Registro_Diapers", valor: event.changes[0].key })

                    datos = {
                        id_Libreta_Registro_Diapers: event.changes[0].key,
                        id_Alumno: 1,
                        id_Libreta_Cantidad: 1,
                        id_Libreta_Estado_Animo: 1,
                        id_Libreta_Observacion: 1,
                        Observacion: "Observa",
                        Fecha: "2022-10-22",
                    }
                    oSolicitud.Datos = JSON.stringify(datos)

                    console.log("oSolicitud: ", oSolicitud);
                    dispatch(onActionDeleteEvent(oSolicitud, "pañal"));
                }
            }
            console.log('-----------------------------------------------')
        }
    }
    const onEditCanceled = (event) => {
        console.log('onEditCanceled')
        console.log('onEditCanceled event: ', event)
    }

    return (
        <React.Fragment>
        Pañal
            <DataGrid
                id="gridContainerDiapers"
                allowColumnReordering={true}
                dataSource={props.registro}
                keyExpr="id_Libreta_Registro_Diapers"
                columnAutoWidth={true}
                showBorders={true}
                className='mb-1'
                wordWrapEnabled={true}

                onEditingStart={onEditingStart}
                // onInitNewRow={this.onInitNewRow}
                // onRowInserting={this.onRowInserting}
                // onRowInserted={this.onRowInserted}
                // onRowUpdating={this.onRowUpdating}
                onRowUpdated={onRowUpdated}
                // onRowRemoving={this.onRowRemoving}
                // onRowRemoved={this.onRowRemoved}
                onSaving={onSaving}
                onSaved={onSaved}
                // onEditCanceling={this.onEditCanceling}
                onEditCanceled={onEditCanceled}
            >
                <Editing
                    mode="row"
                    useIcons={props.edicion}
                    allowUpdating={props.edicion}
                    allowDeleting={props.edicion} 
                    />
                <Column visible={props.edicion} type="buttons" width={110}>
                    <Button name="edit" />
                    <Button name="delete" />
                </Column>
                <Column dataField="fecha" dataType="datetime" format="shortTime" caption="Hora" />
                <Column dataField="pipi" />
                <Column dataField="popo" />
                <Column dataField="id_Libreta_Estado_Animo" caption="Ánimo">
                    <Lookup dataSource={props.estadosAnimo} displayExpr="nombre_Estado_Animo" valueExpr="id_Libreta_Estado_Animo" />
                </Column>
                <Column dataField="id_Libreta_Observacion" caption="Catálogo">
                    <Lookup dataSource={props.observaciones} displayExpr="label" valueExpr="value" />
                </Column>
                <Column dataField="observacion" caption="Miss Observacion" />

            </DataGrid>
        </React.Fragment>
    );
}


TablaDiapers.propTypes = {
    registro: PropTypes.array,
    estadosAnimo: PropTypes.array,
    observaciones: PropTypes.array,
    edicion: PropTypes.bool,
}

export default TablaDiapers