import {

  FAMILIAS_GET_FAMILIA_SUCCESS,
  FAMILIAS_GET_FAMILIA_FAIL,

  FAMILIAS_GET_FAMILIAS_SUCCESS,
  FAMILIAS_GET_FAMILIAS_FAIL,
  
  FAMILIAS_ADD_FAMILIA_SUCCESS,
  FAMILIAS_ADD_FAMILIA_FAIL,
  
  FAMILIAS_UPDATE_FAMILIA_SUCCESS,
  FAMILIAS_UPDATE_FAMILIA_FAIL,
  
  FAMILIAS_DELETE_FAMILIA_SUCCESS,
  FAMILIAS_DELETE_FAMILIA_FAIL,

} from "./actionTypes"


const INIT_STATE = {
  familia: {},
  familias: [],
  error: {},
}

const familias = (state = INIT_STATE, action) => {
  switch (action.type) {

    case FAMILIAS_GET_FAMILIA_SUCCESS:
      return {
        ...state,
        error: {},
        familia: action.payload,
      }

    case FAMILIAS_GET_FAMILIA_FAIL:
      return {
        ...state,
        familia: {},
        error: action.payload
      }

    case FAMILIAS_GET_FAMILIAS_SUCCESS:
      return {
        ...state,
        error: {},
        familias: action.payload,
      }

    case FAMILIAS_GET_FAMILIAS_FAIL:
      return {
        ...state,
        familias: [],
        error: action.payload
      }

    case FAMILIAS_ADD_FAMILIA_SUCCESS:
      return {
        ...state,
        familias: [...state.familias, action.payload],
      }

    case FAMILIAS_ADD_FAMILIA_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case FAMILIAS_UPDATE_FAMILIA_SUCCESS:
      return {
        ...state,
        familias: state.familias.map(user =>
          user.id_Alumno.toString() === action.payload.id_Alumno.toString()
            ? { user, ...action.payload }
            : user
        ),
      }

    case FAMILIAS_UPDATE_FAMILIA_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case FAMILIAS_DELETE_FAMILIA_SUCCESS:
      return {
        ...state,
        familias: state.familias.filter(
          user => user.id.toString() !== action.payload.id.toString()
        ),
      }

    case FAMILIAS_DELETE_FAMILIA_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    default:
      return state
  }
}

export default familias
