// @flow
import {
  GET_FILE_SUCCESS,
  GET_FILE_FAIL,

  ADD_FILE_SUCCESS,
  ADD_FILE_FAIL,

  UPDATE_FILE_SUCCESS,
  UPDATE_FILE_FAIL,

  DELETE_FILE_SUCCESS,
  DELETE_FILE_FAIL,

  GET_REPORTE_ENVIOS_SUCCESS,
  GET_REPORTE_ENVIOS_FAIL,

  GET_REPORTE_ENVIO_DETALLE_SUCCESS,
  GET_REPORTE_ENVIO_DETALLE_FAIL,

  SET_DISPLAY_NOTIFICATION,
  UNSET_DISPLAY_NOTIFICATION,

} from "./actionTypes"

const INIT_STATE = {
  file: { webkitURL: "" },
  files: [],
  error: {},
  displayNotification: { tipo: "", titulo: "", mensaje: "", },
  reporteEnvios: [],
  reporteEnvioListaStatus: {},
}

const Common = (state = INIT_STATE, action) => {
  switch (action.type) {

    case GET_FILE_SUCCESS:
      return {
        ...state,
        file: action.payload,
      }

    case GET_FILE_FAIL:
      return {
        ...state,
        error: action.payload,
        displayNotification: {
          tipo: "error",
          titulo: "Error",
          mensaje: "Ocurrió un error al conseguir los eventos",
        },
      }

    case ADD_FILE_SUCCESS:
      return {
        ...state,
        file: action.payload,
      }

    case ADD_FILE_FAIL:
      return {
        ...state,
        error: action.payload,
        displayNotification: {
          tipo: "error",
          titulo: "Error",
          mensaje: "Ocurrió un error al añadir un nuevo archivo.",
        },
      }

    case UPDATE_FILE_SUCCESS:
      return {
        ...state,
        files: state.files.map(event =>
          event.id.toString() === action.payload.id.toString()
            ? { event, ...action.payload }
            : event
        ),
      }

    case UPDATE_FILE_FAIL:
      return {
        ...state,
        error: action.payload,
        displayNotification: {
          tipo: "error",
          titulo: "Error",
          mensaje: "Ocurrió un error al actualizar el evento.",
        },
      }

    case DELETE_FILE_SUCCESS:
      return {
        ...state,
        files: state.files.filter(
          event => file.id.toString() !== action.file.id.toString()
        ),
      }

    case DELETE_FILE_FAIL:
      return {
        ...state,
        error: action.payload,
        displayNotification: {
          tipo: "error",
          titulo: "Error",
          mensaje: "Ocurrió un error al borrar el eventos",
        },
      }

    case GET_REPORTE_ENVIOS_SUCCESS:
      return {
        ...state,
        reporteEnvios: action.payload,
      }

    case GET_REPORTE_ENVIOS_FAIL:
      return {
        ...state,
        error: action.payload,
        displayNotification: {
          tipo: "error",
          titulo: "Error",
          mensaje: "Ocurrió un error al conseguir los reportes",
        },
      }

    case GET_REPORTE_ENVIO_DETALLE_SUCCESS:
      return {
        ...state,
        reporteEnvioListaStatus: action.payload,
      }

    case GET_REPORTE_ENVIO_DETALLE_FAIL:
      return {
        ...state,
        error: action.payload,
        reporteEnvioListaStatus: {},
        displayNotification: {
          tipo: "error",
          titulo: "Error",
          mensaje: "Ocurrió un error al conseguir los reportes",
        },
      }

    case SET_DISPLAY_NOTIFICATION:
      return {
        ...state,
        displayNotification: action.payload,
      }

    case UNSET_DISPLAY_NOTIFICATION:
      return {
        ...state,
        displayNotification: {
          tipo: "",
          titulo: "",
          mensaje: "",
        },
      }

    default:
      return state
  }
}

export default Common
