import {
  AUTH_LOGIN,
  AUTH_LOGIN_SUCCESS,
  AUTH_LOGIN_ERROR,
  AUTH_LOGOUT,
  AUTH_API_ERROR,
  AUTH_ACCOUNT_SETUP,
  AUTH_CONFIRM_USER,
  AUTH_SOCIAL_LOGIN
} from "./actionTypes"

import authMachine from './machine';
export const { initialState } = authMachine;

const login = (state = initialState, action) => {
  
  // console.log("state: ", state.value);
  // console.log("context: ", state.context);
  // console.log("action: ", action);

  let nuevoState = authMachine.transition(state, action);
  //console.log("nuevoState: ", nuevoState);

  return nuevoState;
}

export default login
