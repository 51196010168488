import React from 'react';
import { useDispatch } from "react-redux"
import { getoSolicitudBase } from "../../helpers/comunicabackend_helper"

import PropTypes from 'prop-types'
import 'devextreme/dist/css/dx.light.css';

import DataGrid, {
    Button,
    Column,
    Editing,
    Lookup
} from 'devextreme-react/data-grid';

import {
    actionUpdateEvent as onActionUpdateEvent,
    actionDeleteEvent as onActionDeleteEvent
} from "../../store/libreta/actions"

const TablaSiesta = (props) => {

    const dispatch = useDispatch()


    const onEditingStart = (event) => {
        console.log('onEditingStart')
        console.log('event: ', event)
    }
    const onRowUpdated = (event) => {
        console.log('onRowUpdated')
        console.log('event: ', event)
    }
    const onSaving = (event) => {
        console.log('onSaving')
        console.log('onSaving event: ', event)
    }
    const onSaved = (event) => {

        console.log('-----------------------------------------------')
        console.log('onSaved')
        console.log('onSaved event: ', event)
        console.log('-----------------------------------------------')
        let datos = {}
        const oSolicitudBase_RegistroEvento = getoSolicitudBase(
            "Libreta::handleRegisterEvent",
            "onEventRegisterEvent",
            datos,
            [{ nombre: "tipoEvento", valor: "siesta" }]
        );
        if (event.changes.length) {
            if (event.changes[0].type === 'update') {
                datos = {
                    id_Libreta_Registro_Siesta: event.changes[0].data.id_Libreta_Registro_Siesta,
                    id_Alumno: event.changes[0].data.id_Alumno,
                    id_Libreta_Estado_Animo: event.changes[0].data.id_Libreta_Estado_Animo,
                    id_Libreta_Observacion: event.changes[0].data.id_Libreta_Observacion,
                    Observacion: event.changes[0].data.observacion.toString(),
                    Fecha: event.changes[0].data.fecha.toString(),
                    Inicio: event.changes[0].data.inicio.toString(),
                    Final: "2022-01-01T00:00:00"
                }
                oSolicitudBase_RegistroEvento.Datos = JSON.stringify(datos)

                const duracionMinutos = { nombre: "duracionMinutos", valor: event.changes[0].data.duracionMinutos }
                oSolicitudBase_RegistroEvento.Parametros.push(duracionMinutos)

                console.log("oSolicitudBase_RegistroEvento: ", oSolicitudBase_RegistroEvento);
                dispatch(onActionUpdateEvent(oSolicitudBase_RegistroEvento, "siesta"));
            } else {
                if (event.changes[0].type === 'remove') {

                    oSolicitudBase_RegistroEvento.Parametros.push({ nombre: "id_Libreta_Registro_Siesta", valor: event.changes[0].key })

                    datos = {
                        id_Libreta_Registro_Siesta: event.changes[0].key,
                        id_Alumno: 1,
                        id_Libreta_Cantidad: 1,
                        id_Libreta_Estado_Animo: 1,
                        id_Libreta_Observacion: 1,
                        Observacion: "Observa",
                        Fecha: "2022-10-22",
                    }
                    oSolicitudBase_RegistroEvento.Datos = JSON.stringify(datos)

                    console.log("oSolicitudBase_RegistroEvento: ", oSolicitudBase_RegistroEvento);
                    dispatch(onActionDeleteEvent(oSolicitudBase_RegistroEvento, "siesta"));
                }
            }
            console.log('-----------------------------------------------')
        }
    }
    const onEditCanceled = (event) => {
        console.log('onEditCanceled')
        console.log('onEditCanceled event: ', event)
    }
    const onEditorPreparing = (e) => {
        // console.log('onEditorPreparing')
        // console.log('onEditorPreparing e: ', e)
        // console.log('onEditorPreparing e: ', e)
        console.log('onEditorPreparing e.editorInstance: ', e.editorInstance)
        console.log('onEditorPreparing e.editorOptions: ', e.editorOptions)
        // if (e.dataField === "observacion" && e.parentType === "dataRow") {
        //     e.editorName = "dxTextArea";
        //     e.editorOptions.height = 200;
        // }       

        if (e.dataField === "observacion" && e.parentType === "dataRow") {
        //   e.editorName = "dxTextArea";
        //   e.editorOptions.height = "auto";
        //   e.editorOptions.onInitialized = function(event) {
        //     console.log("onInitialized::event:", event)
        //     e.editorOptions = event.component;
        //   };
        } 
    }
    const onEditorPrepared = (e) => {
        // console.log('onEditorPrepared')
        // console.log('onEditorPrepared e: ', e)
        // console.log('onEditorPrepared e.editorInstance: ', e.editorInstance)
        console.log('onEditorPrepared e.editorOptions: ', e.editorOptions)
        // if (e.dataField === "observacion" && e.parentType === "dataRow") {
        //     setTimeout(() => {
        //       e.editorOptions.height = e.component.getCellElement(e.row.rowIndex, 0).clientHeight
        //     });
        //   }        
    }

    return (
        <React.Fragment>
            Siesta
            <DataGrid allowColumnReordering={true}
                dataSource={props.registro}
                keyExpr="id_Libreta_Registro_Siesta"
                columnAutoWidth={true}
                showBorders={true}
                className='mb-1'
                wordWrapEnabled={true}

                onEditingStart={onEditingStart}
                // onInitNewRow={this.onInitNewRow}
                // onRowInserting={this.onRowInserting}
                // onRowInserted={this.onRowInserted}
                // onRowUpdating={this.onRowUpdating}
                onRowUpdated={onRowUpdated}
                // onRowRemoving={this.onRowRemoving}
                // onRowRemoved={this.onRowRemoved}
                onSaving={onSaving}
                onSaved={onSaved}
                // onEditCanceling={this.onEditCanceling}
                onEditCanceled={onEditCanceled}
            >
                <Editing
                    mode="row"
                    useIcons={props.edicion}
                    allowUpdating={props.edicion}
                    allowDeleting={props.edicion} 
                    />
                <Column visible={props.edicion} type="buttons" width={110}>
                    <Button name="edit" />
                    <Button name="delete" />
                </Column>
                <Column dataField="fecha" dataType="datetime" format="shortTime" caption="Hora" />
                <Column dataField="inicio" dataType="datetime" format="shortTime" caption="Inicio" />
                <Column dataField="duracionMinutos" dataType="int" caption="Duración">
                    {/* <Lookup dataSource={props.minutos} displayExpr="minutos" valueExpr="id" /> */}
                </Column>
                <Column dataField="id_Libreta_Estado_Animo" caption="Ánimo">
                    <Lookup dataSource={props.estadosAnimo} displayExpr="nombre_Estado_Animo" valueExpr="id_Libreta_Estado_Animo" />
                </Column>
                <Column dataField="id_Libreta_Observacion" caption="Catálogo">
                    <Lookup dataSource={props.observaciones} displayExpr="label" valueExpr="value" />
                </Column>
                <Column dataField="observacion" caption="Miss Observacion" />
            </DataGrid>
        </React.Fragment>
    );
}

TablaSiesta.propTypes = {
    registro: PropTypes.array,
    estadosAnimo: PropTypes.array,
    observaciones: PropTypes.array,
    minutos: PropTypes.array,
    edicion: PropTypes.bool,
    edicion: PropTypes.bool,
}

export default TablaSiesta