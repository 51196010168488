

const dataListadoEstadosAnimo =
[
        {
            "id_Libreta_Estado_Animo": 6,
            "id_Colegio": 7,
            "nombre_Estado_Animo": "Felíz",
            "icono": "mdi mdi-emoticon-happy-outline",
            "borrado": false,
            "indice": 0
        },
        {
            "id_Libreta_Estado_Animo": 7,
            "id_Colegio": 7,
            "nombre_Estado_Animo": "Emocionado",
            "icono": "mdi mdi-emoticon-outline",
            "borrado": false,
            "indice": 1
        },
        {
            "id_Libreta_Estado_Animo": 8,
            "id_Colegio": 7,
            "nombre_Estado_Animo": "Exitado",
            "icono": "mdi mdi-emoticon-excited-outline",
            "borrado": false,
            "indice": 2
        },
        {
            "id_Libreta_Estado_Animo": 9,
            "id_Colegio": 7,
            "nombre_Estado_Animo": "Confuso",
            "icono": "mdi mdi-emoticon-confused-outline",
            "borrado": false,
            "indice": 3
        },
        {
            "id_Libreta_Estado_Animo": 10,
            "id_Colegio": 7,
            "nombre_Estado_Animo": "Llorón",
            "icono": "mdi mdi-emoticon-cry-outline",
            "borrado": false,
            "indice": 4
        }
    ]

export default dataListadoEstadosAnimo