import {
  LIBRETA_EVENT,

  /* DisplayNotification */
  LIBRETA_SET_DISPLAY_NOTIFICATION,
  LIBRETA_UNSET_DISPLAY_NOTIFICATION,

  /* GRUPOS_ACADEMICOS */
  LIBRETA_GET_GRUPOS_ACADEMICOS,
  LIBRETA_GET_GRUPOS_ACADEMICOS_SUCCESS,
  LIBRETA_GET_GRUPOS_ACADEMICOS_FAIL,
  LIBRETA_SET_ID_GRUPO_ACADEMICO,
  LIBRETA_SET_OGRUPO_ACADEMICO,

  /* ALUMNOS_LIBRETA */
  LIBRETA_GET_ALUMNOS_LIBRETA,
  LIBRETA_GET_ALUMNOS_LIBRETA_SUCCESS,
  LIBRETA_GET_ALUMNOS_LIBRETA_FAIL,
  LIBRETA_SET_ID_ALUMNO,

  /* Tipos de eventos */
  LIBRETA_EVENTOS_GET_CATALOGOS,
  LIBRETA_EVENTOS_GET_CATALOGOS_SUCCESS,
  LIBRETA_EVENTOS_GET_CATALOGOS_FAIL,
  LIBRETA_EVENTOS_SET_TIPO_EVENTO,

  /* Comida */
  LIBRETA_EVENTOS_COMIDA_SET_CANTIDAD,

  /* Diapers */
  LIBRETA_EVENTOS_DIAPERS_SET_PIPI,
  LIBRETA_EVENTOS_DIAPERS_SET_POPO,

  /* Siesta */
  LIBRETA_EVENTOS_SIESTA_SET_HORA,
  LIBRETA_EVENTOS_SIESTA_SET_MINUTOS,
  LIBRETA_EVENTOS_SIESTA_SET_DURACION,

  /* FuealBath */
  LIBRETA_EVENTOS_BATH_SET,

  /* Observaciones */
  LIBRETA_EVENTOS_OBSERVACIONES_SET_ID,
  LIBRETA_EVENTOS_COMIDA_SET_OBSERVACIONES,
  LIBRETA_EVENTOS_DIAPERS_SET_OBSERVACIONES,
  LIBRETA_EVENTOS_SIESTA_SET_OBSERVACIONES,
  LIBRETA_EVENTOS_BATH_SET_OBSERVACIONES,
  LIBRETA_EVENTOS_ACADEMIA_SET_OBSERVACIONES,

  
  /* *********************************************************** */
  /* OBSERVACIONES */
  /* Insert */
  LIBRETA_CONFIG_ADD_OBSERVACION,
  LIBRETA_CONFIG_ADD_OBSERVACION_SUCCESS,
  LIBRETA_CONFIG_ADD_OBSERVACION_FAIL,

  /* Update */
  LIBRETA_CONFIG_UPDATE_OBSERVACION,
  LIBRETA_CONFIG_UPDATE_OBSERVACION_SUCCESS,
  LIBRETA_CONFIG_UPDATE_OBSERVACION_FAIL,

  /* Delete */
  LIBRETA_CONFIG_DELETE_OBSERVACION,
  LIBRETA_CONFIG_DELETE_OBSERVACION_SUCCESS,
  LIBRETA_CONFIG_DELETE_OBSERVACION_FAIL,

  /* RegistraEvento */
  LIBRETA_EVENT_REGISTRA,

  LIBRETA_EVENT_REGISTRA_SUCCESS,
  LIBRETA_EVENT_REGISTRA_COMIDA_SUCCESS,
  LIBRETA_EVENT_REGISTRA_DIAPERS_SUCCESS,
  LIBRETA_EVENT_REGISTRA_SIESTA_SUCCESS,
  LIBRETA_EVENT_REGISTRA_BATH_SUCCESS,
  LIBRETA_EVENT_REGISTRA_ACADEMIA_SUCCESS,

  LIBRETA_EVENT_REGISTRA_FAIL,

  /* Accion Set Estado Revision */
  LIBRETA_EVENTOS_SET_ENVIO,
  LIBRETA_EVENTOS_SET_REVISION,
  LIBRETA_EVENTOS_SET_EVENTOS,
  LIBRETA_EVENTOS_SET_REPORTES,
  LIBRETA_EVENTOS_SET_CONFIGURACION,

  /* Update Evento */
  LIBRETA_EVENT_UPDATE_EVENT,
  LIBRETA_EVENT_UPDATE_SUCCESS,
  LIBRETA_EVENT_UPDATE_FAIL,

  LIBRETA_EVENT_DELETE_EVENT,
  LIBRETA_EVENT_DELETE_SUCCESS,
  LIBRETA_EVENT_DELETE_FAIL,

  LIBRETA_GET_REGISTRO_EVENTOS_LIBRETA,
  LIBRETA_CLEAR_REGISTRO_EVENTOS_LIBRETA,
  LIBRETA_GET_REGISTRO_EVENTOS_LIBRETA_SUCCESS,
  LIBRETA_GET_REGISTRO_EVENTOS_LIBRETA_FAIL,

  LIBRETA_EVENTOS_SET_FECHAHORA,
  LIBRETA_EVENTOS_SET_ESTADO_ANIMO,

  LIBRETA_EVENT_OPENMODAL,
  LIBRETA_EVENT_CLOSEMODAL,
  LIBRETA_EVENT_SELECTACADEMICGROUP,
  LIBRETA_EVENT_SELECTUSER,
  LIBRETA_EVENT_COMIDA,
  LIBRETA_EVENT_COMIDA_FECHAHORA,
  LIBRETA_EVENT_COMIDA_HUMOR,
  LIBRETA_EVENT_COMIDA_CANTIDAD,
  LIBRETA_EVENT_COMIDA_OBSERVACIONES,
  LIBRETA_EVENT_COMIDA_CANCEL,
  LIBRETA_EVENT_COMIDA_REGISTRA,

  LIBRETA_EVENT_REVISION_SET_FECHA,
  LIBRETA_EVENT_REVISION_SET_FECHA_FUTURO,
  LIBRETA_EVENT_REVISION_SET_FECHA_PASADO,

  LIBRETA_ENVIO,
  LIBRETA_ENVIO_SUCCESS,
  LIBRETA_ENVIO_FAIL,

  LIBRETA_SET_DISPLAY_REPORTE_ENVIO,
  LIBRETA_UNSET_DISPLAY_REPORTE_ENVIO,  

  LIBRETA_CLEAR_REPORTE_ENVIO_LISTA_STATUS,
  LIBRETA_CLEAR_REFRESH_LISTA_STATUS,

  LIBRETA_REPORTES_SET_FECHA_INICIO,
  LIBRETA_REPORTES_SET_FECHA_FINAL,

} from "./actionTypes"


export const eventAction = () => {
  return {
    type: LIBRETA_EVENT,
    payload: {},
  }
}


// *************************************************************
//     DisplayNotification
export const actionSetDisplayNotification = (notification) => ({
  type: LIBRETA_SET_DISPLAY_NOTIFICATION,
  payload: notification
})
export const actionUnSetDisplayNotification = () => ({
  type: LIBRETA_UNSET_DISPLAY_NOTIFICATION
})

// *************************************************************
//     Grupos Academicos
export const getGruposAcademicosLibreta = oSolicitudBase => ({
  type: LIBRETA_GET_GRUPOS_ACADEMICOS,
  payload: oSolicitudBase,
})

export const getGruposAcademicosLibretaSuccess = gruposAcademicosLibreta => ({
  type: LIBRETA_GET_GRUPOS_ACADEMICOS_SUCCESS,
  payload: gruposAcademicosLibreta,
})

export const getGruposAcademicosLibretaFail = error => ({
  type: LIBRETA_GET_GRUPOS_ACADEMICOS_FAIL,
  payload: error,
})

export const setIdGrupoAcademicoLibretaSelected = id_Grupo_Academico => ({
  type: LIBRETA_SET_ID_GRUPO_ACADEMICO,
  payload: id_Grupo_Academico,
})

export const setLibretaOGrupoAcademicoSelected = oGrupo_Academico => ({
  type: LIBRETA_SET_OGRUPO_ACADEMICO,
  payload: oGrupo_Academico,
})
// *************************************************************


// *************************************************************
//     Alumnos
export const getAlumnosLibreta = oSolicitud => ({
  type: LIBRETA_GET_ALUMNOS_LIBRETA,
  payload: oSolicitud,
})

export const getAlumnosLibretaSuccess = alumnosLibreta => ({
  type: LIBRETA_GET_ALUMNOS_LIBRETA_SUCCESS,
  payload: alumnosLibreta,
})

export const getAlumnosLibretaFail = error => ({
  type: LIBRETA_GET_ALUMNOS_LIBRETA_FAIL,
  payload: error,
})

export const setAlumnoLibretaSelected = alumno => ({
  type: LIBRETA_SET_ID_ALUMNO,
  payload: alumno,
})
// *************************************************************

// *************************************************************
//     Cambio de estado
export const actionSetEstadoEnvio = () => ({
  type: LIBRETA_EVENTOS_SET_ENVIO
})
export const actionSetEstadoRevision = () => ({
  type: LIBRETA_EVENTOS_SET_REVISION
})
export const actionSetEstadoEventos = () => ({
  type: LIBRETA_EVENTOS_SET_EVENTOS
})
export const actionSetEstadoReportes = () => ({
  type: LIBRETA_EVENTOS_SET_REPORTES
})
export const actionSetEstadoConfiguracion = () => ({
  type: LIBRETA_EVENTOS_SET_CONFIGURACION
})

// *************************************************************
//     Registros de Eventos
export const actionGetRegistroEventos = oSolicitud => ({
  type: LIBRETA_GET_REGISTRO_EVENTOS_LIBRETA,
  payload: oSolicitud,
})

export const actionClearRegistroEventos = () => ({
  type: LIBRETA_CLEAR_REGISTRO_EVENTOS_LIBRETA
})

export const actionGetRegistroEventosSuccess = (registros) => ({
  type: LIBRETA_GET_REGISTRO_EVENTOS_LIBRETA_SUCCESS,
  payload: registros,
})
export const actionGetRegistroEventosFail = (error, displayNotification) => ({
  type: LIBRETA_GET_REGISTRO_EVENTOS_LIBRETA_FAIL,
  payload: { error, displayNotification },
})



export const getLibretaCatalogosEventos = oSolicitud => ({
  type: LIBRETA_EVENTOS_GET_CATALOGOS,
  payload: oSolicitud,
})

export const getLibretaCatalogosEventosSuccess = catalogos => ({
  type: LIBRETA_EVENTOS_GET_CATALOGOS_SUCCESS,
  payload: catalogos,
})

export const getLibretaCatalogosEventosFail = error => ({
  type: LIBRETA_EVENTOS_GET_CATALOGOS_FAIL,
  payload: error,
})

export const setTipoEvento = tipoEvento => ({
  type: LIBRETA_EVENTOS_SET_TIPO_EVENTO,
  payload: tipoEvento,
})

export const setEventosFechaHora = fechaHora => ({
  type: LIBRETA_EVENTOS_SET_FECHAHORA,
  payload: fechaHora,
})

export const setEventosEstadoAnimo = estadoAnimo => ({
  type: LIBRETA_EVENTOS_SET_ESTADO_ANIMO,
  payload: estadoAnimo,
})

/* Comida */
export const setEventosCantidadComida = id_Comida => ({
  type: LIBRETA_EVENTOS_COMIDA_SET_CANTIDAD,
  payload: id_Comida,
})

/* Diapers */
export const setEventosDiapersPipi = () => ({
  type: LIBRETA_EVENTOS_DIAPERS_SET_PIPI,
  payload: {},
})

export const setEventosDiapersPopo = () => ({
  type: LIBRETA_EVENTOS_DIAPERS_SET_POPO,
  payload: {},
})

/* Siesta */
export const setEventosSiestaHora = (hora) => ({
  type: LIBRETA_EVENTOS_SIESTA_SET_HORA,
  payload: hora,
})

export const setEventosSiestaMinutos = (minutos) => ({
  type: LIBRETA_EVENTOS_SIESTA_SET_MINUTOS,
  payload: minutos,
})

export const setEventosSiestaDuracion = (duracion) => ({
  type: LIBRETA_EVENTOS_SIESTA_SET_DURACION,
  payload: duracion,
})

/* FuealBath */
export const setEventosFuealBath = () => ({
  type: LIBRETA_EVENTOS_BATH_SET,
  payload: {},
})

// *************************************************************
/* Observaciones */
export const setEventosIdObservacion = id => ({
  type: LIBRETA_EVENTOS_OBSERVACIONES_SET_ID,
  payload: id,
})


export const setEventosComidaObservaciones = observacion => ({
  type: LIBRETA_EVENTOS_COMIDA_SET_OBSERVACIONES,
  payload: observacion,
})
export const setEventosDiapersObservaciones = observacion => ({
  type: LIBRETA_EVENTOS_DIAPERS_SET_OBSERVACIONES,
  payload: observacion,
})
export const setEventosSiestaObservaciones = observacion => ({
  type: LIBRETA_EVENTOS_SIESTA_SET_OBSERVACIONES,
  payload: observacion,
})
export const setEventosBathObservaciones = observacion => ({
  type: LIBRETA_EVENTOS_BATH_SET_OBSERVACIONES,
  payload: observacion,
})
export const setEventosAcademiaObservaciones = observacion => ({
  type: LIBRETA_EVENTOS_ACADEMIA_SET_OBSERVACIONES,
  payload: observacion,
})
// *************************************************************

export const selectAcademicGroupAction = user => {
  return {
    type: LIBRETA_EVENT_SELECTACADEMICGROUP,
    payload: { user },
  }
}

export const selectUserAction = user => {
  return {
    type: LIBRETA_EVENT_SELECTUSER,
    payload: { user },
  }
}

export const eventOpenModalAction = () => {
  return {
    type: LIBRETA_EVENT_OPENMODAL,
    payload: {},
  }
}

export const eventCloseModalAction = () => {
  return {
    type: LIBRETA_EVENT_CLOSEMODAL,
    payload: {},
  }
}


/* Registro Evento */
export const eventRegisterEventAction = (registro) => {
  return {
    type: LIBRETA_EVENT_REGISTRA,
    payload: registro,
  }
}

export const eventRegisterEventComidaActionSuccess = (eventoRegistrado) => ({
  type: LIBRETA_EVENT_REGISTRA_COMIDA_SUCCESS,
  payload: eventoRegistrado,
})

export const eventRegisterEventDiapersActionSuccess = (eventoRegistrado) => ({
  type: LIBRETA_EVENT_REGISTRA_DIAPERS_SUCCESS,
  payload: eventoRegistrado,
})

export const eventRegisterEventSiestaActionSuccess = (eventoRegistrado) => ({
  type: LIBRETA_EVENT_REGISTRA_SIESTA_SUCCESS,
  payload: eventoRegistrado,
})

export const eventRegisterEventBathActionSuccess = (eventoRegistrado) => ({
  type: LIBRETA_EVENT_REGISTRA_BATH_SUCCESS,
  payload: eventoRegistrado,
})

export const eventRegisterEventAcademicActionSuccess = (eventoRegistrado) => ({
  type: LIBRETA_EVENT_REGISTRA_ACADEMIA_SUCCESS,
  payload: eventoRegistrado,
})

export const eventRegisterEventActionFail = (error) => ({
  type: LIBRETA_EVENT_REGISTRA_FAIL,
  payload: error,
})

/* Update Evento */
export const actionUpdateEvent = (registro, tipoEvento) => ({
  type: LIBRETA_EVENT_UPDATE_EVENT,
  payload: { registro, tipoEvento },
})
export const actionUpdateEventSuccess = (eventoRegistrado) => ({
  type: LIBRETA_EVENT_UPDATE_SUCCESS,
  payload: eventoRegistrado,
})
export const actionUpdateEventFail = (error) => ({
  type: LIBRETA_EVENT_UPDATE_FAIL,
  payload: error,
})

/* Delete Evento */
export const actionDeleteEvent = (registro, tipoEvento) => ({
  type: LIBRETA_EVENT_DELETE_EVENT,
  payload: { registro, tipoEvento },
})
export const actionDeleteEventSuccess = (eventoRegistrado) => ({
  type: LIBRETA_EVENT_DELETE_SUCCESS,
  payload: eventoRegistrado,
})
export const actionDeleteEventFail = (error) => ({
  type: LIBRETA_EVENT_DELETE_FAIL,
  payload: error,
})

/* Revision Set Fecha */
export const actionSetFecha = () => ({
  type: LIBRETA_EVENT_REVISION_SET_FECHA,
})
export const actionSetFechaMasUnDia = () => ({
  type: LIBRETA_EVENT_REVISION_SET_FECHA_FUTURO,
})
export const actionSetFechaMenosUnDia = () => ({
  type: LIBRETA_EVENT_REVISION_SET_FECHA_PASADO,
})

/* Envio */
export const actionEnvio = (solicitud) => ({
  type: LIBRETA_ENVIO,
  payload: solicitud,
})
export const actionEnvioSuccess = (reporteEnvio) => ({
  type: LIBRETA_ENVIO_SUCCESS,
  payload: reporteEnvio,
})
export const actionEnvioFail = (reporteEnvio) => ({
  type: LIBRETA_ENVIO_FAIL,
  payload: reporteEnvio,
})


export const actionDisplayReporteEnvio = (display) => {
  if (display) {
    return ({
      type: LIBRETA_SET_DISPLAY_REPORTE_ENVIO
    })
  } else {
    return ({
      type: LIBRETA_UNSET_DISPLAY_REPORTE_ENVIO
    })

  }
}

export const ClearRefreshListaStatus = () => ({
  type: LIBRETA_CLEAR_REFRESH_LISTA_STATUS,
})
//
export const setLibretaReportesFechaInicio = (fecha) => ({
  type: LIBRETA_REPORTES_SET_FECHA_INICIO,
  payload: fecha,
})
export const setLibretaReportesFechaFinal = (fecha) => ({
  type: LIBRETA_REPORTES_SET_FECHA_FINAL,
  payload: fecha,
})


/* *********************************************************** */
/* OBSERVACIONES */
/* Insert */
export const insertObservacion = solicitud => ({
  type: LIBRETA_CONFIG_ADD_OBSERVACION,
  payload: solicitud,
})
export const insertObservacionSuccess = observacion => ({
  type: LIBRETA_CONFIG_ADD_OBSERVACION_SUCCESS,
  payload: observacion,
})
export const insertObservacionFail = error => ({
  type: LIBRETA_CONFIG_ADD_OBSERVACION_FAIL,
  payload: error,
})

/* update */
export const updateObservacion = solicitud => ({
  type: LIBRETA_CONFIG_UPDATE_OBSERVACION,
  payload: solicitud,
})
export const updateObservacionSuccess = observacion => ({
  type: LIBRETA_CONFIG_UPDATE_OBSERVACION_SUCCESS,
  payload: observacion,
})
export const updateObservacionFail = error => ({
  type: LIBRETA_CONFIG_UPDATE_OBSERVACION_FAIL,
  payload: error,
})

/* Delete */
export const deleteObservacion = solicitud => ({
  type: LIBRETA_CONFIG_DELETE_OBSERVACION,
  payload: solicitud,
})
export const deleteObservacionSuccess = observacion => ({
  type: LIBRETA_CONFIG_DELETE_OBSERVACION_SUCCESS,
  payload: observacion,
})
export const deleteObservacionFail = error => ({
  type: LIBRETA_CONFIG_DELETE_OBSERVACION_FAIL,
  payload: error,
})

/* OBSERVACIONES */
/* *********************************************************** */


