import React, { useState, useEffect } from 'react';
import { useDispatch } from "react-redux"
import { getoSolicitudBase } from "../../helpers/comunicabackend_helper"
import { map } from "lodash"
import Breadcrumbs from "components/Common/Breadcrumb2"
import toastr from "toastr"
import "toastr/build/toastr.min.css"
import Select from "react-select"

// import moment from "moment"
import pug from "pug"
import pugTemplate from './data/pugTemplate';
import oldPugTemplate from './data/oldPugTemplate';
import registroApi from './data/registroApi';

import CardContact from "../Contacts/card-contact"
import PropTypes from 'prop-types'
import classnames from "classnames";
import 'devextreme/dist/css/dx.light.css';
import { HtmlEditor } from 'devextreme-react/html-editor';
import { useAuthUser } from "../../store/auth/loginState/selectors"
import {
    Card,
    CardBody,
    CardSubtitle,
    CardText,
    CardTitle,
    Col,
    Container,
    Form,
    FormFeedback,
    FormGroup,
    Input,
    Label,
    Modal,
    Nav,
    NavItem,
    NavLink,
    Row,
    TabContent,
    Table,
    TabPane,
} from "reactstrap"

import DataGrid, {
    Button,
    Column,
    Editing,
    Lookup,
    RequiredRule
} from 'devextreme-react/data-grid';

import {
    List, ItemDragging
} from 'devextreme-react/list';
import {
    useIdLibretaObservacion,
    useLibretaEventosCatalogoObservaciones,
    useDisplayNotification,
} from "../../store/libreta/selectors"

/* oAlumno */
import { useError, useAlumno, useAlumnos } from "../../store/alumnos/selectors"
import { getAlumno as onGetAlumno, getAlumnos as onGetAlumnos } from "../../store/alumnos/actions"


import TextArea from 'devextreme-react/text-area';


import { useAuthUserGmtOffset } from "./../../store/auth/loginState/selectors"

import dataListadoCantidades from "./data/listadoCantidades"
import dataListadoEstadosAnimo from "./data/listadoEstadosAnimo"
import dataListadoObservaciones from "./data/listadoObservaciones"

import {
    useLibretaStatus,
} from "../../store/libreta/selectors"
import {
    insertObservacion as onActionInsertObservacion,
    updateObservacion as onActionUpdateObservacion,
    deleteObservacion as onActiondeleteObservacion,
    getLibretaCatalogosEventos as onGetLibretaCatalogosEventos,
    actionSetEstadoConfiguracion as onActionSetEstadoConfiguracion,

    actionSetDisplayNotification as onSetDisplayNotification,
    actionUnSetDisplayNotification as onUnSetDisplayNotification,
} from "../../store/libreta/actions"


import { select } from 'redux-saga/effects';

import MetaTags from "react-meta-tags";
import {

} from "reactstrap";

import { Link } from "react-router-dom";


const LibretaConfiguracion = () => {

    const formatDate = new Intl.DateTimeFormat('es-MX').format;

    
  const [openModal, setOpenModal] = useState(false);

    const textAreaAttributes = {
        id: 'elementId',
        class: 'class-name'
    }

    const [customActiveTab, setcustomActiveTab] = useState("0");
    const [customLibretaActiveTab, setcustomLibretaActiveTab] = useState("comida");
    const [pugMachote, setPugMachote] = useState(oldPugTemplate);
    const [html, setHtml] = useState("");
    const [verticalActiveTab, setverticalActiveTab] = useState("2");
    const [observaActiveTab, setobservaActiveTab] = useState("observaComida");

    const dispatch = useDispatch()

    const CatalogoObservaciones = useLibretaEventosCatalogoObservaciones()
    const authUser = useAuthUser();
    const displayNotification = useDisplayNotification()

    const oAlumno = useAlumno()
    const oListaAlumnos = useAlumnos()
    const oAlumnoError = useError()

    console.log("--------------| iniciando configuracion|--------------")
    console.log("oAlumno", oAlumno)
    console.log("oListaAlumnos", oListaAlumnos)
    console.log("oAlumnoError", oAlumnoError)

    //set Estado Configuracion
    useEffect(() => {
        dispatch(onActionSetEstadoConfiguracion())
    }, [])

    useEffect(() => {
        //console.log("useEffect::displayNotification: ", displayNotification)
        if (displayNotification !== undefined) {
            if (displayNotification.tipo) {
                switch (displayNotification.tipo) {
                    case "success":
                        toastr.success(
                            displayNotification.mensaje,
                            displayNotification.title
                        )
                        break
                    case "info":
                        toastr.info(displayNotification.mensaje, displayNotification.titulo)
                        break
                    case "warning":
                        toastr.warning(
                            displayNotification.mensaje,
                            displayNotification.titulo
                        )
                        break
                    case "error":
                        toastr.error(
                            displayNotification.mensaje,
                            displayNotification.titulo
                        )
                        break
                    default:
                        break
                }
            }
            dispatch(onUnSetDisplayNotification());
        }
    }, [dispatch, onUnSetDisplayNotification, displayNotification])

    // Catalogos
    useEffect(() => {
        if (CatalogoObservaciones && !CatalogoObservaciones.length) {
            const oSolicitudBase_Catalogos = getoSolicitudBase(
                "Libreta::useEffect",
                "onGetLibretaCatalogosEventos",
                [],
                []
            );
            dispatch(onGetLibretaCatalogosEventos(oSolicitudBase_Catalogos));
        }
    }, [dispatch, CatalogoObservaciones])

    // Alumnos
    useEffect(() => {
        if (oListaAlumnos && !oListaAlumnos.length) {
            const oSolicitudBase = getoSolicitudBase(
                "Libreta::useEffect",
                "onGetAlumnos",
                [],
                []
            );
            dispatch(onGetAlumnos(oSolicitudBase));
        }
    }, [dispatch, oListaAlumnos])

    const selectAlumno = alumno => {

        console.log('***************** |  fn select Alumno  |**************************** ')
        console.log('alumno: ', alumno)
    };

    const toggleObservacion = tab => {
        if (observaActiveTab !== tab) {
            setobservaActiveTab(tab);
        }
    };

    const cambioTextArea = (event) => {
        //console.log(event)
        setPugMachote(event)
    }

    const preview = () => {
        // console.log('preview')
        // console.log()

        var fn = pug.compile(pugMachote);
        var html = fn(registroApi);
        setHtml(html)
        // console.log('html')
        // console.log(html)
    }

    const toggleVertical = tab => {
        if (verticalActiveTab !== tab) {
            setverticalActiveTab(tab);
        }
    };

    const onEditingStart = (event) => {
        // console.log('onEditingStart')
        // console.log('event: ', event)
    }
    const onRowUpdated = (event) => {
        // console.log('onRowUpdated')
        // console.log('event: ', event)
    }
    const onSaving = (event) => {
        console.log('onSaving')
        console.log('onSaving event: ', event)
    }
    const onSaved = (event) => {

        console.log('-----------------------------------------------')
        console.log('onSaved')
        console.log('onSaved event: ', event)
        console.log('-----------------------------------------------')

        let datos = {}

        const oSolicitud = getoSolicitudBase(
            "Libreta::observaciones::onSaved",
            "",
            datos,
            [{ nombre: "", valor: "" }]
        );

        if (event.changes.length) {
            switch (event.changes[0].type) {
                case "insert":
                    datos = {
                        "id_Libreta_Observacion": -1,
                        "id_Colegio": authUser.idColegio,
                        "observacion": event.changes[0].data.observacion.toString(),
                        "id_Libreta_Area": event.changes[0].data.id_Libreta_Area,
                        "borrado": event.changes[0].data.borrado,
                        "indice": event.changes[0].data.indice
                    }
                    oSolicitud.Datos = JSON.stringify(datos)

                    console.log("oSolicitud: ", oSolicitud);
                    dispatch(onActionInsertObservacion(oSolicitud));
                    break;

                case "update":
                    datos = {
                        "id_Libreta_Observacion": event.changes[0].data.id_Libreta_Observacion,
                        "id_Colegio": authUser.idColegio,
                        "observacion": event.changes[0].data.observacion.toString(),
                        "id_Libreta_Area": event.changes[0].data.id_Libreta_Area,
                        "borrado": event.changes[0].data.borrado,
                        "indice": event.changes[0].data.indice
                    }
                    oSolicitud.Datos = JSON.stringify(datos)

                    console.log("oSolicitud: ", oSolicitud);
                    dispatch(onActionUpdateObservacion(oSolicitud));
                    break;


                case "remove":
                    datos = {
                        "id_Libreta_Observacion": event.changes[0].data.id_Libreta_Observacion,
                        "id_Colegio": authUser.idColegio,
                        "observacion": event.changes[0].data.observacion.toString(),
                        "id_Libreta_Area": event.changes[0].data.id_Libreta_Area,
                        "borrado": event.changes[0].data.borrado,
                        "indice": event.changes[0].data.indice
                    }
                    oSolicitud.Datos = JSON.stringify(datos)

                    console.log("oSolicitud: ", oSolicitud);
                    dispatch(onActiondeleteObservacion(oSolicitud));
                    break;

                default:
                    break;
            }

            // if (event.changes[0].type === 'update') {
            //     // datos = {
            //     //     id_Libreta_Registro_Academia: event.changes[0].data.id_Libreta_Registro_Academia,
            //     //     id_Alumno: event.changes[0].data.id_Alumno,
            //     //     id_Libreta_Estado_Animo: event.changes[0].data.id_Libreta_Estado_Animo,
            //     //     id_Libreta_Observacion: event.changes[0].data.id_Libreta_Observacion,
            //     //     Observacion: event.changes[0].data.observacion.toString(),
            //     //     Fecha: event.changes[0].data.fecha.toString(),
            //     // }
            //     // oSolicitud.Datos = JSON.stringify(datos)

            //     // console.log("oSolicitud: ", oSolicitud);
            //     // dispatch(onActionUpdateEvent(oSolicitud, crud));
            // } else {
            //     if (event.changes[0].type === 'remove') {

            //         // oSolicitud.Parametros.push({ nombre: "id_Libreta_Registro_Academia", valor: event.changes[0].key })

            //         // datos = {
            //         //     id_Libreta_Registro_Academia: event.changes[0].key,
            //         //     id_Alumno: 1,
            //         //     id_Libreta_Cantidad: 1,
            //         //     id_Libreta_Estado_Animo: 1,
            //         //     id_Libreta_Observacion: 1,
            //         //     Observacion: "Observa",
            //         // }
            //         // oSolicitud.Datos = JSON.stringify(datos)

            //         // console.log("oSolicitud: ", oSolicitud);
            //         // dispatch(onActionDeleteEvent(oSolicitud, "academia"));
            //     }
            // }
            console.log('-----------------------------------------------')
        }
    }
    const onEditCanceled = (event) => {
        // console.log('onEditCanceled')
        // console.log('onEditCanceled event: ', event)
    }
    const onEditorPreparing = (e) => {
        // console.log('onEditorPreparing')
        // console.log('onEditorPreparing e: ', e)
        // console.log('onEditorPreparing e: ', e)
        // console.log('onEditorPreparing e.editorInstance: ', e.editorInstance)
        // console.log('onEditorPreparing e.editorOptions: ', e.editorOptions)
        // if (e.dataField === "observacion" && e.parentType === "dataRow") {
        //     e.editorName = "dxTextArea";
        //     e.editorOptions.height = 200;
        // }       

        if (e.dataField === "observacion" && e.parentType === "dataRow") {
            //   e.editorName = "dxTextArea";
            //   e.editorOptions.height = "auto";
            //   e.editorOptions.onInitialized = function(event) {
            //     console.log("onInitialized::event:", event)
            //     e.editorOptions = event.component;
            //   };
        }
    }
    const onEditorPrepared = (e) => {
        // console.log('onEditorPrepared')
        // console.log('onEditorPrepared e: ', e)
        // console.log('onEditorPrepared e.editorInstance: ', e.editorInstance)
        // console.log('onEditorPrepared e.editorOptions: ', e.editorOptions)
        // if (e.dataField === "observacion" && e.parentType === "dataRow") {
        //     setTimeout(() => {
        //       e.editorOptions.height = e.component.getCellElement(e.row.rowIndex, 0).clientHeight
        //     });
        //   }        
    }

    const onDragStart = (e) => {
        console.log("onDragStart::event: ", e)
        const items = CatalogoObservaciones.filter((x) => x.id_Libreta_Area == e.element.id)
        console.log("items: ", items)
        console.log("items(e.fromIndex): ", items[e.fromIndex])
        e.itemData = items[e.fromIndex];
        //console.log("onDragStart::event: ", e)
    }

    const onReorder = (e) => {

        console.log("onReorder::event: ", e)
        console.log(e.itemElement.innerText, " from: ", e.fromIndex, " to: ", e.toIndex)


        if (e.itemData.id_Libreta_Observacion > 0) {

            let datos = {}

            const oSolicitud = getoSolicitudBase(
                "Libreta::observaciones::onSaved",
                "",
                datos,
                [
                    { nombre: "reorder", valor: "true" },
                    { nombre: "fromIndex", valor: e.fromIndex },
                    { nombre: "toIndex", valor: e.toIndex },
                ]
            );
            datos = {
                "id_Libreta_Observacion": e.itemData.id_Libreta_Observacion,
                "id_Colegio": authUser.idColegio,
                "observacion": e.itemData.observacion.toString(),
                "id_Libreta_Area": e.itemData.id_Libreta_Area,
                "borrado": e.itemData.borrado,
                "indice": e.toIndex
            }
            oSolicitud.Datos = JSON.stringify(datos)

            console.log("oSolicitud: ", oSolicitud);
            dispatch(onActionUpdateObservacion(oSolicitud));
        }

    }


    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true} className="">
                    <Row className="">
                        <Breadcrumbs title="Libreta"
                            breadcrumbItems={[
                                { title: "Eventos", link: "/libreta" },
                                { title: "Revisión", link: "/libreta/revision" },
                                { title: "Envío", link: "/libreta/envio" },
                                { title: "Configuración", link: "/libreta/configuracion" }
                            ]}
                            activo="Configuración" />
                    </Row>
                    <Row className="">
                        <Nav tabs className="nav-tabs-custom nav-justified">
                            {/* Alumnos */}
                            {/* <NavItem>
                                <NavLink
                                    style={{ cursor: "pointer" }}
                                    className={classnames({
                                        "mb-2": true,
                                        active: verticalActiveTab === "1",
                                    })}
                                    onClick={() => {
                                        toggleVertical("1");
                                    }}
                                >
                                    Alumnos
                                </NavLink>
                            </NavItem> */}
                            {/* Observaciones */}
                            <NavItem>
                                <NavLink
                                    style={{ cursor: "pointer" }}
                                    className={classnames({
                                        active: verticalActiveTab === "4",
                                    })}
                                    onClick={() => {
                                        toggleVertical("4");
                                    }}
                                >
                                    Observaciones
                                </NavLink>
                            </NavItem>
                            {/* Cantidades */}
                            <NavItem>
                                <NavLink
                                    style={{ cursor: "pointer" }}
                                    className={classnames({
                                        "mb-2": true,
                                        active: verticalActiveTab === "2",
                                    })}
                                    onClick={() => {
                                        toggleVertical("2");
                                    }}
                                >
                                    Cantidades
                                </NavLink>
                            </NavItem>
                            {/* Animos */}
                            <NavItem>
                                <NavLink
                                    style={{ cursor: "pointer" }}
                                    className={classnames({
                                        "mb-2": true,
                                        active: verticalActiveTab === "3",
                                    })}
                                    onClick={() => {
                                        toggleVertical("3");
                                    }}
                                >
                                    Ánimos
                                </NavLink>
                            </NavItem>
                            {/* Machote */}
                            <NavItem>
                                <NavLink
                                    style={{ cursor: "pointer" }}
                                    className={classnames({
                                        active: verticalActiveTab === "5",
                                    })}
                                    onClick={() => {
                                        toggleVertical("5");
                                    }}
                                >
                                    Machote
                                </NavLink>
                            </NavItem>
                        </Nav>
                    </Row>
                    <Row className="">
                        <TabContent
                            activeTab={verticalActiveTab}
                            className="text-muted mt-4 mt-md-0"
                        >
                            {/* 1 = Alumnos */}
                            <TabPane tabId="1">
                                <Container fluid={true} className=" p-1 m-1 bg-dark bg-soft" >
                                    <Row className="m-1 p-1">
                                        <Col className="text-start bg-danger bg-soft">
                                            <Select className="form-control"
                                                isClearable
                                                id="idComboBoxGruposAcademicos"
                                                // options={EventosCatalogoComidaObservaciones}
                                                // onChange={onDropdownChange}
                                                placeholder="Filtra por grupo académico">
                                            </Select>
                                        </Col>
                                        <Col className="d-flex align-items-end bg-warning bg-soft">
                                            Buscar:
                                        </Col>
                                        <Col className="d-flex align-items-end text-end bg-success bg-soft">
                                            <button type="button" className="btn-close"
                                                onClick={() => {
                                                }} aria-label="Close">
                                            </button>
                                            <button type="button" className="btn-close"
                                                onClick={() => {
                                                }} aria-label="Close">
                                            </button>
                                            <button type="button" className="btn-close"
                                                onClick={() => {
                                                }} aria-label="Close">
                                            </button>
                                        </Col>
                                    </Row>
                                    <Row className="g-0">

                                        {map(oListaAlumnos, (alumno, key) => (
                                            <CardContact user={alumno} key={"_alumno_" + key}
                                                Click={selectAlumno} />
                                        ))}

                                        {/* </Col> */}
                                    </Row>
                                </Container>
                            </TabPane>

                            {/* 4 = Observaciones */}
                            <TabPane tabId="4">
                                <Row>
                                    <Nav tabs className="nav-tabs-custom nav-justified">
                                        {/* 1 = Comida */}
                                        <NavItem
                                            key={1}>
                                            <NavLink
                                                style={{ cursor: "pointer" }}
                                                className={classnames({
                                                    active: observaActiveTab === 'observaComida',
                                                })}
                                                onClick={() => {
                                                    toggleObservacion("observaComida");
                                                }}
                                            >
                                                <span className="d-block d-sm-none">
                                                    <i className=""> Comida</i>
                                                </span>
                                                <span className="d-none d-sm-block">Comida</span>
                                            </NavLink>
                                        </NavItem>
                                        {/* 2 = Pañal */}
                                        <NavItem
                                            key={2}>
                                            <NavLink
                                                style={{ cursor: "pointer" }}
                                                className={classnames({
                                                    active: observaActiveTab === 'observaPañal',
                                                })}
                                                onClick={() => {
                                                    toggleObservacion("observaPañal");
                                                }}
                                            >
                                                <span className="d-block d-sm-none">
                                                    <i className=""> Pañal</i>
                                                </span>
                                                <span className="d-none d-sm-block">Pañal</span>
                                            </NavLink>
                                        </NavItem>
                                        {/* 3 = Siesta */}
                                        <NavItem
                                            key={3}>
                                            <NavLink
                                                style={{ cursor: "pointer" }}
                                                className={classnames({
                                                    active: observaActiveTab === 'observaSiesta',
                                                })}
                                                onClick={() => {
                                                    toggleObservacion("observaSiesta");
                                                }}
                                            >
                                                <span className="d-block d-sm-none">
                                                    <i className=""> Siesta</i>
                                                </span>
                                                <span className="d-none d-sm-block">Siesta</span>
                                            </NavLink>
                                        </NavItem>
                                        {/* 4 = Baño */}
                                        <NavItem
                                            key={4}>
                                            <NavLink
                                                style={{ cursor: "pointer" }}
                                                className={classnames({
                                                    active: observaActiveTab === 'observaBaño',
                                                })}
                                                onClick={() => {
                                                    toggleObservacion("observaBaño");
                                                }}
                                            >
                                                <span className="d-block d-sm-none">
                                                    <i className=""> Baño</i>
                                                </span>
                                                <span className="d-none d-sm-block">Baño</span>
                                            </NavLink>
                                        </NavItem>
                                        {/* 5 = Academia */}
                                        <NavItem
                                            key={5}>
                                            <NavLink
                                                style={{ cursor: "pointer" }}
                                                className={classnames({
                                                    active: observaActiveTab === 'observaAcademia',
                                                })}
                                                onClick={() => {
                                                    toggleObservacion("observaAcademia");
                                                }}
                                            >
                                                <span className="d-block d-sm-none">
                                                    <i className=""> Academia</i>
                                                </span>
                                                <span className="d-none d-sm-block">Academia</span>
                                            </NavLink>
                                        </NavItem>
                                    </Nav>
                                </Row>
                                <Row>
                                    <TabContent
                                        activeTab={observaActiveTab}
                                        className="text-muted mt-4 mt-md-0">

                                        {/* 1 = Comida */}
                                        <TabPane tabId="observaComida">
                                            <DataGrid
                                                id="gridContainerObservaComida"
                                                dataSource={CatalogoObservaciones.filter((x) => x.id_Libreta_Area === 1)}
                                                keyExpr="id_Libreta_Observacion"
                                                columnAutoWidth={true}
                                                showBorders={true}
                                                className='mb-1'
                                                wordWrapEnabled={true}

                                                onEditingStart={onEditingStart}
                                                onRowUpdated={onRowUpdated}
                                                onSaving={onSaving}
                                                onSaved={onSaved}
                                                onEditCanceled={onEditCanceled}
                                                onEditorPreparing={onEditorPreparing}
                                                onEditorPrepared={onEditorPrepared}
                                            >
                                                <Editing
                                                    mode="popup"
                                                    useIcons={true}
                                                    allowAdding={true}
                                                    allowUpdating={true}
                                                    allowDeleting={false}
                                                />
                                                <Column visible={true} type="buttons" width={110}>
                                                    <Button name="edit" />
                                                    <Button name="delete" />
                                                </Column>
                                                <Column dataField="indice" dataType="numeric" visible={false} caption="Indice" />
                                                <Column dataField="observacion" caption="Observación" ><RequiredRule /></Column>
                                                <Column dataField="borrado" caption="Borrado" />
                                            </DataGrid>
                                            Acomodo
                                            <List
                                                id='1'
                                                dataSource={CatalogoObservaciones.filter((x) => x.id_Libreta_Area === 1 && x.borrado === false)}
                                                displayExpr="observacion"
                                                keyExpr="id_Libreta_Observacion"
                                                height={400}>
                                                <ItemDragging
                                                    allowReordering={true}
                                                    onDragStart={onDragStart}
                                                    onReorder={onReorder}
                                                />
                                            </List>
                                        </TabPane>

                                        {/* 2 = Pañal */}
                                        <TabPane tabId="observaPañal">
                                            <DataGrid
                                                id="gridContainerObservaPañal"
                                                dataSource={CatalogoObservaciones.filter((x) => x.id_Libreta_Area === 2)}
                                                keyExpr="id_Libreta_Observacion"
                                                columnAutoWidth={true}
                                                showBorders={true}
                                                className='mb-1'
                                                wordWrapEnabled={true}

                                                onEditingStart={onEditingStart}
                                                onRowUpdated={onRowUpdated}
                                                onSaving={onSaving}
                                                onSaved={onSaved}
                                                onEditCanceled={onEditCanceled}
                                                onEditorPreparing={onEditorPreparing}
                                                onEditorPrepared={onEditorPrepared}
                                            >
                                                <Editing
                                                    mode="popup"
                                                    useIcons={true}
                                                    allowAdding={true}
                                                    allowUpdating={true}
                                                    allowDeleting={false}
                                                />
                                                <Column visible={true} type="buttons" width={110}>
                                                    <Button name="edit" />
                                                    <Button name="delete" />
                                                </Column>
                                                <Column dataField="indice" visible={false} dataType="numeric" caption="Indice" />
                                                <Column dataField="observacion" caption="Observación" ><RequiredRule /></Column>
                                                <Column dataField="borrado" caption="Borrado" />
                                            </DataGrid>
                                            Acomodo
                                            <List
                                                id='2'
                                                dataSource={CatalogoObservaciones.filter((x) => x.id_Libreta_Area === 2 && x.borrado === false)}
                                                displayExpr="observacion"
                                                keyExpr="id_Libreta_Observacion"
                                                height={400}>
                                                <ItemDragging
                                                    allowReordering={true}
                                                    onDragStart={onDragStart}
                                                    onReorder={onReorder}
                                                />
                                            </List>
                                        </TabPane>

                                        {/* 3 = Siesta */}
                                        <TabPane tabId="observaSiesta">
                                            <DataGrid
                                                id="gridContainerObservaSiesta"
                                                dataSource={CatalogoObservaciones.filter((x) => x.id_Libreta_Area === 3)}
                                                keyExpr="id_Libreta_Observacion"
                                                columnAutoWidth={true}
                                                showBorders={true}
                                                className='mb-1'
                                                wordWrapEnabled={true}

                                                onEditingStart={onEditingStart}
                                                onRowUpdated={onRowUpdated}
                                                onSaving={onSaving}
                                                onSaved={onSaved}
                                                onEditCanceled={onEditCanceled}
                                                onEditorPreparing={onEditorPreparing}
                                                onEditorPrepared={onEditorPrepared}
                                            >
                                                <Editing
                                                    mode="popup"
                                                    useIcons={true}
                                                    allowAdding={true}
                                                    allowUpdating={true}
                                                    allowDeleting={false}
                                                />
                                                <Column visible={true} type="buttons" width={110}>
                                                    <Button name="edit" />
                                                    <Button name="delete" />
                                                </Column>
                                                <Column dataField="indice" visible={false} dataType="numeric" caption="Indice" />
                                                <Column dataField="observacion" caption="Observación" ><RequiredRule /></Column>
                                                <Column dataField="borrado" caption="Borrado" />
                                            </DataGrid>
                                            Acomodo
                                            <List
                                                id='3'
                                                dataSource={CatalogoObservaciones.filter((x) => x.id_Libreta_Area === 3 && x.borrado === false)}
                                                displayExpr="observacion"
                                                keyExpr="id_Libreta_Observacion"
                                                height={400}>
                                                <ItemDragging
                                                    allowReordering={true}
                                                    onDragStart={onDragStart}
                                                    onReorder={onReorder}
                                                />
                                            </List>
                                        </TabPane>

                                        {/* 4 = Baño */}
                                        <TabPane tabId="observaBaño">
                                            <DataGrid
                                                id="gridContainerObservaBaño"
                                                dataSource={CatalogoObservaciones.filter((x) => x.id_Libreta_Area === 4)}
                                                keyExpr="id_Libreta_Observacion"
                                                columnAutoWidth={true}
                                                showBorders={true}
                                                className='mb-1'
                                                wordWrapEnabled={true}

                                                onEditingStart={onEditingStart}
                                                onRowUpdated={onRowUpdated}
                                                onSaving={onSaving}
                                                onSaved={onSaved}
                                                onEditCanceled={onEditCanceled}
                                                onEditorPreparing={onEditorPreparing}
                                                onEditorPrepared={onEditorPrepared}
                                            >
                                                <Editing
                                                    mode="popup"
                                                    useIcons={true}
                                                    allowAdding={true}
                                                    allowUpdating={true}
                                                    allowDeleting={false}
                                                />
                                                <Column visible={true} type="buttons" width={110}>
                                                    <Button name="edit" />
                                                    <Button name="delete" />
                                                </Column>
                                                <Column dataField="indice" visible={false} dataType="numeric" caption="Indice" />
                                                <Column dataField="observacion" caption="Observación" ><RequiredRule /></Column>
                                                <Column dataField="borrado" caption="Borrado" />
                                            </DataGrid>
                                            Acomodo
                                            <List
                                                id='4'
                                                dataSource={CatalogoObservaciones.filter((x) => x.id_Libreta_Area === 4 && x.borrado === false)}
                                                displayExpr="observacion"
                                                keyExpr="id_Libreta_Observacion"
                                                height={400}>
                                                <ItemDragging
                                                    allowReordering={true}
                                                    onDragStart={onDragStart}
                                                    onReorder={onReorder}
                                                />
                                            </List>
                                        </TabPane>

                                        {/* 5 = Academia */}
                                        <TabPane tabId="observaAcademia">
                                            <DataGrid
                                                id="gridContainerObservaAcademia"
                                                dataSource={CatalogoObservaciones.filter((x) => x.id_Libreta_Area === 5)}
                                                keyExpr="id_Libreta_Observacion"
                                                columnAutoWidth={true}
                                                showBorders={true}
                                                className='mb-1'
                                                wordWrapEnabled={true}

                                                onEditingStart={onEditingStart}
                                                onRowUpdated={onRowUpdated}
                                                onSaving={onSaving}
                                                onSaved={onSaved}
                                                onEditCanceled={onEditCanceled}
                                                onEditorPreparing={onEditorPreparing}
                                                onEditorPrepared={onEditorPrepared}
                                                activeStateEnabled={false}
                                            >
                                                <Editing
                                                    mode="popup"
                                                    useIcons={true}
                                                    allowAdding={true}
                                                    allowUpdating={true}
                                                    allowDeleting={false}
                                                />
                                                <Column visible={true} type="buttons" width={110}>
                                                    <Button name="edit" />
                                                    <Button name="delete" />
                                                </Column>
                                                <Column dataField="indice" visible={false} dataType="numeric" caption="Indice" />
                                                <Column dataField="observacion" caption="Observación" ><RequiredRule /></Column>
                                                <Column dataField="borrado" caption="Borrado" />
                                            </DataGrid>
                                            Acomodo
                                            <List
                                                id='5'
                                                dataSource={CatalogoObservaciones.filter((x) => x.id_Libreta_Area === 5 && x.borrado === false)}
                                                displayExpr="observacion"
                                                keyExpr="id_Libreta_Observacion"
                                                height={400}>
                                                <ItemDragging
                                                    allowReordering={true}
                                                    onDragStart={onDragStart}
                                                    onReorder={onReorder}
                                                />
                                            </List>
                                        </TabPane>
                                    </TabContent>
                                </Row>
                            </TabPane>

                            {/* 2 = Cantidades */}
                            <TabPane tabId="2">
                                <DataGrid allowColumnReordering={true}
                                    dataSource={dataListadoCantidades}
                                    keyExpr="id_Libreta_Cantidad"
                                    columnAutoWidth={true}
                                    showBorders={true}
                                    className='mb-1'
                                    wordWrapEnabled={true}

                                    onEditingStart={onEditingStart}
                                    onRowUpdated={onRowUpdated}
                                    onSaving={onSaving}
                                    onSaved={onSaved}
                                    onEditCanceled={onEditCanceled}
                                >
                                    <Editing
                                        mode="popup"
                                        useIcons={true}
                                        allowAdding={true}
                                        allowUpdating={true}
                                        allowDeleting={false}
                                    />
                                    <Column dataField="nombre_Cantidad" dataType="text" caption="Nombre"><RequiredRule /></Column>
                                    <Column dataField="icono" dataType="text" caption="icono" />
                                    <Column dataField="borrado" dataType="boolean" caption="Borrado" />
                                </DataGrid>
                            </TabPane>

                            {/* 3 = Estados de ánimo */}
                            <TabPane tabId="3">
                                <DataGrid allowColumnReordering={true}
                                    dataSource={dataListadoEstadosAnimo}
                                    keyExpr="id_Libreta_Estado_Animo"
                                    columnAutoWidth={true}
                                    showBorders={true}
                                    className='mb-1'
                                    wordWrapEnabled={true}

                                    onEditingStart={onEditingStart}
                                    onRowUpdated={onRowUpdated}
                                    onSaving={onSaving}
                                    onSaved={onSaved}
                                    onEditCanceled={onEditCanceled}
                                >
                                    <Editing
                                        mode="row"
                                        useIcons={true}
                                        allowAdding={true}
                                        allowUpdating={true}
                                        allowDeleting={false}
                                    />
                                    <Column dataField="nombre_Estado_Animo" dataType="text" caption="Nombre" />
                                    <Column dataField="icono" dataType="text" caption="icono" />
                                    <Column dataField="borrado" dataType="boolean" caption="Borrado" />
                                </DataGrid>
                            </TabPane>


                            {/* 5 = Machote */}
                            <TabPane tabId="5">
                                <TextArea
                                    onValueChange={cambioTextArea}
                                    defaultValue={pugMachote}
                                    height={600}
                                />

                                <p className="mb-0">

                                    <button className="btn btn-primary  btn btn-primary" onClick={preview}>
                                        Preview
                                    </button>
                                </p>
                                {/* <p>------------------------------</p>
                                {html}
                                <p>------------------------------</p> */}
                                <div dangerouslySetInnerHTML={{ __html: html }} />
                            </TabPane>

                        </TabContent>
                    </Row>
                    <Modal
                    isOpen={openModal}
                    backdrop={'static'}
                    id="detalleAlumno"
                    >

                    </Modal>

                </Container>
            </div>
        </React.Fragment>
    );
}

LibretaConfiguracion.propTypes = {
    employee: PropTypes.any,
}

export default LibretaConfiguracion

