import React from "react"
import PropTypes from 'prop-types'
import { Link } from "react-router-dom"
import { Row, Col, BreadcrumbItem } from "reactstrap"
import classnames from "classnames";

const Breadcrumb = props => {
  const { title, breadcrumbItems, activo } = props
  const itemLength = breadcrumbItems.length

  return (
    <Row>
      <Col className="g-0 col-auto ">
        <h5 className="m-1 font-size-8">{title}</h5>
        {/* <div className="page-title-box d-flex align-items-center justify-content-between">
          
        </div> */}
      </Col>
      <Col className="g-0 ">
        {/* <div className="page-title-box d-flex align-items-center justify-content-between"> */}
        {/* <div className="page-title-right m-0">
          
        </div> */}
        <div className="float-end m-0">

          <ol className="breadcrumb m-1  p-0">
            {breadcrumbItems.map((item, key) => (
              <BreadcrumbItem key={key} className={classnames({
                " text-decoration-underline ": item.title === activo,
              })}>
                <Link to={item.link}>{item.title}</Link>
              </BreadcrumbItem>
            ))}
          </ol>
        </div>
        {/* </div> */}
      </Col>
    </Row>
  )
}

Breadcrumb.propTypes = {
  breadcrumbItems: PropTypes.array,
  title: PropTypes.string,
  link: PropTypes.string,
  click: PropTypes.any,
  activo: PropTypes.string,
}

export default Breadcrumb
